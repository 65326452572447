import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import ModalDefault from 'components/common/Modal/ModalDefault'
import Button from 'components/common/Button'
import Active from 'assets/svg/active.svg'
import ActiveGrey from 'assets/svg/active-grey.svg'
import Scanner from 'components/common/Scanner'
import { connect } from 'react-redux'

const ModalScanDamageLocation = (props) => {
  const { isOpen, modalInfo } = props

  const [isScanned, setIsScanned] = useState(false)

  if (!isOpen) {
    return <></>
  }

  const onScan = (barcode) => {
    if (barcode.toString() === modalInfo.damageLocation.upc.toString()) {
      setIsScanned(true)
      setTimeout(modalInfo.onSuccess, 1500)
    } else {
      setIsScanned(false)
      modalInfo.onFailure()
    }
  }

  return (
    <ModalDefault isOpen={isOpen}>
      <Scanner needToRemoveListener={isScanned} onReceiveData={onScan} />
      <div
        className={`modal modal--confirm ${
          modalInfo.className ? modalInfo.className : ''
        }`}
      >
        <div className="text text--damaged">Damage Location</div>
        <div className="dock">
          <span className="dock__label">LOC</span>
          <span className="dock__name">{`${modalInfo.damageLocation.aisle}-${modalInfo.damageLocation.bay}`}</span>
          {isScanned ? (
            <Active className="dock__icon" />
          ) : (
            <ActiveGrey className="dock__icon" />
          )}
        </div>
        <div className="button">
          <Button onClick={modalInfo.onBack}>Back</Button>
        </div>
      </div>
    </ModalDefault>
  )
}

ModalScanDamageLocation.propTypes = {
  isOpen: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalScanDamageLocation',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalScanDamageLocation',
  ),
})

export default connect(mapStateToProps)(ModalScanDamageLocation)
