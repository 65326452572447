import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import ModalDefault from 'components/common/Modal/ModalDefault'
import Button from 'components/common/Button'
import { isEmptyObject } from 'helpers/index'
import CancelIcon from 'assets/svg/cancel.svg'
import CancelIconIPod from 'assets/svg/cancel--ipod.svg'
import './style.scss'
import NewIcon from 'assets/svg/new-icon.svg'
import { connect } from 'react-redux'
import { TextField } from "@material-ui/core";

const ModalWrongOrAdditionalProduct = ({ productInfo, isOpen, modalInfo }) => {
  const [addNewCaseQuantity, setAddNewCaseQuantity] = useState("");
  const [isCaseQuantityValid, setIsCaseQuantityValid] = useState(true);
  const [isCaseQuantityBlank, setIsCaseQuantityBlank] = useState(true);
  if (isEmptyObject(productInfo) || !isOpen) {
    return <></>
  }

  function handleQuantityChange(caseQuantity) {
    if (caseQuantity) {
      setIsCaseQuantityBlank(false)
    } else {
      setIsCaseQuantityBlank(true)
    }

    if ( (caseQuantity > 0) || (!caseQuantity) ){
      setAddNewCaseQuantity(caseQuantity);
      setIsCaseQuantityValid(true);
    } else if (caseQuantity < 0) {
      setAddNewCaseQuantity('0');
      setIsCaseQuantityValid(false);
    } else {
      setAddNewCaseQuantity(caseQuantity);
      setIsCaseQuantityValid(false);
    }
  }

  function handleAddButtonClick() {
    setAddNewCaseQuantity('');
    setIsCaseQuantityValid(false);
    modalInfo.onAccept(productInfo.product.products_id, addNewCaseQuantity);

  }

  function handleCloseButtonClick() {
    setAddNewCaseQuantity('');
    setIsCaseQuantityValid(false);
    modalInfo.onClose();

  }

  return (
    <ModalDefault isOpen={isOpen}>
      <div className="modal modal--wrong-product">
        <NewIcon className="svg"/>
        <div className="title">{productInfo.product.products_model}</div>
        <div className="text">{productInfo.description.products_name}
          <div className="case-count" >
            <br/> <br/>
            <TextField
              name="quantity"
              type={'number'}
              variant="outlined"
              label={'Case Count:'}
              value={addNewCaseQuantity}
              error={!isCaseQuantityValid}
              helperText={(!isCaseQuantityValid) ? 'Case Count is invalid' : ''}
              id="quantity"
              autoComplete={'off'}
              margin={'none'}
              onChange={event => handleQuantityChange(event.target.value)}
              inputProps={{
                style: {
                  textAlign: "center",
                  inputMode: 'numeric',
                }
              }}
            />
          </div>
        </div>

        <Button
          disabled={!isCaseQuantityValid || isCaseQuantityBlank}
          onClick={() => handleAddButtonClick()}
        >
          Add to List
        </Button>
        <div onClick={() => handleCloseButtonClick()} className="btn--close">
          {window.innerWidth < 768 ? <CancelIconIPod/> : <CancelIcon/>}
        </div>
      </div>
    </ModalDefault>
  )
}

ModalWrongOrAdditionalProduct.propTypes = {
  productInfo: PropTypes.object,
  modalInfo: PropTypes.object,
  isOpen: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalWrongOrAdditionalProduct',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalWrongOrAdditionalProduct',
  ),
  productInfo: state.productInfo.item,
})

export default connect(mapStateToProps)(ModalWrongOrAdditionalProduct)
