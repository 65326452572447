import { types } from './actions'

const INIT = {
  warehouseLocation: {},
  pallet: {},
}

const inventoryReducer = (state = INIT, action) => {
  const { type, payload } = action

  switch (type) {
    case types.CLEAR_INVENTORY_LOCATION:
      return {
        ...state,
        warehouseLocation: payload,
      }
    case types.FETCH_INVENTORY_LOCATION_SUCCESS:
      return {
        ...state,
        warehouseLocation: payload,
      }
    case types.FETCH_PALLET_SUCCESS:
      return {
        ...state,
        warehouseLocation: payload.location,
        pallet: payload.pallet,
      }
    case types.DELETE_PALLET_SUCCESS:
      return {
        ...state,
        warehouseLocation: payload,
      }
    case types.RESET_INVENTORY_LOCATION_SUCCESS:
      return {
        ...state,
        warehouseLocation: payload,
      }
    case types.FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        warehouseLocation: payload,
      }
    case types.SAVE_PRODUCT_SUCCESS:
      return {
        ...state,
        warehouseLocation: payload,
      }
    case types.SAVE_PALLET_SUCCESS:
      return {
        ...state,
        warehouseLocation: payload,
        pallet: null,
      }
    case types.COPY_PRODUCT_TO_STAGE_SUCCESS:
      return {
        ...state,
        warehouseLocation: payload,
      }
    default:
      return state
  }
}

export default inventoryReducer
