import request from 'helpers/index'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}product`

export const createDamageCases = (payload) =>
  request({
    url: `${apiEndpoint}/product-cases/add-damaged`,
    method: 'POST',
    data: payload,
  })
