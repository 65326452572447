import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import * as query from 'querystringify'
import * as PropTypes from 'prop-types'
import SelectionList from './SelectionList'
import Header from 'components/common/Header'
import Button from 'components/common/Button'
import RefreshIcon from 'assets/svg/refresh.svg'
import { ORDER_PICK_STATUSES } from 'constants/order'
import { TASK_STATUSES, TASK_TYPES } from 'constants/taskAssignment'
import {
  HOME_PAGE,
  START_POWER_PICKING_PAGE,
  POWER_PICKING_LIST_PAGE,
  POWER_PICKING_PULLSHEET_PAGE,
} from 'constants/routes'
import { ORDER_PALLET_STATUSES } from 'constants/orderPalletStatuses'
import { LOCATION_TYPE } from 'constants/locationTypes'
import { closeModal, resetModals, showModal } from 'store/modal/actions'
import { fetchLocationsForLaneStart } from 'store/location/actions'
import { date, dateToFormat, isEmptyObject, redirect } from 'helpers/index'

import {
  createOrderPallet,
  createTempProductsWithQuantity,
  fetchOneOrderPalletStart,
  updateOrderPalletStart,
} from 'store/orderPallet/actions'
import {
  createShippingLane,
  validateShippingLane,
} from 'store/shippingLane/actions'
import ExclamationSmall from 'assets/svg/exclamation-sign.svg'
import { createPickupLane, validatePickupLane } from 'store/pickupLane/actions'
import { updateTaskAssignmentWithoutFetch } from 'store/taskAssignment/actions'
import { updateOrderPickStatusStart } from 'store/orderPickStatus/actions'
import { MAX_PALLETS_PER_TRUCK } from 'constants/order'

const PowerPickingMoveToLanePage = (props) => {
  const {
    user,
    match,
    locations,
    orderPallet,

    fetchLocationsForLane,
    fetchOneOrderPallet,
    updateOrderPallet,
    updateTaskAssignment,
    updateOrderPickStatus,
    createOrderPallet,
    createPickupLane,
    createShippingLane,
    validateShippingLane,
    validatePickupLane,
    createTempProductsWithQuantity,
    resetModals,
    showModal,
    closeModal,
  } = props

  const orderPalletId = match.params.orderPalletId
  const powerPickProcessId =
    match.params.powerPickProcessId === 'null'
      ? null
      : match.params.powerPickProcessId
    const nextTaskAssignmentId =
    match.params.nextTaskAssignmentId === 'null'
      ? null
      : match.params.nextTaskAssignmentId

  const onMount = () => {
    showModal({
      name: 'modalPending',
    })

    fetchOneOrderPallet(orderPalletId)
  }

  const [selectedLane, setSelectedLane] = useState({})
  const [selectedBay, setSelectedBay] = useState({})
  const [lastLaneSelected, setLastLaneSelected] = useState({})
  const ref = useCallback((node) => {
    if (node !== null) {
      node.scrollIntoView()
    }
  }, [])

  const onLocationsUpdate = () => {
    if (locations.length && orderPallet) {
      const orderLanes = locations.filter(
        (location) =>
          location.lane &&
          location.lane.order_pallet.order_id == orderPallet.order_id,
      )

      if (orderLanes.length) {
        const lastLaneSelected = orderLanes.reduce((a, b) =>
          a.lane.created_date > b.lane.created_date ? a : b,
        )
        setLastLaneSelected(lastLaneSelected.regular_location.aisle_id.name)
      }

      closeModal('modalPending')
    }
  }

  const getLocationsTypeId = () => {
    if (orderPallet) {
      return 'PICKUP' ===
        orderPallet.order.shipping_option_id
        ? LOCATION_TYPE.PICKUP_LANE_ID
        : LOCATION_TYPE.SHIPPING_TYPE_ID
    }

    return null
  }

  const getParamsForLocations = () => {
    return query.stringify(
      {
        location_type_id: getLocationsTypeId(),
      },
      true,
    )
  }

  const endCurrentOrderTaskAssignment = () => {
    if (orderTaskAssignment) {
      updateTaskAssignment({
        id: orderTaskAssignment.id,
        data: {
          id: orderTaskAssignment.id,
          end_date: dateToFormat(date(), 'YYYY-MM-DD'),
          status: TASK_STATUSES.FINISHED_TYPE,
        },
      })
    }
  }

  const createNextOrderPallet = () => {
    //TODO: need to correctly determine nextPickPalletId if multiple users
    const nextPickPalletId = orderPallet.pallet_nbr + 1

    createOrderPallet({
      data: {
        order_id: orderPallet.order_pick_status.order_id,
        status_id: ORDER_PICK_STATUSES.STATUS_PICKING_PALLET,
        pick_pallet_id: nextPickPalletId,
        pallet_weight: null,
        warehouse_location_id: null,
      },
    })
  }

  const redirectToPicking = () => {
    if (isAllCasesPickedFromCurrentPickLocation) {
      redirect(START_POWER_PICKING_PAGE.replace(':id', nextTaskAssignmentId))
    } else {
      redirect(
        START_POWER_PICKING_PAGE.replace(
          ':id',
          orderProductPickLocation.task_assignment_id,
        ),
      )
    }
  }

  const redirectToPowerPick = () => {
    redirect(POWER_PICKING_PULLSHEET_PAGE.replace(':id', powerPickProcessId))
  }

  const isPreLastPallet = () =>
    orderPallet.pallet_nbr === MAX_PALLETS_PER_TRUCK - 1
  const isLastPallet = () => orderPallet.pallet_nbr === MAX_PALLETS_PER_TRUCK
  const isAllCasesPicked = () =>
    orderPallet.orderProductPickLocations.filter(
      (item) =>
        item.location_pick_quantity !==
        item.picked_quantity + item.unavailable_quantity,
    ).length === 0

  const isTheEndOfTheOrder = () => {
    if (!isEmptyObject(orderPallet)) {
      return isAllCasesPicked() || isLastPallet()
    }

    return false
  }

  const onDone = () => {
    const selectedLocation = availableLocations.find(
      (location) =>
        location.regular_location.aisle_id.name == selectedLane &&
        location.regular_location.bay_id.name == selectedBay,
    )

    updateOrderPallet({
      request: {
        id: orderPalletId,
        data: {
          id: orderPalletId,
          warehouse_location_id: selectedLocation.id,
          status_id: ORDER_PALLET_STATUSES.SHIPPING_LANE_TYPE,
        },
      },
    })

    if (getLocationsTypeId() === LOCATION_TYPE.SHIPPING_TYPE_ID) {
      createShippingLane({
        data: {
          warehouse_location_id: selectedLocation.id,
          order_pallet_id: orderPalletId,
          created_by_user_id: user.warehouseStaffId,
        },
      })
    } else {
      createPickupLane({
        data: {
          warehouse_location_id: selectedLocation.id,
          order_pallet_id: orderPalletId,
          created_by_user_id: user.warehouseStaffId,
        },
      })
    }


    switch (true) {
      //last pallet but not all order products picked -> create new order
      case isLastPallet() && !isAllCasesPicked():
        createTempProductsWithQuantity({
          data: {
            order_pallet_id: orderPallet.id,
          },
        })

        endCurrentOrderTaskAssignment()
        endAllLocationPickingTasksAssignments()

        updateOrderPickStatus({
          id: orderPallet.order_pick_status.id,
          data: {
            id: orderPallet.order_pick_status.id,
            status_id: ORDER_PICK_STATUSES.STATUS_COMPLETED_ORDER,
            order_id: orderPallet.order_pick_status.order_id,
          },
        })

        redirect(POWER_PICKING_LIST_PAGE)
        break
      case isTheEndOfTheOrder():
        endCurrentOrderTaskAssignment()
        
        updateOrderPickStatus({
          id: orderPallet.order_pick_status.id,
          data: {
            id: orderPallet.order_pick_status.id,
            status_id: ORDER_PICK_STATUSES.STATUS_COMPLETED_ORDER,
            order_id: orderPallet.order_pick_status.order_id,
          },
        })

        redirect(POWER_PICKING_LIST_PAGE)

        break
      //pick pallet < 9
      case !isLastPallet() && !isPreLastPallet():
        createNextOrderPallet()

        if (powerPickProcessId) {
          redirectToPowerPick()
        } else {
          redirectToPicking()
        }
        break
      //pick pallet = 9
      case isPreLastPallet():
        if (user.isManager()) {
          createNextOrderPallet()
          if (powerPickProcessId) {
            redirectToPowerPick()
          } else {
            redirectToPicking()
          }
        }

        if (!user.isManager()) {
          endCurrentOrderTaskAssignment()
          endAllLocationPickingTasksAssignments()
          redirect(POWER_PICKING_LIST_PAGE)
        }

        break
      //pick pallet = 10
      case isLastPallet():
        endCurrentOrderTaskAssignment()
        redirect(POWER_PICKING_LIST_PAGE)

        break
      default:
        break
    }
  }

  const resetBay = () => {
    setSelectedBay({})
    resetModals()
  }

  const onValidateAfterAction = (data) => {
    if (data.success) {
      onDone()
    } else {
      showModal({
        name: 'modalWarning',
        text: data.message,
        onAccept: resetBay,
        onDeny: resetBay,
        className: 'modal--tasks modal--pause',
        modalIcon: <ExclamationSmall />,
      })
    }
  }

  const onSelectBay = () => {
    if (!isEmptyObject(selectedBay)) {
      showModal({
        name: 'modalPending',
      })

      const selectedLocation = availableLocations.find(
        (location) =>
          location.regular_location.aisle_id.name == selectedLane &&
          location.regular_location.bay_id.name == selectedBay,
      )

      if (getLocationsTypeId() === LOCATION_TYPE.SHIPPING_TYPE_ID) {
        validateShippingLane({
          request: {
            data: {
              warehouse_location_upc: selectedLocation.upc,
            },
          },
          afterAction: onValidateAfterAction,
        })
      } else {
        validatePickupLane({
          request: {
            data: {
              warehouse_location_upc: selectedLocation.upc,
            },
          },
          afterAction: onValidateAfterAction,
        })
      }
    }
  }

  useEffect(onMount, [])

  let timeoutId
  const reloadPage = () => {
    timeoutId = setTimeout(() => {
      if (orderPallet) {
        fetchLocationsForLane(getParamsForLocations())
      }
      reloadPage()
    }, 10000)
  }
  useEffect(() => {
    reloadPage()
    return () => clearTimeout(timeoutId)
  }, [])
  
  const onOrderPalletUpdate = () => {
    if (orderPallet) {
      fetchLocationsForLane(getParamsForLocations())
    }
  }


  useEffect(onOrderPalletUpdate, [orderPallet])
  useEffect(onLocationsUpdate, [locations])
  useEffect(onSelectBay, [selectedBay])

  const headerText = isEmptyObject(selectedLane) ? 'Select Lane' : selectedLane

  const onRefresh = () => {
    resetModals()
    setSelectedLane({})
    setSelectedBay({})
    setLastLaneSelected({})
    onMount()
  }

  const selectLane = (item) => {
    setSelectedLane(item)
  }

  const selectBay = (item) => {
    setSelectedBay(item)
  }

  const availableLocations = locations.filter(
    (location) => location.lane == null,
  )

  const getAisles = () => {
    const aisles = availableLocations.map((aisle) => {
      return aisle.regular_location.aisle_id.name
    })

    const aislesNoDups = aisles.filter(
      (value, index, array) => array.indexOf(value) === index,
    )

    return aislesNoDups.sort((a, b) => {
      return a.localeCompare(b, undefined, {
        numeric: true,
        sensitivity: 'base',
      })
    })
  }

  const getBays = () => {
    const bayLocations = availableLocations.filter(
      (location) => location.regular_location.aisle_id.name == selectedLane,
    )

    const bays = bayLocations.map((bay) => {
      return bay.regular_location.bay_id.name
    })

    return bays.sort(function(a, b) {
      return a - b;
    })
  }

  const items = isEmptyObject(selectedLane) ? getAisles() : getBays()

  const onSelectItem = (item) => {
    if (isEmptyObject(selectedLane)) {
      selectLane(item)
    } else {
      selectBay(item)
    }
  }

  if (!locations.length || !orderPallet) {
    return <></>
  }

  const lastOrderPallet =
    orderPallet.order_pick_pallets[orderPallet.order_pick_pallets.length - 1]

  const currentPickLocationId = lastOrderPallet.order_product_pick_location_id
  const orderProductPickLocation = orderPallet.orderProductPickLocations.find(
    (item) => item.id === currentPickLocationId,
  )

  const tasksToDo = orderPallet.tasks
    .filter((item) => item.end_date === null)
    .filter((item) => item.task_type.id === TASK_TYPES.LOCATION_PICKING_TYPE)

  const orderTaskAssignment = orderPallet.tasks.find(
    (item) => item.task_type.id === TASK_TYPES.PICKING_TYPE,
  ).task_assignments[0]

  const isAllCasesPickedFromCurrentPickLocation =
    orderProductPickLocation.location_pick_quantity ===
    orderProductPickLocation.picked_quantity +
      orderProductPickLocation.unavailable_quantity

  const endAllLocationPickingTasksAssignments = () => {
    const allTasksAssignments = tasksToDo
      .reduce(
        (accumulator, currentValue) => [
          ...accumulator,
          ...currentValue.task_assignments,
        ],
        [],
      )
      .filter((item) => item.end_date === null)

    allTasksAssignments.forEach((item) => {
      updateTaskAssignment({
        id: item.id,
        data: {
          id: item.id,
          end_date: dateToFormat(date(), 'YYYY-MM-DD'),
          status: TASK_STATUSES.FINISHED_TYPE,
        },
      })
    })
  }

  if (!lastOrderPallet) {
    const redirectToHomePage = () => redirect(HOME_PAGE)

    return (
      <div className="tasks-page tasks-page--move-to-lane">
        <Header>{headerText}</Header>
        <div className="error-content">
          Last Order Pallet was not defined
          <Button onClick={redirectToHomePage}>Back to Home</Button>
        </div>
      </div>
    )
  }

  return (
    <div className="tasks-page tasks-page--move-to-lane">
      <Header>
        {headerText}
        <div className="refresh" onClick={onRefresh}>
          <RefreshIcon />
        </div>
      </Header>
      <div className="content">
        <SelectionList
          items={items}
          onSelectItem={onSelectItem}
          scrollTo={lastLaneSelected}
          ref={ref}
        />
      </div>
    </div>
  )
}

PowerPickingMoveToLanePage.propTypes = {
  locations: PropTypes.array,
  orderPallet: PropTypes.object,
}

const mapStateToProps = (state) => ({
  orderPallet: state.orderPallet.item,
  locations: state.location.forLane,
})

const mapDispatchToProps = {
  fetchLocationsForLane: fetchLocationsForLaneStart,
  resetModals,
  closeModal,
  showModal,
  updateOrderPallet: updateOrderPalletStart,
  createPickupLane,
  createShippingLane,
  validateShippingLane,
  validatePickupLane,
  updateTaskAssignment: updateTaskAssignmentWithoutFetch,
  fetchOneOrderPallet: fetchOneOrderPalletStart,
  updateOrderPickStatus: updateOrderPickStatusStart,
  createOrderPallet,
  createTempProductsWithQuantity,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PowerPickingMoveToLanePage)
