import React from 'react'
import PropTypes from 'prop-types'
import ModalDefault from 'components/common/Modal/ModalDefault'
import CancelIcon from 'assets/svg/cancel.svg'
import CancelIconIPod from 'assets/svg/cancel--ipod.svg'

import './style.scss'
import { connect } from 'react-redux'

const ModalWorkers = ({ isOpen, modalInfo }) => {
  if (!isOpen) {
    return <></>
  }

  return (
    <ModalDefault isOpen={isOpen}>
      <div className="modal modal--workers">
        <p className="title">Workers</p>
        {modalInfo.children}
        <div onClick={modalInfo.onDeny} className="btn--close">
          {window.innerWidth < 768 ? <CancelIconIPod /> : <CancelIcon />}
        </div>
      </div>
    </ModalDefault>
  )
}

ModalWorkers.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalWorkers',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalWorkers',
  ),
})

export default connect(mapStateToProps)(ModalWorkers)
