import { types } from './actions'

const INIT = {
  user: {},
  isAuthorized: false,
  errors: {},
  isLoading: false,
}

const authReducer = (state = INIT, action) => {
  const { type, payload } = action

  switch (type) {
    case types.CHECK_USER_START:
      return {
        ...state,
        user: {},
      }
    case types.CHECK_USER_SUCCESS:
      return {
        ...state,
        user: payload,
        errors: {},
      }
    case types.CHECK_USER_FAIL:
      return {
        ...state,
        errors: payload,
      }
    case types.LOGIN_START:
      return {
        ...state,
        user: {},
      }
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        user: payload,
        isAuthorized: true,
      }
    case types.LOGIN_FAIL:
      return {
        ...state,
        errors: payload,
      }
    case types.SET_NEW_PIN_CODE_START:
      return {
        ...state,
      }
    case types.SET_NEW_PIN_CODE_SUCCESS:
      return {
        ...state,
      }
    case types.SET_NEW_PIN_CODE_FAIL:
      return {
        ...state,
        errors: payload,
      }
    case types.RESET_USER:
      return {
        ...state,
        user: {},
      }
    case types.RESET:
      return INIT
    default:
      return state
  }
}

export default authReducer
