import { takeEvery, call, put } from 'redux-saga/effects'
import {
  fetchContainerProductSuccess,
  fetchProductCaseSuccess,
  fetchProductForSearchingSuccess,
  types,
} from 'store/product/actions'
import {
  fetchProductInfoBy,
  createProductCase,
  createContainerProduct,
  updateContainerProduct,
  addLoadedCases,
  updateProductCase,
  fetchOneByProductCase,
} from 'api/product'
import {
  fetchProductInfoFail,
  fetchProductInfoSuccess,
} from 'store/product/actions'
import {
  fetchOneUnloadProcessStart,
  fetchUnloadProcessListFail,
} from 'store/unloadProcess/actions'
import { createDamageCases } from 'api/case'
import { recountContainerPallets } from '../containerPallets/actions'

function* fetchProductInfo(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(fetchProductInfoBy, request)
    yield put(fetchProductInfoSuccess(response.data.data.item))

    if (afterAction) {
      afterAction(response.data.data.success)
    }
  } catch (error) {
    yield put(fetchProductInfoFail())
  }
}

function* productCaseCreate(action) {
  const { payload } = action

  try {
    const response = yield call(createProductCase, payload)
    yield put(fetchProductCaseSuccess(response.data.data.item))
  } catch (error) {}
}

function* fetchProductCase(action) {
  const { payload } = action
  const { request, afterAction, onSuccess, onFail } = payload

  try {
    const response = yield call(fetchOneByProductCase, request)

    yield put(fetchProductCaseSuccess(response.data.data.item))

    if (onSuccess) {
      yield onSuccess(response.data.data.item)
    }
  } catch (error) {
    if (onFail) {
      yield onFail(error)
    }
  } finally {
    if (afterAction) {
      yield afterAction()
    }
  }
}

function* productCaseUpdate(action) {
  const { payload } = action

  try {
    const response = yield call(updateProductCase, payload)
    yield put(fetchProductCaseSuccess(response.data.data.item))
  } catch (error) {}
}

function* containerProductCreate(action) {
  const { payload } = action

  try {
    const response = yield call(createContainerProduct, payload)
    yield put(fetchOneUnloadProcessStart({ id: payload.processId }))
    yield put(fetchContainerProductSuccess(response.data.data.item))
  } catch (error) {}
}

function* containerProductUpdate(action) {
  const { payload } = action

  try {
    const response = yield call(updateContainerProduct, payload.containerQty)
    yield put(recountContainerPallets(action.payload))
  } catch (error) {}
}

function* damagedCasesCreate(action) {
  const { payload } = action
  const { request, afterAction } = payload
  try {
    yield call(createDamageCases, request)

    if (afterAction) {
      afterAction()
    }
  } catch (error) {}
}

function* loadedCasesAdd(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const { processId: id } = payload
    const response = yield call(addLoadedCases, payload)
    yield put(fetchOneUnloadProcessStart({ id }))
    if (afterAction) {
      yield afterAction(response.data.data)
    }
  } catch (error) {
    yield put(fetchUnloadProcessListFail())
  }
}

export default function* productSaga() {
  yield takeEvery(types.productCase.CREATE_PRODUCT_CASE, productCaseCreate)
  yield takeEvery(types.productCase.FETCH_PRODUCT_CASE_START, fetchProductCase)
  yield takeEvery(types.productCase.UPDATE_PRODUCT_CASE, productCaseUpdate)
  yield takeEvery(types.productCase.ADD_LOADED_CASES, loadedCasesAdd)
  yield takeEvery(types.productCase.ADD_DAMAGED_CASES, damagedCasesCreate)

  yield takeEvery(
    types.containerProduct.CREATE_CONTAINER_PRODUCT,
    containerProductCreate,
  )
  yield takeEvery(
    types.containerProduct.UPDATE_CONTAINER_PRODUCT,
    containerProductUpdate,
  )

  yield takeEvery(types.productInfo.FETCH_PRODUCT_INFO_START, fetchProductInfo)
}
