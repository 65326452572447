import { takeEvery, call, put } from 'redux-saga/effects'
import { types } from './actions'
import {
  create,
  createForPutaway,
  remove,
  update,
  removeMany,
  createMany,
  createPalletLabel,
} from 'api/warehousePallet'

function* createWarehousePallet(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(create, request)

    if (afterAction) {
      yield afterAction(response.data.data.item)
    }
  } catch (error) {}
}

function* createWarehousePalletForPutaway(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(createForPutaway, request)

    if (afterAction) {
      yield afterAction(response.data.data.item)
    }
  } catch (error) {}
}

function* createManyWarehousePallet(action) {
  const { payload } = action
  const { request, onSuccess } = payload

  try {
    const response = yield call(createMany, request)

    if (onSuccess) {
      yield onSuccess(response.data.data.items)
    }
  } catch (error) {}
}

function* updateWarehousePallet(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    yield call(update, request)
    if (afterAction) {
      afterAction(request.id)
    }
  } catch (error) {}
}

function* updateWarehousePalletSync(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    yield call(update, request)

    if (afterAction) {
      afterAction()
    }
  } catch (error) {}
}

function* deleteWarehousePallet(action) {
  const { payload } = action

  try {
    yield call(remove, payload)
  } catch (error) {}
}

function* deleteManyWarehousePallet(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    yield call(removeMany, request)
  } catch (error) {
  } finally {
    if (afterAction) {
      afterAction()
    }
  }
}

function* printPalletLabel(action) {
  const { payload } = action

  try {
    yield call(createPalletLabel, payload)
  } catch (error) {}
}

export default function* warehousePalletSaga() {
  yield takeEvery(types.CREATE_WAREHOUSE_PALLET, createWarehousePallet)
  yield takeEvery(
    types.CREATE_WAREHOUSE_PALLET_FOR_PUTAWAY,
    createWarehousePalletForPutaway,
  )
  yield takeEvery(types.CREATE_MANY_WAREHOUSE_PALLET, createManyWarehousePallet)
  yield takeEvery(types.UPDATE_WAREHOUSE_PALLET, updateWarehousePallet)
  yield takeEvery(types.DELETE_WAREHOUSE_PALLET, deleteWarehousePallet)
  yield takeEvery(types.DELETE_MANY_WAREHOUSE_PALLET, deleteManyWarehousePallet)
  yield takeEvery(types.UPDATE_WAREHOUSE_PALLET_SYNC, updateWarehousePalletSync)
  yield takeEvery(types.PRINT_PALLET_LABEL, printPalletLabel)
}
