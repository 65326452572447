export const types = {
  RECOUNT_CONTAINER_PALLETS: 'RECOUNT_CONTAINER_PALLETS',
  MARK_PALLET_COMPLETE: 'MARK_PALLET_COMPLETE',
}

export const recountContainerPallets = (payload) => ({
  type: types.RECOUNT_CONTAINER_PALLETS,
  payload,
})

export const markPalletComplete = (payload) => ({
  type: types.MARK_PALLET_COMPLETE,
  payload,
})
