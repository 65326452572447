export const API_URL = process.env.REACT_APP_API_URL

export const PRIMARY_IP_API_URL = process.env.REACT_APP_PRIMARY_IP_API_URL

export const PRIMARY_IP_API_KEY = process.env.REACT_APP_PRIMARY_IP_API_KEY

export const SECONDARY_IP_API_URL = process.env.REACT_APP_SECONDARY_IP_API_URL

export const SECONDARY_IP_API_KEY = process.env.REACT_APP_SECONDARY_IP_API_KEY

export const NO_CONNECTION_MAX_RETRIES =
  process.env.REACT_APP_NO_CONNECTION_MAX_RETRIES || 1

export const NO_CONNECTION_RETRY_WAIT =
  process.env.REACT_APP_NO_CONNECTION_RETRY_WAIT || 2000

export const NO_CONNECTION_MAX_IGNORES =
  process.env.REACT_APP_NO_CONNECTION_MAX_IGNORES || 8
