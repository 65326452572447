import { call, put, select } from 'redux-saga/effects'
import { print, fetchLibrary } from 'api/print'
import { fetchPrinterLibrarySuccess } from 'store/print/actions'
import { takeLatest } from '@redux-saga/core/effects'
import { types } from './actions'

function* printStart(action) {
  const { payload } = action
  const { request, onSuccess, onError } = payload

  try {
    const response = yield call(print, {
      data: request,
    })

    onSuccess()
  } catch (error) {
    onError()
  }
}

function* fetchPrinterLibraryStart(action) {
  const { payload } = action
  const { afterAction } = payload

  try {
    const response = yield call(fetchLibrary, null)
    yield put(fetchPrinterLibrarySuccess(response.data.data.item))

    if (afterAction) {
      afterAction()
    }
  } catch (error) {}
}

export default function* printSaga() {
  yield takeLatest(types.PRINT_START, printStart)
  yield takeLatest(types.FETCH_PRINTER_LIBRARY_START, fetchPrinterLibraryStart)
}
