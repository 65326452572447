import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchOneTaskAssignmentStart } from 'store/taskAssignment/actions'
import { TASK_TYPES } from 'constants/taskAssignment'
import PickingTaskContent from './PickingTaskPage/PickingTaskContent'
import { isEmptyObject } from 'helpers/index'
import { closeModal, showModal } from 'store/modal/actions'

const TaskPage = (props) => {
  const {
    taskAssignment,
    user,
    match,
    isTaskAssignmentFetched,

    fetchOneTaskAssignmentStart,
    showModal,
    closeModal,
  } = props

  const taskId = match.params.id

  const fetchAssignment = () => {
    showModal({ name: 'modalPending' })
    //TODO: CHECK MULTIPLE REQUESTS
    fetchOneTaskAssignmentStart({
      id: taskId,
      warehouse_staff_id: user.warehouse_staff_id,
    })
  }

  const onTaskAssignmentChange = () => {
    if (
      isEmptyObject(taskAssignment) ||
      taskAssignment.id !== parseInt(taskId)
    ) {
      fetchAssignment()
    }

    if (!isEmptyObject(taskAssignment) || isTaskAssignmentFetched) {
      closeModal('modalPending')
    }
  }

  useEffect(onTaskAssignmentChange, [taskAssignment])
  useEffect(fetchAssignment, [fetchOneTaskAssignmentStart])

  const getTaskContent = () => {
    if (isEmptyObject(taskAssignment)) {
      return <></>
    }

    const taskTypeId = taskAssignment.task.task_type_id
    switch (taskTypeId) {
      case TASK_TYPES.PICKING_TYPE:
        return <PickingTaskContent user={user} match={match} />
      default:
        return
    }
  }

  return <>{getTaskContent()}</>
}

const mapStateToProps = (state) => ({
  isTaskAssignmentFetched: state.taskAssignment.isFetched,
})

const mapDispatchToProps = {
  fetchOneTaskAssignmentStart,
  showModal,
  closeModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskPage)
