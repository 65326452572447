import { takeEvery, call, put } from 'redux-saga/effects'
import {
  fetchCreateMeasurementItemsFail,
  fetchCreateMeasurementItemsSuccess,
  fetchMeasurementItemFail,
  types,
} from 'store/measurement/actions'
import { fetchMeasurementItemSuccess } from 'store/measurement/actions'
import { fetchMany, updateMany } from 'api/measurement'

function* fetchMeasurementItems(action) {
  const { payload } = action

  try {
    const response = yield call(fetchMany, payload)
    yield put(fetchMeasurementItemSuccess(response.data.data.items))
  } catch (error) {
    yield put(fetchMeasurementItemFail())
  }
}

function* fetchCreateMeasurementItems(action) {
  const { payload } = action

  try {
    const response = yield call(fetchMany, payload)
    yield put(fetchCreateMeasurementItemsSuccess(response.data.data.items))
  } catch (error) {
    yield put(fetchCreateMeasurementItemsFail())
  }
}

function* updateMeasurementItems(action) {
  const { payload } = action

  try {
    yield call(updateMany, payload)
  } catch (error) {}
}

export default function* measurementSaga() {
  yield takeEvery(types.FETCH_MEASUREMENT_ITEMS_START, fetchMeasurementItems)
  yield takeEvery(
    types.FETCH_CREATE_MEASUREMENT_ITEMS_START,
    fetchCreateMeasurementItems,
  )
  yield takeEvery(types.UPDATE_MEASUREMENT_ITEMS, updateMeasurementItems)
}
