import React from 'react'
import * as PropTypes from 'prop-types'
import './style.scss'

const Switch = (props) => {
  const {
    name = '',
    className = '',
    selected = {},
    items = [],
    onChange,
  } = props

  return (
    <div className={`switch ${className}`}>
      {items.map((item) => (
        <label
          key={item.label}
          className={`switch__tab ${
            selected.value === item.value ? 'switch__tab--active' : ''
          }`}
        >
          <span className="switch__tab__text">{item.label}</span>
          <input
            type="radio"
            name="radio"
            className="switch__tab__radio"
            onClick={() => onChange(name, item)}
          />
        </label>
      ))}
    </div>
  )
}

Switch.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,

  selected: PropTypes.object,

  items: PropTypes.array.isRequired,

  onChange: PropTypes.func.isRequired,
}

export default Switch
