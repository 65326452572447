import { types } from './actions'

const INIT = {
  isTemporaryHidden: false,
  isPermanentHidden: false,
}

const deviceOrientationReducer = (state = INIT, action) => {
  const { type } = action

  switch (type) {
    case types.HIDE_TEMPORARY:
      return {
        ...state,
        isTemporaryHidden: true,
      }
    case types.HIDE_PERMANENTLY:
      return {
        ...state,
        isPermanentHidden: true,
      }
    case types.RESET_TEMPORARY:
      return {
        ...state,
        isTemporaryHidden: false,
      }
    default:
      return state
  }
}

export default deviceOrientationReducer
