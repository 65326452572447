import React from 'react'
import * as PropTypes from 'prop-types'

const TableListCell = ({ item }) => <div className="list__cell">{item}</div>

TableListCell.propTypes = {
  item: PropTypes.any,
}

export default TableListCell
