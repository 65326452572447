import { types } from './actions'
import * as api from 'api/shippingLane'
import { takeLatest, call } from 'redux-saga/effects'

function* createShippingLane(action) {
  const { payload } = action

  try {
    yield call(api.create, payload)
  } catch (error) {}
}

function* validateShippingLane(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(api.confirmEmpty, request)

    if (afterAction) {
      yield afterAction(response.data.data)
    }
  } catch (error) {}
}

export default function* shippingLaneSaga() {
  yield takeLatest(types.CREATE_SHIPPING_LANE, createShippingLane)
  yield takeLatest(types.VALIDATE_SHIPPING_LANE, validateShippingLane)
}
