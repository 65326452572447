import React from 'react'
import * as PropTypes from 'prop-types'
import ModalPending from 'components/common/Modal/ModalPending'
import ModalWrongOrAdditionalProduct from 'components/common/Modal/ModalWrongOrAdditionalProduct'
import ModalWrongLocation from 'components/common/Modal/ModalWrongLocation'
import ModalWorkers from 'components/common/Modal/ModalWorkers'
import ModalWarning from 'components/common/Modal/ModalWarning'
import ModalOptionsList from 'components/common/Modal/ModalOptionsList'
import ModalSuccess from 'components/common/Modal/ModalSuccess'
import ModalSuccessReset from 'components/features/Auth/LoginPage/ModalSuccessReset'
import ModalScanDamageLocationForMultipleCase from 'components/common/Modal/ModalScanDamageLocationForMultipleCase'
import ModalScanDamageLocation from 'components/common/Modal/ModalScanDamageLocation'
import ModalResume from 'components/common/Modal/ModalResume'
import ModalQaCaseQty from 'components/common/Modal/ModalQaCaseQty'
import ModalPickedQty from 'components/common/Modal/ModalPickedQty'
import ModalPalletAdjustment from 'components/common/Modal/ModalPalletAdjustment'
import ModalChoosePrinter from 'components/common/Modal/ModalChoosePrinter'
import ModalNoTasks from 'components/common/Modal/ModalNoTasks'
import ModalLogout from 'components/common/Modal/ModalLogout'
import ModalDamageQty from 'components/common/Modal/ModalDamageQty'
import ModalDamagePutawayLocation from 'components/common/Modal/ModalDamagePutawayLocation'
import ModalConfirm from 'components/common/Modal/ModalConfirm'
import ModalOrderNotes from 'components/common/Modal/ModalOrderNotes'
import ModalChoose from 'components/common/Modal/ModalChoose'
import ModalCaseReceived from 'components/common/Modal/ModalCaseReceived'
import ModalCaseQty from 'components/common/Modal/ModalCaseQty'
import ModalInputQuantityControl from 'components/common/Modal/ModalInputQuantityControl'
import ModalAddDamagedProducts from 'components/common/Modal/ModalAddDamagedProducts'
import PopupStaffDetails from 'components/features/UnloadProcess/PrepareUnloadProcessListPage/PopupStaffDetail'
import PopupAssignment from 'components/features/UnloadProcess/PrepareUnloadProcessListPage/PopupAssignment'
import MeasurementPopup from 'components/features/UnloadProcess/UnloadContainerProductListPage/MeasurementPopup'
import ModalWrongCaseUpc from 'components/common/Modal/ModalWrongCaseUpc'
import ModalPalletQty from 'components/common/Modal/ModalPalletQty'
import ModalSystemError from 'components/common/Modal/ModalSystemError'
import AppStatusBanner from '../AppStatusBanner'
import EnvironmentBanner from '../EnvironmentBanner'
import ModalYesOrNo from '../Modal/ModalYesOrNo'

const PageWrapper = ({ children }) => (
  <div className="page-content-wrapper">
    <EnvironmentBanner />
    <AppStatusBanner />
    <ModalPending />
    <ModalWrongOrAdditionalProduct />
    <ModalWrongLocation />
    <ModalWorkers />
    <ModalWarning />
    <ModalSuccess />
    <ModalSuccessReset />
    <ModalScanDamageLocationForMultipleCase />
    <ModalScanDamageLocation />
    <ModalResume />
    <ModalQaCaseQty />
    <ModalPickedQty />
    <ModalPalletAdjustment />
    <ModalNoTasks />
    <ModalLogout />
    <ModalDamageQty />
    <ModalDamagePutawayLocation />
    <ModalConfirm />
    <ModalOrderNotes />
    <ModalChoose />
    <ModalCaseReceived />
    <ModalCaseQty />
    <ModalInputQuantityControl />
    <ModalAddDamagedProducts />
    <PopupStaffDetails />
    <PopupAssignment />
    <MeasurementPopup />
    <ModalWrongCaseUpc />
    <ModalPalletQty />
    <ModalChoosePrinter />
    <ModalOptionsList />
    <ModalSystemError />
    <ModalYesOrNo />

    {children}
  </div>
)

PageWrapper.propTypes = {
  children: PropTypes.any.isRequired,
}

export default PageWrapper
