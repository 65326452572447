import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import Header from 'components/common/Header'
import Scanner from 'components/common/Scanner'
import TableList from 'components/common/TableList'
import ArrowRoundedIcon from 'assets/svg/arrow-rounded.svg'
import {
  fetchRestockProcessListStart,
  updateRestockProcess,
  updateScannedRestockLocation,
} from 'store/restockProcess/actions'
import { connect } from 'react-redux'
import Button from 'components/common/Button'
import { redirect } from 'helpers/index'
import {
  RESTOCK_TASK_LIST_PAGE,
  SCANNING_CASE_UPS_PAGE,
} from 'constants/routes'
import { RESTOCK_TYPES } from 'constants/taskAssignment'
import { updateWarehouseCase } from 'store/warehouseCase/actions'
import { STATUS_CREATED, STATUS_IN_PROGRESS } from 'constants/processStatuses'

const columns = [
  {
    id: 1,
    label: 'Model',
    value: 'model',
  },
  {
    id: 2,
    label: 'Task Type',
    value: 'taskType',
  },
  {
    id: 3,
    label: 'Name',
    value: 'name',
  },
  {
    id: 4,
    label: 'To Location',
    value: 'toLocation',
  },
  {
    id: 5,
    label: 'Case',
    value: 'caseQuantity',
  },
]

const ScanningDamageCasePage = (props) => {
  const {
    processList,
    scannedProcessList,

    updateWarehouseCase,
    updateScannedRestockLocation,
    fetchRestockProcessList,
    updateRestockProcess,
  } = props

  const onPageLoad = () => {
    fetchRestockProcessList({
      filters: [RESTOCK_TYPES.DAMAGE_TYPE],
      conditions: {
        status: STATUS_CREATED,
      },
    })
  }

  useEffect(onPageLoad, [])

  const close = () => redirect(RESTOCK_TASK_LIST_PAGE)

  const onScan = (barcode) => {
    processList.forEach((element) => {
      const firstAndSingleRestockLocation = element.entity.restock_location[0]

      if (
        barcode.toString() ===
          firstAndSingleRestockLocation.product.products_upc_case &&
        scannedProcessList.indexOf(element) === -1 // TODO: this will likely need to change to match the new multi-upc table data, re: wms-1835 (07.2021)
      ) {
        updateScannedRestockLocation([...scannedProcessList, element])

        updateRestockProcess({
          id: element.id,
          data: {
            id: element.id,
            status: STATUS_IN_PROGRESS,
          },
        })
      }
    })
  }

  const getNormalizedItems = () => {
    let normalizedItems = []
    let normalizedIds = []

    scannedProcessList.forEach((item) => {
      const firstAndSingleRestockLocation = item.entity.restock_location[0]

      let toLocation = `${firstAndSingleRestockLocation.warehouse_location_to.regular_location.aisle_id.name}-${firstAndSingleRestockLocation.warehouse_location_to.regular_location.bay_id.name}-${firstAndSingleRestockLocation.warehouse_location_to.regular_location.level}`

      normalizedItems.forEach((element, index) => {
        if (
          element.toLocation === toLocation &&
          element.productId ===
            firstAndSingleRestockLocation.product.products_id
        ) {
          normalizedIds = [...normalizedIds, item.id]
          normalizedItems[index].caseQuantity +=
            firstAndSingleRestockLocation.quantity
        }
      })

      if (normalizedIds.indexOf(item.id) === -1) {
        normalizedItems = [
          ...normalizedItems,
          {
            productId: firstAndSingleRestockLocation.product.products_id,
            model: firstAndSingleRestockLocation.product.products_model,
            taskType: item.tasks[0].task_type.name,
            name: firstAndSingleRestockLocation.product_name,
            toLocation: toLocation,
            caseQuantity: firstAndSingleRestockLocation.quantity,
            options: {
              selected: false,
            },
          },
        ]

        normalizedIds = [...normalizedIds, item.id]
      }
    })

    return normalizedItems
  }

  const onStartRestocking = () => {
    let updatedWarehouseCaseIds = []

    scannedProcessList.forEach((element) => {
      const firstAndSingleRestockLocation = element.entity.restock_location[0]

      const warehouseCaseInLocation = firstAndSingleRestockLocation.warehouse_location_from.warehouse_cases_in_location.find(
        (item) =>
          item.product_id === firstAndSingleRestockLocation.product.products_id,
      )

      if (updatedWarehouseCaseIds.indexOf(warehouseCaseInLocation.id) === -1) {
        updatedWarehouseCaseIds = [
          ...updatedWarehouseCaseIds,
          warehouseCaseInLocation.id,
        ]

        const decreaseCaseQuantity = scannedProcessList.filter(
          (item) =>
            item.entity.restock_location[0].warehouse_location_from.warehouse_cases_in_location.find(
              (casesInLocations) =>
                casesInLocations.product_id ===
                item.entity.restock_location[0].product.products_id,
            ).id === warehouseCaseInLocation.id,
        ).length

        const newCaseQuantity =
          warehouseCaseInLocation.location_case_qty - decreaseCaseQuantity

        updateWarehouseCase({
          id: warehouseCaseInLocation.id,
          data: {
            id: warehouseCaseInLocation.id,
            location_case_qty: newCaseQuantity,
          },
        })
      }
    })

    redirect(SCANNING_CASE_UPS_PAGE)
  }

  return (
    <div className="page page--restock page--restock-dmg">
      <Header>
        <div className="title">{'Dmg Restock Task'}</div>
        <div className="text--close" onClick={close}>
          Close
        </div>
      </Header>
      <Scanner needToRemoveListener={false} onReceiveData={onScan} />
      <div className="content">
        <div className="content__header">
          <p className="text">
            <span className="text--bold">
              {scannedProcessList.length}/{processList.length}
            </span>{' '}
            Scanned
          </p>
          <p className="text">
            <span className="text--bold">
              {processList.length - scannedProcessList.length}
            </span>{' '}
            Rest
          </p>
        </div>
        <TableList
          className="list--restock list--restock-dmg"
          columns={columns}
          rows={getNormalizedItems()}
          events={{}}
          rowBackgroundElement={
            <span className="swipe__text">
              Unload
              <ArrowRoundedIcon className="svg" />
            </span>
          }
        />
        {scannedProcessList.length ? (
          <Button onClick={onStartRestocking}>Start Restocking</Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

ScanningDamageCasePage.propTypes = {
  processList: PropTypes.array,
  scannedProcessList: PropTypes.array,

  updateWarehouseCase: PropTypes.func,
  fetchRestockProcessList: PropTypes.func,
  updateScannedRestockLocation: PropTypes.func,
}

const mapStateToProps = (state) => ({
  processList: state.restockProcess.list,
  scannedProcessList: state.restockProcess.scannedItem,
})

const mapDispatchToProps = {
  updateWarehouseCase: updateWarehouseCase,
  fetchRestockProcessList: fetchRestockProcessListStart,
  updateScannedRestockLocation: updateScannedRestockLocation,
  updateRestockProcess: updateRestockProcess,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScanningDamageCasePage)
