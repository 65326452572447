import React, { useState } from 'react'
import '../style.scss'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import Header from 'components/common/Header'
import { redirect } from 'helpers/index'
import { updateWarehouseCase } from 'store/warehouseCase/actions'
import QuantityControl from 'components/common/QuantityControl'
import { INVENTORY_TOOL_SCAN_LOCATION_PAGE } from 'constants/routes'
import BarcodeService from 'services/barcodeService'
import {
  createWarehousePallet,
  updateWarehousePallet,
} from 'store/warehousePallet/actions'

const ZoneInventoryTool = (props) => {
  const {
    warehouseCase,

    updateWarehouseCase,
    createWarehousePallet,
    updateWarehousePallet,
  } = props

  const [caseQuantity, setCaseQuantity] = useState(
    warehouseCase.location_case_qty,
  )

  const notFullPallets = warehouseCase.warehouse_pallets.filter(
    (item) => item.qty_cases_on_pallet < warehouseCase.cases_pre_pallet,
  )

  const onChange = (name, value) => setCaseQuantity(value)
  const onDecrement = () => setCaseQuantity(caseQuantity - 1)
  const onIncrement = () => setCaseQuantity(caseQuantity + 1)

  const createPallet = (countOfCases) => {
    const warehouse = warehouseCase.warehouseLocation.warehouse

    createWarehousePallet({
      request: {
        data: {
          warehouse_case_id: warehouseCase.id,
          pallet_upc: '111',
          qty_cases_on_pallet: countOfCases,
        },
      },
      afterAction: (item) => {
        updateWarehousePallet({
          request: {
            id: item.id,
            data: {
              id: item.id,
              pallet_upc: BarcodeService.generatePalletBarcode(
                item.id,
                warehouse.warehouse_id,
              ),
            },
          },
        })
      },
    })
  }

  const decrementCasesOnPallet = () => {
    let countOfCasesToDelete = warehouseCase.location_case_qty - caseQuantity

    const warehousePallets = warehouseCase.warehouse_pallets

    updateWarehouseCase({
      id: warehouseCase.id,
      data: {
        id: warehouseCase.id,
        location_case_qty: caseQuantity,
      },
    })

    warehousePallets.forEach((item) => {
      if (countOfCasesToDelete > item.qty_cases_on_pallet) {
        updateWarehousePallet({
          request: {
            id: item.id,
            data: {
              id: item.id,
              qty_cases_on_pallet: 0,
            },
          },
        })

        countOfCasesToDelete -= item.qty_cases_on_pallet
      } else if (!countOfCasesToDelete) {
        updateWarehousePallet({
          request: {
            id: item.id,
            data: {
              id: item.id,
              qty_cases_on_pallet:
                item.qty_cases_on_pallet - countOfCasesToDelete,
            },
          },
        })

        countOfCasesToDelete = 0
      }
    })
  }

  const incrementCasesOnPallet = () => {
    let countOfNewCases = caseQuantity - warehouseCase.location_case_qty

    updateWarehouseCase({
      id: warehouseCase.id,
      data: {
        id: warehouseCase.id,
        location_case_qty: caseQuantity,
      },
    })

    if (!notFullPallets.length) {
      notFullPallets.forEach((item) => {
        const placeCountForCases =
          warehouseCase.cases_pre_pallet - item.qty_cases_on_pallet

        if (countOfNewCases > placeCountForCases) {
          updateWarehousePallet({
            request: {
              id: item.id,
              data: {
                id: item.id,
                qty_cases_on_pallet: warehouseCase.cases_pre_pallet,
              },
            },
          })

          countOfNewCases -= placeCountForCases
        } else {
          updateWarehousePallet({
            request: {
              id: item.id,
              data: {
                id: item.id,
                qty_cases_on_pallet:
                  item.qty_cases_on_pallet + placeCountForCases,
              },
            },
          })

          countOfNewCases = 0
        }
      })
    }

    while (countOfNewCases > 0) {
      if (countOfNewCases > warehouseCase.cases_pre_pallet) {
        createPallet(warehouseCase.cases_pre_pallet)
        countOfNewCases -= warehouseCase.cases_pre_pallet
      } else {
        createPallet(countOfNewCases)
        countOfNewCases = 0
      }
    }
  }

  const onDone = () => {
    if (caseQuantity > warehouseCase.location_case_qty) {
      incrementCasesOnPallet()
    }

    if (caseQuantity < warehouseCase.location_case_qty) {
      decrementCasesOnPallet()
    }

    redirect(INVENTORY_TOOL_SCAN_LOCATION_PAGE)
  }

  return (
    <div className="tasks-page tasks-page--damage-resolution tasks-page--scan-resolution task-page--inventory">
      <Header>
        {warehouseCase.product_description.products_name}
        <div className="text text--close" onClick={onDone}>
          Done
        </div>
      </Header>
      <div className="content">
        <div className="content__info">
          <div className="content__info-item">
            <div>Model</div>
            <div className="content__info-text">
              {warehouseCase.product.products_model}
            </div>
          </div>
          <div className="content__info-item">
            <div>Manf Modal</div>
            <div className="content__info-text">
              {warehouseCase.product.products_manf_model}
            </div>
          </div>
        </div>

        <QuantityControl
          minValue={0}
          onChange={onChange}
          value={caseQuantity}
          onDecrease={onDecrement}
          onIncrease={onIncrement}
          styles={{ fontSize: 56.7 }}
        />
      </div>
    </div>
  )
}

ZoneInventoryTool.propTypes = {
  warehouseCase: PropTypes.object,

  updateWarehouseCase: PropTypes.func,
  createWarehousePallet: PropTypes.func,
  updateWarehousePallet: PropTypes.func,
}

const mapDispatchToProps = {
  updateWarehouseCase: updateWarehouseCase,
  createWarehousePallet: createWarehousePallet,
  updateWarehousePallet: updateWarehousePallet,
}

export default connect(null, mapDispatchToProps)(ZoneInventoryTool)
