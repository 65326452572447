import { takeEvery, call, put } from 'redux-saga/effects'
import { fetch } from 'api/appInfo'
import { fetchAppInfoFail, fetchAppInfoSuccess, types } from './actions'

function* fetchAppInfo(action) {
  const { payload } = action

  try {
    const response = yield call(fetch, payload)

    response && (yield put(fetchAppInfoSuccess(response.data)))
  } catch (error) {
    yield put(fetchAppInfoFail())
  }
}

export default function* appInfoSaga() {
  yield takeEvery(types.FETCH_APP_INFO_START, fetchAppInfo)
}
