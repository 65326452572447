import { takeEvery, call, put } from 'redux-saga/effects'
import { fetchLocationsForLaneSuccess, types } from './actions'
import {
  fetchAll,
  create,
  remove,
  update,
  fetchAvailableBaysByAisleAndWarehouse,
  fetchOne,
  createGroup,
  fetchForLane,
  fetchOneBy,
} from 'api/locations'
import {
  createGroupLocationFail,
  createGroupLocationSuccess,
  createLocationFail,
  createLocationSuccess,
  fetchAvailableBaysFail,
  fetchAvailableBaysSuccess,
  fetchLocationsFail,
  fetchLocationsStart,
  fetchLocationsSuccess,
  fetchOneLocationSuccess,
  updateLocationFail,
  updateLocationSuccess,
} from './actions'
import { sortObjectsList } from 'helpers/sorting'
import query from 'querystringify'
import { MAPPER_LOCATION_LIST_SORTING } from 'constants/mappers'

function* fetchLocations(action) {
  const { payload } = action

  try {
    const response = yield call(fetchAll, payload)

    const queryStringParams = query.parse(payload)
    let items = response.data.data.items

    if (queryStringParams.sortBy && queryStringParams.orderBy) {
      items = sortObjectsList(
        items,
        MAPPER_LOCATION_LIST_SORTING[queryStringParams.sortBy],
        queryStringParams.orderBy,
      )
    }

    yield put(fetchLocationsSuccess(items))
  } catch (error) {
    yield put(fetchLocationsFail())
  }
}

function* fetchOneLocation(action) {
  const { payload } = action

  try {
    const response = yield call(fetchOne, payload)
    yield put(fetchOneLocationSuccess(response.data.data.item))
  } catch (error) {
    yield put(fetchLocationsFail())
  }
}

function* fetchOneLocationBy(action) {
  const { payload } = action
  const { request, afterAction, onSuccess, onFail } = payload

  try {
    const response = yield call(fetchOneBy, request)
    yield put(fetchOneLocationSuccess(response.data.data.item))
    if (onSuccess) {
      onSuccess(response.data.data.item)
    }
  } catch (error) {
    yield put(fetchLocationsFail())
    if (onFail) {
      onFail()
    }
  }
  if (afterAction) {
    afterAction()
  }
}

function* createLocation(action) {
  const { payload } = action

  try {
    const response = yield call(create, payload)
    yield put(createLocationSuccess(response))
  } catch (error) {
    yield put(createLocationFail())
  }
}

function* createGroupLocation(action) {
  const { payload } = action

  try {
    const response = yield call(createGroup, payload)
    yield put(createGroupLocationSuccess(response))
  } catch (error) {
    yield put(createGroupLocationFail())
  }
}

function* updateLocation(action) {
  const { payload } = action

  try {
    yield call(update, payload)
    yield put(updateLocationSuccess())
  } catch (error) {
    yield put(updateLocationFail())
  }
}

function* deleteLocation(action) {
  const { payload } = action

  try {
    yield call(remove, payload)
    yield call(fetchLocationsStart)
  } catch (error) {
    yield put(fetchLocationsFail())
  }
}

function* fetchAvailableBays(action) {
  const { payload } = action

  try {
    const response = yield call(fetchAvailableBaysByAisleAndWarehouse, payload)
    yield put(fetchAvailableBaysSuccess(response.data.items))
  } catch (error) {
    yield put(fetchAvailableBaysFail())
  }
}

function* fetchLocationsForLane(action) {
  const { payload } = action

  try {
    const response = yield call(fetchForLane, payload)
    yield put(fetchLocationsForLaneSuccess(response.data))
  } catch (error) {}
}

export default function* locationSaga() {
  yield takeEvery(types.FETCH_LOCATIONS_START, fetchLocations)
  yield takeEvery(types.CREATE_LOCATION_START, createLocation)
  yield takeEvery(types.DELETE_LOCATION, deleteLocation)
  yield takeEvery(types.UPDATE_LOCATION_START, updateLocation)
  yield takeEvery(types.FETCH_AVAILABLE_BAYS_START, fetchAvailableBays)
  yield takeEvery(types.FETCH_ONE_LOCATION_START, fetchOneLocation)
  yield takeEvery(types.FETCH_ONE_LOCATION_BY_START, fetchOneLocationBy)
  yield takeEvery(types.CREATE_GROUP_LOCATION_START, createGroupLocation)
  yield takeEvery(types.FETCH_LOCATIONS_FOR_LANE_START, fetchLocationsForLane)
}
