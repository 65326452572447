import React from 'react'
import * as PropTypes from 'prop-types'
import ModalDefault from 'components/common/Modal/ModalDefault'
import Button from 'components/common/Button'
import './style.scss'
import CancelIcon from 'assets/svg/cancel.svg'
import CancelIconIPod from 'assets/svg/cancel--ipod.svg'
import ExclamationSign from 'assets/svg/exclamation.svg'
import { connect } from 'react-redux'

const ModalLogout = (props) => {
  const { isOpen, modalInfo } = props

  if (!isOpen) {
    return <></>
  }

  const onClick = () => modalInfo.onConfirm()
  const onClose = () => modalInfo.onDeny()

  return (
    <ModalDefault isOpen={isOpen}>
      <div
        className={`modal modal--confirm modal--logout ${
          modalInfo.className || ''
        }`}
      >
        <ExclamationSign className="icon--exclamation" />
        <div className="text--bold">Logout From PWA</div>
        <Button onClick={onClick}>Confirm</Button>
        {window.innerWidth < 768 ? (
          <CancelIconIPod onClick={onClose} className="btn--close" />
        ) : (
          <CancelIcon onClick={onClose} className="btn--close" />
        )}
      </div>
    </ModalDefault>
  )
}

ModalLogout.propTypes = {
  isOpen: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalLogout',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalLogout',
  ),
})

export default connect(mapStateToProps)(ModalLogout)
