// Note, this started as a copy of the RestockStorageToStorage page, minimal changes as of 2022.01
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import Header from 'components/common/Header'
import {
  fetchBulkRestockTaskStart,
  fetchRestockProcessListStart,
  updateRestockLocation,
  updateRestockProcess,
  processBulkToTask,
} from 'store/restockProcess/actions'
import Active from 'assets/svg/active--ipad.svg'
import ActiveGrey from 'assets/svg/active-grey--ipad.svg'
import Scanner from 'components/common/Scanner'
import { closeModal, resetModals, showModal } from 'store/modal/actions'
import Button from 'components/common/Button'
import {
  deleteWarehousePallet,
  updateWarehousePallet,
} from 'store/warehousePallet/actions'
import { isEmptyObject, redirect, date, dateToFormat } from 'helpers/index'
import {
  createWarehouseCaseSync,
  updateWarehouseCase,
} from 'store/warehouseCase/actions'
import { LOCATION_TYPE } from 'constants/locationTypes'
import { BULK_STORAGE_TASK_LIST } from 'constants/routes'
import { STATUS_COMPLETED } from 'constants/processStatuses'
import Tooltip from 'components/common/Tooltip'
import MoreIcon from 'assets/svg/more-icon.svg'
import Report from 'assets/svg/report.svg'
import { fetchLocationsStart } from 'store/location/actions'
import * as query from 'querystringify'
import { LOCATION_TYPE_FILTER, WAREHOUSE_FILTER } from 'constants/filters'
import { PROCESS_TYPES } from 'constants/process'
import { createDamageCases } from 'store/product/actions'
import { RESTOCK_LOCATION_STATUSES } from 'constants/restockLocationStatus'
import { updateTaskAssignmentWithoutFetch } from 'store/taskAssignment/actions'
import { TASK_STATUSES } from 'constants/taskAssignment'

const BulkRestockToStoragePage = (props) => {
  const {
    user,
    match,
    currentTask,
    damageLocation,

    resetModals,
    updateWarehouseCase,
    updateTaskAssignmentWithoutFetch,
    closeModal,
    createWarehouseCase,
    deleteWarehousePallet,
    showModal,
    fetchBulkRestockTask,
    fetchLocations,
    createDamageCases,
    processBulkToTask,
  } = props

  const taskId = match.params.id

  const getLocationParamsForFetch = () =>
    query.stringify(
      {
        [WAREHOUSE_FILTER]: user.warehouseId,
        [LOCATION_TYPE_FILTER]: LOCATION_TYPE.DAMAGE_TYPE_ID,
      },
      true,
    )

  const onMount = () => {
    showModal({
      name: 'modalPending',
    })
    fetchBulkRestockTask({
      id: taskId,
    })

    fetchLocations(getLocationParamsForFetch())
  }

  const [locationTitle, setLocationTitle] = useState('')
  const [restockLocation, setRestockLocation] = useState({})
  const [processId, setProcessId] = useState()
  const [pallet, setPallet] = useState({})
  const [isToLocationScanned, setIsToLocationScanned] = useState(false)

  const [isScanned, setIsScanned] = useState(false)

  const onUpdateCurrentTask = () => {
    if (!isEmptyObject(currentTask)) {
      const firstAndSingleRestockLocation = currentTask.entity.restock_location
      setRestockLocation(firstAndSingleRestockLocation)

      const processId = currentTask.process_id
      setProcessId(processId)

      const pallet = currentTask.warehouse_pallets.find(
        (item) => item.qty_cases_on_pallet !== 0,
      )
      setPallet(pallet)
    }
  }

  const onUpdateRestockLocation = () => {
    if (!isEmptyObject(restockLocation)) {
      setLocationTitle(
        [
          restockLocation['warehouse_location_to'].regular_location.aisle.name,
          restockLocation['warehouse_location_to'].regular_location.bay.name,
          restockLocation['warehouse_location_to'].regular_location.level,
        ].join('-'),
      )
    }
  }

  const onUpdate = () => {
    if (locationTitle || damageLocation) {
      closeModal('modalPending')
    }
  }

  useEffect(onMount, [])
  useEffect(onUpdateCurrentTask, [currentTask])
  useEffect(onUpdateRestockLocation, [restockLocation])
  useEffect(onUpdate, [locationTitle, damageLocation])

  if (
    isEmptyObject(currentTask) ||
    isEmptyObject(restockLocation) ||
    !locationTitle ||
    !damageLocation
  ) {
    return <></>
  }

  // const afterActionUpdateWarehousePallet = (warehouseCase) => {
  //   updateWarehousePallet({
  //     id: pallet.id,
  //     data: {
  //       id: pallet.id,
  //       warehouse_case_id: warehouseCase.id,
  //     },
  //   })
  // }

  const onPushCompleteButton = () => {
    const warehouseCasesTo =
      restockLocation.warehouse_location_to.warehouse_cases_in_location
    const warehouseCaseIdTo = warehouseCasesTo ? warehouseCasesTo.id : null

    processBulkToTask({
      data: {
        to: {
          warehouse_case_id: warehouseCaseIdTo,
          product_id: restockLocation.product.products_id,
          updated_by_user_id: user.warehouseStaffId,
          created_by_user_id: user.warehouseStaffId,
          location_case_qty: pallet.qty_cases_on_pallet,
          warehouse_location_id: restockLocation.warehouse_location_to.id,
        },
        from: {
          warehouse_location_id: restockLocation.warehouse_location_from_id,
        },
        warehouse_pallet: {
          id: pallet.id,
        },
        process: {
          id: processId,
          status: STATUS_COMPLETED,
        },
        restock_location: {
          id: restockLocation.id,
          status_id: RESTOCK_LOCATION_STATUSES.COMPLETE,
        },
        task: {
          assignment_id: currentTask.restocker.taskAssignment.id,
        },
      },
    })

    redirect(BULK_STORAGE_TASK_LIST)
  }

  const onScan = (barcode) => {
    if (
      restockLocation.warehouse_location_to.upc.toString() !==
      barcode.toString()
    ) {
      showModal({
        name: 'modalWrongLocation',
        onAccept: resetModals,
        text: locationTitle,
      })
      return
    }

    setIsScanned(true)
    setTimeout(() => {
      setIsToLocationScanned(true)
      setIsScanned(false)
    }, 1000)
  }

  const onComplete = () => {
    updateRestockProcess({
      id: processId,
      data: {
        id: processId,
        status: STATUS_COMPLETED,
      },
    })

    updateRestockLocation({
      id: restockLocation.id,
      data: {
        id: restockLocation.id,
        status_id: RESTOCK_LOCATION_STATUSES.COMPLETE,
        updated_date: dateToFormat(date(), 'YYYY-MM-DD HH:mm:ss'),
        updated_by_user_id: user.warehouseStaffId,
      },
    })

    updateTaskAssignmentWithoutFetch({
      id: currentTask.restocker.taskAssignment.id,
      data: {
        id: currentTask.restocker.taskAssignment.id,
        end_date: dateToFormat(date(), 'YYYY-MM-DD'),
        status: TASK_STATUSES.FINISHED_TYPE,
      },
    })

    redirect(BULK_STORAGE_TASK_LIST)
  }

  const getBottomContent = () => {
    const statusIcon = isScanned ? <Active /> : <ActiveGrey />
    let content = ''

    switch (true) {
      case isToLocationScanned:
        content = <Button onClick={onPushCompleteButton}>{'Complete'}</Button>
        break
      case !isToLocationScanned:
        content = <>Scan Location UPC {statusIcon}</>
        break
      default:
        break
    }

    return (
      <div className="content__section--white">
        <span className="status">{content}</span>
      </div>
    )
  }

  const onFailureScanDamageLocation = () => {
    const damageLocationTitle = `${damageLocation.aisle}-${damageLocation.bay}`

    const onAccept = () => closeModal('modalWrongLocation')

    showModal({
      name: 'modalWrongLocation',
      onAccept: onAccept,
      text: damageLocationTitle,
      className: 'modal--tasks',
    })
  }

  const onSuccessScanDamageLocation = () => {
    const damageCaseInLocation = damageLocation.warehouse_cases_in_location.find(
      (item) => item.product_id === restockLocation.product.products_id,
    )

    if (!damageCaseInLocation) {
      createWarehouseCase({
        request: {
          data: {
            product_id: restockLocation.product.products_id,
            updated_by_user_id: user.warehouseStaffId,
            created_by_user_id: user.warehouseStaffId,
            location_case_qty: pallet.qty_cases_on_pallet,
            warehouse_location_id: damageLocation.id,
          },
        },
      })
    } else {
      updateWarehouseCase({
        id: damageCaseInLocation.id,
        data: {
          id: damageCaseInLocation.id,
          location_case_qty:
            damageCaseInLocation.location_case_qty + pallet.qty_cases_on_pallet,
        },
      })

      createDamageCases({
        request: {
          data: {
            process_type: PROCESS_TYPES.RESTOCK_PROCESS_TYPE,
            warehouse_location_id: restockLocation.warehouseLocation.id,
            count_damaged_cases: pallet.qty_cases_on_pallet,
          },
        },
      })
    }

    deleteWarehousePallet({
      id: pallet.id,
      data: {
        id: pallet.id,
      },
    })

    onComplete()
  }

  const showModalScanDamageLocationWithData = () => {
    showModal({
      name: 'modalScanDamageLocation',
      damageLocation: damageLocation,
      onSuccess: onSuccessScanDamageLocation,
      onFailure: onFailureScanDamageLocation,
      onBack: resetModals,
      className: 'modal--tasks modal--damaged',
    })
  }

  const tooltipItems = [
    {
      id: 1,
      icon: <Report className="tooltip__icon" />,
      label: 'Report Case Damage',
      className: `tooltip__item tooltip__item--disabled`,
      onClick: () => {},
    },
    {
      id: 2,
      icon: <Report className="tooltip__icon" />,
      label: 'Report Pallet Damage',
      className: 'tooltip__item',
      onClick: showModalScanDamageLocationWithData,
    },
  ]

  return (
    <div className="tasks-page tasks-page--start-restock page--restock-relocation">
      <Header>
        <Tooltip button={<MoreIcon />}>
          <ul>
            {tooltipItems.map((item) => (
              <li
                key={item.id}
                className={item.className}
                onClick={item.onClick}
              >
                {item.icon ? item.icon : ''}
                {item.label}
              </li>
            ))}
          </ul>
          <span className="triangle" />
        </Tooltip>
      </Header>
      <Scanner needToRemoveListener={false} onReceiveData={onScan} />
      <div className="content">
        <div className="content__section">
          <div className="dock__new-wrapper">
            <div className="dock">
              <span className="dock__label">LOC</span>
              <span className="dock__name">{locationTitle}</span>
            </div>
          </div>
          <h3 className="fireworks-name-new">{restockLocation.product_name}</h3>
          <ul className="fireworks-new">
            <li className="fireworks-new__item">
              <span className="fireworks__name">Model</span>
              <span className="fireworks__sub-name">
                {restockLocation.product.products_model}
              </span>
            </li>
            <li className="fireworks-new__item">
              <span className="fireworks__name">Manf Model</span>
              <span className="fireworks__sub-name">
                {restockLocation.product.products_manf_model}
              </span>
            </li>
          </ul>
        </div>
        {getBottomContent()}
      </div>
    </div>
  )
}

BulkRestockToStoragePage.propTypes = {
  damageLocation: PropTypes.object,
  currentTask: PropTypes.object,

  fetchRestockProcessList: PropTypes.func,
  resetModals: PropTypes.func,
  updateWarehouseCase: PropTypes.func,
  closeModal: PropTypes.func,
  updateWarehousePallet: PropTypes.func,
  deleteWarehousePallet: PropTypes.func,
  showModal: PropTypes.func,
  fetchBulkRestockTask: PropTypes.func,
  updateRestockProcess: PropTypes.func,
  createWarehouseCase: PropTypes.func,
  fetchLocations: PropTypes.func,
  createDamageCases: PropTypes.func,
  updateRestockLocation: PropTypes.func,
}

const mapStateToProps = (state) => ({
  damageLocation: state.location.list[0],
  currentTask: state.restockProcess.task,
})

const mapDispatchToProps = {
  fetchLocations: fetchLocationsStart,
  fetchBulkRestockTask: fetchBulkRestockTaskStart,
  fetchRestockProcessList: fetchRestockProcessListStart,

  createDamageCases: createDamageCases,
  createWarehouseCase: createWarehouseCaseSync,

  updateWarehouseCase: updateWarehouseCase,
  updateRestockProcess: updateRestockProcess,
  updateWarehousePallet: updateWarehousePallet,
  updateRestockLocation: updateRestockLocation,

  processBulkToTask,

  deleteWarehousePallet: deleteWarehousePallet,

  updateTaskAssignmentWithoutFetch,

  showModal: showModal,
  closeModal: closeModal,
  resetModals: resetModals,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BulkRestockToStoragePage)
