import { DIRECTION_ASC, DIRECTION_DESC } from 'constants/common'

export const sortObjectsList = (list, key, direction = DIRECTION_ASC) => {
  const properties = key.split('.')

  return list.sort((a, b) => {
    let valueA = a
    let valueB = b

    for (let prop of properties) {
      if (valueA[prop]) {
        valueA = valueA[prop]
      }

      if (valueB[prop]) {
        valueB = valueB[prop]
      }
    }

    const result = valueA >= valueB ? 1 : -1

    return direction === DIRECTION_DESC ? result * -1 : result
  })
}
