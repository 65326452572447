import React from 'react'
import * as PropTypes from 'prop-types'
import './style.scss'
import ModalDefault from 'components/common/Modal/ModalDefault'
import { connect } from 'react-redux'

const ModalSuccess = ({ isOpen, modalInfo }) => {
  if (!isOpen) {
    return <></>
  }

  return (
    <ModalDefault isOpen={isOpen}>
      <div className="modal modal--success">{modalInfo.children}</div>
    </ModalDefault>
  )
}

ModalSuccess.propTypes = {
  isOpen: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalSuccess',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalSuccess',
  ),
})

export default connect(mapStateToProps)(ModalSuccess)
