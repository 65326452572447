import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import Header from 'components/common/Header'
import Tooltip from 'components/common/Tooltip'
import Button from 'components/common/Button'
import Scanner from 'components/common/Scanner'
import MoreIcon from 'assets/svg/more-icon.svg'
import Report from 'assets/svg/report.svg'
import Active from 'assets/svg/active.svg'
import ActiveGrey from 'assets/svg/active-grey.svg'
import { RESTOCK_TASK_LIST_PAGE } from 'constants/routes'
import { isEmptyObject, redirect } from 'helpers/index'
import { resetModals, showModal } from 'store/modal/actions'
import {
  fetchOneRestockProcessStart,
  updateRestockLocation,
  updateScannedPallet,
  resetRestockProcess,
  processRestock,
} from 'store/restockProcess/actions'
import { fetchLocationsStart } from 'store/location/actions'
import * as query from 'querystringify'
import { LOCATION_TYPE_FILTER, WAREHOUSE_FILTER } from 'constants/filters'
import { LOCATION_TYPE } from 'constants/locationTypes'
import {
  createWarehouseCase,
  updateWarehouseCase,
} from 'store/warehouseCase/actions'
import {
  createRestockTaskAssignment,
  updateTaskAssignment,
  resetAssignments,
} from 'store/taskAssignment/actions'
import { TASK_STATUSES, TASK_TYPES } from 'constants/taskAssignment'
import { ROLES } from 'constants/roles'
import { STATUS_COMPLETED } from 'constants/processStatuses'
import { PROCESS_TYPES } from 'constants/process'
import { createDamageCases } from 'store/product/actions'
import { RESTOCK_LOCATION_STATUSES } from 'constants/restockLocationStatus'
import ExclamationSign from 'assets/svg/exclamation.svg'
import { MdWarning } from 'react-icons/md'

const INIT_DAMAGE_CASE_QTY = 0

const FROM_SCAN_ID = 1

const INIT_STATUS_PANEL = {
  id: FROM_SCAN_ID,
  isScanPalletScanned: false,
  isScanLocToScanned: false,
  text: 'Scan Pallet UPC',
  icon: <ActiveGrey className="scan-indicator" />,
}

const StartRestockPage = (props) => {
  const {
    user,
    match,
    isOpenModalScanDamageLocation,
    restockProcess,
    damageLocation,
    scannedPallet,

    resetModals,
    fetchLocations,
    showModal,
    createRestockTaskAssignment,
    createWarehouseCase,
    updateWarehouseCase,
    updateRestockLocation,
    updateTaskAssignment,
    fetchOneRestockProcess,
    createDamageCases,
    updateScannedPallet,
    resetRestockProcess,
    resetAssignments,
    processRestock,
  } = props

  const [wrongLocation, setWrongLocation] = useState('')
  const [damageCasesQty, setDamageCasesQty] = useState(INIT_DAMAGE_CASE_QTY)
  const [taskAssignment, setTaskAssignment] = useState({})
  const [statusPanel, setStatusPanel] = useState(INIT_STATUS_PANEL)

  const getLocationParamsForFetch = () =>
    query.stringify(
      {
        [WAREHOUSE_FILTER]: user.warehouseId,
        [LOCATION_TYPE_FILTER]: LOCATION_TYPE.DAMAGE_TYPE_ID,
      },
      true,
    )

  const onPageLoad = () => {
    fetchLocations(getLocationParamsForFetch())
    fetchOneRestockProcess({ id: match.params.id })
  }

  const onUpdateRestockProcess = () => {
    if (isEmptyObject(restockProcess)) {
      return
    }

    if (!restockProcess.team.length) {
      createRestockTaskAssignment({
        request: {
          data: {
            task_id: restockProcess.tasks.find(
              (task) => task.task_type_id === TASK_TYPES.RESTOCKING_TYPE,
            ).id,
            warehouse_staff_id: user.warehouseStaffId,
            role_id: ROLES.RESTOCKER,
          },
        },
      })
      fetchOneRestockProcess({ id: match.params.id })
    } else {
      setTaskAssignment(restockProcess.team[0].taskAssignment)
    }
  }

  useEffect(onPageLoad, [])
  useEffect(onUpdateRestockProcess, [restockProcess])

  if (isEmptyObject(restockProcess)) {
    return ''
  }

  const restockLocation = restockProcess.entity.restock_location

  const showModalScanDamageLocationWithData = () => {
    showModal({
      name: 'modalScanDamageLocation',
      damageLocation: damageLocation,
      onSuccess: onSuccessScanDamageLocation,
      onFailure: onFailureScanDamageLocation,
      onBack: onBackFromScanDamageLocation,
      className: 'modal--tasks modal--damaged',
    })
  }

  const showModalDamageQtyWithData = () => {
    showModal({
      name: 'modalDamageQty',
      value: damageCasesQty,
      onConfirm: onConfirmDamageQty,
      onDeny: resetModals,
      className: 'modal--tasks modal--damaged',
    })
  }

  const tooltipItems = [
    {
      id: 1,
      icon: <Report className="tooltip__icon" />,
      label: 'Report Case Damage',
      className: `tooltip__item ${
        statusPanel.isScanLocToPalletScanned ? 'tooltip__item--disabled' : ''
      }`,
      onClick: statusPanel.isScanLocToPalletScanned
        ? showModalDamageQtyWithData
        : () => false,
    },
    {
      id: 2,
      icon: <Report className="tooltip__icon" />,
      label: 'Report Pallet Damage',
      className: 'tooltip__item',
      onClick: showModalScanDamageLocationWithData,
    },
  ]

  const isPalletScanned = statusPanel.isScanPalletScanned

  const currentLoc = isPalletScanned
    ? 'warehouse_location_to'
    : 'warehouse_location_from'

  const locationLabel = isPalletScanned ? 'TO' : 'FROM'
  const locationTitle = `${restockLocation[currentLoc].regular_location.aisle.name}-${restockLocation[currentLoc].regular_location.bay.name}-${restockLocation[currentLoc].regular_location.level}`
  const locationQty =
    restockLocation[currentLoc].warehouse_cases_in_location.location_case_qty

  const onScan = (barcode) => {
    switch (true) {
      case !statusPanel.isScanPalletScanned:
        const pallet = restockLocation.warehouse_pallets.find(
          (pallet) => pallet.pallet_upc.toString() === barcode.toString(),
        )

        if (!pallet) {
          showModal({
            name: 'modalWarning',
            onAccept: resetModals,
            onDeny: resetModals,
            text: 'Wrong Pallet UPC',
            className: 'modal--tasks',
            modalIcon: (
              <ExclamationSign className="icon--restock icon--exclamation" />
            ),
          })

          return
        }

        updateScannedPallet(pallet)

        setStatusPanel({
          ...statusPanel,
          icon: <Active className="scan-indicator" />,
        })
        setTimeout(
          () =>
            setStatusPanel({
              ...statusPanel,
              isScanPalletScanned: true,
              text: 'Scan Location UPC',
            }),
          1000,
        )

        // onStageChanged()
        break
      case !statusPanel.isScanLocToScanned:
        if (restockLocation.warehouse_location_to.upc !== barcode.toString()) {
          setWrongLocation(locationTitle)
          showModal({
            name: 'modalWrongLocation',
            onAccept: resetModals,
            text: wrongLocation,
            className: 'modal--tasks',
          })
          return
        }
        if (!scannedPallet) {
          showModal({
            name: 'modalWarning',
            onAccept: resetModals,
            onDeny: resetModals,
            text: 'Problem With Scanned Pallet',
            className: 'modal--tasks',
            modalIcon: (
              <ExclamationSign className="icon--restock icon--exclamation" />
            ),
          })

          return
        }

        setStatusPanel({
          ...statusPanel,
          icon: <Active className="scan-indicator" />,
        })
        setTimeout(() => {
          setStatusPanel({
            ...statusPanel,
            isScanLocToScanned: true,
          })
        }, 1000)
        break
      default:
        break
    }
  }

  const onConfirmDamageQty = (quantity) => {
    resetModals()
    setDamageCasesQty(quantity)
    showModalScanDamageLocationWithData()
  }

  const onSuccessScanDamageLocation = () => {
    resetModals()

    // Check if pallet is scanned and send all the cases
    const casesQty = !damageCasesQty ? restockLocation.quantity : damageCasesQty

    const damageCaseInLocation = damageLocation.warehouse_cases_in_location.find(
      (item) => item.product_id === restockLocation.product.products_id,
    )

    if (!damageCaseInLocation) {
      createWarehouseCase({
        data: {
          product_id: restockLocation.product.products_id,
          updated_by_user_id: user.warehouseStaffId,
          created_by_user_id: user.warehouseStaffId,
          location_case_qty: casesQty,
          warehouse_location_id: damageLocation.id,
        },
      })
    } else {
      updateWarehouseCase({
        location: getLocationParamsForFetch(),
        id: damageCaseInLocation.id,
        data: {
          id: damageCaseInLocation.id,
          location_case_qty: damageCaseInLocation.location_case_qty + casesQty,
        },
      })

      createDamageCases({
        request: {
          data: {
            process_type: PROCESS_TYPES.ORDER_PROCESS_TYPE,
            warehouse_location_id: restockLocation.warehouseLocation.id,
            count_damaged_cases: damageCasesQty,
          },
        },
      })
    }

    // Request to backend with damage cases

    if (restockLocation.quantity === casesQty) {
      onComplete()
    }
  }

  const onFailureScanDamageLocation = () => {
    resetModals()
    setWrongLocation(
      `${damageLocation.regular_location.aisle_id.name}-${damageLocation.regular_location.bay_id.name}`,
    )
    showModal({
      name: 'modalWrongLocation',
      onAccept: resetModals,
      text: wrongLocation,
      className: 'modal--tasks',
    })
  }

  const onBackFromScanDamageLocation = () => {
    resetModals()

    if (damageCasesQty) {
      setDamageCasesQty(INIT_DAMAGE_CASE_QTY)
      showModalDamageQtyWithData()
    }
  }

  const endRestock = () => {
    resetModals()

    updateTaskAssignment({
      id: taskAssignment.id,
      data: {
        id: taskAssignment.id,
        status: TASK_STATUSES.FINISHED_TYPE,
      },
    })

    updateRestockLocation({
      id: restockLocation.id,
      data: {
        id: restockLocation.id,
        status_id: RESTOCK_LOCATION_STATUSES.COMPLETE,
      },
    })

    resetRestockProcess()
    resetAssignments()

    setWrongLocation('')
    setDamageCasesQty(INIT_DAMAGE_CASE_QTY)
    setTaskAssignment({})
    setStatusPanel(INIT_STATUS_PANEL)

    redirect(RESTOCK_TASK_LIST_PAGE)
  }

  const endRestockConfirm = () => {
    showModal({
      name: 'modalConfirm',
      onAccept: endRestock,
      onDeny: resetModals,
      className: 'modal--picking-task',
      children: (
        <div className="modal--content-centered">
          <MdWarning style={{ 'font-size': '75px', color: '#ffbf00' }} />
          <p className="text--bold">Cancel Restock</p>
          <p>Are you sure you want cancel the restock?</p>
        </div>
      ),
    })
  }

  const onComplete = () => {
    const restockLocationTo = restockLocation.warehouse_location_to
    const fromCaseInLocation =
      restockLocation.warehouse_location_from.warehouse_cases_in_location
    const toCaseInLocation = restockLocationTo.warehouse_cases_in_location
    const pickLocation = restockLocation.restocked_pick_location

    processRestock({
      request: {
        data: {
          to_location_id: restockLocationTo.id,
          from_location_id: restockLocation.warehouse_location_from.id,
          from_warehouse_case_id: fromCaseInLocation.id,
          restock_qty: scannedPallet.qty_cases_on_pallet,
          damageCasesQty: damageCasesQty,
          from_location_case_qty:
            fromCaseInLocation.location_case_qty -
            scannedPallet.qty_cases_on_pallet,
          warehouse_pallet_id: scannedPallet.id,
          task_assignment_id: taskAssignment.id,
          task_assignment_status: TASK_STATUSES.FINISHED_TYPE,
          to_warehouse_case_id: toCaseInLocation.id,
          to_location_case_qty:
            toCaseInLocation.location_case_qty +
            scannedPallet.qty_cases_on_pallet -
            damageCasesQty,
          process_id: restockProcess.id,
          process_status: STATUS_COMPLETED,
          restock_location_id: restockLocation.id,
          restock_status_id: RESTOCK_LOCATION_STATUSES.COMPLETE,
          restocked_pick_location: !!restockLocation.restocked_pick_location,
          restocked_pick_location_id: pickLocation ? pickLocation.id : null,
          pick_location_task_assignment_id: pickLocation
            ? pickLocation.task_assignment_id
            : null,
        },
      },
      afterAction: () => {},
    })

    resetRestockProcess()
    resetAssignments()

    setWrongLocation('')
    setDamageCasesQty(INIT_DAMAGE_CASE_QTY)
    setTaskAssignment({})
    setStatusPanel(INIT_STATUS_PANEL)

    redirect(RESTOCK_TASK_LIST_PAGE)
  }

  return (
    <div className="tasks-page tasks-page--start-restock">
      {!isOpenModalScanDamageLocation && (
        <Scanner needToRemoveListener={false} onReceiveData={onScan} />
      )}

      <Header>
        {isPalletScanned ? (
          <Tooltip button={<MoreIcon />}>
            <ul style={{ zIndex: '999' }}>
              {tooltipItems.map((item) => (
                <li
                  key={item.id}
                  className={item.className}
                  onClick={item.onClick}
                >
                  {item.icon ? item.icon : ''}
                  {item.label}
                </li>
              ))}
            </ul>
            <span className="triangle" />
          </Tooltip>
        ) : (
          <></>
        )}
        <Button onClick={endRestockConfirm}>Cancel Restock</Button>
      </Header>
      <div className="content">
        <div className="content__section">
          <div className="dock__new-wrapper">
            <div className="dock">
              <span className="dock__label">{locationLabel}</span>
              <span className="dock__name">{locationTitle}</span>
              <span className="restock-qty-task">[ {locationQty} ]</span>
            </div>
          </div>
          <h3 className="fireworks-name-new">
            {restockLocation.name
              ? restockLocation.name
              : restockLocation.product_name}
          </h3>
          {/* // todo - the api on this page seems to return something different than most similar pages. Usually ther's .name, but for Zone Pick Restocks it's .product_name */}
          <ul className="fireworks-new">
            <li className="fireworks-new__item">
              <span className="fireworks__name">Model</span>
              <span className="fireworks__sub-name">
                {restockLocation.product.products_model}
              </span>
            </li>
            <li className="fireworks-new__item">
              <span className="fireworks__name">Manf Model</span>
              <span className="fireworks__sub-name">
                {restockLocation.product.products_manf_model}
              </span>
            </li>
          </ul>
        </div>
        <div className="content__section content__section--white">
          <span className="status">
            {!statusPanel.isScanLocToScanned ? (
              <>
                {statusPanel.text} {statusPanel.icon}
              </>
            ) : (
              <Button onClick={onComplete}>Complete</Button>
            )}
          </span>
        </div>
        <div></div>
      </div>
    </div>
  )
}

StartRestockPage.propTypes = {
  modals: PropTypes.object,
  damageLocation: PropTypes.object,
  restockProcess: PropTypes.object,

  isOpenModalScanDamageLocation: PropTypes.bool,
  resetModals: PropTypes.func,
}

const mapStateToProps = (state) => ({
  // taskAssignmentList: state.taskAssignment.items,
  restockProcess: state.restockProcess.item,
  damageLocation: state.location.list[0],
  isOpenModalScanDamageLocation: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalScanDamageLocation',
  ),
  scannedPallet: state.restockProcess.scannedPallet,
})

const mapDispatchToProps = {
  fetchLocations: fetchLocationsStart,
  fetchOneRestockProcess: fetchOneRestockProcessStart,
  showModal: showModal,
  updateWarehouseCase: updateWarehouseCase,
  updateTaskAssignment: updateTaskAssignment,
  updateRestockLocation: updateRestockLocation,
  createRestockTaskAssignment: createRestockTaskAssignment,
  createWarehouseCase: createWarehouseCase,
  resetModals: resetModals,
  createDamageCases: createDamageCases,
  updateScannedPallet: updateScannedPallet,
  resetRestockProcess: resetRestockProcess,
  resetAssignments: resetAssignments,
  processRestock: processRestock,
}

export default connect(mapStateToProps, mapDispatchToProps)(StartRestockPage)
