import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import Header from 'components/common/Header'
import LocationToIcon from 'assets/svg/to-location.svg'
import {
  fetchOneRestockProcessStart,
  fetchRestockProcessListStart,
} from 'store/restockProcess/actions'
import Active from 'assets/svg/active--ipad.svg'
import ActiveGrey from 'assets/svg/active-grey--ipad.svg'
import Scanner from 'components/common/Scanner'
import {
  closeModal,
  resetModals,
  showModal,
  showPendingModal,
} from 'store/modal/actions'
import Button from 'components/common/Button'
import {
  createWarehousePallet,
  deleteWarehousePallet,
  fetchOneWarehousePalletStart,
  fetchOneWarehousePalletSuccess,
  updateWarehousePallet,
  updateWarehousePalletSync,
} from 'store/warehousePallet/actions'
import { isEmptyObject, redirect } from 'helpers/index'
import { updateWarehouseCase } from 'store/warehouseCase/actions'
import Report from 'assets/svg/report.svg'
import Tooltip from 'components/common/Tooltip'
import MoreIcon from 'assets/svg/more-icon.svg'
import * as query from 'querystringify'
import { LOCATION_TYPE_FILTER, WAREHOUSE_FILTER } from 'constants/filters'
import { LOCATION_TYPE } from 'constants/locationTypes'
import { fetchLocationsStart } from 'store/location/actions'
import { PROCESS_TYPES } from 'constants/process'
import { createDamageCases } from 'store/product/actions'
import BarcodeService from 'services/barcodeService'
import {
  COMBINE_PALLET_PAGE,
  PARTIAL_PALLET_MOVE_TO_WRAP_PAGE,
} from 'constants/routes'
import Printer from 'assets/svg/printer-icon.svg'
import DashedArrow from 'assets/svg/dashed-arrow--right.svg'
import Paper from 'assets/svg/paper-icon.svg'
import Pallet from 'assets/svg/wrapped-pallet-icon.svg'
import ExclamationSign from 'assets/svg/exclamation.svg'
import { openPrintDialog } from 'store/print/actions'
import Print from 'components/common/Print'
import { PALLET_LABEL } from 'constants/print'

const MoveToWrap = (props) => {
  const {
    match,
    user,
    damageLocation,
    newWarehousePallet,
    currentProcess,
    modalDamagePutawayLocationIsOpen,

    resetModals,
    updateWarehousePalletSync,
    updateWarehouseCase,
    closeModal,
    fetchLocations,
    createDamageCases,
    updateWarehousePallet,
    createWarehousePallet,
    setOneWarehousePallet,
    fetchOneWarehousePallet,
    deleteWarehousePallet,
    showModal,
    showPendingModal,
    fetchOneRestockProcess,
    openPrintDialog,
  } = props

  const NoPalletId = 'null'
  const processId = match.params.processId
  const palletId = match.params.palletId

  const [locationTitle, setLocationTitle] = useState('')
  const [restockLocation, setRestockLocation] = useState(undefined)
  const [warehousePallet, setWarehousePallet] = useState(undefined)
  const [
    fromWarehouseCasesInLocation,
    setFromWarehouseCasesInLocation,
  ] = useState(undefined)
  const [toWarehouseCasesInLocation, setToWarehouseCasesInLocation] = useState(
    undefined,
  )
  const [isLocationScanned, setIsLocationScanned] = useState(false)
  const [isPalletScanned, setIsPalletScanned] = useState(false)
  const [isScanned, setIsScanned] = useState(false)
  const [iteration, setIteration] = useState(1)
  const [isLastPallet, setIsLastPallet] = useState(false)
  const [isPalletCreated, setIsPalletCreated] = useState(false)
  const [isPalletPrinted, setIsPalletPrinted] = useState(false)

  const onMount = () => {
    showPendingModal()
    fetchOneRestockProcess({
      id: processId,
    })

    fetchLocations(getParamsForDamageLocation())

    if (palletId !== NoPalletId) {
      fetchOneWarehousePallet(palletId)
      setIsPalletCreated(true)
    }
  }

  const getParamsForDamageLocation = () =>
    query.stringify(
      {
        [WAREHOUSE_FILTER]: user.warehouseId,
        [LOCATION_TYPE_FILTER]: LOCATION_TYPE.DAMAGE_TYPE_ID,
      },
      true,
    )

  const onUpdateCurrentProcess = () => {
    if (!isEmptyObject(currentProcess)) {
      const restockLocations = currentProcess.entity.restock_location
      const deletedRestockLocations = restockLocations.filter(
        (item) => !!item.warehouse_pallets[0].is_deleted === true,
      )
      const notDeletedRestockLocations = restockLocations.filter(
        (item) => !!item.warehouse_pallets[0].is_deleted === false,
      )

      if (notDeletedRestockLocations.length === 1) {
        setIsLastPallet(true)
        setOneWarehousePallet({})
        setRestockLocation(notDeletedRestockLocations[0])
        setIteration(deletedRestockLocations.length + 1)
      } else if (notDeletedRestockLocations.length === 0) {
        setRestockLocation(restockLocations[deletedRestockLocations.length - 1])
        setIteration(deletedRestockLocations.length)
      } else {
        setOneWarehousePallet({})
        setRestockLocation(notDeletedRestockLocations[0])
        setIteration(deletedRestockLocations.length + 1)
      }
    }
  }

  const onUpdateRestockLocation = () => {
    if (restockLocation !== undefined) {
      const aisle =
        restockLocation.warehouse_location_from.regular_location.aisle_id.name
      const bay =
        restockLocation.warehouse_location_from.regular_location.bay_id.name
      const level =
        restockLocation.warehouse_location_from.regular_location.level

      if ((isLocationScanned && isPalletScanned) || isPalletCreated) {
        setLocationTitle('Wrap Area')
      } else {
        setLocationTitle(`${aisle}-${bay}-${level}`)
      }

      setWarehousePallet(restockLocation.warehouse_pallets[0])

      const productId = restockLocation.product.products_id
      const fromWarehouseCasesInLocations =
        restockLocation.warehouse_location_from.warehouse_cases_in_location
      const fromWarehouseCasesInLocation = fromWarehouseCasesInLocations.find(
        (item) => item.product_id === productId,
      )

      setFromWarehouseCasesInLocation(fromWarehouseCasesInLocation)

      const toWarehouseCasesInLocations =
        restockLocation.warehouse_location_to.warehouse_cases_in_location
      const toWarehouseCasesInLocation = toWarehouseCasesInLocations.find(
        (item) => item.product_id === productId,
      )

      setToWarehouseCasesInLocation(toWarehouseCasesInLocation)
    }
  }

  const closePending = () => {
    if (
      warehousePallet !== undefined &&
      damageLocation !== undefined &&
      !isEmptyObject(currentProcess)
    ) {
      closeModal('modalPending')
    }
  }

  useEffect(onMount, [])
  useEffect(onUpdateCurrentProcess, [currentProcess])
  useEffect(onUpdateRestockLocation, [restockLocation])
  useEffect(closePending, [warehousePallet, damageLocation, currentProcess])

  if (
    warehousePallet === undefined ||
    damageLocation === undefined ||
    isEmptyObject(currentProcess)
  ) {
    return <></>
  }

  const getAllPallets = () => {
    let allPallets = []

    currentProcess.entity.restock_location.forEach((item) => {
      item.warehouse_pallets.forEach((pallet) => {
        allPallets = [pallet, ...allPallets]
      })
    })

    return allPallets
  }

  const getPalletsToDelete = () => {
    return getAllPallets().filter((item) => item.is_deleted === 1)
  }

  const deleteAllPalletsToDelete = () => {
    getPalletsToDelete().forEach((pallet) => {
      deleteWarehousePallet({
        id: pallet.id,
        data: {
          id: pallet.id,
        },
      })
    })
  }

  const createNewWarehousePallet = () => {
    const warehouseId =
      restockLocation.warehouse_location_to.warehouse.warehouse_id

    createWarehousePallet({
      request: {
        data: {
          warehouse_case_id: toWarehouseCasesInLocation.id,
          container_id: null,
          pallet_upc: '111',
          qty_cases_on_pallet: getCountCaseOnPallets(),
        },
      },
      afterAction: (item) => {
        updateWarehousePalletSync({
          request: {
            id: item.id,
            data: {
              id: item.id,
              pallet_upc: BarcodeService.generatePalletBarcode(
                item.id,
                warehouseId,
              ),
            },
          },
          afterAction: () => {
            redirect(
              PARTIAL_PALLET_MOVE_TO_WRAP_PAGE.replace(
                ':processId',
                currentProcess.id,
              ).replace(':palletId', item.id),
            )
            fetchOneWarehousePallet(item.id)
            setIsPalletCreated(true)
          },
        })
      },
    })
  }

  const onPalletScanned = () => {
    updateWarehouseCase({
      id: fromWarehouseCasesInLocation.id,
      data: {
        id: fromWarehouseCasesInLocation.id,
        location_case_qty:
          fromWarehouseCasesInLocation.location_case_qty -
          warehousePallet.qty_cases_on_pallet,
      },
    })

    updateWarehousePalletSync({
      request: {
        id: warehousePallet.id,
        data: {
          id: warehousePallet.id,
          is_deleted: 1,
        },
      },
    })

    if (!isPalletCreated && isLastPallet) {
      createNewWarehousePallet()
    }
  }

  const onScan = (barcode) => {
    if (!isLocationScanned) {
      if (
        restockLocation.warehouse_location_from.upc.toString() ===
        barcode.toString()
      ) {
        setIsScanned(true)
        setTimeout(() => {
          setIsLocationScanned(true)
          setIsScanned(false)
        }, 1500)
      } else {
        showModalWrongLocationWithData()
      }
    } else if (!isPalletScanned) {
      if (warehousePallet.pallet_upc.toString() === barcode.toString()) {
        onPalletScanned()
        setIsScanned(true)
        setTimeout(() => {
          setIsPalletScanned(true)
          setIsScanned(false)
        }, 1500)
      } else {
        showModal({
          name: 'modalWarning',
          onAccept: resetModals,
          onDeny: resetModals,
          text: 'Wrong Pallet UPC',
          className: 'modal--tasks',
          modalIcon: (
            <ExclamationSign className="icon--restock icon--exclamation" />
          ),
        })
      }
    }
  }

  const onNext = () => {
    fetchOneRestockProcess({
      id: processId,
    })

    setIsPalletScanned(false)
    setIsLocationScanned(false)
  }

  const onAfterPrint = () => {
    setIsPalletPrinted(true)
  }

  const onPrintPalletLabel = () => {
    openPrintDialog({
      id: newWarehousePallet.id,
      document: PALLET_LABEL,
      onPrintDialogClose: onAfterPrint,
    })
  }

  const generateButtonPrintLabel = () => {
    if (isEmptyObject(newWarehousePallet)) {
      return <></>
    }

    return (
      <>
        <Button onClick={onPrintPalletLabel} />
      </>
    )
  }

  const getBottomContent = () => {
    if (isPalletCreated) {
      return (
        <div className="content__section content__section--white">
          <span className="status">{generateButtonPrintLabel()}</span>
        </div>
      )
    }

    if (!isLocationScanned || !isPalletScanned) {
      return (
        <div className="content__section content__section--white">
          <span className="status">
            {`Scan ${!isLocationScanned ? `Location` : `Pallet`} UPC`}
            {isScanned ? <Active /> : <ActiveGrey />}
          </span>
        </div>
      )
    }

    return (
      <div className="content__section content__section--white">
        <span className="status">
          <Button onClick={onNext}>{'Next'}</Button>
        </span>
      </div>
    )
  }

  const getCountCaseOnPallets = () => {
    return currentProcess.entity.restock_location.reduce(
      (accumulator, currentValue) => {
        return (
          accumulator + currentValue.warehouse_pallets[0].qty_cases_on_pallet
        )
      },
      0,
    )
  }

  const getCountCaseOnNewPallet = () => newWarehousePallet.qty_cases_on_pallet

  const openModalDamageQty = () => {
    resetModals()
    showModal({
      name: 'modalDamageQty',
      value: 1,
      onConfirm: openModalDamagePutawayLocation,
      onDen: resetModals,
      maxValue: getCountCaseOnNewPallet(),
      className: 'modal--tasks modal--damaged',
    })
  }

  const openModalDamagePutawayLocation = (value) => {
    resetModals()
    showModal({
      name: 'modalDamagePutawayLocation',
      onCaseUpcCorrect: onConfirmDamageLocationScan,
      onCaseUpcNotCorrect: showModalWrongLocationWithData,
      onBack: openModalDamageQty,
      damageQuantity: value,
      text: getDamageLocationName(),
      upc: getDamageLocationUPC(),
      className: 'modal--tasks modal--damaged',
    })
  }

  const showModalWrongLocationWithData = () => {
    showModal({
      name: 'modalWrongLocation',
      onAccept: onCloseModalWrongLocation,
      text: modalDamagePutawayLocationIsOpen
        ? getDamageLocationName()
        : locationTitle,
    })
  }

  const tooltipItems = [
    {
      id: 1,
      icon: <Report className="tooltip__icon" />,
      label: 'Report Case Damage',
      className: 'tooltip__item',
      onClick: openModalDamageQty,
    },
    {
      id: 2,
      icon: <Report className="tooltip__icon" />,
      label: 'Report Pallet Damage',
      className: `tooltip__item tooltip__item--disabled`,
      onClick: () => {},
    },
  ]

  const onConfirmDamageLocationScan = (value) => {
    resetModals()

    const productId = restockLocation.product.products_id

    createDamageCases({
      request: {
        data: {
          product_id: productId,
          process_type: PROCESS_TYPES.RESTOCK_PROCESS_TYPE,
          warehouse_location_id: damageLocation.id,
          count_damaged_cases: value,
        },
      },
    })

    updateWarehousePallet({
      id: newWarehousePallet.id,
      data: {
        id: newWarehousePallet.id,
        qty_cases_on_pallet: newWarehousePallet.qty_cases_on_pallet - value,
      },
    })

    fetchOneWarehousePallet(palletId)
  }

  const getInstruction = () => {
    return (
      <div className="staging-or-putaway-block">
        <Header>Partial Label</Header>
        <div className="content">
          <div className="icons">
            <Printer style={{ marginRight: '30px' }} />
            <DashedArrow style={{ marginRight: '35px' }} />
            <Paper style={{ marginRight: '30px' }} />
            <DashedArrow />
            <Pallet />
          </div>
          <div className="buttons">
            <Button onClick={InstructionNext}>{'Next'}</Button>
          </div>
        </div>
      </div>
    )
  }

  const InstructionNext = () => {
    deleteAllPalletsToDelete()
    redirect(
      COMBINE_PALLET_PAGE.replace(':processId', currentProcess.id).replace(
        ':palletId',
        newWarehousePallet.id,
      ),
    )
  }

  const onCloseModalWrongLocation = () => closeModal('modalWrongLocation')

  const getDamageLocationName = () =>
    damageLocation ? `${damageLocation.aisle}-${damageLocation.bay}` : ''

  const getDamageLocationUPC = () => (damageLocation ? damageLocation.upc : ' ')

  return (
    <div className="tasks-page tasks-page--start-restock">
      <Header>
        {isPalletCreated && (
          <Tooltip button={<MoreIcon />}>
            <ul>
              {tooltipItems.map((item) => (
                <li
                  key={item.id}
                  className={item.className}
                  onClick={item.onClick}
                >
                  {item.icon ? item.icon : ''}
                  {item.label}
                </li>
              ))}
            </ul>
            <span className="triangle" />
          </Tooltip>
        )}
        {`Partial Pallet ${iteration}`}
      </Header>
      {!isPalletCreated && (
        <Scanner needToRemoveListener={false} onReceiveData={onScan} />
      )}
      <div className="content">
        <div className="content__section">
          <div className="dock__wrapper">
            <div className="dock">
              <span className="dock__label">LOC</span>
              <span className="dock__name">{locationTitle}</span>
            </div>
          </div>
          <h3 className="fireworks-name">{restockLocation.product_name}</h3>
          <ul className="fireworks">
            <li className="fireworks__item">
              <span className="fireworks__name">Model</span>
              <span className="fireworks__sub-name">
                {restockLocation.product.products_model}
              </span>
            </li>
            <li className="fireworks__item">
              <span className="fireworks__name">Manf Model</span>
              <span className="fireworks__sub-name">
                {restockLocation.product.products_manf_model}
              </span>
            </li>
            <li className="fireworks__item">
              <span className="fireworks__name">Case</span>
              <span className="fireworks__sub-name">
                {warehousePallet.qty_cases_on_pallet}
              </span>
            </li>
            <li className="fireworks__item">
              <LocationToIcon />
            </li>
          </ul>
        </div>
        {getBottomContent()}
      </div>
      {isPalletPrinted && getInstruction()}
      <Print />
    </div>
  )
}

MoveToWrap.propTypes = {
  damageLocation: PropTypes.object,
}

const mapStateToProps = (state) => ({
  currentProcess: state.restockProcess.item,
  damageLocation: state.location.list[0],
  modalDamagePutawayLocationIsOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalDamagePutawayLocation',
  ),
  newWarehousePallet: state.warehousePalletReducer.item,
})

const mapDispatchToProps = {
  fetchRestockProcessList: fetchRestockProcessListStart,
  resetModals: resetModals,
  updateWarehousePalletSync: updateWarehousePalletSync,
  updateWarehouseCase: updateWarehouseCase,
  closeModal: closeModal,
  fetchLocations: fetchLocationsStart,
  createDamageCases: createDamageCases,
  updateWarehousePallet: updateWarehousePallet,
  createWarehousePallet: createWarehousePallet,
  setOneWarehousePallet: fetchOneWarehousePalletSuccess,
  fetchOneWarehousePallet: fetchOneWarehousePalletStart,
  deleteWarehousePallet: deleteWarehousePallet,
  showModal: showModal,
  showPendingModal: showPendingModal,
  fetchOneRestockProcess: fetchOneRestockProcessStart,
  openPrintDialog: openPrintDialog,
}

export default connect(mapStateToProps, mapDispatchToProps)(MoveToWrap)
