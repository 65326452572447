import { requestWithIgnore } from 'helpers/index'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}app-info`

export const fetch = (payload) =>
  requestWithIgnore({
    url: `${apiEndpoint}/${payload.project_id}`,
    method: 'GET',
  })
