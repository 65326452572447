import React from 'react'
import * as PropTypes from 'prop-types'
import { HOME_PAGE } from 'constants/routes'
import { redirect } from 'helpers/index'
import './style.scss'

const onClose = () => redirect(HOME_PAGE)

const CloseButton = (props) => {
  const onClose = () => {
    if (props.onCloseAction) {
      props.onCloseAction()
    }
    redirect(HOME_PAGE)
  }
  return (
    <div className="closebutton" onClick={onClose}>
      <div>X</div>
      {props.children}
    </div>
  )
}

CloseButton.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.any,
}

export default CloseButton
