import { types } from './actions'

const INIT = {
  items: [],
  isLoading: false,
}

const measurementReducer = (state = INIT, action) => {
  const { type, payload } = action

  switch (type) {
    case types.FETCH_CREATE_MEASUREMENT_ITEMS_START:
    case types.FETCH_MEASUREMENT_ITEMS_START:
      return { ...state, isLoading: true }
    case types.FETCH_CREATE_MEASUREMENT_ITEMS_SUCCESS:
    case types.FETCH_MEASUREMENT_ITEMS_SUCCESS:
      return { items: payload, isLoading: false }
    case types.FETCH_CREATE_MEASUREMENT_ITEMS_FAIL:
    case types.FETCH_MEASUREMENT_ITEMS_FAIL:
      return INIT
    case types.UPDATE_MEASUREMENT_ITEMS:
    case types.CLEAR_MEASUREMENT_ITEMS:
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default measurementReducer
