export const types = {
  FETCH_QA_HOLD_LIST_SUCCESS: 'FETCH_QA_HOLD_LIST_SUCCESS',
  FETCH_QA_HOLD_LIST_FAIL: 'FETCH_QA_HOLD_LIST_FAIL',
  FETCH_QA_HOLD_LIST_START: 'FETCH_QA_HOLD_LIST_START',
  DELETE_QA_HOLD: 'DELETE_QA_HOLD',
  UPDATE_QA_HOLD: 'UPDATE_QA_HOLD',
}

export const fetchQaHoldListSuccess = (payload) => ({
  type: types.FETCH_QA_HOLD_LIST_SUCCESS,
  payload,
})

export const fetchQaHoldListFail = (payload) => ({
  type: types.FETCH_QA_HOLD_LIST_FAIL,
  payload,
})

export const fetchQaHoldListStart = (payload) => ({
  type: types.FETCH_QA_HOLD_LIST_START,
  payload,
})

export const deleteQaHold = (payload) => ({
  type: types.DELETE_QA_HOLD,
  payload,
})

export const updateQaHold = (payload) => ({
  type: types.UPDATE_QA_HOLD,
  payload,
})
