import React, { useState, useRef } from 'react'
import * as PropTypes from 'prop-types'
import './style.scss'

import ReactSwipe from 'react-swipe'

const Swipe = (props) => {
  const { rowBackgroundElement, children, onSwipe, animated = true } = props

  const POS_START_ANIMATION = 2
  const POS_END_ANIMATION = 10
  const ROUND_POSITION = 100

  const WIDTH_INCREASE_VALUE = 15
  const WIDTH_DECREASE_VALUE = 15
  const WIDTH_NULL = 0

  const OPACITY_DECREASE_VALUE = 25

  const INTERVAL_END_ANIMATION = 100
  const CLEAR_TIMEOUT = 300

  const [isSwipeActive, changeSwipeTouchStatus] = useState(false)

  const rowBackgroundElementRef = useRef(null)
  const swipeItemRef = useRef(null)

  const toggle = (status) => changeSwipeTouchStatus(status)

  const onTouchMove = (posFromSwipe) => {
    toggle(true)
    if (animated) {
      const rowBackgroundElement = rowBackgroundElementRef.current
      const position = Math.round(posFromSwipe * ROUND_POSITION)
      if (position > POS_START_ANIMATION) {
        rowBackgroundElement.style.width = `${
          position + WIDTH_INCREASE_VALUE
        }px`
        rowBackgroundElement.style.opacity = position / OPACITY_DECREASE_VALUE
      }
    }
  }

  const onTouchEnd = () => {
    if (animated) {
      const rowBackgroundElement = rowBackgroundElementRef.current
      const rowBackgroundElementWidth = rowBackgroundElement.offsetWidth
      const timer = setInterval(() => {
        if (rowBackgroundElementWidth < POS_END_ANIMATION) {
          rowBackgroundElement.style.width = `${WIDTH_NULL}px`
          toggle(false)
        }
        rowBackgroundElement.style.width = `${
          rowBackgroundElementWidth - WIDTH_DECREASE_VALUE
        }px`
      }, INTERVAL_END_ANIMATION)
      setTimeout(() => clearInterval(timer), CLEAR_TIMEOUT)
    }
  }

  const callBack = () => {
    toggle(false)
    onSwipe()
  }

  return (
    <div
      className={`swipe${isSwipeActive ? ' swipe--active' : ''}`}
      onTouchEnd={onTouchEnd}
    >
      <div className="swipe__icon" ref={rowBackgroundElementRef}>
        {rowBackgroundElement}
      </div>
      <ReactSwipe
        className="carousel"
        swipeOptions={{
          continuous: false,
          callback: callBack,
          swiping: onTouchMove,
          transitionEnd: () => toggle(false),
        }}
      >
        <div className="swipe__primary" ref={swipeItemRef}>
          {children}
        </div>
        <div className="swipe__secondary" />
      </ReactSwipe>
    </div>
  )
}

Swipe.propTypes = {
  animated: PropTypes.bool,

  onSwipe: PropTypes.func,

  children: PropTypes.element,
  rowBackgroundElement: PropTypes.element,
}

export default Swipe
