import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import Header from 'components/common/Header'
import CloseButton from 'components/common/CloseButton'
import Scanner from 'components/common/Scanner'
import { connect } from 'react-redux'
import { redirect } from 'helpers/index'
import Active from 'assets/svg/active--ipad.svg'
import ActiveGrey from 'assets/svg/active-grey--ipad.svg'
import * as query from 'querystringify'
import { LOCATION_TYPE_FILTER, WAREHOUSE_FILTER } from 'constants/filters'
import { LOCATION_TYPE } from 'constants/locationTypes'
import { fetchLocationsStart } from 'store/location/actions'
import { CONTAINER_LIST_PAGE } from 'constants/routes'

const ScanQuarantineLocationPage = (props) => {
  const {
    user,
    quarantineLocation,

    fetchLocations,
  } = props

  const [isScanned, setIsScanned] = useState(false)

  const getLocationParamsForFetch = () =>
    query.stringify(
      {
        [WAREHOUSE_FILTER]: user.warehouseId,
        [LOCATION_TYPE_FILTER]: LOCATION_TYPE.QUARANTINE_TYPE_ID,
      },
      true,
    )

  const onPageLoad = () => {
    fetchLocations(getLocationParamsForFetch())
  }

  useEffect(onPageLoad, [])

  const getQuarantineLocationName = () => {
    if (quarantineLocation) {
      return `${quarantineLocation.aisle}-${quarantineLocation.bay}`
    }

    return ''
  }

  const onScan = (barcode) => {
    if (quarantineLocation.upc.toString() === barcode.toString()) {
      setIsScanned(true)

      setTimeout(() => {
        redirect(CONTAINER_LIST_PAGE)
      }, 1500)
    }
  }

  return (
    <div className="page page--restock page--qa-custom">
      <Header>
        <div className="title">QA Custom</div>
        <CloseButton />
      </Header>
      <Scanner onReceiveData={onScan} needToRemoveListener={false} />
      <div className="content">
        <div className="content__section">
          <div className="dock__wrapper">
            <div className="dock">
              <span className="dock__label">LOC</span>
              <span className="dock__name">{getQuarantineLocationName()}</span>
            </div>
          </div>
        </div>
        <div className="content__section--white">
          <span className="status">
            Scan Location UPC
            {isScanned ? <Active /> : <ActiveGrey />}
          </span>
        </div>
      </div>
    </div>
  )
}

ScanQuarantineLocationPage.propTypes = {
  quarantineLocation: PropTypes.object,
}

const mapStateToProps = (state) => ({
  quarantineLocation: state.location.list[0],
})

const mapDispatchToProps = {
  fetchLocations: fetchLocationsStart,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScanQuarantineLocationPage)
