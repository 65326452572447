import { types } from './actions'

const INIT = {
  relocateTask: null,
}

const relocateReducer = (state = INIT, action) => {
  const { type, payload } = action

  switch (type) {
    case types.FETCH_RELOCATE_TASK_SUCCESS:
      return { relocateTask: payload }
    case types.PROCESS_RELOCATE:
      return { relocateTask: null }
    default:
      return state
  }
}

export default relocateReducer