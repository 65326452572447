import request, { requestWithRetry, requestWithIgnore } from 'helpers'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}processes/restock`

export const processStorageToStorage = (payload) =>
  request({
    url: `${API_URL}restock-process/storage-to-storage`,
    method: 'POST',
    data: payload,
  })

export const processBulkFromTask = (payload) =>
  request({
    url: `${API_URL}restock-process/process-bulk-from-task`,
    method: 'POST',
    data: payload,
  })

export const processBulkToTask = (payload) =>
  request({
    url: `${API_URL}restock-process/process-bulk-to-task`,
    method: 'POST',
    data: payload,
  })

export const create = (payload) =>
  request({
    url: `${apiEndpoint}`,
    method: 'POST',
    data: payload,
  })

export const startBulkToTask = (payload) =>
  request({
    url: `${apiEndpoint}/task/start-bulk-to-task`,
    method: 'POST',
    data: payload,
  })

export const fetchBulkRestockTask = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}/task/${payload.id}`,
    method: 'GET',
  })

export const startBulkFromTask = (payload) =>
  request({
    url: `${apiEndpoint}/task/start-bulk-from-task`,
    method: 'POST',
    data: payload,
  })

export const createToLocationFromStorage = (payload) =>
  request({
    url: `${apiEndpoint}/many-from-storage`,
    method: 'POST',
    data: payload,
  })

export const update = (payload) =>
  request({
    url: `${apiEndpoint}/${payload.id}`,
    method: 'PUT',
    data: payload,
  })

export const fetchMany = (payload) =>
  requestWithIgnore(
    {
      url: `${apiEndpoint}`,
      method: 'GET',
      params: payload,
    },
    {
      maxIgnores: 1,
    },
  )

export const fetchOne = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}/${payload.id}`,
    method: 'GET',
  })

export const createLocationRestock = (payload) =>
  request({
    url: `${API_URL}restock-locations`,
    method: 'POST',
    data: payload,
  })

export const updateLocationRestock = (payload) =>
  request({
    url: `${API_URL}restock-locations/${payload.id}`,
    method: 'PUT',
    data: payload,
  })

export const processRestock = (payload) =>
  request({
    url: `${API_URL}processes/process-restock`,
    method: 'PUT',
    data: payload,
  })
