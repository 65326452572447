export const types = {
  FETCH_MEASUREMENT_ITEMS_START: 'FETCH_MEASUREMENT_ITEMS_START',
  FETCH_MEASUREMENT_ITEMS_SUCCESS: 'FETCH_MEASUREMENT_ITEMS_SUCCESS',
  FETCH_MEASUREMENT_ITEMS_FAIL: 'FETCH_MEASUREMENT_ITEMS_FAIL',
  UPDATE_MEASUREMENT_ITEMS: 'UPDATE_MEASUREMENT_ITEMS',
  FETCH_CREATE_MEASUREMENT_ITEMS_START: 'FETCH_CREATE_MEASUREMENT_ITEMS_START',
  FETCH_CREATE_MEASUREMENT_ITEMS_SUCCESS:
    'FETCH_CREATE_MEASUREMENT_ITEMS_SUCCESS',
  FETCH_CREATE_MEASUREMENT_ITEMS_FAIL: 'FETCH_CREATE_MEASUREMENT_ITEMS_FAIL',
  CLEAR_MEASUREMENT_ITEMS: 'CLEAR_MEASUREMENT_ITEMS',
}

export const fetchMeasurementItemStart = (payload) => ({
  type: types.FETCH_MEASUREMENT_ITEMS_START,
  payload,
})

export const fetchMeasurementItemSuccess = (payload) => ({
  type: types.FETCH_MEASUREMENT_ITEMS_SUCCESS,
  payload,
})

export const fetchMeasurementItemFail = (payload) => ({
  type: types.FETCH_MEASUREMENT_ITEMS_FAIL,
  payload,
})

export const updateMeasurementItems = (payload) => ({
  type: types.UPDATE_MEASUREMENT_ITEMS,
  payload,
})

export const fetchCreateMeasurementItemsStart = (payload) => ({
  type: types.FETCH_CREATE_MEASUREMENT_ITEMS_START,
  payload,
})

export const fetchCreateMeasurementItemsSuccess = (payload) => ({
  type: types.FETCH_CREATE_MEASUREMENT_ITEMS_SUCCESS,
  payload,
})
export const fetchCreateMeasurementItemsFail = (payload) => ({
  type: types.FETCH_CREATE_MEASUREMENT_ITEMS_FAIL,
  payload,
})

export const clearMeasurementItems = (payload) => ({
  type: types.CLEAR_MEASUREMENT_ITEMS,
  payload,
})
