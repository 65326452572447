export const AUTOHIDE_DURATION = 3000

export const SNACK_ERROR = {
  variant: 'error',
  autoHideDuration: AUTOHIDE_DURATION,
}

export const SNACK_SUCCESS = {
  variant: 'success',
  autoHideDuration: AUTOHIDE_DURATION,
}

export const SNACK_WARNING = {
  variant: 'warning',
  autoHideDuration: AUTOHIDE_DURATION,
}

export const SNACK_INFO = {
  variant: 'info',
  autoHideDuration: AUTOHIDE_DURATION,
}
