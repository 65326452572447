import React, { useEffect, useState } from 'react'
import '../style.scss'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import Header from 'components/common/Header'
import { redirect } from 'helpers/index'
import {
  fetchOneWarehouseCaseSync,
  updateWarehouseCase,
} from 'store/warehouseCase/actions'
import QuantityControl from 'components/common/QuantityControl'
import { INVENTORY_TOOL_SCAN_LOCATION_PAGE } from 'constants/routes'
import BarcodeService from 'services/barcodeService'
import {
  createManyWarehousePallet,
  deleteManyWarehousePallet,
  updateWarehousePallet,
} from 'store/warehousePallet/actions'
import { closeModal, resetModals, showModal } from 'store/modal/actions'
import Button from 'components/common/Button'

import { openPrintDialog } from 'store/print/actions'
import Print from 'components/common/Print'
import { PALLET_LABEL } from 'constants/print'

const MultiplePalletInventoryTool = (props) => {
  const {
    warehouseCase,

    updateWarehouseCase,
    createManyWarehousePallet,
    updateWarehousePallet,
    deleteManyWarehousePallet,
    fetchOneWarehouseCase,
    showModal,
    resetModals,
    closeModal,
    openPrintDialog,
  } = props

  const [checkedPalletId, setCheckedPalletId] = useState([])

  const currentPallet = warehouseCase.warehouse_pallets.filter(
    (item) => checkedPalletId.indexOf(item.id) === -1,
  )[0]

  const [caseQuantity, setCaseQuantity] = useState(0)
  const [caseQuantityInLocation, setCaseQuantityInLocation] = useState(0)
  const [isPalletPrinted, setIsPalletPrinted] = useState(false)

  const isLastPallet =
    checkedPalletId.length === warehouseCase.warehouse_pallets.length - 1

  const onChange = (name, value) => setCaseQuantity(value)
  const onDecrement = () => setCaseQuantity(caseQuantity - 1)
  const onIncrement = () => setCaseQuantity(caseQuantity + 1)

  const getWarehouseCase = () => {
    const afterAction = () => closeModal('modalPending')

    showModal({
      name: 'modalPending',
    })

    fetchOneWarehouseCase({
      request: warehouseCase.id,
      afterAction: afterAction,
    })
  }

  const createPallets = (countOfPallets) => {
    const warehouse = warehouseCase.warehouseLocation.warehouse

    let warehousePallets = []

    for (let i = 0; i < countOfPallets; i++) {
      warehousePallets = [
        ...warehousePallets,
        {
          warehouse_case_id: warehouseCase.id,
          pallet_upc: '111',
          qty_cases_on_pallet: 0,
        },
      ]
    }

    createManyWarehousePallet({
      request: warehousePallets,
      onSuccess: (items) => {
        items.forEach((item) => {
          updateWarehousePallet({
            request: {
              id: item.id,
              data: {
                id: item.id,
                pallet_upc: BarcodeService.generatePalletBarcode(
                  item.id,
                  warehouse.warehouse_id,
                ),
              },
            },
          })
        })

        getWarehouseCase()
      },
    })
  }

  const deletePallets = (countPalletToDelete) => {
    const ids = warehouseCase.warehouse_pallets
      .map((item, i) => i < countPalletToDelete && item.id)
      .filter((item) => item)

    deleteManyWarehousePallet({
      request: ids,
      afterAction: getWarehouseCase,
    })
  }

  const onConfirm = (value) => {
    resetModals()

    if (warehouseCase.warehouse_pallets.length > value) {
      deletePallets(warehouseCase.warehouse_pallets.length - value)
    }

    if (warehouseCase.warehouse_pallets.length < value) {
      createPallets(value - warehouseCase.warehouse_pallets.length)
    }
  }

  const onMount = () => {
    showModal({
      name: 'modalPalletQty',
      maxValue: warehouseCase.warehouseLocation.pallets_capacity,
      value: !!warehouseCase.warehouse_pallets.length
        ? warehouseCase.warehouse_pallets.length
        : 1,
      className: 'modal--inventory-pallet-qty',
      onConfirm: onConfirm,
    })
  }

  const printPalletLabel = (id) => {
    openPrintDialog({
      id: id,
      document: PALLET_LABEL,
      onPrintDialogClose: () => {},
    })
  }

  useEffect(onMount, [])

  const onDone = () => {
    updateWarehouseCase({
      id: warehouseCase.id,
      data: {
        id: warehouseCase.id,
        location_case_qty: caseQuantityInLocation + caseQuantity,
      },
    })

    updateWarehousePallet({
      request: {
        id: currentPallet.id,
        data: {
          id: currentPallet.id,
          qty_cases_on_pallet: caseQuantity,
        },
      },
    })

    redirect(INVENTORY_TOOL_SCAN_LOCATION_PAGE)
  }

  const onPrintPalletLabel = () => {
    setTimeout(() => {
      //add a slight delay to prevent modal collision
      showModal({ name: 'modalPending' })

      setIsPalletPrinted(true)

      updateWarehouseCase({
        id: warehouseCase.id,
        data: {
          id: warehouseCase.id,
          location_case_qty: caseQuantityInLocation + caseQuantity,
        },
      })

      updateWarehousePallet({
        request: {
          id: currentPallet.id,
          data: {
            id: currentPallet.id,
            qty_cases_on_pallet: caseQuantity,
          },
        },
        afterAction: (id) => {
          printPalletLabel(id)
        },
      })
    }, 100)
  }

  const getPrintPalletButton = () => {
    if (!currentPallet) {
      return <></>
    }

    return <Button onClick={onPrintPalletLabel}>Print Pallet Label</Button>
  }

  const onCountNextPallet = () => {
    const newCheckedPalletId = [...checkedPalletId, currentPallet.id]

    setCheckedPalletId(newCheckedPalletId)
    setCaseQuantityInLocation(caseQuantityInLocation + caseQuantity)

    setIsPalletPrinted(false)

    if (isLastPallet) {
      redirect(INVENTORY_TOOL_SCAN_LOCATION_PAGE)
    }
  }

  return (
    <div className="tasks-page tasks-page--damage-resolution tasks-page--scan-resolution task-page--inventory">
      <Header>
        <span className="centered"> Inventory Tool</span>
        {isLastPallet && (
          <div className="text text--close" onClick={onDone}>
            Done
          </div>
        )}
      </Header>
      <div className="content">
        <div className="centered colored">
          <span className="name-text">
            {warehouseCase.product_description.products_name}
          </span>
        </div>
        <div className="content__info">
          <div className="content__info-item">
            <div>Model</div>
            <div className="content__info-text">
              {warehouseCase.product.products_model}
            </div>
          </div>
          <div className="content__info-item">
            <div>Manf Model</div>
            <div className="content__info-text">
              {warehouseCase.product.products_manf_model}
            </div>
          </div>
          <div className="content__info-item">
            <div>Pallet Qty</div>
            <div className="content__info-text">
              {checkedPalletId.length + 1}/
              {warehouseCase.warehouse_pallets.length}
            </div>
          </div>
        </div>
      </div>
      <span className="centered">Cases on Pallet</span>
      <div className="centered">
        <QuantityControl
          minValue={0}
          onChange={onChange}
          value={caseQuantity}
          onDecrease={onDecrement}
          onIncrease={onIncrement}
          styles={{ fontSize: 56.7 }}
        />
      </div>
      {getPrintPalletButton()}
      {isPalletPrinted && (
        <Button onClick={onCountNextPallet}>Count Next Pallet</Button>
      )}
      <Print />
    </div>
  )
}

MultiplePalletInventoryTool.propTypes = {}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  updateWarehouseCase: updateWarehouseCase,
  createManyWarehousePallet: createManyWarehousePallet,
  updateWarehousePallet: updateWarehousePallet,
  showModal: showModal,
  resetModals: resetModals,
  closeModal: closeModal,
  deleteManyWarehousePallet: deleteManyWarehousePallet,
  fetchOneWarehouseCase: fetchOneWarehouseCaseSync,
  openPrintDialog: openPrintDialog,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MultiplePalletInventoryTool)
