import React from 'react'
import * as PropTypes from 'prop-types'

import 'react-circular-progressbar/dist/styles.css'
import 'assets/components/progress-bar.scss'

import Icon from 'components/common/Icon'
import CheckmarkIcon from 'assets/svg/check-icon--empty.svg'

const Avatar = (props) => {
  const {
    link,
    loadPercent,
    className,
    isChecked,
    isPrimary,
    onClick,
    subText,
  } = props

  const firstName = subText == null ? subText : subText.split(' ')[0]

  return (
    <>
      <Icon
        key={link}
        onClick={onClick}
        className={`avatar ${loadPercent === 100 ? 'avatar--success' : ''} ${
          className ? className : ''
        } ${!isPrimary ? 'avatar--secondary' : ''}`.trim()}
        src={link}
      >
        {isChecked && <CheckmarkIcon className="checkmark" />}
      </Icon>
      {subText !== null ? (
        <span className="avatar-list__name">{firstName}</span>
      ) : (
        <></>
      )}
    </>
  )
}

Avatar.propTypes = {
  link: PropTypes.string.isRequired,
  className: PropTypes.string,
  subText: PropTypes.string,

  isChecked: PropTypes.bool,
  isPrimary: PropTypes.bool,

  loadPercent: PropTypes.number,

  onClick: PropTypes.func,
}

export default Avatar
