import request, { requestWithRetry } from 'helpers'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}printers`

export const print = (payload) =>
  request(
    {
      url: `${apiEndpoint}/jobs`,
      method: 'POST',
      data: payload.data,
    },
    true,
  )

export const fetchLibrary = () =>
  requestWithRetry({
    url: `${apiEndpoint}`,
    method: 'GET',
  })
