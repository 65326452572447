import React, { useEffect, useRef } from 'react'
import * as PropTypes from 'prop-types'
import './style.scss'

const TextInput = (props) => {
  const {
    type = 'text',
    inputmode = undefined,
    placeholder = undefined,
    className = '',
    name,
    tabIndex,
    pattern = undefined,
    value,
    onChange,
    onBlur,
    fontSize = 18,
    autoScale = false,
    disabled = false,
    isInvalid = false,
    saveInputData = true,
    autocomplete = true,
    autofocus = false,
    width = null,
  } = props

  const inputElement = useRef()

  useEffect(() => {
    autofocus && inputElement.current.focus()
  }, [autofocus, inputElement])

  const onChangeField = (name, value) => {
    if (pattern === undefined) {
      return onChange(name, value)
    }

    pattern.test(value)
      ? onChange(name, value)
      : console.log('-Not valid-', pattern.test(value))
  }

  const quantityOfCharacters = value.toString().replace(/\./g, '').length
  const coeffWidthFitContent = 1.3
  const inputWidth = (quantityOfCharacters * fontSize) / coeffWidthFitContent

  return (
    <input
      inputMode={inputmode}
      tabIndex={tabIndex}
      className={`text-input ${className} ${
        isInvalid ? 'text-input--invalid' : ''
      }`}
      type={type}
      name={name}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      onChange={(e) => onChangeField(name, e.target.value)}
      style={{
        fontSize,
        width: autoScale ? inputWidth : width,
        maxWidth: '100%',
      }}
      onBlur={() => (onBlur ? onBlur() : false)}
      readOnly={!saveInputData}
      onFocus={(e) => !saveInputData && e.target.removeAttribute('readonly')}
      autoComplete={autocomplete ? 'on' : 'off'}
      autoFocus={autofocus}
      ref={inputElement}
    />
  )
}

TextInput.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  inputmode: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,

  tabIndex: PropTypes.number,
  fontSize: PropTypes.number,

  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  autoScale: PropTypes.bool,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,

  pattern: PropTypes.any,

  saveInputData: PropTypes.bool,
  autocomplete: PropTypes.bool,
}

export default TextInput
