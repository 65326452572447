import { combineReducers } from 'redux'
import unloadProcessReducer from 'store/unloadProcess/reducer'
import modalReducer from 'store/modal/reducer'
import productInfoReducer from 'store/product/productInfo/reducer'
import productCaseReducer from 'store/product/productCase/reducer'
import containerProductReducer from 'store/product/containerProduct/reducer'
import measurementReducer from 'store/measurement/reducer'
import authReducer from 'store/auth/reducer'
import staffReducer from 'store/staff/reducer'
import taskAssignmentReducer from 'store/taskAssignment/reducer'
import orderReducer from 'store/order/reducer'
import locationReducer from './location/reducer'
import restockProcessReducer from 'store/restockProcess/reducer'
import taskReducer from 'store/task/reducer'
import containerPalletReducer from 'store/containerPallets/reducer'
import qaHoldReducer from 'store/qaHold/reduser'
import appInfoReducer from 'store/appInfo/reducer'
import homeAnalyticsReducer from 'store/homeAnalytics/reducer'
import warehousePalletReducer from 'store/warehousePallet/reducer'
import damageProductReducer from 'store/damageProduct/reducer'
import orderPalletReducer from 'store/orderPallet/reducer'
import orderPickStatusReducer from 'store/orderPickStatus/reducer'
import orderLoadStatusReducer from 'store/orderLoadStatus/reducer'
import deviceOrientationReducer from 'store/deviceOrientation/reducer'
import warehouseCaseReducer from 'store/warehouseCase/reducer'
import productReducer from 'store/product/product/reducer'
import printReducer from 'store/print/reducer'
import relocateReducer from 'store/relocate/reducer'
import inventoryReducer from 'store/inventory/reducer'

export const STORE_VERSION = '3'

const rootReducer = combineReducers({
  version: () => STORE_VERSION,
  unloadProcess: unloadProcessReducer,
  modal: modalReducer,
  productInfo: productInfoReducer,
  productCase: productCaseReducer,
  product: productReducer,
  containerProduct: containerProductReducer,
  measurement: measurementReducer,
  auth: authReducer,
  staff: staffReducer,
  taskAssignment: taskAssignmentReducer,
  order: orderReducer,
  location: locationReducer,
  restockProcess: restockProcessReducer,
  task: taskReducer,
  containerPalletReducer: containerPalletReducer,
  qaHoldReducer: qaHoldReducer,
  appInfo: appInfoReducer,
  homeAnalyticsReducer: homeAnalyticsReducer,
  warehousePalletReducer: warehousePalletReducer,
  damageProduct: damageProductReducer,
  orderPallet: orderPalletReducer,
  orderPickStatus: orderPickStatusReducer,
  orderLoadStatus: orderLoadStatusReducer,
  deviceOrientation: deviceOrientationReducer,
  warehouseCase: warehouseCaseReducer,
  print: printReducer,
  relocate: relocateReducer,
  inventoryReducer: inventoryReducer,
})

export default rootReducer
