import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import {
  AllInclusive,
  Exposure,
  EditLocation,
  ListAlt,
} from '@material-ui/icons'
import TabContent from './TabContent'
import { RESTOCK_TYPE_GROUPS } from 'constants/taskAssignment'
import { isEqualObjects } from 'helpers/index'

const TABS = [
  {
    id: 1,
    label: 'All',
    filters: RESTOCK_TYPE_GROUPS.ALL_RESTOCK_FILTER,
    icon: <AllInclusive />,
  },
  {
    id: 2,
    label: 'Restock',
    filters: RESTOCK_TYPE_GROUPS.RESTOCK_FILTER,
    icon: <Exposure />,
  },
  {
    id: 3,
    label: 'Relocate',
    filters: RESTOCK_TYPE_GROUPS.RELOCATE_FILTER,
    icon: <EditLocation />,
  },
  {
    id: 4,
    label: 'To Inv.',
    filters: RESTOCK_TYPE_GROUPS.TO_INV_FILTER,
    icon: <ListAlt />,
  },
]

const RestockTaskContent = ({ items, filters, onFilterChange }) => {
  const calcCurrentFilter = () => {
    let selectedFilterLabel
    TABS.forEach((tab) => {
      if (
        isEqualObjects(filters, tab.filters) ||
        tab.filters.indexOf(filters[0]) > -1
      ) {
        selectedFilterLabel = tab.label
      }
    })
    return selectedFilterLabel
  }
  const [numberOfReturnedItems, setNumberOfReturnedItems] = useState(
    items.length,
  ) // TODO - alter API so meta includes "numberFound", and use that here... so it won't use items.length, but rather the meta.numberFound for this page
  if (numberOfReturnedItems !== items.length) {
    setNumberOfReturnedItems(items.length)
  }

  const [currentFilter, setCurrentFilter] = useState(calcCurrentFilter())
  const [drawerOpen, setDrawerOpen] = useState(false)
  const toggleDrawerOpen = (open) => (event) => {
    setDrawerOpen(open)
  }

  const groupFilterClick = (e) => {
    const index = e.currentTarget.getAttribute('index')
    onFilterChange(TABS[index].filters)
    setCurrentFilter(TABS[index].label)
    setDrawerOpen(false)
  }

  return (
    <div className="tab">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <MenuIcon
          fontSize={'large'}
          onClick={toggleDrawerOpen(true)}
          style={{ marginLeft: '2%' }}
        />
        {currentFilter}
        <span style={{ marginLeft: '5px', fontSize: 'x-small' }}>
          [ {numberOfReturnedItems} ]
        </span>
      </div>
      <Drawer
        anchor={'left'}
        open={drawerOpen}
        onClose={toggleDrawerOpen(false)}
      >
        <span style={{ textAlign: 'center', marginTop: '5%' }}>
          Filter Categories
        </span>

        <Divider />
        <List>
          {TABS.map((item, index) => {
            const { label, icon, id } = item
            return (
              <ListItem
                button
                key={id}
                index={index}
                onClick={(e) => {
                  groupFilterClick(e)
                }}
              >
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText primary={label} />
              </ListItem>
            )
          })}
        </List>
      </Drawer>
      <TabContent onFilterChange={onFilterChange} items={items} />
    </div>
  )
}

RestockTaskContent.propTypes = {
  items: PropTypes.array,
}

export default RestockTaskContent
