import request, { requestWithRetry } from 'helpers'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}warehouse-cases`

export const create = (payload) =>
  request({
    url: `${apiEndpoint}`,
    method: 'POST',
    data: payload,
  })

export const update = (payload) =>
  request({
    url: `${apiEndpoint}/${payload.id}`,
    method: 'PUT',
    data: payload,
  })

export const remove = (payload) =>
  request({
    url: `${apiEndpoint}/${payload}`,
    method: 'DELETE',
  })

export const fetchOne = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}/${payload}`,
    method: 'GET',
  })

export const updateSold = (payload) =>
  request({
    url: `${apiEndpoint}/sold/${payload.id}`,
    method: 'PUT',
    data: payload,
  })
