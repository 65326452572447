import React from 'react'
import * as PropTypes from 'prop-types'
import Card from '../Card'
import Icon from 'components/common/Icon'
import EditIcon from 'assets/svg/edit.svg'

const CardList = (props) => {
  const { items, className, options = {}, events = {} } = props

  return (
    <div className={` card-list ${className ? className : ''}`}>
      <div className="card-list__items">
        {items.map((item) => (
          <Card
            options={options}
            events={events}
            key={item.staffLogin.staff_id}
            item={item}
          />
        ))}
      </div>
      {options.editable && items.length > 0 && (
        <Icon className="icon--edit" onClick={events.onEdit}>
          <EditIcon />
        </Icon>
      )}
    </div>
  )
}

CardList.propTypes = {
  items: PropTypes.array.isRequired,

  className: PropTypes.string,

  onEdit: PropTypes.func,
}

export default CardList
