import React, { useEffect } from 'react'
import './style.scss'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import Header from 'components/common/Header'
import RestockTaskContent from 'components/features/Task/RestockTaskListPage/RestockTaskContent'
import CloseButton from 'components/common/CloseButton'
import TaskTotalIcon from 'components/common/TaskTotalIcon'
import { redirect } from 'helpers/index'
import { RESTOCK_TASK_LIST_PAGE } from 'constants/routes'
import { fetchRestockProcessListStart } from 'store/restockProcess/actions'
import * as query from 'querystringify'
import { STATUS_CREATED, STATUS_IN_PROGRESS } from 'constants/processStatuses'
import { fetchRestockCount } from 'store/homeAnalytics/actions'
import { closeModal, showModal } from 'store/modal/actions'
import { RESTOCK_TYPE_GROUPS } from 'constants/taskAssignment'

const RestockTaskListPage = ({
  processList,
  fetchRestockProcessList,
  user,
  fetchRestockCount,
  homeAnalytics,
  closeModal,
  showModal,
}) => {
  const getSelectedFilters = () => {
    const params = query.parse(window.location.search)

    return params.filters
      ? params.filters.split(',').map((param) => parseInt(param))
      : RESTOCK_TYPE_GROUPS.ALL_RESTOCK_FILTER
  }

  const getAnalytics = () => {
    fetchRestockCount(RESTOCK_TYPE_GROUPS.ALL_RESTOCK_FILTER)
  }
  let listedItems = processList // note 2021.12.17 - this is a hack. Better to RESET/flush processList store data in Redux on page close. then just use processList and get rid of listedItems
  const onProcessListChange = () => {
    getAnalytics()
    if (listedItems) {
      closeModal('modalPending')
    }
  }
  const onPageLoad = () => {
    listedItems = null
    showModal({ name: 'modalPending' })
    fetchRestockProcessList({
      filters: getSelectedFilters(),
      conditions: {
        warehouse_staff_id: user.warehouseStaffId,
        statuses: [STATUS_CREATED, STATUS_IN_PROGRESS],
      },
    })
    getAnalytics()
  }

  const onFilterChange = (filterList) => {
    showModal({ name: 'modalPending' })
    fetchRestockProcessList({
      filters: filterList,
      conditions: {
        statuses: [STATUS_CREATED, STATUS_IN_PROGRESS],
        warehouse_staff_id: user.warehouseStaffId,
      },
    })

    redirect(`${RESTOCK_TASK_LIST_PAGE}?filters=${filterList.join(',')}`)
  }
  let timeoutId
  const reloadPage = () => {
    timeoutId = setTimeout(() => {
      fetchRestockProcessList({
        filters: getSelectedFilters(),
        conditions: {
          warehouse_staff_id: user.warehouseStaffId,
          statuses: [STATUS_CREATED, STATUS_IN_PROGRESS],
        },
      })
      getAnalytics()
      reloadPage()
    }, 10000)
  }

  useEffect(() => {
    reloadPage()
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(onPageLoad, [])
  useEffect(onProcessListChange, [processList])

  return (
    <div className="page page--restock">
      <Header>
        <TaskTotalIcon count={homeAnalytics.restockCount} />
        <div className="title">Restock Task List</div>
        <CloseButton />
      </Header>
      <div className="content">
        <RestockTaskContent
          items={listedItems}
          filters={getSelectedFilters()}
          onFilterChange={onFilterChange}
        />
      </div>
    </div>
  )
}

RestockTaskListPage.propTypes = {
  processList: PropTypes.array,
}

const mapStateToProps = (state) => ({
  processList: state.restockProcess.list,
  homeAnalytics: state.homeAnalyticsReducer,
})

const mapDispatchToProps = {
  fetchRestockProcessList: fetchRestockProcessListStart,
  fetchRestockCount: fetchRestockCount,
  closeModal: closeModal,
  showModal: showModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(RestockTaskListPage)
