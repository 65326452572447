import React, { useState, useEffect } from 'react'
import Header from 'components/common/Header'
import Scanner from 'components/common/Scanner'
import { connect } from 'react-redux'
import ActiveGrey from 'assets/svg/active-grey.svg'
import './style.scss'
import { fetchOneTaskAssignmentStart } from 'store/taskAssignment/actions'
import Active from 'assets/svg/active.svg'
import ExclamationSign from 'assets/svg/exclamation.svg'
import { resetModals, showModal, showPendingModal } from 'store/modal/actions'
import { processLoad } from 'store/order/actions'
import { redirect } from 'helpers'
import {
  PALLETS_TO_LOAD_LIST_PAGE,
  ORDERS_TO_LOAD_LIST_PAGE,
} from 'constants/routes'

const PalletLoadingPage = (props) => {
  const {
    match,
    fetchOneTaskAssignment,
    taskAssignment,
    showModal,
    resetModals,
    processLoad,
    showPendingModal,
  } = props

  const [matchId, setMatchId] = useState(match.params.id)

  if (matchId !== match.params.id) {
    setMatchId(match.params.id)
  }
  const id = matchId

  const showModalInvalidDock = () => {
    showModal({
      name: 'modalWarning',
      onAccept: resetModals,
      onDeny: resetModals,
      text: 'Invalid Dock ',
      className: 'modal--tasks',
      modalIcon: (
        <ExclamationSign className="icon--restock icon--exclamation" />
      ),
    })
  }

  const onCorrectScan = (dock_id) => {
    showPendingModal()
    processLoad({
      request: {
        data: {
          lane_location: {
            id: taskAssignment.entity.orderPalletLaneLocation.id,
          },
          orders_id: taskAssignment.entity.order.orders_id,
          order_loading_task_assignment_id: Object.keys(
            taskAssignment.entity.order.loading_task.assignments,
          )[0],
          pallet_loading_task_assignment_id: taskAssignment.id,
          shipment_id: taskAssignment.entity.order.shipment_id,
          order_pallet_id:
            taskAssignment.entity.orderPalletLaneLocation.order_pallet_id,
          dock_id: dock_id,
        },
      },
      afterAction: (isLastPallet) => {
        if (isLastPallet) {
          redirect(ORDERS_TO_LOAD_LIST_PAGE)
        } else {
          redirect(
            PALLETS_TO_LOAD_LIST_PAGE.replace(':id', taskAssignment.process.id),
          )
        }
      },
    })
  }

  const onScan = (scannedUPC) => {
    if (!taskAssignment.entity?.dockLocations) return

    const matchedDock = taskAssignment.entity.dockLocations.find(
      (dock) => dock.upc === scannedUPC,
    )

    const isCorrect = !!matchedDock

    if (!statusPanel.isDockScanned) {
      switch (true) {
        case !isCorrect:
          showModalInvalidDock()
          break
        case isCorrect:
          setStatusPanel({
            ...statusPanel,
            icon: <Active className="scan-indicator" />,
          })

          setTimeout(() => {
            setStatusPanel({
              ...statusPanel,
              isDockScanned: true,
            })
          }, 300)

          onCorrectScan(matchedDock.dock_id)
          break
        default:
          // No default action needed
          break
      }
    }
  }

  const onPageLoad = () => {
    fetchOneTaskAssignment({ id })
  }

  useEffect(onPageLoad, [matchId])

  const INIT_STATUS_PANEL = {
    isDockScanned: false,
    scannedItem: {},
    fetchParams: {},
    text: 'Scan Dock  When Loaded',
    icon: <ActiveGrey className="scan-indicator" />,
  }
  const [statusPanel, setStatusPanel] = useState(INIT_STATUS_PANEL)

  const showScanIndicator = () => (
    <>
      {statusPanel.text} {statusPanel.icon}
    </>
  )

  return (
    <div className="order-pallet-loading-page">
      <Header>
        <div className="title">Order Loading</div>
      </Header>
      <div className="content">
        <Scanner needToRemoveListener={false} onReceiveData={onScan} />
        <span className="status">{showScanIndicator()}</span>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  taskAssignment: state.taskAssignment.item,
})

const mapDispatchToProps = {
  fetchOneTaskAssignment: fetchOneTaskAssignmentStart,
  showModal,
  resetModals,
  processLoad,
  showPendingModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(PalletLoadingPage)
