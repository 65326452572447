import React from 'react'
import * as PropTypes from 'prop-types'
import PausedIcon from 'assets/svg/paused-icon.svg'
import './style.scss'

import ModalDefault from 'components/common/Modal/ModalDefault/index'
import Button from 'components/common/Button'
import { connect } from 'react-redux'

const ModalResume = ({ isOpen, modalInfo }) => {
  if (!isOpen) {
    return <></>
  }

  return (
    <ModalDefault isOpen={isOpen}>
      <div
        className={`modal modal--resume ${
          modalInfo.className ? modalInfo.className : ''
        }`}
      >
        <PausedIcon />
        <Button onClick={modalInfo.onResume} className="btn">
          Resume
        </Button>
      </div>
    </ModalDefault>
  )
}

ModalResume.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalResume',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalResume',
  ),
})

export default connect(mapStateToProps)(ModalResume)
