import React from 'react'
import * as PropTypes from 'prop-types'
import AssignToItem from './AssignToItem'

const AssignToBlock = ({ items = [], onRemove }) => (
  <div className="row">
    <div className="title">Assign To</div>
    <div className="list list--asign">
      {items.map((item) => (
        <AssignToItem
          key={item.staffLogin.staff_id}
          item={item}
          onRemove={onRemove}
        />
      ))}
    </div>
  </div>
)
AssignToBlock.propTypes = {
  items: PropTypes.array,

  onRemove: PropTypes.func,
}

export default AssignToBlock
