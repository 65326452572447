import { types } from './actions'
import * as api from 'api/pickupLane'
import { takeLatest, call } from 'redux-saga/effects'

function* createPickupLane(action) {
  const { payload } = action

  try {
    yield call(api.create, payload)
  } catch (error) {}
}

function* validatePickupLane(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(api.confirmEmpty, request)

    if (afterAction) {
      yield afterAction(response.data.data)
    }
  } catch (error) {}
}

export default function* pickupLaneSaga() {
  yield takeLatest(types.CREATE_PICKUP_LANE, createPickupLane)
  yield takeLatest(types.VALIDATE_PICKUP_LANE, validatePickupLane)
}
