import React from 'react'
import * as Sentry from '@sentry/browser'
import * as serviceWorker from './serviceWorker'
import 'assets/app.scss'
import { render } from 'react-dom'
import { configureStore } from 'store'
import { loadState } from 'helpers'
import { useLSVersion } from 'hooks/useLSVersion'
import { Provider } from 'react-redux'
import rootReducer from 'store/rootReducer'
import rootSaga from 'store/rootSaga'
import App from 'components/common/App'
import { CookiesProvider } from 'react-cookie'
import ErrorBoundary from 'components/common/ErrorBoundary'

//

if (window.location.href.indexOf('76fireworks') > -1) {
  Sentry.init({
    dsn:
      'https://b1790440180b4d768fd38aa3737bb2c2@o680325.ingest.sentry.io/5770484',
  })
}
export const store = configureStore(
  rootReducer,
  rootSaga,
  useLSVersion(loadState()),
)

render(
  <Provider store={store}>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </Provider>,
  document.querySelector('#root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//
//
serviceWorker.unregister()
