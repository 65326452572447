import React from 'react'
import * as PropTypes from 'prop-types'
import Button from 'components/common/Button'

const ExpandedContent = ({ items, onScanDamageLocation }) => (
  <div className="details">
    <ul className="details__list">
      {items.map((item) => (
        <li key={item.id} className="details__item">
          <div className="details__text">
            <span className="text--bold">Model</span> {item.model}
          </div>
          <div className="details__text">
            <span className="text--bold">Name</span>
            {item.name}
          </div>
          <div className="details__text">
            <span className="text--bold">Case</span>
            {item.case}
          </div>
        </li>
      ))}
    </ul>
    {<Button onClick={onScanDamageLocation}>Scan Damage Location UPC</Button>}
  </div>
)

ExpandedContent.propTypes = {
  item: PropTypes.object,

  onScanDamageLocation: PropTypes.func,
}

export default ExpandedContent
