import { takeEvery, call } from 'redux-saga/effects'
import { types } from './actions'
import { create } from 'api/orderPickPallet'

function* createOrderPickPallet(action) {
  const { payload } = action

  try {
    yield call(create, payload)
  } catch (error) {}
}

export default function* orderPickPalletSaga() {
  yield takeEvery(types.CREATE_ORDER_PICK_PALLET, createOrderPickPallet)
}
