import React, { useEffect, useState } from 'react'
import '../../style.scss'
import './style.scss'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  POWER_PICKING_PAGE,
  POWER_PICKING_WRAP_PALLET_PAGE,
} from 'constants/routes'
import {
  dateToFormat,
  timeDifference,
  isEmptyObject,
  redirect,
} from 'helpers/index'
import { closeModal, resetModals, showModal } from 'store/modal/actions'
import Header from 'components/common/Header'
import Toggle from 'components/common/Toggle'
import CloseButton from 'components/common/CloseButton'
import OrderListItem from './OrderListItem'
import {
  createTaskAssignment,
  deleteTaskAssignment,
} from 'store/taskAssignment/actions'
import {
  fetchOrdersPowerPickStart,
  updateOrdersStart,
  resetOrders,
  validateClaim,
  validateStart,
} from 'store/order/actions'
import { fetchPreviousOrderPalletStart } from 'store/orderPallet/actions'
import { ORDER_PICK_STATUSES } from 'constants/order'
import { ROLES } from 'constants/roles'
import { WAREHOUSE_SHIFT_HOURS } from 'constants/common'
import { logout } from 'store/auth/actions'
import { List } from '@material-ui/core'
import PrinterIcon from 'assets/svg/printer-icon.svg'
import { openPrintDialog } from 'store/print/actions'
import { ORDER_PALLET_LABEL } from 'constants/print'
import Print from 'components/common/Print'
import { updatePowerPickAuto } from 'store/order/actions'
import ExclamationSmall from 'assets/svg/exclamation-sign.svg'
import { PICKING_PAGE_STATUSES } from '../../../../../constants/order'
import { TASK_TYPES } from 'constants/taskAssignment'

const PowerPickingListPage = (props) => {
  const {
    orderProcessList = [],
    user,
    fetchOrdersPowerPick,
    fetchPreviousOrderPallet,
    updateOrders,
    deleteTaskAssignment,
    resetModals,
    showModal,
    createTaskAssignment,
    isLoading,
    previousOrderPallet,
    resetOrders,
    openPrintDialog,
    updatePowerPickAuto,
    isPowerPickAuto,
    isAutoPickOnlyUser,
    validateClaim,
    validateStart,
  } = props

  const [selectedItem, setSelectedItem] = useState({})

  const onLeave = () => {
    resetOrders()
  }

  const onChangeOrdersList = () => {
    if (orderProcessList.length > 0 || !isLoading) {
      resetModals()
    }
  }

  const assignToOrder = (processId, taskId) => {
    updateOrders({
      items: [
        {
          id: processId,
          order_pick_status: ORDER_PICK_STATUSES.STATUS_ASSIGNED,
        },
      ],
    })
    createTaskAssignment({
      request: {
        data: {
          task_id: taskId,
          warehouse_staff_id: user.warehouseStaffId,
          role_id: ROLES.PICKING_WORKER,
        },
      },
      afterAction: () => {
        fetchOrdersPowerPick({
          params: {
            warehouse_staff_id: user.warehouseStaffId,
            'filters[order_statuses]': PICKING_PAGE_STATUSES,
            task_type_id: TASK_TYPES.PICKING_TYPE,
          },
          type: 'today',
        })
      },
    })
  }

  const onLoad = () => {
    setTimeout(() => {
      showModal({
        name: 'modalPending',
      })

      if (isAutoPickOnlyUser && !isPowerPickAuto) {
        updatePowerPickAuto(true)
      }

      setSelectedItem({})

      fetchOrdersPowerPick({
        params: {
          warehouse_staff_id: user.warehouseStaffId,
          'filters[order_statuses]': PICKING_PAGE_STATUSES,
          task_type_id: TASK_TYPES.PICKING_TYPE,
        },
        type: 'today',
      })
    }, 100)

    fetchPreviousOrderPallet(user.id)

    return onLeave()
  }

  const onValidateStartAfterAction = (data) => {
    if (data.success) {
      redirect(
        POWER_PICKING_PAGE.replace(':id', selectedItem.taskAssignment.id),
      )
    } else {
      resetModals()

      showModal({
        name: 'modalWarning',
        text: data.message,
        onAccept: onLoad,
        onDeny: onLoad,
        className: 'modal--tasks modal--pause',
        modalIcon: <ExclamationSmall />,
      })
    }
  }

  const onValidateClaimAfterAction = (data) => {
    if (data.success) {
      assignToOrder(selectedItem.id, selectedItem.task.id)
    } else {
      resetModals()

      showModal({
        name: 'modalWarning',
        text: data.message,
        onAccept: onLoad,
        onDeny: onLoad,
        className: 'modal--tasks modal--pause',
        modalIcon: <ExclamationSmall />,
      })
    }
  }

  const unassignFromOrder = (
    processId,
    orderPickStatusId,
    taskAssignmentId,
  ) => {
    updateOrders({
      items: [
        {
          id: processId,
          order_pick_status: ORDER_PICK_STATUSES.STATUS_QUEUE,
        },
      ],
    })
    deleteTaskAssignment({
      request: {
        id: taskAssignmentId,
      },
      afterAction: () => {
        fetchOrdersPowerPick({
          params: {
            warehouse_staff_id: user.warehouseStaffId,
            'filters[order_statuses]': PICKING_PAGE_STATUSES,
            task_type_id: TASK_TYPES.PICKING_TYPE,
          },
          type: 'today',
        })
      },
    })
  }

  const onChangeSelectedTask = () => {
    if (isEmptyObject(selectedItem)) {
      return
    }

    showModal({
      name: 'modalPending',
    })

    if (selectedItem.taskAssignment.id) {
      if (selectedItem.unclaim) {
        unassignFromOrder(
          selectedItem.id,
          selectedItem.entity.orderPickStatus.id,
          selectedItem.taskAssignment.id,
        )
        return 
      }

      if (
        selectedItem.entity.orderPickStatus.id ===
        ORDER_PICK_STATUSES.STATUS_PICKING
      ) {
        redirect(
          POWER_PICKING_PAGE.replace(':id', selectedItem.taskAssignment.id),
        )
      } else {
        validateStart({
          request: {
            data: {
              task_id: selectedItem.task.id,
              task_assignment_id: selectedItem.taskAssignment.id,
              orders_id: selectedItem.entity.order.orders_id,
            },
          },
          afterAction: onValidateStartAfterAction,
        })
      }
    } else {
      validateClaim({
        request: {
          data: {
            orders_id: selectedItem.entity.order.orders_id,
            task_type_id: TASK_TYPES.PICKING_TYPE,
          },
        },
        afterAction: onValidateClaimAfterAction,
      })
    }
  }

  useEffect(onLoad, [])
  useEffect(onChangeOrdersList, [orderProcessList])
  useEffect(onChangeSelectedTask, [selectedItem, setSelectedItem])

  const onSelect = (item) => {
    setSelectedItem(item)
  }

  const showModalOrderNotes = (item) => {
    showModal({
      name: 'modalOrderNotes',
      onClose: resetModals,
      order: item.entity.order,
    })
  }

  let palletUpdateTime
  let showReprint = false
  if (previousOrderPallet && !isEmptyObject(previousOrderPallet)) {
    palletUpdateTime = dateToFormat(previousOrderPallet.updated_date, 'hh:mm a')
    showReprint =
      timeDifference(previousOrderPallet.updated_date, Date.now(), 'hours') <
      WAREHOUSE_SHIFT_HOURS
  }

  const onSelectReprint = (id) => {
    openPrintDialog({
      id: id,
      document: ORDER_PALLET_LABEL,
      onPrintDialogClose: () => {
        resetModals()
      },
    })
  }

  const onReprint = () => {
    showModal({
      name: 'modalConfirm',
      onAccept: () => onSelectReprint(previousOrderPallet.id),
      onDeny: resetModals,
      className: 'modal--picking-task',
      children: (
        <div className="modal--content-centered">
          <PrinterIcon className="icon--picking " />
          <p className="text--bold">
            Reprint last <br /> Pallet Label?
          </p>
          <p className="text">
            Order {previousOrderPallet.order_id}&nbsp;{' '}
            <span className="text--small">{palletUpdateTime}</span>
          </p>
        </div>
      ),
    })
  }

  const onUnclaim = (item) => {
    item.unclaim = true
    setSelectedItem(item)
  }

  const getContent = () => {
    return orderProcessList.length > 0 ? (
      <List>
        {orderProcessList.map((item, index) => (
          <OrderListItem
            key={item.id}
            index={index}
            item={item}
            itemId={item.id}
            onSelect={onSelect}
            onUnclaim={onUnclaim}
            showModalOrderNotes={showModalOrderNotes}
          />
        ))}
      </List>
    ) : (
      <div className="no-tasks">
        <div className="no-tasks__text">No Orders</div>
      </div>
    )
  }

  return (
    <div className="tasks-page">
      <Header>
        <div className="auto--toggle">
          Auto
          <Toggle
            text=""
            checked={isPowerPickAuto}
            onChange={() => {
              updatePowerPickAuto(!isPowerPickAuto)
            }}
            disableSwitch={isAutoPickOnlyUser}
          />
        </div>
        {showReprint ? (
          <div className="reprints--button" onClick={onReprint}>
            <div>Reprint</div>
          </div>
        ) : (
          ''
        )}

        <div className="title picking--title">ORDERS</div>
        <CloseButton onCloseAction={onLeave} />
      </Header>
      <div className="content">{getContent()}</div>
      <Print />
    </div>
  )
}

PowerPickingListPage.propTypes = {
  orderProcessList: PropTypes.array,
  modals: PropTypes.object,
}

const mapStateToProps = (state) => ({
  orderProcessList: state.order.powerPickOrders,
  isLoading: state.order.isLoading,
  previousOrderPallet: state.orderPallet.previousOrderPallet,
  isPowerPickAuto: state.order.isPowerPickAuto,
  isAutoPickOnlyUser: state.auth.user.user.warehouseStaff.is_auto_pick,
})

const mapDispatchToProps = {
  fetchOrdersPowerPick: fetchOrdersPowerPickStart,
  createTaskAssignment: createTaskAssignment,
  fetchPreviousOrderPallet: fetchPreviousOrderPalletStart,
  openPrintDialog,
  validateClaim,
  validateStart,
  updatePowerPickAuto: updatePowerPickAuto,

  resetModals: resetModals,
  updateOrders: updateOrdersStart,
  deleteTaskAssignment,
  logout: logout,
  showModal: showModal,
  closeModal: closeModal,
  resetOrders: resetOrders,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PowerPickingListPage)
