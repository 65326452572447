export const types = {
  FETCH_ONE_ORDER_PICK_STATUS_START: 'FETCH_ONE_ORDER_PICK_STATUS_START',
  FETCH_ONE_ORDER_PICK_STATUS_SUCCESS: 'FETCH_ONE_ORDER_PICK_STATUS_SUCCESS',

  UPDATE_ORDER_PICK_STATUS_START: 'UPDATE_ORDER_PICK_STATUS_START',
}

export const fetchOneOrderPickStatusStart = (payload) => ({
  type: types.FETCH_ONE_ORDER_PICK_STATUS_START,
  payload,
})

export const fetchOneOrderPickStatusSuccess = (payload) => ({
  type: types.FETCH_ONE_ORDER_PICK_STATUS_SUCCESS,
  payload,
})

export const updateOrderPickStatusStart = (payload) => ({
  type: types.UPDATE_ORDER_PICK_STATUS_START,
  payload,
})
