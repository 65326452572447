import React from 'react'
import { call, put } from 'redux-saga/effects'
import {
  types,
  loginSuccess,
  loginFail,
  checkUserSuccess,
  checkUserFail,
  setNewPinCodeFail,
  reset,
} from './actions'
import { login, checkUser, getUserData, logout } from 'api/auth'
import { redirect } from 'helpers/index'
import { updateUser } from 'api/user'
import { resetModals, showModal } from 'store/modal/actions'
import { MANAGER_ROLES, ROLES } from 'constants/roles'
import { LOGIN_PAGE, TASK_LIST_PAGE } from 'constants/routes'
import { store } from '../../index'
import ExclamationSign from 'assets/svg/exclamation.svg'
import { fork, takeLatest } from '@redux-saga/core/effects'
import {
  PRIMARY_IP_API_URL,
  PRIMARY_IP_API_KEY,
  SECONDARY_IP_API_URL,
  SECONDARY_IP_API_KEY,
} from 'constants/apiconfig.js'

function* userCheck(action) {
  const { payload } = action

  try {
    const response = yield call(checkUser, payload)
    yield put(checkUserSuccess(response.data.data.item))
  } catch (error) {
    yield put(checkUserFail(error.response.data.errors))
  }
}

function* loginUser(action) {
  let userIp
  let additionalMessage
  try {
    const res1 = yield call(fetch, PRIMARY_IP_API_URL + PRIMARY_IP_API_KEY)
    const json1 = yield res1.json()
    if (json1.ip) {
      userIp = json1.ip
    } else {
      throw new Error()
    }
  } catch (e) {
    try {
      const res2 = yield call(
        fetch,
        SECONDARY_IP_API_URL + SECONDARY_IP_API_KEY,
      )
      const json2 = yield res2.json()
      if (json2.ip) {
        userIp = json2.ip
      } else {
        throw new Error()
      }
    } catch (e2) {
      additionalMessage = 'Contact Administrator.'
    }
  }

  const ipObj = { userIp: userIp }
  let { payload } = action
  payload.data = { ...payload.data, ...ipObj }
  const onClose = () => store.dispatch(resetModals())

  try {
    yield put(
      showModal({
        name: 'modalPending',
        content: <div className="text">Logging in...</div>,
      }),
    )
    const responseWithToken = yield call(login, payload)
    const token = responseWithToken.data.data.access_token

    const responseWithUserData = yield call(getUserData, token)

    yield put(loginSuccess({ ...responseWithUserData.data, token }))
    yield put(resetModals())
    redirect('/')
  } catch (error) {
    yield put(
      showModal({
        name: 'modalWarning',
        onAccept: onClose,
        text: additionalMessage ? (
          <>
            <span>Login failed.</span>
            <br />
            <span>{additionalMessage}</span>
          </>
        ) : (
          'Login failed'
        ),
        modalIcon: (
          <ExclamationSign className="icon--restock icon--exclamation" />
        ),
      }),
    )
    yield put(loginFail(error.response.data.errors))
  }
}

function* userUpdate(action) {
  const { payload } = action

  try {
    yield call(updateUser, payload)
  } catch (error) {
    yield put(setNewPinCodeFail(error.response.data.errors))
  }
}

function* logoutUser(action) {
  const { payload } = action
  console.log('logoutUser in saga')
  console.log(payload)

  try {
    yield put(reset(payload))
    yield put(resetModals(payload))
    yield fork(redirect, LOGIN_PAGE)

    yield call(logout, payload)
  } catch (error) {
    redirect(LOGIN_PAGE)
  }
}

export default function* authSaga() {
  yield takeLatest(types.CHECK_USER_START, userCheck)
  yield takeLatest(types.LOGIN_START, loginUser)
  yield takeLatest(types.SET_NEW_PIN_CODE_START, userUpdate)
  yield takeLatest(types.LOGOUT, logoutUser)
}
