import React from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'

const NotFoundPage = () => (
  <div className="page--error">
    <h3 className="title">Page not found</h3>
    <Link style={{ textDecoration: 'none' }} to={'/'} className="btn">
      Back to Home
    </Link>
  </div>
)

export default NotFoundPage
