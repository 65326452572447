import React, { memo } from 'react'
import * as PropTypes from 'prop-types'
import Button from 'components/common/Button'

const RestockContent = (props) => {
  const {
    time,
    productCount,

    onRefresh,
    onAction,
  } = props

  const onSelect = () => onAction()

  return (
    <div className="no-tasks">
      <div className="no-tasks__time">Refreshed at {time}</div>
      <div className="no-tasks__text">{`${productCount} products are restocking`}</div>
      <Button onClick={onRefresh}>Refresh</Button>
      <Button onClick={onSelect}>Wrap Pallet</Button>
    </div>
  )
}

RestockContent.propTypes = {
  time: PropTypes.string,

  productCount: PropTypes.number,

  onRefresh: PropTypes.func,
  onAction: PropTypes.func,
}

export default memo(RestockContent)
