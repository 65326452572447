import React from 'react'
import { connect } from 'react-redux'
import ModalDefault from 'components/common/Modal/ModalDefault'
import './style.scss'
import Avatar from 'components/features/StaffCard/Avatar'
import StaffTaskAssignmentList from 'components/features/UnloadProcess/PrepareUnloadProcessListPage/PopupStaffDetail/StaffTaskAssignmentList'
import * as PropTypes from 'prop-types'
import { getPhotoLink, isEmptyObject } from 'helpers/index'

const customStyles = {
  content: {
    padding: 0,
  },
}

const PopupStaffDetails = ({ isOpen, staffItem, modalInfo }) => {
  if (isEmptyObject(staffItem)) {
    return <></>
  }

  const taskAssignments = staffItem.taskAssignment
    ? [...[staffItem.taskAssignment]]
    : []

  return isOpen ? (
    <ModalDefault isOpen={isOpen} style={customStyles}>
      <div className="popup--staff-detail">
        <div className="header">
          <div className="title">Staff Detail</div>
          <button className="btn--empty" onClick={modalInfo.onDeny}>
            Close
          </button>
        </div>
        <div className="desc">
          <Avatar link={getPhotoLink(staffItem.staffLogin.photo_url)} />
          <div className="desc__info">
            <p className="title">{staffItem.staffLogin.name}</p>
            <div className="row">
              {staffItem.roles.map((role) =>
                role.is_primary ? (
                  <span className="badge badge--active">{role.name}</span>
                ) : (
                  <span className="badge">{role.name}</span>
                ),
              )}
            </div>
          </div>
        </div>
        {taskAssignments.length > 0 && (
          <StaffTaskAssignmentList items={taskAssignments} />
        )}
      </div>
    </ModalDefault>
  ) : (
    <></>
  )
}

PopupStaffDetails.propTypes = {
  isOpen: PropTypes.bool.isRequired,

  staffItem: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'popupStaffDetails',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'popupStaffDetails',
  ),
  staffItem: state.modal.openModalsInfo.find(
    (item) => item.name === 'popupStaffDetails',
  )
    ? state.modal.openModalsInfo.find(
        (item) => item.name === 'popupStaffDetails',
      ).staffItem
    : {},
})

export default connect(mapStateToProps)(PopupStaffDetails)
