export const types = {
  PROCESS_STORAGE_TO_STORAGE_START: 'PROCESS_STORAGE_TO_STORAGE_START',
  PROCESS_STORAGE_TO_STORAGE_SUCCESS: 'PROCESS_STORAGE_TO_STORAGE_SUCCESS',
  PROCESS_BULK_TO_TASK: 'PROCESS_BULK_TO_TASK',
  PROCESS_BULK_FROM_TASK: 'PROCESS_BULK_FROM_TASK',
  CREATE_RESTOCK_PROCESS: 'CREATE_RESTOCK_PROCESS',
  CREATE_RESTOCK_PROCESSES_TO_LOCATION_FROM_STORAGE:
    'CREATE_RESTOCK_PROCESSES_TO_LOCATION_FROM_STORAGE',
  UPDATE_RESTOCK_PROCESS: 'UPDATE_RESTOCK_PROCESS',
  FETCH_RESTOCK_PROCESS_LIST_START: 'FETCH_RESTOCK_PROCESS_LIST_START',
  FETCH_RESTOCK_PROCESS_LIST_SUCCESS: 'FETCH_RESTOCK_PROCESS_LIST_SUCCESS',
  FETCH_RESTOCK_PROCESS_LIST_FAIL: 'FETCH_RESTOCK_PROCESS_LIST_FAIL',
  FETCH_ONE_RESTOCK_PROCESS_START: 'FETCH_ONE_RESTOCK_PROCESS_START',
  FETCH_ONE_RESTOCK_PROCESS_SUCCESS: 'FETCH_ONE_RESTOCK_PROCESS_SUCCESS',
  FETCH_ONE_RESTOCK_PROCESS_FAIL: 'FETCH_ONE_RESTOCK_PROCESS_FAIL',

  FETCH_BULK_RESTOCK_TASK_START: 'FETCH_BULK_RESTOCK_TASK_START',
  FETCH_BULK_RESTOCK_TASK_SUCCESS: 'FETCH_BULK_RESTOCK_TASK_SUCCESS',
  FETCH_BULK_RESTOCK_TASK_FAIL: 'FETCH_BULK_RESTOCK_TASK_FAIL',
  UPDATE_RESTOCK_LOCATION: 'UPDATE_RESTOCK_LOCATION',

  CREATE_RESTOCK_LOCATION: 'CREATE_RESTOCK_LOCATION',

  UPDATE_SCANNED_RESTOCK_LOCATION: 'UPDATE_SCANNED_RESTOCK_LOCATION',
  UPDATE_SCANNED_PALLET: 'UPDATE_SCANNED_PALLET',

  RESET_RESTOCK_PROCESS: 'RESET_RESTOCK_PROCESS',
  PROCESS_RESTOCK: 'PROCESS_RESTOCK',
  START_BULK_STORAGE_FROM_TASK: 'START_BULK_STORAGE_FROM_TASK',
  START_BULK_STORAGE_TO_TASK: 'START_BULK_STORAGE_TO_TASK',
}

export const processStorageToStorageStart = (payload) => ({
  type: types.PROCESS_STORAGE_TO_STORAGE_START,
  payload,
})

export const processStorageToStorageSuccess = (payload) => ({
  type: types.PROCESS_STORAGE_TO_STORAGE_SUCCESS,
  payload,
})

export const processBulkToTask = (payload) => ({
  type: types.PROCESS_BULK_TO_TASK,
  payload,
})

export const processBulkFromTask = (payload) => ({
  type: types.PROCESS_BULK_FROM_TASK,
  payload,
})  

export const createRestockProcess = (payload) => ({
  type: types.CREATE_RESTOCK_PROCESS,
  payload,
})

export const fetchBulkRestockTaskStart = (payload) => ({
  type: types.FETCH_BULK_RESTOCK_TASK_START,
  payload,
})

export const fetchBulkRestockTaskSuccess = (payload) => ({
  type: types.FETCH_BULK_RESTOCK_TASK_SUCCESS,
  payload,
})

export const fetchBulkRestockTaskFail = (payload) => ({
  type: types.FETCH_BULK_RESTOCK_TASK_FAIL,
  payload,
})

export const createRestockProcessesToLocationFromStorage = (payload) => ({
  type: types.CREATE_RESTOCK_PROCESSES_TO_LOCATION_FROM_STORAGE,
  payload,
})

export const fetchRestockProcessListStart = (payload) => ({
  type: types.FETCH_RESTOCK_PROCESS_LIST_START,
  payload,
})

export const fetchRestockProcessListSuccess = (payload) => ({
  type: types.FETCH_RESTOCK_PROCESS_LIST_SUCCESS,
  payload,
})

export const fetchRestockProcessListFail = (payload) => ({
  type: types.FETCH_RESTOCK_PROCESS_LIST_FAIL,
  payload,
})

export const fetchOneRestockProcessStart = (payload) => ({
  type: types.FETCH_ONE_RESTOCK_PROCESS_START,
  payload,
})

export const fetchOneRestockProcessSuccess = (payload) => ({
  type: types.FETCH_ONE_RESTOCK_PROCESS_SUCCESS,
  payload,
})

export const fetchOneRestockProcessFail = (payload) => ({
  type: types.FETCH_ONE_RESTOCK_PROCESS_FAIL,
  payload,
})

export const updateRestockProcess = (payload) => ({
  type: types.UPDATE_RESTOCK_PROCESS,
  payload,
})

export const startBulkStorageFromTask = (payload) => ({
  type: types.START_BULK_STORAGE_FROM_TASK,
  payload,
})

export const createRestockLocation = (payload) => ({
  type: types.CREATE_RESTOCK_LOCATION,
  payload,
})

export const updateRestockLocation = (payload) => ({
  type: types.UPDATE_RESTOCK_LOCATION,
  payload,
})

export const updateScannedRestockLocation = (payload) => ({
  type: types.UPDATE_SCANNED_RESTOCK_LOCATION,
  payload,
})

export const updateScannedPallet = (payload) => ({
  type: types.UPDATE_SCANNED_PALLET,
  payload,
})

export const resetRestockProcess = (payload) => ({
  type: types.RESET_RESTOCK_PROCESS,
  payload,
})

export const processRestock = (payload) => ({
  type: types.PROCESS_RESTOCK,
  payload,
})

export const startBulkStorageToTask = (payload) => ({
  type: types.START_BULK_STORAGE_TO_TASK,
  payload,
})