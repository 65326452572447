import { requestWithRetry } from 'helpers/index'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}emailer`

export const sendEmailPlogFromFrontend = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}/send-email-plog`,
    method: 'PUT',
    data: payload,
  })
