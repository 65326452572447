export const types = {
  CREATE_PICKUP_LANE: 'CREATE_PICKUP_LANE',
  VALIDATE_PICKUP_LANE: 'VALIDATE_PICKUP_LANE',
}

export const createPickupLane = (payload) => ({
  type: types.CREATE_PICKUP_LANE,
  payload,
})

export const validatePickupLane = (payload) => ({
  type: types.VALIDATE_PICKUP_LANE,
  payload,
})
