import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import '../style.scss'
import Header from 'components/common/Header'
import { closeModal, resetModals, showModal } from 'store/modal/actions'
import {
  fetchDamageProductsForInventoryStart,
  updateDamageProductsStart,
} from 'store/damageProduct/actions'
import Button from 'components/common/Button'
import CancelIconIpod from 'assets/svg/cancel--ipod.svg'
import {
  REPURPOSE_ID,
  RETAIL_ID,
  RETURN_TO_INVENTORY_ID,
} from 'constants/resolutions'
import { RESTOCK_TYPES } from 'constants/taskAssignment'
import { createRestockLocation } from 'store/restockProcess/actions'
import { RESTOCK_LOCATION_STATUSES } from 'constants/restockLocationStatus'
import { redirect } from 'helpers/index'
import { SCAN_DAMAGE_CASE_RESOLUTION_PAGE } from 'constants/routes'
import { updateWarehouseCase } from 'store/warehouseCase/actions'

const DamageResolutionPage = (props) => {
  const {
    damageProduct,
    match,
    user,

    resetModals,
    closeModal,
    showModal,
    fetchDamageProductsForInventory,
    updateDamageProducts,
    createRestockLocation,
    updateWarehouseCase,
  } = props

  const damageProductId = match.params.id

  const onMount = () => {
    showModal({
      name: 'modalPending',
    })

    fetchDamageProductsForInventory(damageProductId)
  }

  const closePending = () => {
    if (damageProduct !== null) {
      closeModal('modalPending')
    }
  }

  useEffect(onMount, [])
  useEffect(closePending, [damageProduct])

  if (damageProduct === null) {
    return <></>
  }

  const showModalInventory = () => {
    showModal({
      name: 'modalConfirm',
      onAccept: onInventory,
      onDeny: resetModals,
      className: 'modal--damage-resolution',
      cancelIcon: <CancelIconIpod />,
      children: (
        <div className="modal--damage-resolution__content">
          <p className="modal--damage-resolution_title">Damage Resolution</p>
          <p>Inventory</p>
        </div>
      ),
    })
  }

  const showModalRepurpose = () => {
    showModal({
      name: 'modalConfirm',
      onAccept: onRepurpose,
      onDeny: resetModals,
      className: 'modal--damage-resolution',
      cancelIcon: <CancelIconIpod />,
      children: (
        <div className="modal--damage-resolution__content">
          <p className="modal--damage-resolution_title">Damage Resolution</p>
          <p>Repurpose</p>
        </div>
      ),
    })
  }

  const showModalResale = () => {
    showModal({
      name: 'modalConfirm',
      onAccept: onResale,
      onDeny: resetModals,
      className: 'modal--damage-resolution',
      cancelIcon: <CancelIconIpod />,
      children: (
        <div className="modal--damage-resolution__content">
          <p className="modal--damage-resolution_title">Damage Resolution</p>
          <p>Resale</p>
        </div>
      ),
    })
  }

  const onInventory = () => {
    updateDamageProducts({
      id: damageProductId,
      data: {
        id: damageProductId,
        resolution_id: RETURN_TO_INVENTORY_ID,
      },
    })

    resetModals()

    createRestockLocation({
      data: {
        product_id: damageProduct.damaged_product.product_id,
        restock_type_id: RESTOCK_TYPES.DAMAGE_TYPE,
        warehouse_location_from_id: damageProduct.from_location.id,
        warehouse_location_to_id: damageProduct.to_location.id,
        status_id: RESTOCK_LOCATION_STATUSES.ACTIVE,
        quantity: 1,
        created_by_user_id: user.warehouseStaffId,
        updated_by_user_id: user.warehouseStaffId,
      },
    })

    redirect(SCAN_DAMAGE_CASE_RESOLUTION_PAGE)
  }

  const onRepurpose = () => {
    updateDamageProducts({
      id: damageProductId,
      data: {
        id: damageProductId,
        resolution_id: REPURPOSE_ID,
      },
    })

    resetModals()

    updateWarehouseCase({
      id: damageProduct.from_location.warehouse_cases_id,
      data: {
        id: damageProduct.from_location.warehouse_cases_id,
        updated_by_user_id: user.warehouseStaffId,
        location_case_qty: damageProduct.from_location.location_case_qty - 1,
      },
    })

    redirect(SCAN_DAMAGE_CASE_RESOLUTION_PAGE)
  }

  const onResale = () => {
    updateDamageProducts({
      id: damageProductId,
      data: {
        id: damageProductId,
        resolution_id: RETAIL_ID,
      },
    })

    resetModals()

    updateWarehouseCase({
      id: damageProduct.from_location.warehouse_cases_id,
      data: {
        id: damageProduct.from_location.warehouse_cases_id,
        updated_by_user_id: user.warehouseStaffId,
        location_case_qty: damageProduct.from_location.location_case_qty - 1,
      },
    })

    redirect(SCAN_DAMAGE_CASE_RESOLUTION_PAGE)
  }

  return (
    <div className="tasks-page tasks-page--damage-resolution tasks-page--scan-resolution">
      <Header>
        {damageProduct.damaged_product.products_description.products_name}
      </Header>
      <div className="content">
        <div className="content__info">
          <div className="content__info-item">
            <div>Model</div>
            <div className="content__info-text">
              {damageProduct.damaged_product.product.products_model}
            </div>
          </div>
          <div className="content__info-item">
            <div>Manf Modal</div>
            <div className="content__info-text">
              {damageProduct.damaged_product.product.products_manf_model}
            </div>
          </div>
        </div>
        <Button onClick={showModalInventory}>Inventory</Button>
        <Button onClick={showModalRepurpose}>Repurpose</Button>
        <Button onClick={showModalResale}>Resale</Button>
      </div>
    </div>
  )
}

DamageResolutionPage.propTypes = {
  damageLocation: PropTypes.object,
}

const mapStateToProps = (state) => ({
  damageProduct: state.damageProduct.itemForInventory,
})

const mapDispatchToProps = {
  resetModals: resetModals,
  closeModal: closeModal,
  showModal: showModal,
  fetchDamageProductsForInventory: fetchDamageProductsForInventoryStart,
  updateDamageProducts: updateDamageProductsStart,
  createRestockLocation: createRestockLocation,
  updateWarehouseCase: updateWarehouseCase,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DamageResolutionPage)
