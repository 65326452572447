import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import RestockList from 'components/features/Task/RestockTaskListPage/RestockTaskContent/TabContent/RestockList'
import { RESTOCK_TYPE_LABELS, RESTOCK_TYPES } from 'constants/taskAssignment'
import ExpandedContent from 'components/features/Task/RestockTaskListPage/RestockTaskContent/TabContent/ExpandedContent'
import { redirect } from 'helpers/index'
import { ANY_STORAGE_TYPE, ANY_PICK_TYPE } from 'constants/locationTypes'
import {
  DAMAGED_RESTOCK_TASK_PAGE,
  RESTOCK_QA_HOLD_PAGE,
  RESTOCK_STORAGE_TO_STORAGE_PAGE,
  PARTIAL_PALLET_MOVE_TO_WRAP_PAGE,
  PICK_TO_PICK_RESTOCK_TASK_PAGE,
  SCANNING_DAMAGE_CASE_PAGE,
  START_RESTOCK_PAGE,
  RESTOCK_SMALL_PARCEL_PAGE,
  BULK_RESTOCK_TO_STORAGE_PAGE,
} from 'constants/routes'
import { connect } from 'react-redux'
import { resetModals, showModal } from 'store/modal/actions'
import { IS_SINGLE } from 'constants/damageRestockTaskTypes'
import { STATUS_IN_PROGRESS } from 'constants/processStatuses'
import { updateRestockProcess } from 'store/restockProcess/actions'

const TabContent = (props) => {
  const { items, showModal, resetModals, updateRestockProcess } = props

  const [selectedItemId, setSelectedItemId] = useState(0)
  const [damagedTasks, setDamagedTasks] = useState([])

  useEffect(() => {
    setDamagedTasks(
      items.filter(
        (item) =>
          item.entity.restock_location.restock_type_id ===
          RESTOCK_TYPES.DAMAGE_TYPE,
      ),
    )
  }, [items])

  const onAction = (item) => {
    updateRestockProcess({
      id: item.id,
      data: {
        id: item.id,
        status: STATUS_IN_PROGRESS,
      },
    })

    redirect(START_RESTOCK_PAGE.replace(':id', item.id))
  }
  const onCombineAction = (item) => {
    updateRestockProcess({
      id: item.id,
      data: {
        id: item.id,
        status: STATUS_IN_PROGRESS,
      },
    })

    redirect(
      PARTIAL_PALLET_MOVE_TO_WRAP_PAGE.replace(':processId', item.id).replace(
        ':palletId',
        null,
      ),
    )
  }
  const onScanFromLocationFoSingleCase = () => {
    updateRestockProcess({
      id: selectedItemId.toString(),
      data: {
        id: selectedItemId.toString(),
        status: STATUS_IN_PROGRESS,
      },
    })

    redirect(
      DAMAGED_RESTOCK_TASK_PAGE.replace(
        ':ids',
        selectedItemId.toString(),
      ).replace(':pageType', IS_SINGLE),
    )
  }
  const onStartScanning = () => redirect(SCANNING_DAMAGE_CASE_PAGE)
  const onQaHoldAction = (item) => {
    updateRestockProcess({
      id: item.id,
      data: {
        id: item.id,
        status: STATUS_IN_PROGRESS,
      },
    })

    redirect(RESTOCK_QA_HOLD_PAGE.replace(':processId', item.id))
  }

  const onStorageToStorageAction = (item) => {
    updateRestockProcess({
      id: item.id,
      data: {
        id: item.id,
        status: STATUS_IN_PROGRESS,
      },
    })

    redirect(RESTOCK_STORAGE_TO_STORAGE_PAGE.replace(':id', item.id))
  }

  const onPickToPickAction = (item) => {
    updateRestockProcess({
      id: item.id,
      data: {
        id: item.id,
        status: STATUS_IN_PROGRESS,
      },
    })

    redirect(PICK_TO_PICK_RESTOCK_TASK_PAGE.replace(':id', item.id))
  }

  const onSmallParcel = (item) => {
    updateRestockProcess({
      id: item.id,
      data: {
        id: item.id,
        status: STATUS_IN_PROGRESS,
      },
    })

    redirect(RESTOCK_SMALL_PARCEL_PAGE.replace(':id', item.id))
  }

  const onStagingToStorageAction = (item) => {
    updateRestockProcess({
      id: item.id,
      data: {
        id: item.id,
        status: STATUS_IN_PROGRESS,
      },
    })

    redirect(BULK_RESTOCK_TO_STORAGE_PAGE.replace(':id', item.id))
  }

  const getNormalizedItems = (items) =>
    items.map((item) => {
      const firstAndSingleRestockLocation = item.entity.restock_location
      const onClick = () => {
        switch (firstAndSingleRestockLocation.restock_type_id) {
          case RESTOCK_TYPES.DAMAGE_TYPE:
            setSelectedItemId(item.id)
            showModal({
              name: 'modalScanDamageLocation',
              damageLocation: getDamageLocation(),
              onBack: resetModals,
              onSuccess: onScanFromLocationFoSingleCase,
              onFailure: () => {},
              className: 'modal--tasks modal--damaged',
            })
            break
          case RESTOCK_TYPES.COMBINE_TYPE:
            onCombineAction(item)
            break
          case RESTOCK_TYPES.QA_HOLD_TYPE:
            onQaHoldAction(item)
            break
          case RESTOCK_TYPES.PICK_TO_PICK_TYPE:
            onPickToPickAction(item)
            break
          case RESTOCK_TYPES.STORAGE_TO_STORAGE_TYPE:
            onStorageToStorageAction(item)
            break
          case RESTOCK_TYPES.SMALL_PARCEL_TYPE:
            onSmallParcel(item)
            break
          default:
            onAction(item)
            break
        }
      }
      const fromAisle =
        firstAndSingleRestockLocation.warehouse_location_from.regular_location
          .aisle.name

      const fromBay =
        firstAndSingleRestockLocation.warehouse_location_from.regular_location
          .bay.name

      const fromLevel =
        firstAndSingleRestockLocation.warehouse_location_from.regular_location
          .level

      const fromQuantity = firstAndSingleRestockLocation.warehouse_location_from
        .warehouse_cases_in_location
        ? firstAndSingleRestockLocation.warehouse_location_from
            .warehouse_cases_in_location.location_case_qty
        : 0

      const locationFrom = (
        <>
          {fromAisle}-{fromBay}-{fromLevel}
          &nbsp;
          <span className="restock-qty">[ {fromQuantity} ]</span>
        </>
      )
      const toLocationType =
        firstAndSingleRestockLocation.warehouse_location_to.location_type.name

      const toAisle =
        firstAndSingleRestockLocation.warehouse_location_to.regular_location
          .aisle.name

      const toBay =
        firstAndSingleRestockLocation.warehouse_location_to.regular_location.bay
          .name

      const toLevel =
        firstAndSingleRestockLocation.warehouse_location_to.regular_location
          .level

      const toQuantity = firstAndSingleRestockLocation.warehouse_location_to
        .warehouse_cases_in_location
        ? firstAndSingleRestockLocation.warehouse_location_to
            .warehouse_cases_in_location.location_case_qty
        : 0

      const locationTo = (
        <>
          {toAisle}-{toBay}-{toLevel}
          &nbsp;
          <span className="restock-qty">[ {toQuantity} ]</span>
        </>
      )
      const isBulkStorage =
        item.entity.restock_location.warehouse_location_to.location_type
          .is_bulk_storage

      const restockDetails = firstAndSingleRestockLocation.orders
        ? firstAndSingleRestockLocation.orders.map((order) => {
            return (
              <>
                {order.order_id}
                &nbsp;
                <span className="restock-qty">
                  [{order.location_pick_quantity}]
                </span>
                &nbsp; &nbsp;
              </>
            )
          })
        : ''

      return {
        id: item.id,
        location: locationFrom,
        locationTo: locationTo,
        isBulkToLocation: isBulkStorage,
        total_pick_quantity: firstAndSingleRestockLocation.total_pick_quantity,
        taskType: toLocationType,
        model: firstAndSingleRestockLocation.product.products_model,
        details: restockDetails,
        status: item.status,
        action: onClick,
        options: {
          expandable: false,
          swipeable: false,
        },
        expandedContent: <></>,
      }
    })

  const getMultipleDamagedItem = (damagedTasks) => {
    let countDamagedCases = 0
    let expandedDamagedItems = []

    damagedTasks.forEach((element) => {
      const firstAndSingleRestockLocation = element.entity.restock_location

      countDamagedCases += firstAndSingleRestockLocation.quantity

      expandedDamagedItems.forEach((expandedDamagedItem, index) => {
        let isItemAdded = false

        if (
          expandedDamagedItem.product_id ===
            firstAndSingleRestockLocation.product.products_id &&
          expandedDamagedItem.warehouse_location_from ===
            firstAndSingleRestockLocation.warehouse_location_from.id &&
          expandedDamagedItem.warehouse_location_to ===
            firstAndSingleRestockLocation.warehouse_location_to.id
        ) {
          expandedDamagedItems[index].case +=
            firstAndSingleRestockLocation.quantity
          isItemAdded = true
        } else if (!isItemAdded && index === expandedDamagedItems.length - 1) {
          expandedDamagedItems = [
            ...expandedDamagedItems,
            {
              id: element.id,
              model: firstAndSingleRestockLocation.product.products_model,
              name: firstAndSingleRestockLocation.product_name,
              case: firstAndSingleRestockLocation.quantity,
              product_id: firstAndSingleRestockLocation.product.products_id,
              warehouse_location_from:
                firstAndSingleRestockLocation.warehouse_location_from.id,
              warehouse_location_to:
                firstAndSingleRestockLocation.warehouse_location_to.id,
            },
          ]
        }
      })

      if (!expandedDamagedItems.length) {
        expandedDamagedItems = [
          ...expandedDamagedItems,
          {
            id: element.id,
            model: firstAndSingleRestockLocation.product.products_model,
            name: firstAndSingleRestockLocation.product_name,
            case: firstAndSingleRestockLocation.quantity,
            product_id: firstAndSingleRestockLocation.product.products_id,
            warehouse_location_from:
              firstAndSingleRestockLocation.warehouse_location_from.id,
            warehouse_location_to:
              firstAndSingleRestockLocation.warehouse_location_to.id,
          },
        ]
      }
    })

    // every Damaged Process have the same from location
    const anyDamagedTask = damagedTasks[0]
    const firstAndSingleRestockLocation = anyDamagedTask.entity.restock_location
    const damagedLocation = `${firstAndSingleRestockLocation.warehouse_location_from.regular_location.aisle_id.name}-${firstAndSingleRestockLocation.warehouse_location_from.regular_location.bay_id.name}-${firstAndSingleRestockLocation.warehouse_location_from.regular_location.level}`

    return {
      id: anyDamagedTask.id,
      location: damagedLocation,
      taskType:
        RESTOCK_TYPE_LABELS[
          anyDamagedTask.entity.restock_location.restock_type_id
        ],
      model: 'Multiple',
      case: countDamagedCases,
      action: showModalScanDamageLocationForMultipleCaseWithInfo,

      options: {
        expandable: true,
        swipeable: false,
      },
      expandedContent: (
        <ExpandedContent
          onScanDamageLocation={
            showModalScanDamageLocationForMultipleCaseWithInfo
          }
          items={expandedDamagedItems}
        />
      ),
    }
  }

  const showModalScanDamageLocationForMultipleCaseWithInfo = () => {
    showModal({
      name: 'modalScanDamageLocationForMultipleCase',
      damageLocation: getDamageLocation(),
      onBack: resetModals,
      onStartScanning: onStartScanning,
      onSuccess: () => {},
      onFailure: () => {},
      className: 'modal--tasks modal--damaged',
    })
  }

  const generateNormalizedItems = () => {
    if (damagedTasks.length > 1) {
      let damagedItem = getMultipleDamagedItem(damagedTasks)

      let notDamagedItems = items.filter(
        (item) =>
          item.entity.restock_location.restock_type_id !==
          RESTOCK_TYPES.DAMAGE_TYPE,
      )
      return [...getNormalizedItems(notDamagedItems), damagedItem]
    }

    return getNormalizedItems(items)
  }

  const getDamageLocation = () => {
    // every Damaged Process have the same from location
    const anyDamagedTask = damagedTasks[0]

    if (anyDamagedTask) {
      const firstAndSingleRestockLocation =
        anyDamagedTask.entity.restock_location

      return {
        aisle:
          firstAndSingleRestockLocation.warehouse_location_from.regular_location
            .aisle_id.name,
        bay:
          firstAndSingleRestockLocation.warehouse_location_from.regular_location
            .bay_id.name,
        upc: firstAndSingleRestockLocation.warehouse_location_from.upc,
      }
    }

    return {
      aisle: '',
      bay: '',
      upc: '',
    }
  }

  return (
    <div className="tab__content">
      <RestockList rows={generateNormalizedItems()} />
    </div>
  )
}

TabContent.propTypes = {
  items: PropTypes.array,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  showModal: showModal,
  resetModals: resetModals,
  updateRestockProcess: updateRestockProcess,
}

export default connect(mapStateToProps, mapDispatchToProps)(TabContent)
