import { types } from '../actions'

const INIT = {
  item: {},
  isLoading: false,
}

const productCaseReducer = (state = INIT, action) => {
  const { type, payload } = action

  switch (type) {
    case types.productCase.FETCH_PRODUCT_CASE_SUCCESS:
      return { ...state, item: payload }
    case types.productCase.CREATE_PRODUCT_CASE:
    case types.productCase.SET_DAMAGED_CASES:
    case types.productCase.ADD_LOADED_CASES:
    default:
      return state
  }
}

export default productCaseReducer
