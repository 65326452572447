export const types = {
  FETCH_APP_INFO_START: 'FETCH_APP_INFO_START',
  FETCH_APP_INFO_SUCCESS: 'FETCH_APP_INFO_SUCCESS',
  FETCH_APP_INFO_FAIL: 'FETCH_APP_INFO_FAIL',
  SET_IS_OFFLINE: 'SET_OFFLINE',
  SET_SYSTEM_ERROR: 'SET_SYSTEM_ERROR',
  CLEAR_SYSTEM_ERROR: 'CLEAR_SYSTEM_ERROR',
  SET_IGNORE_COUNT: 'SET_IGNORE_COUNT',
}

export const fetchAppInfoStart = (payload) => ({
  type: types.FETCH_APP_INFO_START,
  payload,
})
export const fetchAppInfoSuccess = (payload) => ({
  type: types.FETCH_APP_INFO_SUCCESS,
  payload,
})
export const fetchAppInfoFail = (payload) => ({
  type: types.FETCH_APP_INFO_FAIL,
  payload,
})
export const setIsOffline = (payload) => ({
  type: types.SET_IS_OFFLINE,
  payload,
})
export const setIgnoreCount = (payload) => ({
  type: types.SET_IGNORE_COUNT,
  payload,
})
export const setSystemError = (payload) => ({
  type: types.SET_SYSTEM_ERROR,
  payload,
})
export const clearSystemError = (payload) => ({
  type: types.CLEAR_SYSTEM_ERROR,
  payload,
})
