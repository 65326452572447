import React, { useState, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import QuantityControl from 'components/common/QuantityControl'
import ModalDefault from 'components/common/Modal/ModalDefault'
import Button from 'components/common/Button'
import CancelIcon from 'assets/svg/cancel.svg'
import CancelIconIPod from 'assets/svg/cancel--ipod.svg'

import './style.scss'
import { isEmptyObject } from 'helpers/index'
import { connect } from 'react-redux'
import Toggle from 'components/common/Toggle'

const ModalPalletAdjustment = ({ productInfo, modalInfo, isOpen }) => {
  const [initialCasesPerLayer, setInitialCasesPerLayer] = useState()
  const [initialLayersPerPallet, setInitialLayersPerPallet] = useState()
  const [initialCasesPerPallet, setInitialCasesPerPallet] = useState()

  const [sparkCasesPerPallet, setSparkCasesPerPallet] = useState()

  const [liveCasesPerLayer, setLiveCasesPerLayer] = useState()
  const [liveLayersPerPallet, setLiveLayersPerPallet] = useState()

  const [palletCountsMatch, setPalletCountsMatch] = useState(false)

  const [forceRecount, setForceRecount] = useState(false)

  useEffect(() => {
    if (
      modalInfo !== undefined &&
      modalInfo.scannedItem !== undefined &&
      !isEmptyObject(modalInfo.scannedItem)
    ) {
      const casesPerLayer = modalInfo.scannedItem.product_case.cases_per_layer
      const layersPerPallet =
        modalInfo.scannedItem.product_case.layers_per_pallet
      const maxCasesPerPallet =
        modalInfo.scannedItem.product_case.max_cases_per_pallet
      const totalCases = modalInfo.scannedItem.quantity
      const shouldHaveSinglePartialOnly =
        modalInfo.scannedItem.cases_on_pallets < maxCasesPerPallet
      const highestCaseCountOnAnyPallet =
        modalInfo.scannedItem.container_pallets.length > 0
          ? modalInfo.scannedItem.container_pallets.reduce((max, pallet) => {
              return pallet.cases_per_pallet > max
                ? pallet.cases_per_pallet
                : max
            }, modalInfo.scannedItem.container_pallets[0].cases_per_pallet)
          : 0

      const calculateOptimalCasesOnPalletsArray = () => {
        let wholePallets = Math.floor(totalCases / maxCasesPerPallet)
        let remainder = totalCases % maxCasesPerPallet
        let caseCountsOnPallets = new Array(wholePallets).fill(
          maxCasesPerPallet,
        )
        remainder > 0 && caseCountsOnPallets.push(remainder)
        return caseCountsOnPallets
      }
      const optimalCasesOnPalletsArray = calculateOptimalCasesOnPalletsArray()

      const comparePalletCounts = () => {
        const remainingCases = [...optimalCasesOnPalletsArray] // Create a copy to track remaining cases
        for (
          let i = 0;
          i < modalInfo.scannedItem.container_pallets.length;
          i++
        ) {
          const casesPerPallet =
            modalInfo.scannedItem.container_pallets[i].cases_per_pallet

          // Check if cases_per_pallet is in the remainingCases array
          const index = remainingCases.indexOf(casesPerPallet)
          if (index !== -1) {
            // If found, remove the matching entry from the remainingCases array
            remainingCases.splice(index, 1)
          } else {
            setPalletCountsMatch(false)
            return // counts don't match, exit the loop early
          }
        }

        if (remainingCases.length === 0) {
          setPalletCountsMatch(true)
        } else {
          setPalletCountsMatch(false)
        }
      }

      modalInfo.scannedItem.container_pallets.length > 0
        ? comparePalletCounts()
        : setPalletCountsMatch(false)

      const forInitialCasesPerPallet = !shouldHaveSinglePartialOnly
        ? highestCaseCountOnAnyPallet
        : casesPerLayer * layersPerPallet

      setInitialCasesPerLayer(casesPerLayer)
      setInitialLayersPerPallet(layersPerPallet)
      setLiveCasesPerLayer(casesPerLayer)
      setLiveLayersPerPallet(layersPerPallet)
      setSparkCasesPerPallet(
        modalInfo.scannedItem.product_case.max_cases_per_pallet,
      )
      setInitialCasesPerPallet(forInitialCasesPerPallet)
    }
  }, [modalInfo])

  const isStackChanged =
    liveCasesPerLayer !== initialCasesPerLayer ||
    liveLayersPerPallet !== initialLayersPerPallet ||
    sparkCasesPerPallet !== initialCasesPerPallet ||
    !palletCountsMatch
      ? true
      : false

  useEffect(() => {
    setForceRecount(isStackChanged)
  }, [isStackChanged])

  if (!isOpen) {
    return <></>
  }

  const onChangeToggle = () => {
    if (isStackChanged) {
      setForceRecount(true)
    } else {
      setForceRecount(!forceRecount)
    }
  }

  return (
    <ModalDefault isOpen={isOpen}>
      <div className="modal modal--pallet">
        <div className="title">Pallet</div>
        <QuantityControl
          value={liveCasesPerLayer}
          label={'Case / Layer'}
          name="casesPerLayer"
          onChange={(name, value) => setLiveCasesPerLayer(value)}
          onDecrease={() => setLiveCasesPerLayer(liveCasesPerLayer - 1)}
          onIncrease={() => setLiveCasesPerLayer(liveCasesPerLayer + 1)}
        />
        <QuantityControl
          value={liveLayersPerPallet}
          label={'Layer / Pallet'}
          name="layersPerPallet"
          onChange={(name, value) => setLiveLayersPerPallet(value)}
          onDecrease={() => setLiveLayersPerPallet(liveLayersPerPallet - 1)}
          onIncrease={() => setLiveLayersPerPallet(liveLayersPerPallet + 1)}
        />
        <div>
          <Toggle
            text="Force Recount"
            checked={forceRecount}
            onChange={onChangeToggle}
            disableSwitch={isStackChanged}
          />
        </div>
        <Button
          onClick={() =>
            modalInfo.onConfirm({
              ...modalInfo.scannedItem.product_case,
              ...{
                cases_per_layer: liveCasesPerLayer,
                layers_per_pallet: liveLayersPerPallet,
                max_cases_per_pallet: liveCasesPerLayer * liveLayersPerPallet,
                forceRecount: forceRecount,
              },
            })
          }
        >
          Confirm
        </Button>
        <div onClick={modalInfo.onClose} className="btn--close">
          {window.innerWidth < 768 ? <CancelIconIPod /> : <CancelIcon />}
        </div>
      </div>
    </ModalDefault>
  )
}

ModalPalletAdjustment.propTypes = {
  isOpen: PropTypes.bool,
  productInfo: PropTypes.object,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalPalletAdjustment',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalPalletAdjustment',
  ),
  productInfo: state.productInfo.item,
})

export default connect(mapStateToProps)(ModalPalletAdjustment)
