import { takeLatest, call, put } from 'redux-saga/effects'
import {
  validateFromBarcode,
  validateToUPC,
  startRelocate,
  fetchRelocateTask,
  processRelocate,
} from 'api/relocate'
import { types, fetchRelocateTaskSuccess } from './actions'

function* beginRelocate(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(startRelocate, request)

    if (afterAction) {
      yield afterAction(response.data.data)
    }
  } catch (error) {}
}

function* getRelocateTask(action) {
  const { payload } = action

  try {
    const response = yield call(fetchRelocateTask, payload)

    yield put(fetchRelocateTaskSuccess(response.data.data.item))
  } catch (error) {}
}

function* finishRelocate(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(processRelocate, request)
    if (afterAction) {
      yield afterAction(response.data.data)
    }
  } catch (error) {}
}

function* verifyToUPC(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(validateToUPC, request)

    if (afterAction) {
      yield afterAction(response.data.data)
    }
  } catch (error) {}
}

function* verifyFromBarcode(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(validateFromBarcode, request)

    if (afterAction) {
      yield afterAction(response.data.data)
    }
  } catch (error) {}
}

export default function* relocateSaga() {
  yield takeLatest(types.START_RELOCATE, beginRelocate)
  yield takeLatest(types.FETCH_RELOCATE_TASK, getRelocateTask)
  yield takeLatest(types.VALIDATE_TO_UPC, verifyToUPC)
  yield takeLatest(types.VALIDATE_FROM_BARCODE, verifyFromBarcode)
  yield takeLatest(types.PROCESS_RELOCATE, finishRelocate)
}
