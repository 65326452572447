export const WMS_PDF_KEY_VALUE = '7d72W37nd7cjj3jdFRED93d033HhgReVMu76dGek7'
export const WMS_PDF_KEY_NAME = 'wms_pdf_key'

export const STOCK_4X6 = 1
export const STOCK_2_4_X_3_9 = 2
export const STOCK_2_4 = 3
export const STOCK_LETTER = 4

export const ORDER_PALLET_LABEL = {
  printerStockId: STOCK_4X6,
  path: 'order_pallet_label',
}

export const PALLET_LABEL = {
  printerStockId: STOCK_4X6,
  path: 'pallet_label',
}

export const CONTAINER_PALLET_LABELS = {
  printerStockId: STOCK_4X6,
  path: 'container_pallet_labels',
}

export const CASE_UPC = {
  printerStockId: STOCK_2_4_X_3_9,
  path: 'case_upc',
}
