import request, { requestWithRetry } from 'helpers'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}tasks`

export const fetchOne = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}/${payload.id}`,
    method: 'GET',
  })

export const update = (payload) =>
  request({
    url: `${apiEndpoint}`,
    method: 'PUT',
    data: payload,
  })

export const create = (payload) =>
  request({
    url: apiEndpoint,
    method: 'POST',
    data: payload,
  })
