const fillWithZero = (data, length, zeroSide = 'left') => {
  const string = data.toString()

  return zeroSide === 'left'
    ? `${'0'.repeat(length - string.length)}${string}`
    : `${string}${'0'.repeat(length - string.length)}`
}

class BarcodeService {
  static _params = {
    WMS_BARCODE: 4,
    TYPE_PALLET: 20,
    PALLET_BARCODE_LENGTH: 6,
    WAREHOUSE_BARCODE_LENGTH: 3,
  }

  static generatePalletBarcode = (palletId, warehouseId) => {
    return [
      this._params.WMS_BARCODE,
      this._params.TYPE_PALLET,
      fillWithZero(warehouseId, this._params.WAREHOUSE_BARCODE_LENGTH),
      fillWithZero(palletId, this._params.PALLET_BARCODE_LENGTH),
    ].join('')
  }
}

export default BarcodeService
