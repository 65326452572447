import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import QuantityControl from 'components/common/QuantityControl'
import ModalDefault from 'components/common/Modal/ModalDefault'
import Button from 'components/common/Button'
import CancelIcon from 'assets/svg/cancel.svg'
import CancelIconIPod from 'assets/svg/cancel--ipod.svg'

import './style.scss'
import PalletLocationsGroup from 'components/common/PalletLocationsGroup'
import { connect } from 'react-redux'

const ModalCaseReceived = (props) => {
  const { isOpen, modalInfo } = props

  const customStyles = {
    fontSize: 30,
  }

  const [quantity, setQuantity] = useState(null)

  if (!isOpen) {
    return <></>
  }

  const onAcceptHandler = (quantity) => {
    setQuantity(0)

    modalInfo.onAccept(quantity)
  }

  if (isOpen && !quantity) {
    setQuantity(modalInfo.product.quantity - modalInfo.alreadyReceivedQty)
  }

  return (
    <ModalDefault isOpen={isOpen}>
      <div className="modal modal-full modal--case-received">
        <div className="title">
          Case Received (
          {`${modalInfo.alreadyReceivedQty}/${modalInfo.product.quantity}`})
          <div className="title__under-text">
            {modalInfo.product.description.products_name}
          </div>
        </div>
        <PalletLocationsGroup
          processId={modalInfo.processId}
          product={modalInfo.product}
          locationTypes={modalInfo.locationTypes}
          key={'case-received'}
        />
        <span style={{ fontSize: '35px', fontWeight: 'bold' }}>{quantity}</span>
        {/* <QuantityControl
          value={quantity}
          name="quantity"
          minValue={-modalInfo.alreadyReceivedQty}
          onChange={(name, value) => setQuantity(value)}
          onDecrease={() => setQuantity(parseInt(quantity) - 1)}
          onIncrease={() => setQuantity(parseInt(quantity) + 1)}
          styles={customStyles}
        /> */}
        <Button onClick={() => onAcceptHandler(quantity)}>Confirm</Button>
        <div onClick={modalInfo.onClose} className="btn--close">
          {window.innerWidth < 768 ? <CancelIconIPod /> : <CancelIcon />}
        </div>
      </div>
    </ModalDefault>
  )
}

ModalCaseReceived.propTypes = {
  isOpen: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalCaseReceived',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalCaseReceived',
  ),
})

export default connect(mapStateToProps)(ModalCaseReceived)
