import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import LocationToIcon from 'assets/svg/to-location.svg'
import Active from 'assets/svg/active--ipad.svg'
import ActiveGrey from 'assets/svg/active-grey--ipad.svg'
import LocationFromIcon from 'assets/svg/from-location.svg'
import MoreIcon from 'assets/svg/more-icon.svg'
import Report from 'assets/svg/report.svg'
import ReportMissingIcon from 'assets/svg/report-missing.svg'
import { STATUS_COMPLETED } from 'constants/processStatuses'
import { RESTOCK_TASK_LIST_PAGE } from 'constants/routes'
import Header from 'components/common/Header'
import Scanner from 'components/common/Scanner'
import Button from 'components/common/Button'
import Tooltip from 'components/common/Tooltip'
import { isEmptyObject, redirect } from 'helpers/index'
import { closeModal, resetModals, showModal } from 'store/modal/actions'
import {
  fetchOneRestockProcessStart,
  updateRestockProcess,
} from 'store/restockProcess/actions'
import {
  createWarehouseCase,
  updateWarehouseCase,
} from 'store/warehouseCase/actions'
import { PROCESS_TYPES } from 'constants/process'
import { fetchLocationsStart } from 'store/location/actions'
import { createDamageCases } from 'store/product/actions'
import * as query from 'querystringify'
import { LOCATION_TYPE_FILTER, WAREHOUSE_FILTER } from 'constants/filters'
import { LOCATION_TYPE } from 'constants/locationTypes'
import { MODAL_CASE_QTY } from 'components/common/Modal'

const PickToPickRestockPage = (props) => {
  const {
    match,
    user,
    currentProcess,
    damageLocation,

    fetchLocationsStart,
    fetchOneRestockProcessStart,
    createDamageCases,
    createWarehouseCase,
    updateWarehouseCase,
    updateRestockProcess,

    modalDamagePutawayLocationIsOpen,

    showModal,
    closeModal,
    resetModals,
  } = props

  const processId = match.params.id

  const [locationTitle, setLocationTitle] = useState('')
  const [scannedCasesQty, setScannedCasesQty] = useState(0)

  const [restockLocation, setRestockLocation] = useState({})

  const [isFromLocationScanned, setIsFromLocationScanned] = useState(false)
  const [isToLocationScanned, setIsToLocationScanned] = useState(false)
  const [isCaseScanned, setIsCaseScanned] = useState(false)
  const [isSuccessScan, setIsSuccessScan] = useState(false)

  const onMount = () => {
    showModal({ name: 'modalPending' })
    fetchOneRestockProcessStart({ id: processId })
    fetchLocationsStart(
      query.stringify(
        {
          [WAREHOUSE_FILTER]: user.warehouseId,
          [LOCATION_TYPE_FILTER]: LOCATION_TYPE.DAMAGE_TYPE_ID,
        },
        true,
      ),
    )
  }

  const onChangeCurrentProcess = () => {
    if (!isEmptyObject(currentProcess)) {
      setRestockLocation(currentProcess.entity.restock_location[0])
    }
  }

  const onChangeRestockLocation = () => {
    if (!isEmptyObject(restockLocation)) {
      const type = isCaseScanned
        ? 'warehouse_location_to'
        : 'warehouse_location_from'

      setLocationTitle(
        [
          restockLocation[type].regular_location.aisle_id.name,
          restockLocation[type].regular_location.bay_id.name,
          restockLocation[type].regular_location.level,
        ].join('-'),
      )

      closeModal('modalPending')
    }
  }

  useEffect(onMount, [])
  useEffect(onChangeCurrentProcess, [currentProcess])
  useEffect(onChangeRestockLocation, [restockLocation])

  if (isEmptyObject(currentProcess) || !restockLocation || !locationTitle) {
    return ''
  }

  const getWarehouseCaseFrom = () =>
    restockLocation.warehouse_location_from.warehouse_cases_in_location.find(
      (item) => item.product_id === restockLocation.product.products_id,
    )
  const getWarehouseCaseTo = () =>
    restockLocation.warehouse_location_to.warehouse_cases_in_location.find(
      (item) => item.product_id === restockLocation.product.products_id,
    )

  const onScanFromLocation = (barcode) => {
    if (
      restockLocation.warehouse_location_from.upc.toString() ===
      barcode.toString()
    ) {
      setIsSuccessScan(true)
      setTimeout(() => {
        setIsFromLocationScanned(true)
        setIsSuccessScan(false)
      }, 1500)
    } else {
      showModal({
        name: 'modalWrongLocation',
        onAccept: resetModals,
        text: locationTitle,
      })
    }
  }

  const onScanCase = (barcode) => {
    const onConfirm = (quantity) => {
      resetModals()

      setScannedCasesQty(quantity)
      setIsSuccessScan(true)

      setTimeout(() => {
        setIsCaseScanned(true)
        setLocationTitle(
          [
            restockLocation.warehouse_location_to.regular_location.aisle_id
              .name,
            restockLocation.warehouse_location_to.regular_location.bay_id.name,
            restockLocation.warehouse_location_to.regular_location.level,
          ].join('-'),
        )
        setIsSuccessScan(false)
      }, 1500)

      // Remove <quantity> of cases from From Location
      updateWarehouseCase({
        id: getWarehouseCaseFrom().id,
        data: {
          id: getWarehouseCaseFrom().id,
          location_case_qty:
            getWarehouseCaseFrom().location_case_qty - quantity,
        },
      })

      // Create new if cases_in_location_to doesn't exists, else update existing record
      !getWarehouseCaseTo()
        ? createWarehouseCase({
            data: {
              updated_by_user_id: user.warehouseStaffId,
              created_by_user_id: user.warehouseStaffId,
              product_id: restockLocation.product.products_id,
              location_case_qty: quantity,
              warehouse_location_id: restockLocation.warehouse_location_to.id,
            },
          })
        : updateWarehouseCase({
            id: getWarehouseCaseTo().id,
            data: {
              id: getWarehouseCaseTo().id,
              location_case_qty:
                getWarehouseCaseTo().location_case_qty + quantity,
            },
          })
    }

    if (
      restockLocation.product.products_upc_case.toString() ===
      barcode.toString() // TODO: this will likely need to change to match the new multi-upc table data, re: wms-1835 (07.2021)
    ) {
      showModal({
        name: MODAL_CASE_QTY,
        onDeny: resetModals,
        onConfirm: onConfirm,
        value: restockLocation.quantity,
        maxValue: restockLocation.quantity,
      })
    } else {
      showModal({
        name: 'modalWrongCaseUPC',
        onAccept: resetModals,
      })
    }
  }

  const onScanToLocation = (barcode) => {
    if (
      restockLocation.warehouse_location_to.upc.toString() ===
      barcode.toString()
    ) {
      setIsSuccessScan(true)

      setTimeout(() => {
        setIsToLocationScanned(true)
        setIsSuccessScan(false)
      }, 1500)
    } else {
      showModal({
        name: 'modalWrongLocation',
        onAccept: resetModals,
        text: locationTitle,
      })
    }
  }

  const onScan = (barcode) => {
    switch (true) {
      case !isFromLocationScanned:
        onScanFromLocation(barcode)
        break
      case !isCaseScanned:
        onScanCase(barcode)
        break
      case !isToLocationScanned:
        onScanToLocation(barcode)
        break
      default:
        break
    }
  }

  const onComplete = () => {
    updateRestockProcess({
      id: processId,
      data: {
        id: processId,
        status: STATUS_COMPLETED,
      },
    })

    redirect(RESTOCK_TASK_LIST_PAGE)
  }

  const getStatusPanel = () => {
    let content = ''
    const statusIcon = isSuccessScan ? <Active /> : <ActiveGrey />

    switch (true) {
      case isCaseScanned && isToLocationScanned:
        content = <Button onClick={onComplete}>{'Complete'}</Button>
        break
      case isCaseScanned:
      case !isFromLocationScanned:
        content = <>Scan Location UPC {statusIcon}</>
        break
      case isFromLocationScanned:
        content = <>Scan Case UPC {statusIcon}</>
        break
      default:
        break
    }

    return (
      <div className="content__section--white">
        <span className="status">{content}</span>
      </div>
    )
  }

  const getDamageLocationName = () =>
    damageLocation ? `${damageLocation.aisle}-${damageLocation.bay}` : ''

  const getDamageLocationUPC = () => (damageLocation ? damageLocation.upc : '')

  const onConfirmDamageLocationScan = (value) => {
    resetModals()

    createDamageCases({
      request: {
        data: {
          product_id: restockLocation.product.products_id,
          process_type: PROCESS_TYPES.RESTOCK_PROCESS_TYPE,
          warehouse_location_id: damageLocation.id,
          count_damaged_cases: value,
        },
      },
    })

    updateWarehouseCase({
      id: getWarehouseCaseFrom().id,
      data: {
        id: getWarehouseCaseFrom().id,
        location_case_qty: getWarehouseCaseFrom().location_case_qty - value,
      },
    })

    setScannedCasesQty(scannedCasesQty + value)
  }

  const onCloseModalWrongLocation = () => closeModal('modalWrongLocation')

  const showModalWrongLocationWithInfo = () => {
    showModal({
      name: 'modalWrongLocation',
      onAccept: onCloseModalWrongLocation,
      text: modalDamagePutawayLocationIsOpen
        ? getDamageLocationName()
        : locationTitle,
      className: 'modal--tasks',
    })
  }

  const showModalDamagePutawayLocation = (value) => {
    resetModals()
    showModal({
      name: 'modalDamagePutawayLocation',
      damageQuantity: value,
      text: getDamageLocationName(),
      upc: getDamageLocationUPC(),
      className: 'modal--tasks modal--damaged',
      onCaseUpcCorrect: onConfirmDamageLocationScan,
      onCaseUpcNotCorrect: showModalWrongLocationWithInfo,
      onBack: showModalDamageQty,
    })
  }

  const showModalDamageQty = () => {
    showModal({
      name: 'modalDamageQty',
      value: 1,
      onConfirm: showModalDamagePutawayLocation,
      onDeny: resetModals,
      maxValue: restockLocation.quantity - scannedCasesQty,
      className: 'modal--tasks modal--damaged',
    })
  }

  const onReportMissing = () => {
    resetModals()

    // TODO:need to clarify
    // updateWarehouseCase({
    //   id: getWarehouseCaseFrom().id,
    //   data: {
    //     id: getWarehouseCaseFrom().id,
    //     location_case_qty: 0
    //   }
    // });

    setScannedCasesQty(restockLocation.quantity)
  }

  const showModalReportMissing = () => {
    showModal({
      name: 'modalConfirm',
      className: 'modal--report',
      onDeny: resetModals,
      onAccept: onReportMissing,
      children: <div className="text"> Report(s) Missing </div>,
    })
  }

  const tooltipItems = [
    {
      id: 1,
      icon: <Report className="tooltip__icon" />,
      label: 'Report Damage',
      className: `tooltip__item ${
        !isCaseScanned ? 'tooltip__item--disabled' : ''
      }`,
      onClick: showModalDamageQty,
    },
    {
      id: 2,
      icon: <ReportMissingIcon className="tooltip__icon" />,
      label: ' Report Missing',
      className: `tooltip__item ${
        !isCaseScanned ? 'tooltip__item--disabled' : ''
      }`,
      onClick: showModalReportMissing,
    },
  ]

  return (
    <div className="tasks-page tasks-page--start-restock page--restock-relocation">
      <Header>
        {isToLocationScanned && scannedCasesQty !== restockLocation.quantity && (
          <Tooltip button={<MoreIcon />}>
            <ul>
              {tooltipItems.map((item) => (
                <li
                  key={item.id}
                  className={item.className}
                  onClick={item.onClick}
                >
                  {item.icon ? item.icon : ''}
                  {item.label}
                </li>
              ))}
            </ul>
            <span className="triangle" />
          </Tooltip>
        )}
      </Header>
      <Scanner needToRemoveListener={false} onReceiveData={onScan} />

      <div className="content">
        <div className="content__section">
          <div className="dock__wrapper">
            <div className="dock">
              <span className="dock__label">LOC</span>
              <span className="dock__name">{locationTitle}</span>
            </div>
          </div>
          <h3 className="fireworks-name">{restockLocation.product_name}</h3>
          <ul className="fireworks">
            <li className="fireworks__item">
              <span className="fireworks__name">Model</span>
              <span className="fireworks__sub-name">
                {restockLocation.product.products_model}
              </span>
            </li>
            <li className="fireworks__item">
              <span className="fireworks__name">Manf Model</span>
              <span className="fireworks__sub-name">
                {restockLocation.product.products_manf_model}
              </span>
            </li>
            <li className="fireworks__item">
              {isFromLocationScanned ? (
                <LocationToIcon />
              ) : (
                <LocationFromIcon />
              )}
            </li>
          </ul>
        </div>
        {getStatusPanel()}
      </div>
    </div>
  )
}

PickToPickRestockPage.propTypes = {
  match: PropTypes.object,
  currentProcess: PropTypes.object,
  damageLocation: PropTypes.object,

  fetchOneRestockProcessStart: PropTypes.func,
  createWarehouseCase: PropTypes.func,
  updateWarehouseCase: PropTypes.func,
  updateRestockProcess: PropTypes.func,
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  resetModals: PropTypes.func,
}

const mapStateToProps = (state) => ({
  damageLocation: state.location.list[0],
  currentProcess: state.restockProcess.item,
  modalDamagePutawayLocationIsOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalDamagePutawayLocation',
  ),
})

const mapDispatchToProps = {
  fetchOneRestockProcessStart,
  fetchLocationsStart,

  createWarehouseCase,
  createDamageCases,

  updateWarehouseCase,
  updateRestockProcess,

  showModal,
  closeModal,
  resetModals,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PickToPickRestockPage)
