import React, { useState } from 'react'
import '../../style.scss'
import * as PropTypes from 'prop-types'
import Header from 'components/common/Header'
import Scanner from 'components/common/Scanner'
import { connect } from 'react-redux'
import { redirect } from 'helpers/index'
import Active from 'assets/svg/active--ipad.svg'
import ActiveGrey from 'assets/svg/active-grey--ipad.svg'
import * as query from 'querystringify'
import {
  INVENTORY_TOOL_PAGE,
  INVENTORY_TOOL_SCAN_LOCATION_PAGE,
} from 'constants/routes'
import { closeModal, resetModals, showModal } from 'store/modal/actions'
import { fetchProductCaseStart } from 'store/product/actions'
import { LOCATION_TYPE } from 'constants/locationTypes'
import ExclamationSmall from 'assets/svg/exclamation-sign.svg'
import { createWarehouseCaseSync } from 'store/warehouseCase/actions'

const InventoryToolScanCase = (props) => {
  const {
    location,
    user,

    showModal,
    closeModal,
    resetModals,
    fetchProductCase,
    createWarehouseCase,
  } = props

  const [isScanned, setIsScanned] = useState(false)

  const getProductCaseParamsForFetch = (upc) =>
    query.stringify(
      {
        case_upc: upc,
      },
      true,
    )

  const showModalWrongCaseUpc = () => {
    showModal({
      name: 'modalWarning',
      text: 'Wrong InventoryToolCasePage UPC!',
      onAccept: resetModals,
      onDeny: resetModals,
      className: 'modal--tasks modal--pause',
      modalIcon: <ExclamationSmall />,
    })
  }

  const onCaseScannedSuccessfully = (warehouseCasesId) => {
    setIsScanned(true)

    setTimeout(() => {
      redirect(INVENTORY_TOOL_PAGE.replace(':id', warehouseCasesId))
    }, 1500)
  }

  const createWarehouseCaseAndRedirect = (item) => {
    const afterAction = (item) => {
      onCaseScannedSuccessfully(item.id)
      closeModal('modalPending')
    }

    createWarehouseCase({
      request: {
        data: {
          product_id: item.product_id,
          location_case_qty: 0,
          updated_by_user_id: user.warehouseStaffId,
          created_by_user_id: user.warehouseStaffId,
          warehouse_location_id: location.id,
        },
      },
      afterAction: afterAction,
    })
  }

  const findProductByUpcAndCreateNewWarehouseCaseIfExist = (barcode) => {
    showModal({
      name: 'modalPending',
    })

    const onSuccess = (item) => {
      if (item) {
        createWarehouseCaseAndRedirect(item)
      } else {
        closeModal('modalPending')
        showModalWrongCaseUpc()
      }
    }

    fetchProductCase({
      request: getProductCaseParamsForFetch(barcode),
      onSuccess: onSuccess,
    })
  }

  const onScan = (barcode) => {
    const checkForMatch = () => {
      let objectReturn
      location.warehouse_cases_in_location.forEach((caseItem) => {
        for (const [key, value] of Object.entries(caseItem.product.case_Upcs)) {
          if (value.products_upc_case.toString() === barcode.toString()) {
            objectReturn = caseItem
          }
        }
      })
      return objectReturn
    }
    const warehouseCase = checkForMatch()

    const isLocationForMultipleCases =
      [LOCATION_TYPE.QUARANTINE_TYPE_ID, LOCATION_TYPE.DAMAGE_TYPE_ID].indexOf(
        location.location_type.id,
      ) > -1

    const isLocationHasWarehouseCases = !!location.warehouse_cases_in_location
      .length

    switch (true) {
      case !!warehouseCase:
        onCaseScannedSuccessfully(warehouseCase.id)
        break
      case !isLocationHasWarehouseCases:
        findProductByUpcAndCreateNewWarehouseCaseIfExist(barcode)
        break
      case isLocationForMultipleCases:
        findProductByUpcAndCreateNewWarehouseCaseIfExist(barcode)
        break
      default:
        showModalWrongCaseUpc()
    }
  }

  const getLocationName = () => {
    const { aisle_id, bay_id, level } = location.regular_location

    return `${aisle_id.name}-${bay_id.name}-${level}`
  }

  const onClose = () => redirect(INVENTORY_TOOL_SCAN_LOCATION_PAGE)

  const getScanContent = () => {
    return (
      <div className="content page--inventory-scan">
        <Scanner onReceiveData={onScan} needToRemoveListener={false} />
        <div className="content__section--white">
          <span className="status">
            Scan Case UPC
            {isScanned ? <Active /> : <ActiveGrey />}
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className="page page--restock page--qa-custom page--inventory-scan">
      <Header>
        {getLocationName()}
        <div className="text text--close" onClick={onClose}>
          Close
        </div>
      </Header>
      {getScanContent()}
    </div>
  )
}

InventoryToolScanCase.propTypes = {
  location: PropTypes.object,

  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  resetModals: PropTypes.func,
}

const mapStateToProps = (state) => ({
  location: state.location.editedItem,
})

const mapDispatchToProps = {
  fetchProductCase: fetchProductCaseStart,
  createWarehouseCase: createWarehouseCaseSync,
  showModal: showModal,
  closeModal: closeModal,
  resetModals: resetModals,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InventoryToolScanCase)
