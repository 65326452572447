import React from 'react'
import * as PropTypes from 'prop-types'
import '../style.scss'
import Avatar from 'components/features/StaffCard/Avatar'
import { getPhotoLink } from 'helpers/index'
import { MANAGER_ROLES, WORKER_ROLES } from 'constants/roles'

const PERCENT_HARDCODE = 60
const CUSTOM_PHOTO_API = 'https://api.dicebear.com/7.x/initials/svg?seed='

const Card = ({ item, options, events }) => {
  const onAvatarClick = () =>
    options.selectable ? events.onSelect(item) : false
  const isManager = item.taskAssignment
    ? !!MANAGER_ROLES.find(
        (managerRoleId) => managerRoleId === item.taskAssignment.role_id,
      )
    : item.roles.filter(
        (role) =>
          !!MANAGER_ROLES.find((managerRoleId) => managerRoleId === role.id),
      ).length > 0
  const role = item.roles.find((role) =>
    isManager
      ? MANAGER_ROLES.indexOf(role.id) > -1
      : WORKER_ROLES.indexOf(role.id) > -1,
  )
  const roleName = !!role && !!role.name ? role.name : ''
  const assignmentsCount = item.taskAssignments
    ? item.taskAssignments.length
    : 0

  return (
    <div className="card">
      <Avatar
        isChecked={item.is_checked}
        isPrimary={item.roles.filter((role) => role.is_primary).length > 0}
        onClick={onAvatarClick}
        link={
          item.staffLogin.photo_url
            ? getPhotoLink(item.staffLogin.photo_url)
            : `${CUSTOM_PHOTO_API}${item.staffLogin.name}`
        }
        loadPercent={PERCENT_HARDCODE}
      />
      <div className="text">{item.staffLogin.name}</div>
      {!options.viewable ? (
        <div className={`badge${isManager ? ' badge--secondary' : ''}`}>
          {roleName}
        </div>
      ) : (
        ''
      )}
      {options.viewable ? (
        <div
          className="badge badge--secondary"
          onClick={() => events.onView(item)}
        >
          View
        </div>
      ) : (
        ''
      )}
      {assignmentsCount > 0 ? (
        <div className="badge badge--secondary badge--circle">
          {assignmentsCount}
        </div>
      ) : (
        false
      )}
    </div>
  )
}

Card.propTypes = {
  item: PropTypes.object.isRequired,
}

export default Card
