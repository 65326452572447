import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import TableList from 'components/common/TableList'
import Button from 'components/common/Button'
import { connect } from 'react-redux'
import Header from 'components/common/Header'
import CloseButton from 'components/common/CloseButton'
import { fetchQaHoldListStart } from 'store/qaHold/actions'
import { redirect } from 'helpers/index'
import { SCAN_PALLET_UPC_PAGE } from 'constants/routes'
import * as query from 'querystringify'

const columns = [
  {
    id: 1,
    label: 'Container',
    value: 'container',
  },
  {
    id: 2,
    label: 'QA Product',
    value: 'qaProduct',
  },
  {
    id: 3,
    label: '',
    value: 'button',
  },
]

const ContainerListPage = (props) => {
  const {
    qaHolds,

    fetchQaHoldList,
  } = props

  const onMount = () => {
    fetchQaHoldList(query.stringify({ 'filters[resolution_id]': null }, true))
  }

  useEffect(onMount, [])

  const onUpdateQaHolds = () => {
    if (qaHolds.length) {
      const normalizedQaHolds = getNormalizedItems()

      if (normalizedQaHolds.length === 1) {
        redirect(SCAN_PALLET_UPC_PAGE.replace(':id', normalizedQaHolds[0].id))
      }
    }
  }

  useEffect(onUpdateQaHolds, [qaHolds])

  const getNormalizedItems = () => {
    let selectedContainerIds = []
    let normalizedItems = []

    qaHolds.forEach((item) => {
      if (selectedContainerIds.indexOf(item.container.id) === -1) {
        normalizedItems = [
          ...normalizedItems,
          {
            id: item.container.id,
            container: item.container.name,
            qaProduct: qaHolds.filter(
              (element) => element.container.id === item.container.id,
            ).length,
            button: (
              <Button
                onClick={() => {
                  onSelect(item.container.id)
                }}
              >
                Select
              </Button>
            ),
            options: {
              selected: false,
            },
          },
        ]

        selectedContainerIds = [...selectedContainerIds, item.container.id]
      }
    })

    return normalizedItems
  }

  const onSelect = (containerId) =>
    redirect(SCAN_PALLET_UPC_PAGE.replace(':id', containerId))

  return (
    <div>
      <div className="page page--restock page--restock-dmg">
        <Header>
          <div className="title">{'Container List'}</div>
          <CloseButton />
        </Header>
      </div>
      <TableList
        rows={getNormalizedItems()}
        columns={columns}
        className="list--restock"
      />
    </div>
  )
}

ContainerListPage.propTypes = {
  fetchQaHoldList: PropTypes.func,

  qaHolds: PropTypes.array,
}

const mapStateToProps = (state) => ({
  qaHolds: state.qaHoldReducer.list,
})

const mapDispatchToProps = {
  fetchQaHoldList: fetchQaHoldListStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContainerListPage)
