import { types } from './actions'

const INIT = {
  item: null,
  items: [],
}

const taskReducer = (state = INIT, action) => {
  const { type, payload } = action

  switch (type) {
    case types.CREATE_TASK_START:
      return state
    case types.CREATE_TASK_SUCCESS:
      return {
        ...state,
        item: payload,
      }
    default:
      return state
  }
}

export default taskReducer
