import { fetchOneOrderLoadStatusSuccess, types } from './actions'
import * as api from 'api/orderLoadStatus'
import { takeLatest, call, put } from 'redux-saga/effects'

function* fetchOneOrderLoadStatus(action) {
  const { payload } = action

  try {
    const response = yield call(api.fetchOneBy, payload)
    yield put(fetchOneOrderLoadStatusSuccess(response.data.data))
  } catch (error) {}
}

function* updateOrderLoadStatus(action) {
  const { payload } = action

  try {
    yield call(api.update, payload)
  } catch (error) {}
}

export default function* orderLoadStatusSaga() {
  yield takeLatest(
    types.FETCH_ONE_ORDER_LOAD_STATUS_START,
    fetchOneOrderLoadStatus,
  )
  yield takeLatest(types.UPDATE_ORDER_LOAD_STATUS_START, updateOrderLoadStatus)
}
