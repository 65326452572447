import React from 'react'
import * as PropTypes from 'prop-types'

import './style.scss'

const Tile = (props) => {
  const {
    primaryCount,
    secondaryCount,
    body,
    footer,

    className,

    onClick,
  } = props

  return (
    <div onClick={onClick} className={`tile ${className || ''}`}>
      <div className="tile__header">
        {!!secondaryCount && (
          <div className="count--secondary">{secondaryCount}</div>
        )}
        {!!primaryCount && <div className="count">{primaryCount}</div>}
      </div>
      <div className="tile__body">{body}</div>
      <div className="tile__footer">{footer}</div>
    </div>
  )
}

Tile.propTypes = {
  onClick: PropTypes.func,

  className: PropTypes.string,

  body: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
}

export default Tile
