export const types = {
  CREATE_WAREHOUSE_PALLET: 'CREATE_WAREHOUSE_PALLET',
  CREATE_WAREHOUSE_PALLET_FOR_PUTAWAY: 'CREATE_WAREHOUSE_PALLET_FOR_PUTAWAY',
  CREATE_MANY_WAREHOUSE_PALLET: 'CREATE_MANY_WAREHOUSE_PALLET',
  UPDATE_WAREHOUSE_PALLET: 'UPDATE_WAREHOUSE_PALLET',
  UPDATE_WAREHOUSE_PALLET_SUCCESS: 'UPDATE_WAREHOUSE_PALLET_SUCCESS',
  DELETE_WAREHOUSE_PALLET: 'DELETE_WAREHOUSE_PALLET',
  DELETE_MANY_WAREHOUSE_PALLET: 'DELETE_MANY_WAREHOUSE_PALLET',
  UPDATE_WAREHOUSE_PALLET_SYNC: 'UPDATE_WAREHOUSE_PALLET_SYNC',
  FETCH_ONE_WAREHOUSE_PALLET_SUCCESS: 'FETCH_ONE_WAREHOUSE_PALLET_SUCCESS',
  FETCH_ONE_WAREHOUSE_PALLET_START: 'FETCH_ONE_WAREHOUSE_PALLET_START',
  PRINT_PALLET_LABEL: 'PRINT_PALLET_LABEL',
}

export const createWarehousePallet = (payload) => ({
  type: types.CREATE_WAREHOUSE_PALLET,
  payload,
})

export const createWarehousePalletForPutaway = (payload) => ({
  type: types.CREATE_WAREHOUSE_PALLET_FOR_PUTAWAY,
  payload,
})

export const createManyWarehousePallet = (payload) => ({
  type: types.CREATE_MANY_WAREHOUSE_PALLET,
  payload,
})

export const updateWarehousePallet = (payload) => ({
  type: types.UPDATE_WAREHOUSE_PALLET,
  payload,
})

export const deleteWarehousePallet = (payload) => ({
  type: types.DELETE_WAREHOUSE_PALLET,
  payload,
})

export const deleteManyWarehousePallet = (payload) => ({
  type: types.DELETE_MANY_WAREHOUSE_PALLET,
  payload,
})

export const updateWarehousePalletSync = (payload) => ({
  type: types.UPDATE_WAREHOUSE_PALLET_SYNC,
  payload,
})

export const fetchOneWarehousePalletSuccess = (payload) => ({
  type: types.FETCH_ONE_WAREHOUSE_PALLET_SUCCESS,
  payload,
})

export const fetchOneWarehousePalletStart = (payload) => ({
  type: types.FETCH_ONE_WAREHOUSE_PALLET_START,
  payload,
})

export const printPalletLabel = (payload) => ({
  type: types.PRINT_PALLET_LABEL,
  payload,
})
