import React from 'react'
import * as PropTypes from 'prop-types'
import TableListCell from 'components/common/TableList/TableListCell'

const TableListHeader = ({ columns }) => (
  <div className="list__header">
    {columns.map((column) => (
      <TableListCell item={column.label} key={column.id} />
    ))}
  </div>
)

TableListHeader.propTypes = {
  columns: PropTypes.array.isRequired,
}

export default TableListHeader
