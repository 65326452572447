import React, { useEffect } from 'react'
import './style.scss'
import * as PropTypes from 'prop-types'
import {
  PREPARE_UNLOAD_PROCESS_LIST_PAGE,
  TASK_LIST_PAGE,
  RESTOCK_TASK_LIST_PAGE,
  BULK_STORAGE_TASK_LIST,
  POWER_PICKING_LIST_PAGE,
  // SCAN_QUARANTINE_LOCATION_PAGE,
  // SCAN_DAMAGE_LOCATION_RESOLUTION_PAGE,
  // INVENTORY_TOOL_SCAN_LOCATION_PAGE,
  CONTAINER_PALLET_PUTAWAY_PAGE,
  INVENTORY_PAGE,
  RELOCATE_PRODUCT_PAGE,
  ORDERS_TO_LOAD_LIST_PAGE,
} from 'constants/routes'
import { isSmallScreen } from 'constants/responsiveDesign'
import { connect } from 'react-redux'
import { redirect } from 'helpers/index'
import Header from 'components/common/Header'
import Avatar from 'components/features/StaffCard/Avatar'
import ContainerUnloadIcon from 'assets/svg/unload_main.svg'
// import PickingIcon from 'assets/svg/picking_main.svg' // for Picking
import PowerPickIcon from 'assets/svg/power_pick_main.svg'
import PalletPutawayIcon from 'assets/svg/putaway_main.svg'
import LoadingIcon from 'assets/svg/loading_main.svg'
import RestockingIcon from 'assets/svg/restock_main.svg'
import RelocateProductIcon from 'assets/svg/relocate_main.svg'
import InventoryToolIcon from 'assets/svg/inventory_tool_main.svg'
import BulkStorageIcon from 'assets/svg/bulk_storage.svg'
import Tile from 'components/common/Tile'
import { resetModals, showModal } from 'store/modal/actions'
import { logout } from 'store/auth/actions'
import {
  fetchContainerUnloadCount,
  // fetchMyiPodTaskCount,  // for Picking
  fetchRestockCount,
  fetchBulkRestockFromCount,
  fetchBulkRestockToCount,
  fetchPowerPickingCount,
  fetchPutawayCount,
  fetchLoadingCount,
  fetchInventoryCountStart,
} from 'store/homeAnalytics/actions'
import { fetchScanToTruckEnabled } from 'store/order/actions'
import { printPalletLabel } from 'store/warehousePallet/actions'
import { buildVersionText } from 'helpers/version'
import { RESTOCK_TYPE_GROUPS } from 'constants/taskAssignment'

const MainPage = (props) => {
  const {
    user,
    appInfo,
    resetModals,
    showModal,
    logout,
    fetchRestockCount,
    fetchBulkRestockFromCount,
    fetchBulkRestockToCount,
    fetchContainerUnloadCount,
    fetchScanToTruckEnabled,
    scanToTruckEnabled,
    // fetchMyiPodTaskCount,  // for Picking
    fetchPowerPickingCount,
    fetchLoadingCount,
    fetchPutawayCount,
    fetchInventoryCountStart,
    homeAnalytics,
    // printPalletLabel,
  } = props

  const onRestocking = () => redirect(RESTOCK_TASK_LIST_PAGE)

  const onBulkStorage = () => redirect(BULK_STORAGE_TASK_LIST)

  // const onPicking = () => redirect(TASK_LIST_PAGE)  // for Picking

  const onPowerPicking = () => redirect(POWER_PICKING_LIST_PAGE)

  // const onInventoryTool = () => redirect(INVENTORY_TOOL_SCAN_LOCATION_PAGE)

  const onRelocateProduct = () => redirect(RELOCATE_PRODUCT_PAGE)

  const onLoading = () => redirect(ORDERS_TO_LOAD_LIST_PAGE)

  const onInventory = () => redirect(INVENTORY_PAGE)

  const onContainerList = () => {
    resetModals()
    redirect(PREPARE_UNLOAD_PROCESS_LIST_PAGE)
  }

  const onPutaways = () => {
    redirect(CONTAINER_PALLET_PUTAWAY_PAGE)
  }

  // const showModalChooseForResolution = () => {
  //   showModal({
  //     name: 'modalChoose',
  //     className: 'main-page__modal',
  //     chooses: resolutionButtons,
  //     onChoose: onClickResolutionModalButton,
  //     onDeny: resetModals,
  //   })
  // } //

  const tileInfo = [
    {
      id: 1,
      name: 'Restocking',
      onClick: onRestocking,
      icon: <RestockingIcon />,
      count: homeAnalytics.restockCount,
      toDisplay: true,
    },
    {
      id: 2,
      name: 'Picking', // note: simplfying name, but keeping all logic pointing to "Power Picking", bc alternatives are redundant (eg. Order Picking) and regular "Picking" is occupied by legacy code currently not in use. [April, 2022]
      onClick: onPowerPicking,
      icon: <PowerPickIcon />,
      count: homeAnalytics.powerPickingCount,
      toDisplay: true,
    },
    {
      id: 3,
      name: 'Container Unload',
      onClick: onContainerList,
      icon: <ContainerUnloadIcon />,
      count: homeAnalytics.containerUnloadCount,
      toDisplay: !isSmallScreen(),
    },
    {
      id: 4,
      name: 'Container Putaway',
      onClick: onPutaways,
      icon: <PalletPutawayIcon />,
      count: homeAnalytics.putawayCount,
      toDisplay: true,
    },
    {
      id: 5,
      name: 'Bulk Storage',
      onClick: onBulkStorage,
      icon: <BulkStorageIcon />,
      count: homeAnalytics.bulkRestockToCount,
      secondaryCount: homeAnalytics.bulkRestockFromCount,
      toDisplay: false,
    },
    {
      id: 6,
      name: 'Relocate Product',
      onClick: onRelocateProduct,
      icon: <RelocateProductIcon />,
      toDisplay: user.isRelocate,
    },
    {
      id: 7,
      name: 'Loading',
      onClick: onLoading,
      icon: <LoadingIcon />,
      count: homeAnalytics.loadingCount,
      toDisplay: scanToTruckEnabled,
    },
    {
      id: 8,
      name: 'Inventory',
      onClick: onInventory,
      icon: <InventoryToolIcon />,
      count: homeAnalytics.inventoryCount,
      toDisplay: true,
    },
    // {
    //   id: 6,
    //   name: 'Picking',
    //   onClick: onPicking,
    //   icon: <PickingIcon />,
    //   count: homeAnalytics.myiPodTaskCount,
    //   toDisplay: true,
    // },
    // {
    //   id: 7,
    //   name: 'Resolution',
    //   onClick: showModalChooseForResolution,
    //   icon: <ResolutionIcon />,
    //   count: 0,
    //   toDisplay: user.isManager(),
    // }, // comment out to hide tile until usable.
    // {
    //   id: 8,
    //   name: 'Inventory Tool',
    //   onClick: onInventoryTool,
    //   icon: <InventoryToolIcon />,
    //   count: 0,
    //   toDisplay: user.isManager(),
    // },
  ]

  const getAnalytics = () => {
    fetchRestockCount(RESTOCK_TYPE_GROUPS.ALL_RESTOCK_FILTER)
    fetchBulkRestockFromCount()
    fetchBulkRestockToCount()
    fetchPutawayCount()
    fetchInventoryCountStart()
    fetchContainerUnloadCount()
    // fetchMyiPodTaskCount({
    //   warehouse_staff_id: user.warehouseStaffId,
    // }) //for Picking
    fetchPowerPickingCount({
      warehouse_staff_id: user.warehouseStaffId,
    })
    fetchLoadingCount({
      warehouse_staff_id: user.warehouseStaffId,
    })
    fetchScanToTruckEnabled()
  }

  const onMount = () => {
    getAnalytics()
  }

  useEffect(onMount, [])

  let timeoutId
  const reloadAnalytics = () => {
    timeoutId = setTimeout(() => {
      getAnalytics()
      reloadAnalytics()
    }, 60000)
  }

  useEffect(() => {
    reloadAnalytics()
    return () => clearTimeout(timeoutId)
  }, [])

  const onLogout = () =>
    showModal({
      name: 'modalLogout',
      onConfirm: logout,
      onDeny: resetModals,
    })

  // const onDamageResolution = () => {
  //   resetModals()
  //   redirect(SCAN_DAMAGE_LOCATION_RESOLUTION_PAGE)
  // }

  // const onQACustom = () => {
  //   resetModals()
  //   redirect(SCAN_QUARANTINE_LOCATION_PAGE)
  // }

  // const onCustomerReturn = () => {}

  // const resolutionButtons = [
  //   {
  //     id: 1,
  //     label: 'Damage Resolution',
  //     action: onDamageResolution,
  //   },
  //   {
  //     id: 2,
  //     label: 'QA Custom',
  //     action: onQACustom,
  //   },
  //   {
  //     id: 3,
  //     label: 'Customer Return',
  //     action: onCustomerReturn,
  //   },
  // ]

  // const onClickResolutionModalButton = (id) =>
  //   resolutionButtons.find((item) => item.id === id).action()

  const version = buildVersionText(appInfo)
  return (
    <div className="main-page">
      <Header>
        <Avatar key={user.id} link={user.photo} />
        <div className="title">Home</div>
        <div className="text--close" onClick={onLogout}>
          Logout
        </div>
      </Header>
      <div className="main-page__body">
        {tileInfo
          .filter((item) => item.toDisplay)
          .map((item) => {
            return (
              <Tile
                key={item.id}
                className={`tile--main ${
                  !item.count && !item.secondaryCount
                    ? 'tile--main--no-count'
                    : ''
                }`}
                primaryCount={item.count}
                secondaryCount={item.secondaryCount ?? null}
                body={item.icon}
                footer={item.name}
                onClick={item.onClick}
              />
            )
          })}
        <div className="version-info">{version}</div>
      </div>
    </div>
  )
}

MainPage.propTypes = {
  appInfo: PropTypes.object,

  fetchAppInfo: PropTypes.func,
}

const mapStateToProps = (state) => ({
  appInfo: state.appInfo.newItem,
  homeAnalytics: state.homeAnalyticsReducer,
  scanToTruckEnabled: state.order.scanToTruckEnabled,
})

const mapDispatchToProps = {
  showModal: showModal,
  resetModals: resetModals,
  logout: logout,
  fetchRestockCount: fetchRestockCount,
  fetchBulkRestockFromCount,
  fetchScanToTruckEnabled,
  fetchBulkRestockToCount,
  fetchContainerUnloadCount: fetchContainerUnloadCount,
  // fetchMyiPodTaskCount: fetchMyiPodTaskCount, // for Picking
  fetchPowerPickingCount: fetchPowerPickingCount,
  fetchPutawayCount: fetchPutawayCount,
  printPalletLabel: printPalletLabel,
  fetchLoadingCount,
  fetchInventoryCountStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPage)
