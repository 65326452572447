import React from 'react'
import * as PropTypes from 'prop-types'

import './style.scss'

const Header = ({ children }) => <div className="header">{children}</div>

Header.propTypes = {
  children: PropTypes.any,
}

export default Header
