import React from 'react'
import * as PropTypes from 'prop-types'
import './style.scss'

const Button = (props) => {
  const {
    children,
    disabled = false,
    className = '',
    tabIndex = 1000,
    onClick = () => false,
    onDoubleClick = () => false,
  } = props

  return (
    <button
      disabled={disabled}
      className={`btn ${className ? className : ''}`.trim()}
      tabIndex={tabIndex}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  className: PropTypes.string,

  tabIndex: PropTypes.number,

  disabled: PropTypes.bool,

  onClick: PropTypes.func,

  onDoubleClick: PropTypes.func,
}

export default Button
