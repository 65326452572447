export const types = {
  FETCH_UNLOAD_PROCESS_LIST_START: 'FETCH_UNLOAD_PROCESS_LIST_START',
  FETCH_UNLOAD_PROCESS_LIST_SUCCESS: 'FETCH_UNLOAD_PROCESS_LIST_SUCCESS',
  FETCH_UNLOAD_PROCESS_LIST_FAIL: 'FETCH_UNLOAD_PROCESS_LIST_FAIL',
  FETCH_ONE_UNLOAD_PROCESS_START: 'FETCH_ONE_UNLOAD_PROCESS_START',
  FETCH_ONE_UNLOAD_PROCESS_SUCCESS: 'FETCH_ONE_UNLOAD_PROCESS_SUCCESS',
  FETCH_ONE_UNLOAD_PROCESS_FAIL: 'FETCH_ONE_UNLOAD_PROCESS_FAIL',
  UPDATE_UNLOAD_PROCESS: 'UPDATE_UNLOAD_PROCESS',
  FETCH_PUTAWAY_TASKS_START: 'FETCH_PUTAWAY_TASKS_START',
  FETCH_PUTAWAY_TASKS_SUCCESS: 'FETCH_PUTAWAY_TASKS_SUCCESS',
  BEGIN_UNLOAD_PUTAWAY_PROCESS: 'BEGIN_UNLOAD_PUTAWAY_PROCESS',
  START_UNLOAD_PUTAWAY_TASK: 'START_UNLOAD_PUTAWAY_TASK',
  START_UNLOAD_PUTAWAY_TASK_SUCCESS: 'START_UNLOAD_PUTAWAY_TASK_SUCCESS',
  START_PUTAWAY_PALLET_RELOCATION_TASK: 'START_PUTAWAY_PALLET_RELOCATION_TASK',
  FETCH_UNLOAD_PUTAWAY_TASK: 'FETCH_UNLOAD_PUTAWAY_TASK',
  FETCH_UNLOAD_PUTAWAY_TASK_SUCCESS: 'FETCH_UNLOAD_PUTAWAY_TASK_SUCCESS',
  PROCESS_UNLOAD_PUTAWAY: 'PROCESS_UNLOAD_PUTAWAY',
  UPDATE_UNLOAD_PROCESS_SUCCESS: 'UPDATE_UNLOAD_PROCESS_SUCCESS',
  DISMISS_START_UNLOAD_ERRORS: 'DISMISS_START_UNLOAD_ERRORS',
  RESET_CONTAINER_PRODUCT: 'RESET_CONTAINER_PRODUCT',
}

export const fetchUnloadProcessListStart = (payload) => ({
  type: types.FETCH_UNLOAD_PROCESS_LIST_START,
  payload,
})

export const fetchUnloadProcessListSuccess = (payload) => ({
  type: types.FETCH_UNLOAD_PROCESS_LIST_SUCCESS,
  payload,
})

export const fetchUnloadProcessListFail = (payload) => ({
  type: types.FETCH_UNLOAD_PROCESS_LIST_FAIL,
  payload,
})

export const fetchOneUnloadProcessStart = (payload) => ({
  type: types.FETCH_ONE_UNLOAD_PROCESS_START,
  payload,
})

export const fetchOneUnloadProcessSuccess = (payload) => ({
  type: types.FETCH_ONE_UNLOAD_PROCESS_SUCCESS,
  payload,
})

export const fetchOneUnloadProcessFail = (payload) => ({
  type: types.FETCH_ONE_UNLOAD_PROCESS_FAIL,
  payload,
})

export const updateUnloadProcess = (payload) => ({
  type: types.UPDATE_UNLOAD_PROCESS,
  payload,
})

export const updateUnloadProcessSuccess = (payload) => ({
  type: types.UPDATE_UNLOAD_PROCESS_SUCCESS,
  payload,
})

export const dismissStartUnloadErrors = (payload) => ({
  type: types.DISMISS_START_UNLOAD_ERRORS,
  payload,
})

export const fetchPutawayTasksStart = (payload) => ({
  type: types.FETCH_PUTAWAY_TASKS_START,
  payload,
})

export const fetchPutawayTasksSuccess = (payload) => ({
  type: types.FETCH_PUTAWAY_TASKS_SUCCESS,
  payload,
})

export const beginUnloadPutawayProcess = (payload) => ({
  type: types.BEGIN_UNLOAD_PUTAWAY_PROCESS,
  payload,
})

export const startUnloadPutawayTask = (payload) => ({
  type: types.START_UNLOAD_PUTAWAY_TASK,
  payload,
})

export const startUnloadPutawayTaskSuccess = (payload) => ({
  type: types.START_UNLOAD_PUTAWAY_TASK_SUCCESS,
  payload,
})

export const startPutawayPalletRelocationTask = (payload) => ({
  type: types.START_PUTAWAY_PALLET_RELOCATION_TASK,
  payload,
})

export const processUnloadPutaway = (payload) => ({
  type: types.PROCESS_UNLOAD_PUTAWAY,
  payload,
})

export const fetchUnloadPutawayTask = (payload) => ({
  type: types.FETCH_UNLOAD_PUTAWAY_TASK,
  payload,
})

export const fetchUnloadPutawayTaskSuccess = (payload) => ({
  type: types.FETCH_UNLOAD_PUTAWAY_TASK_SUCCESS,
  payload,
})

export const resetContainerProduct = (payload) => ({
  type: types.RESET_CONTAINER_PRODUCT,
  payload,
})
