import React, { useState } from 'react'
import * as PropTypes from 'prop-types'

const Tooltip = ({ button, children }) => {
  const [isShow, setIsShow] = useState(false)

  const toggleContent = () => setIsShow(!isShow)

  return (
    <div className="tooltip">
      <span className="tooltip__button" onClick={toggleContent}>
        {button}
      </span>
      {isShow && <div className="tooltip__content">{children}</div>}
    </div>
  )
}

Tooltip.propTypes = {
  button: PropTypes.any.isRequired,
}

export default Tooltip
