export const types = {
  FETCH_DAMAGE_PRODUCTS_START: 'FETCH_DAMAGE_PRODUCTS_START',
  FETCH_DAMAGE_PRODUCTS_SUCCESS: 'FETCH_DAMAGE_PRODUCTS_SUCCESS',
  FETCH_DAMAGE_PRODUCTS_FOR_INVENTORY_START:
    'FETCH_DAMAGE_PRODUCTS_FOR_INVENTORY_START',
  FETCH_DAMAGE_PRODUCTS_FOR_INVENTORY_SUCCESS:
    'FETCH_DAMAGE_PRODUCTS_FOR_INVENTORY_SUCCESS',
  UPDATE_DAMAGE_PRODUCTS_START: 'UPDATE_DAMAGE_PRODUCTS_START',
  DELETE_DAMAGE_PRODUCTS_START: 'DELETE_DAMAGE_PRODUCTS_START',
  CREATE_DAMAGE_PRODUCTS_START: 'CREATE_DAMAGE_PRODUCTS_START',
}

export const fetchDamageProductsSyncStart = (payload) => ({
  type: types.FETCH_DAMAGE_PRODUCTS_START,
  payload,
})

export const fetchDamageProductsSuccess = (payload) => ({
  type: types.FETCH_DAMAGE_PRODUCTS_SUCCESS,
  payload,
})

export const fetchDamageProductsForInventoryStart = (payload) => ({
  type: types.FETCH_DAMAGE_PRODUCTS_FOR_INVENTORY_START,
  payload,
})

export const fetchDamageProductsForInventorySuccess = (payload) => ({
  type: types.FETCH_DAMAGE_PRODUCTS_FOR_INVENTORY_SUCCESS,
  payload,
})

export const updateDamageProductsStart = (payload) => ({
  type: types.UPDATE_DAMAGE_PRODUCTS_START,
  payload,
})

export const deleteDamageProductsStart = (payload) => ({
  type: types.DELETE_DAMAGE_PRODUCTS_START,
  payload,
})

export const createDamageProductsStart = (payload) => ({
  type: types.CREATE_DAMAGE_PRODUCTS_START,
  payload,
})
