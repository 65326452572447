import { types } from './actions'

const INIT = {
  item: {},
  list: [],
  putawayTasks: [],
  putawayTask: null,
  lastRefresh: new Date(),
  isFetched: false,
  startUnloadErrors: [],
}

const unloadProcessReducer = (state = INIT, action) => {
  const { type, payload } = action

  switch (type) {
    case types.FETCH_UNLOAD_PROCESS_LIST_START:
      return {
        ...state,
        list: [],
        isFetched: false,
      }
    case types.FETCH_UNLOAD_PROCESS_LIST_SUCCESS:
      return {
        ...state,
        list: payload,
        isFetched: true,
      }
    case types.FETCH_UNLOAD_PROCESS_LIST_FAIL:
      return INIT
    case types.FETCH_ONE_UNLOAD_PROCESS_START:
      return { ...state }
    case types.FETCH_ONE_UNLOAD_PROCESS_SUCCESS:
      return {
        ...state,
        item: payload,
      }
    case types.FETCH_ONE_UNLOAD_PROCESS_FAIL:
      return {
        ...state,
        item: {},
      }
    case types.FETCH_PUTAWAY_TASKS_START:
      return {
        ...state,
        putawayTasks: [],
      }
    case types.FETCH_PUTAWAY_TASKS_SUCCESS:
      return {
        ...state,
        putawayTasks: payload,
        lastRefresh: new Date(),
      }
    case types.FETCH_CONTAINER_PUTAWAY_PRODUCTS_SUCCESS:
      return {
        ...state,
        putawayProducts: payload,
      }
    case types.START_UNLOAD_PUTAWAY_TASK_SUCCESS:
    case types.FETCH_UNLOAD_PUTAWAY_TASK_SUCCESS:
      return { ...state, putawayTask: payload }
    case types.UPDATE_UNLOAD_PROCESS_SUCCESS:
      return {
        ...state,
        startUnloadErrors: payload,
      }
    case types.DISMISS_START_UNLOAD_ERRORS:
      return {
        ...state,
        startUnloadErrors: [],
      }
    default:
      return state
  }
}

export default unloadProcessReducer
