import axios from 'axios'
import history from '../history'
import moment from 'moment'
import * as qs from 'querystringify'
import { PHOTO_LINK_START } from 'constants/common'
import { LOGIN_PAGE } from 'constants/routes'
import { store } from '../index'
import { reset as restUser } from 'store/auth/actions'
import {
  setIsOffline,
  setIgnoreCount,
  setSystemError,
} from 'store/appInfo/actions'
import {
  NO_CONNECTION_MAX_RETRIES,
  NO_CONNECTION_RETRY_WAIT,
  NO_CONNECTION_MAX_IGNORES,
} from 'constants/apiconfig'

const getToken = () => {
  const state = localStorage.getItem('state')
  const serializedState = JSON.parse(state)

  if (serializedState.auth) {
    return serializedState.auth.user.token
  } else {
    return ''
  }
}

export const requestWithRetry = (axiosConfig, options = {}, retryCount = 0) => {
  return request(axiosConfig, { retryNoResponse: true, ...options }, retryCount)
}

export const requestWithIgnore = (
  axiosConfig,
  options = {},
  retryCount = 0,
) => {
  return request(
    axiosConfig,
    { ignoreNoResponse: true, ...options },
    retryCount,
  )
}

const request = (axiosConfig, options = {}, retryCount = 0) => {
  axiosConfig.headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Referrer-Policy': 'unsafe-url',
    Authorization: `Bearer ${getToken()}`,
    ...axiosConfig.headers,
  }

  const handleNoResponse = (error) => {
    const maxIgnores = options.maxIgnores || NO_CONNECTION_MAX_IGNORES
    const maxRetries = options.maxRetries || NO_CONNECTION_MAX_RETRIES
    const retryWait = options.retryWait || NO_CONNECTION_RETRY_WAIT

    if (options.retryNoResponse && retryCount < maxRetries) {
      setTimeout(() => {
        console.log(
          `${axiosConfig.url} no response. retry ${
            retryCount + 1
          } of ${maxRetries}`,
        )
        return request(axiosConfig, options, retryCount + 1)
      }, retryWait)
    } else if (options.ignoreNoResponse) {
      let ignoreCount = store.getState().appInfo.ignoreCount

      if (ignoreCount < maxIgnores) {
        ++ignoreCount
        console.log(
          `${axiosConfig.url} no response. ignore count: ${ignoreCount} of ${maxIgnores}`,
        )

        store.dispatch(setIgnoreCount(ignoreCount))
      } else {
        store.dispatch(setIsOffline(true))
      }
    } else {
      console.log(error)
      store.dispatch(
        setSystemError({
          message: error.toString,
          url: axiosConfig.url,
        }),
      )
    }

    return null
  }

  const handleClientError = () => {
    store.dispatch(restUser())
    redirect(LOGIN_PAGE)
  }

  return axios(axiosConfig)
    .then((response) => {
      store.dispatch(setIgnoreCount(0))
      store.dispatch(setIsOffline(false))
      return response
    })
    .catch((error) => {
      switch (true) {
        case !error.response:
          return handleNoResponse(error, options)
        case isClientError(error.response.status):
          return handleClientError()
        default:
          console.log('response is here', error.response)
          store.dispatch(
            setSystemError({ url: axiosConfig.url, ...error.response.data.errors }),
          )
      }
    })
}

export default request

export const saveState = (state) => {
  try {
    localStorage.setItem('state', JSON.stringify(state))
  } catch (error) {
    console.error("Can't save state to localStorage!")
  }
}

// Selector of reducers, which we want to store in LocalStorage
export const stateToStorageSelector = (state) => ({
  auth: state.auth,
  deviceOrientation: state.deviceOrientation,
  version: state.version,
})

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')

    if (serializedState === null) {
      return {}
    }

    const state = JSON.parse(serializedState)

    saveState(state)

    return state
  } catch (error) {
    return {}
  }
}

export const redirect = (route) => history.push(route)

export const getPhotoLink = (photoName) => `${PHOTO_LINK_START}${photoName}`

export const date = (value) => new Date(moment(value))
export const dateToFormat = (value, format) => moment(value).format(format)
export const timeDifference = (startDatetime, endDatetime, timeDescription) =>
  moment(endDatetime).diff(startDatetime, timeDescription)

export const isEmptyObject = (object) =>
  Object.entries(object).length === 0 && object.constructor === Object
export const isEqualObjects = (obj1, obj2) =>
  JSON.stringify(obj1) === JSON.stringify(obj2)
export const isArrayContainsObject = (object, array) => {
  for (let i = 0; i < array.length; i++) {
    if (isEqualObjects(array[i], object)) {
      return true
    }
  }

  return false
}

export const getQueryParams = () => qs.parse(window.location.search)
export const getQueryParam = (param) => window.location.search[param]
export const getPathParams = () =>
  window.location.pathname.split('/').filter((item) => item.length > 0)
export const makeQueryString = (params) => qs.stringify(params, true)
export const appendQueryString = (params) =>
  makeQueryString({ ...params, ...getQueryParams() })

export const getMin = (...nums) => Math.min(...nums)

export const oneOf = (...params) => params.find((item) => !!item === true)

export const route = (urlStr, params = {}) =>
  Object.keys(params).length
    ? Object.keys(params).reduce(
        (url, key) => url.replace(`:${key}`, params[key]),
        urlStr,
      )
    : urlStr

export const isClientError = (status) => status >= 400 && status < 500
