import React from 'react'
import * as PropTypes from 'prop-types'
import Avatar from 'components/features/StaffCard/Avatar'
import { getPhotoLink } from 'helpers/index'
const CUSTOM_PHOTO_API = 'https://api.dicebear.com/7.x/initials/svg?seed='

const AssignToItem = ({ item, onRemove }) => {
  const link = item.staffLogin.photo_url
    ? getPhotoLink(item.staffLogin.photo_url)
    : `${CUSTOM_PHOTO_API}${item.staffLogin.name}`

  const onClick = () => onRemove(item)

  return (
    <div className="list__item" key={item.staffLogin.staff_id}>
      <Avatar link={link} />
      <div className="list__item--avatar-name">
        {item.staffLogin.name.split(' ')[0]}
      </div>
      <span className="list__item--close" onClick={onClick} />
    </div>
  )
}

AssignToItem.propTypes = {
  item: PropTypes.object,

  onRemove: PropTypes.func,
}

export default AssignToItem
