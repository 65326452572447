import React, { useEffect, useState } from 'react'
import '../style.scss'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as query from 'querystringify'
import { fetchOneLocationByStart } from 'store/location/actions'
import { closeModal, showModal } from 'store/modal/actions'
import InventoryToolScanCase from 'components/features/InventoryTool/InventoryToolCasePage/InventoryToolScanCase'
import { debounce } from 'throttle-debounce'

const InventoryToolCasePage = (props) => {
  const {
    match,
    user,

    showModal,
    closeModal,
    fetchLocations,
  } = props

  const locationId = match.params.id

  const [isLocationLoaded, setIsLocationLoaded] = useState(false)

  const getLocationParamsForFetch = () =>
    query.stringify(
      {
        id: locationId,
      },
      true,
    )

  const onMount = () => {
    showModal({
      name: 'modalPending',
    })

    setIsLocationLoaded(false)

    const afterAction = () => closeModal('modalPending')

    const onSuccess = () => setIsLocationLoaded(true)

    fetchLocations({
      afterAction,
      request: getLocationParamsForFetch(),
      onSuccess,
    })
  }

  useEffect(onMount, [])

  if (!isLocationLoaded) {
    return <></>
  }

  return <InventoryToolScanCase user={user} />
}

InventoryToolCasePage.propTypes = {
  fetchLocations: PropTypes.func,
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
}

const mapDispatchToProps = {
  fetchLocations: fetchOneLocationByStart,
  showModal: showModal,
  closeModal: closeModal,
}

export default connect(null, mapDispatchToProps)(InventoryToolCasePage)
