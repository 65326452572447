import { connect } from 'react-redux'
import TaskPage from '../index'

const mapStateToProps = (state) => ({
  page: {
    title: 'Picking Task',
  },
  taskAssignment: state.taskAssignment.item,
})

export default connect(mapStateToProps, null)(TaskPage)
