import { fork } from 'redux-saga/effects'
import unloadProcessSaga from 'store/unloadProcess/saga'
import productSaga from 'store/product/saga'
import measurementSaga from 'store/measurement/saga'
import authSaga from 'store/auth/saga'
import staffSaga from 'store/staff/saga'
import taskAssignmentSaga from 'store/taskAssignment/saga'
import orderSaga from 'store/order/saga'
import locationSaga from './location/saga'
import warehouseCaseSaga from 'store/warehouseCase/saga'
import orderProductPickLocationSaga from 'store/orderProductPickLocation/saga'
import restockProcessSaga from 'store/restockProcess/saga'
import warehousePalletSaga from 'store/warehousePallet/saga'
import taskSaga from 'store/task/saga'
import containerPalletSaga from 'store/containerPallets/saga'
import qaHoldSaga from 'store/qaHold/sags'
import appInfoSaga from 'store/appInfo/saga'
import homeAnalyticsSaga from 'store/homeAnalytics/saga'
import damageProductSaga from 'store/damageProduct/saga'
import orderPalletSaga from 'store/orderPallet/saga'
import orderPickPalletSaga from 'store/orderPickPallet/saga'
import shippingLaneSaga from 'store/shippingLane/saga'
import pickupLaneSaga from 'store/pickupLane/saga'
import orderPickStatusSaga from 'store/orderPickStatus/saga'
import orderLoadStatusSaga from 'store/orderLoadStatus/saga'
import printSaga from 'store/print/saga'
import emailerSaga from 'store/emailer/saga'
import relocateSaga from 'store/relocate/saga'
import orderPalletLaneLocationSaga from 'store/orderPalletLaneLocation/saga'
import inventorySaga from 'store/inventory/saga'

export default function* rootSaga() {
  yield fork(unloadProcessSaga)
  yield fork(productSaga)
  yield fork(measurementSaga)
  yield fork(authSaga)
  yield fork(staffSaga)
  yield fork(taskAssignmentSaga)
  yield fork(orderSaga)
  yield fork(locationSaga)
  yield fork(warehouseCaseSaga)
  yield fork(orderProductPickLocationSaga)
  yield fork(restockProcessSaga)
  yield fork(warehousePalletSaga)
  yield fork(taskSaga)
  yield fork(containerPalletSaga)
  yield fork(qaHoldSaga)
  yield fork(appInfoSaga)
  yield fork(homeAnalyticsSaga)
  yield fork(damageProductSaga)
  yield fork(orderPalletSaga)
  yield fork(orderPickPalletSaga)
  yield fork(shippingLaneSaga)
  yield fork(pickupLaneSaga)
  yield fork(orderPickStatusSaga)
  yield fork(orderLoadStatusSaga)
  yield fork(printSaga)
  yield fork(emailerSaga)
  yield fork(relocateSaga)
  yield fork(orderPalletLaneLocationSaga)
  yield fork(inventorySaga)
}
