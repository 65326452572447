import React, { forwardRef } from 'react'
import Card from '@material-ui/core/Card'
import * as PropTypes from 'prop-types'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'
import './style.scss'

const SelectionList = forwardRef((props, ref) => {
  const { items, onSelectItem, scrollTo } = props

  return (
    <div className="selection--list">
      {items.map((item) => {
        const onClickSelect = () => onSelectItem(item)

        const itemProps = scrollTo == item ? { ref: ref } : {}
        return (
          <Card
            key={item}
            className="selection--list__card"
            onDoubleClick={(e) => {
              onClickSelect()
            }}
            {...itemProps}
          >
            <CardActionArea>
              <CardContent className="selection--list__card-content">
                {item}
              </CardContent>
            </CardActionArea>
          </Card>
        )
      })}
    </div>
  )
})

SelectionList.propTypes = {
  items: PropTypes.array.isRequired,
  onSelectItem: PropTypes.func.isRequired,
}

export default SelectionList
