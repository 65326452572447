import React from 'react'
import 'components/common/Header/style.scss'

export const GreenDot = () => (
  <img
    src={
      'https://d29j2csl3b3dix.cloudfront.net/_spark/_images/dot_cropped_green.png'
    }
    className="icon__img stock_dot"
    alt="green dot"
  />
)

export const YellowDot = () => (
  <img
    src={
      'https://d29j2csl3b3dix.cloudfront.net/_spark/_images/dot_cropped_yellow.png'
    }
    className="icon__img stock_dot"
    alt="yellow dot"
  />
)

export const YellowHoldDot = () => (
  <img
    src={
      'https://d29j2csl3b3dix.cloudfront.net/_spark/_images/dot_cropped_yellow_hold.png'
    }
    className="icon__img stock_dot"
    alt="yellow hold dot"
  />
)

export const RedDot = () => (
  <img
    src={
      'https://d29j2csl3b3dix.cloudfront.net/_spark/_images/dot_cropped_red.png'
    }
    className="icon__img stock_dot"
    alt="red dot"
  />
)

export const BlackDot = () => (
  <img
    src={
      'https://d29j2csl3b3dix.cloudfront.net/_spark/_images/dot_cropped_black.png'
    }
    className="icon__img stock_dot"
    alt="black dot"
  />
)
