import { types } from './actions'

const INIT = {
  item: null,
}

export default function orderLoadStatusReducer(state = INIT, action) {
  const { type, payload } = action

  switch (type) {
    case types.FETCH_ONE_ORDER_LOAD_STATUS_SUCCESS:
      return {
        ...state,
        item: payload,
      }
    default:
      return state
  }
}
