export const types = {
  productInfo: {
    FETCH_PRODUCT_INFO_START: 'FETCH_PRODUCT_INFO_START',
    FETCH_PRODUCT_INFO_SUCCESS: 'FETCH_PRODUCT_INFO_SUCCESS',
    FETCH_PRODUCT_INFO_FAIL: 'FETCH_PRODUCT_INFO_FAIL',
  },
  productCase: {
    FETCH_PRODUCT_CASE_SUCCESS: 'FETCH_PRODUCT_CASE_SUCCESS',
    FETCH_PRODUCT_CASE_START: 'FETCH_PRODUCT_CASE_START',
    CREATE_PRODUCT_CASE: 'CREATE_PRODUCT_CASE',
    UPDATE_PRODUCT_CASE: 'UPDATE_PRODUCT_CASE',
    ADD_DAMAGED_CASES: 'ADD_DAMAGED_CASES',
    ADD_LOADED_CASES: 'ADD_LOADED_CASES',
  },
  containerProduct: {
    FETCH_CONTAINER_PRODUCT_SUCCESS: 'FETCH_CONTAINER_PRODUCT_SUCCESS',
    CREATE_CONTAINER_PRODUCT: 'CREATE_CONTAINER_PRODUCT',
    UPDATE_CONTAINER_PRODUCT: 'UPDATE_CONTAINER_PRODUCT',
  },
}

export const fetchProductInfoStart = (payload) => ({
  type: types.productInfo.FETCH_PRODUCT_INFO_START,
  payload,
})

export const fetchProductInfoSuccess = (payload) => ({
  type: types.productInfo.FETCH_PRODUCT_INFO_SUCCESS,
  payload,
})

export const fetchProductInfoFail = (payload) => ({
  type: types.productInfo.FETCH_PRODUCT_INFO_FAIL,
  payload,
})

export const fetchProductCaseSuccess = (payload) => ({
  type: types.productCase.FETCH_PRODUCT_CASE_SUCCESS,
  payload,
})

export const fetchProductCaseStart = (payload) => ({
  type: types.productCase.FETCH_PRODUCT_CASE_START,
  payload,
})

export const createProductCase = (payload) => ({
  type: types.productCase.CREATE_PRODUCT_CASE,
  payload,
})

export const updateProductCase = (payload) => ({
  type: types.productCase.UPDATE_PRODUCT_CASE,
  payload,
})

export const createDamageCases = (payload) => ({
  type: types.productCase.ADD_DAMAGED_CASES,
  payload,
})

export const addLoadedCases = (payload) => ({
  type: types.productCase.ADD_LOADED_CASES,
  payload,
})

export const fetchContainerProductSuccess = (payload) => ({
  type: types.containerProduct.FETCH_CONTAINER_PRODUCT_SUCCESS,
  payload,
})

export const createContainerProduct = (payload) => ({
  type: types.containerProduct.CREATE_CONTAINER_PRODUCT,
  payload,
})

export const updateContainerProduct = (payload) => ({
  type: types.containerProduct.UPDATE_CONTAINER_PRODUCT,
  payload,
})
