import { types } from './actions'

const INIT = {
  isLoading: false,
}

export default function containerPalletReducer(state = INIT, action) {
  const { type } = action

  switch (type) {
    case types.RECOUNT_CONTAINER_PALLETS:
      return {
        ...state,
        isLoading: true,
      }
    default:
      return state
  }
}
