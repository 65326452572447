import { remove, fetchMany, update } from 'api/qaHold'
import { takeEvery, call, put } from 'redux-saga/effects'
import {
  fetchQaHoldListFail,
  fetchQaHoldListSuccess,
} from 'store/qaHold/actions'
import { types } from 'store/qaHold/actions'

function* fetchQaHoldList(action) {
  const { payload } = action

  try {
    const response = yield call(fetchMany, payload)
    yield put(fetchQaHoldListSuccess(response.data.data.items))
  } catch (error) {
    yield put(fetchQaHoldListFail())
  }
}

function* deleteQaHold(action) {
  const { payload } = action

  try {
    yield call(remove, payload)
  } catch (error) {}
}

function* updateQaHold(action) {
  const { payload } = action

  try {
    yield call(update, payload)
  } catch (error) {}
}

export default function* qaHoldSaga() {
  yield takeEvery(types.FETCH_QA_HOLD_LIST_START, fetchQaHoldList)
  yield takeEvery(types.DELETE_QA_HOLD, deleteQaHold)
  yield takeEvery(types.UPDATE_QA_HOLD, updateQaHold)
}
