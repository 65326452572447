import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  fetchOneTaskAssignmentStart,
  resetAssignments,
  updateTaskAssignment,
} from 'store/taskAssignment/actions'
import { closeModal, showModal, resetModals } from 'store/modal/actions'
import { TASK_STATUSES } from 'constants/taskAssignment'
import { ORDER_PICK_STATUSES } from 'constants/order'
import { isEmptyObject, redirect } from 'helpers/index'
import {
  POWER_PICKING_LIST_PAGE,
  POWER_PICKING_PULLSHEET_PAGE,
} from 'constants/routes'
import {
  createOrderPallet,
  updateOrderPalletStart,
} from 'store/orderPallet/actions'
import { MAX_PALLETS_PER_TRUCK } from 'constants/order'

const PowerPickingPage = (props) => {
  const {
    taskAssignment,
    user,
    match,
    isTaskAssignmentFetched,

    fetchOneTaskAssignmentStart,
    updateTaskAssignment,
    createOrderPallet,
    showModal,
    closeModal,
    resetModals,
  } = props

  const taskAssignmentId = match.params.id

  const onMount = () => {
    fetchAssignment()
  }

  const fetchAssignment = () => {
    showModal({ name: 'modalPending' })

    fetchOneTaskAssignmentStart({
      id: taskAssignmentId,
      warehouse_staff_id: user.warehouse_staff_id,
    })
  }

  const onChangeTaskAssignment = () => {
    if (!isEmptyObject(taskAssignment) || isTaskAssignmentFetched) {
      closeModal('modalPending')
    }

    if (isEmptyObject(taskAssignment)) {
      return
    }

    if (taskAssignment.id !== parseInt(taskAssignmentId)) {
      fetchAssignment()
      return
    }

    const pickLocations = taskAssignment.entity.pickLocations
    const pickingAssignments = taskAssignment.entity.picking_assignments

    const nextPickingAssignment = pickingAssignments.find(
      (item) =>
        !item.end_date && item.warehouse_staff_id === user.warehouseStaffId,
    )
    const notPickedLocation = pickLocations.find(
      (item) => item.location_pick_quantity !== item.picked_quantity,
    )

    switch (true) {
      case taskAssignment.status === TASK_STATUSES.CREATED_TYPE:
        return startPicking()
      case !!nextPickingAssignment:
        return redirect(
          POWER_PICKING_PULLSHEET_PAGE.replace(
            ':id',
            taskAssignment.process.id,
          ),
        )
      case (taskAssignment.status === TASK_STATUSES.STARTED_TYPE ||
        taskAssignment.status === TASK_STATUSES.STARTED_TYPE) &&
        !nextPickingAssignment &&
        !notPickedLocation:
        return finishPicking()
      case taskAssignment.status === TASK_STATUSES.STARTED_TYPE:
        return redirect(POWER_PICKING_LIST_PAGE)
      default:
        return
    }
  }

  const startPicking = () => {
    resetModals()
    showModal({
      name: 'modalPending',
    })

    const orderPallets = taskAssignment.entity.orderPallets
    const nextPickPalletId =
      orderPallets.length > 0
        ? orderPallets.reduce((prev, current) =>
            prev.pick_pallet_id > current.pick_pallet_id ? prev : current,
          ).pick_pallet_id + 1
        : 1

    if (nextPickPalletId > MAX_PALLETS_PER_TRUCK) {
      console.error(
        `Error: Not able to pick order, pick pallet #${nextPickPalletId}`,
      )
      return
    }

    updateTaskAssignment({
      id: taskAssignmentId,
      data: {
        id: taskAssignmentId,
        status: TASK_STATUSES.STARTED_TYPE,
      },
    })

    createOrderPallet({
      data: {
        order_id: taskAssignment.entity.order.orders_id,
        status_id: ORDER_PICK_STATUSES.STATUS_PICKING_PALLET,
        pick_pallet_id: nextPickPalletId,
        pallet_weight: null,
        warehouse_location_id: null,
      },
    })
  }

  const finishPicking = () => {
    updateTaskAssignment({
      id: taskAssignmentId,
      data: {
        id: taskAssignmentId,
        status: TASK_STATUSES.FINISHED_TYPE,
      },
    })

    redirect(POWER_PICKING_LIST_PAGE)
  }

  useEffect(onMount, [])
  useEffect(onChangeTaskAssignment, [taskAssignment])

  if (
    isEmptyObject(taskAssignment) ||
    taskAssignment.id !== parseInt(taskAssignmentId)
  ) {
    return ''
  }

  const getTaskContent = () => {
    if (isEmptyObject(taskAssignment)) {
      return <></>
    }

    return (
      <div className="tasks-page tasks-page--picking">
        <p>Getting Tasks...</p>
      </div>
    )
  }

  return <>{getTaskContent()}</>
}

PowerPickingPage.propTypes = {
  taskAssignment: PropTypes.object,
  resetModals: PropTypes.func,
  updateTaskAssignment: PropTypes.func,
  showModal: PropTypes.func,
  fetchTaskAssignmentList: PropTypes.func,
  createOrderPallet: PropTypes.func,
}

const mapStateToProps = (state) => ({
  isTaskAssignmentFetched: state.taskAssignment.isFetched,
  taskAssignment: state.taskAssignment.item,
  lastRefresh: state.taskAssignment.lastRefresh,
  taskAssignmentList: state.taskAssignment.items,
})

const mapDispatchToProps = {
  updateTaskAssignment,
  resetAssignments,
  fetchOneTaskAssignmentStart,
  createOrderPallet,
  updateOrderPalletStart,
  resetModals,
  showModal,
  closeModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(PowerPickingPage)
