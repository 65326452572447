export const types = {
  START_RELOCATE: 'START_RELOCATE',
  FETCH_RELOCATE_TASK: 'FETCH_RELOCATE_TASK',
  FETCH_RELOCATE_TASK_SUCCESS: 'FETCH_RELOCATE_TASK_SUCCESS',
  VALIDATE_TO_UPC: 'VALIDATE_TO_UPC',
  VALIDATE_FROM_BARCODE: 'VALIDATE_FROM_BARCODE',
  PROCESS_RELOCATE: 'PROCESS_RELOCATE',
}

export const startRelocate = (payload) => ({
  type: types.START_RELOCATE,
  payload,
})

export const fetchRelocateTask = (payload) => ({
  type: types.FETCH_RELOCATE_TASK,
  payload,
})

export const fetchRelocateTaskSuccess = (payload) => ({
  type: types.FETCH_RELOCATE_TASK_SUCCESS,
  payload,
})

export const validateToUPC = (payload) => ({
  type: types.VALIDATE_TO_UPC,
  payload,
})

export const validateFromBarcode = (payload) => ({
  type: types.VALIDATE_FROM_BARCODE,
  payload,
})

export const processRelocate = (payload) => ({
  type: types.PROCESS_RELOCATE,
  payload,
})
