import { connect } from 'react-redux'
import ModalPending from './component'

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalPending',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalPending',
  ),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ModalPending)
