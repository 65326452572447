import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import './style.scss'
import PalletProductListContent from './PalletListContent'
import {
  fetchOneOrderPalletStart,
  updateOrderPalletStart,
} from 'store/orderPallet/actions'
import PalletWeightScreen from './PalletWeightScreen'
import { redirect, route } from 'helpers/index'
import {
  POWER_PICKING_MOVE_TO_LANE_PAGE,
  POWER_PICKING_PULLSHEET_PAGE,
  SCAN_LANE_PAGE,
} from 'constants/routes'
import { showPendingModal } from 'store/modal/actions'
import { openPrintDialog } from 'store/print/actions'
import Print from 'components/common/Print'
import { ORDER_PALLET_LABEL } from 'constants/print'
import { ORDER_STATUSES } from 'constants/order'
import { DEFAULT_ORDER_PALLET_LABEL_PRINT_QUANTITY } from 'constants/order'
import { fetchScanLaneEnabled } from 'store/order/actions'

// TODO - Remove if still commented out after 2023-10-20
// import {
//   QUANTITY_LABELS_TO_PRINT_ON_WRAP_PICKUP_ORDER,
//   DEFAULT_ORDER_PALLET_LABEL_PRINT_QUANTITY,
// } from 'constants/order'

const screens = {
  PRODUCT_LIST: 'PRODUCT_LIST',
  PALLET_WEIGHT: 'PALLET_WEIGHT',
}

const PowerPickingWrapPalletListPage = (props) => {
  const {
    fetchOneOrderPalletStart,
    updateOrderPalletStart,
    showPendingModal,
    openPrintDialog,
    fetchScanLaneEnabled,

    scanLaneEnabled,
    item,
    user,
    powerPickProcessId,
    match,
  } = props

  const [currentScreen, setCurrentScreen] = useState(screens.PRODUCT_LIST)
  const [orderPalletId, setOrderPalletId] = useState(null)
  const nextTaskAssignmentId = match.params.nextTaskAssignmentId

  const onMount = () => {
    fetchOneOrderPalletStart(match.params.orderPalletId)
    setOrderPalletId(match.params.orderPalletId)
    fetchScanLaneEnabled()
  }

  const printLabel = (id, quantityToPrint) => {
    openPrintDialog({
      id: id,
      document: ORDER_PALLET_LABEL,
      qty: quantityToPrint,
      onPrintDialogClose: () => {
        redirectToLane()
      },
    })
  }

  useEffect(onMount, [fetchOneOrderPalletStart])

  const onClickEnterPalletWeightHandler = () =>
    setCurrentScreen(screens.PALLET_WEIGHT)
  const onCancelPalletWeightHandler = () =>
    setCurrentScreen(screens.PRODUCT_LIST)

  const onCancelPalletListHandler = () => {
    redirect(POWER_PICKING_PULLSHEET_PAGE.replace(':id', item.process_id))
  }

  const onConfirmAndPrintHandler = (palletWeight, quantityToPrint) => {
    setTimeout(() => {
      //add a slight delay to prevent modal collision
      showPendingModal()

      updateOrderPalletStart({
        request: {
          id: item.id,
          data: {
            id: item.id,
            pallet_weight: palletWeight,
            updated_by_user_id: user.id,
          },
        },
        afterAction: (id) => {
          printLabel(id, quantityToPrint)
        },
      })
    })
  }

  const redirectToLane = () => {
    let redirectPage = '';
  
    if (scanLaneEnabled) {
      redirectPage = SCAN_LANE_PAGE.replace(':orderPalletId', orderPalletId)
        .replace(':powerPickProcessId', powerPickProcessId)
        .replace(':nextTaskAssignmentId', nextTaskAssignmentId);
    } else {
      redirectPage = POWER_PICKING_MOVE_TO_LANE_PAGE.replace(':orderPalletId', orderPalletId)
        .replace(':powerPickProcessId', powerPickProcessId)
        .replace(':nextTaskAssignmentId', nextTaskAssignmentId);
    }
  
    redirect(redirectPage);
  }
  

  const showProductListContent = () => {
    return (
      <PalletProductListContent
        onClickEnterPalletWeight={onClickEnterPalletWeightHandler}
        onCancelPalletList={onCancelPalletListHandler}
        orderPallet={item}
      />
    )
  }

  const doesNeedMultipleLabels = item
    ? item.order.orders_status === ORDER_STATUSES.STATUS_SCHEDULED_TO_PICKUP ||
      !!item.order.pickup_date
    : false

  const showPalletWeightScreen = () => {
    return (
      <PalletWeightScreen
        item={item}
        onConfirmAndPrint={onConfirmAndPrintHandler}
        onCancel={onCancelPalletWeightHandler}
        // TODO - Remove if still commented out after 2023-10-20
        // quantityToPrint={
        //   doesNeedMultipleLabels
        //     ? QUANTITY_LABELS_TO_PRINT_ON_WRAP_PICKUP_ORDER
        //     : DEFAULT_ORDER_PALLET_LABEL_PRINT_QUANTITY
        // }
        quantityToPrint={DEFAULT_ORDER_PALLET_LABEL_PRINT_QUANTITY}
      />
    )
  }

  const showCurrentScreen = () => {
    switch (currentScreen) {
      case screens.PALLET_WEIGHT:
        return showPalletWeightScreen()
      case screens.PRODUCT_LIST:
      default:
        return showProductListContent()
    }
  }

  return (
    <div className="tasks-page tasks-page--wrap">
      {showCurrentScreen()}
      <Print />
    </div>
  )
}

const mapStateToProps = (state) => ({
  item: state.orderPallet.item,
  powerPickProcessId: state.orderPallet.powerPickProcessId,
  scanLaneEnabled: state.order.scanLaneEnabled,
})

const mapDispatchToProps = {
  fetchOneOrderPalletStart,
  updateOrderPalletStart,
  fetchScanLaneEnabled,
  showPendingModal,
  openPrintDialog,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PowerPickingWrapPalletListPage)
