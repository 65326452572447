import request, { requestWithRetry } from 'helpers'
import { API_URL } from 'constants/apiconfig'

const apiEndpointLocations = `${API_URL}locations`

export const fetchAll = (payload) =>
  requestWithRetry({
    url: `${apiEndpointLocations}${payload}`,
    method: 'GET',
  })

export const fetchOne = (payload) =>
  requestWithRetry({
    url: `${apiEndpointLocations}/${payload.id}`,
    method: 'GET',
  })

export const fetchOneBy = (payload) =>
  requestWithRetry({
    url: `${apiEndpointLocations}-one-by${payload}`,
    method: 'GET',
  })

export const create = (payload) =>
  request({
    url: apiEndpointLocations,
    method: 'POST',
    data: payload,
  })

export const createGroup = (payload) =>
  request({
    url: `${apiEndpointLocations}/group`,
    method: 'POST',
    data: payload,
  })

export const remove = (payload) =>
  request({
    url: `${apiEndpointLocations}/${payload}`,
    method: 'DELETE',
  })

export const update = (payload) =>
  request({
    url: `${apiEndpointLocations}/${payload.id}`,
    method: 'PUT',
    data: payload,
  })

export const fetchAvailableBaysByAisleAndWarehouse = (payload) =>
  requestWithRetry({
    url: `${apiEndpointLocations}/available-bays`,
    method: 'GET',
    params: payload,
  })

export const fetchForLane = (payload) =>
  requestWithRetry({
    url: `${apiEndpointLocations}-order-pallets${payload}`,
    method: 'GET',
  })
