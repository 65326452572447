import React from 'react'
import * as PropTypes from 'prop-types'
import './style.scss'
import TextInput from 'components/common/TextInput'
import REGEX from 'constants/regex'
import IncreaseIcon from 'assets/svg/add.svg'
import DecreaseIcon from 'assets/svg/subtract.svg'

const QuantityControl = (props) => {
  const {
    value = 0,
    minValue = 0,
    maxValue,

    label = '',
    name = '',

    styles = {},

    onChange,
    onDecrease,
    onIncrease,
  } = props

  const { btnSize = 40, btnViewBox = 63, fontSize = 22 } = styles

  const onChangeTextInput = (name, value) => {
    if (!value) {
      return onChange(name, 0)
    }

    const normalizedValue = parseInt(value)

    if (
      normalizedValue < minValue ||
      (maxValue && normalizedValue > maxValue)
    ) {
      return false
    }

    onChange(name, normalizedValue)
  }

  return (
    <div className="quantity-control">
      <DecreaseIcon
        onClick={onDecrease}
        className={`quantity-control__btn${
          value > minValue ? '' : ' invisible'
        }`}
        height={btnSize}
        width={btnSize}
        viewBox={`0 0 ${btnViewBox} ${btnViewBox}`}
      />
      <TextInput
        inputMode="numeric"
        pattern={REGEX.IS_INTEGER}
        onChange={onChangeTextInput}
        name={name}
        value={value}
        fontSize={fontSize}
        autoScale
      />
      {label ? <span className="quantity-control__text">{label}</span> : <></>}
      <IncreaseIcon
        onClick={onIncrease}
        className={`quantity-control__btn${
          maxValue ? (value < maxValue ? '' : ' invisible') : ''
        }`}
        height={btnSize}
        width={btnSize}
        viewBox={`0 0 ${btnViewBox} ${btnViewBox}`}
      />
    </div>
  )
}

QuantityControl.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minValue: PropTypes.number,
  maxValue: PropTypes.number,

  label: PropTypes.string,
  name: PropTypes.string,

  styles: PropTypes.object,

  onChange: PropTypes.func,
  onDecrease: PropTypes.func,
  onIncrease: PropTypes.func,
}

export default QuantityControl
