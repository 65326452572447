import request, { requestWithRetry } from 'helpers'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}processes/container-unload`

export const fetchMany = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}-get`,
    method: 'POST',
    data: payload,
  })

export const fetchOne = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}/${payload.id}`,
    method: 'GET',
  })

export const create = (payload) =>
  request({
    url: apiEndpoint,
    method: 'POST',
    data: payload,
  })

export const update = (payload) =>
  request({
    url: `${apiEndpoint}/${payload.id}`,
    method: 'PUT',
    data: payload,
  })

export const fetchPutawayTasks = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}/get-putaway-tasks/${payload.process_id}/${payload.product_id}`,
    method: 'GET',
  })

export const fetchContainerPutawayProducts = (payload) =>
  requestWithRetry({
    url: `${API_URL}processes/container/putaway-products/${payload}`,
    method: 'GET',
  })

export const beginUnloadPutawayProcess = (payload) =>
  request({
    url: `${apiEndpoint}/begin-unload-putaway`,
    method: 'POST',
    data: payload,
  })

export const startUnloadPutaway = (payload) =>
  request({
    url: `${apiEndpoint}/start-unload-putaway`,
    method: 'POST',
    data: payload,
  })

export const startPutawayPalletRelocation = (payload) =>
  request({
    url: `${apiEndpoint}/start-putaway-pallet-relocation`,
    method: 'POST',
    data: payload,
  })

export const processUnloadPutaway = (payload) =>
  request({
    url: `${apiEndpoint}/process-unload-putaway/${payload}`,
    method: 'GET',
  })

export const fetchUnloadPutaway = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}/fetch-unload-putaway/${payload}`,
    method: 'GET',
  })

export const resetContainerProduct = (payload) =>
  request({
    url: `${apiEndpoint}/container-product/reset/${payload}`,
    method: 'GET',
  })
