import request, { requestWithRetry } from 'helpers'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}order-product-pick-locations/`

export const update = (payload) =>
  request({
    url: `${apiEndpoint}${payload.id}`,
    method: 'PUT',
    data: payload,
  })

export const checkStock = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}check-stock/${payload.id}`,
    method: 'GET',
  })

export const checkStockOrder = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}check-stock-order/${payload.process_id}`,
    method: 'GET',
  })
