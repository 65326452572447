import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import Header from 'components/common/Header'
import Button from 'components/common/Button'
import Scanner from 'components/common/Scanner'
import ActiveGrey from 'assets/svg/active-grey.svg'
import Active from 'assets/svg/active.svg'
import LocationToIcon from 'assets/svg/to-location.svg'
import LocationFromIcon from 'assets/svg/from-location.svg'
import {
  RESTOCK_TASK_LIST_PAGE,
  SCANNING_CASE_UPS_PAGE,
} from 'constants/routes'
import { redirect } from 'helpers/index'
import {
  fetchOneRestockProcessStart,
  updateRestockProcess,
  updateScannedRestockLocation,
} from 'store/restockProcess/actions'
import { updateWarehouseCase } from 'store/warehouseCase/actions'
import {
  createTaskAssignment,
  updateTaskAssignment,
} from 'store/taskAssignment/actions'
import {
  RESTOCK_TYPES,
  TASK_STATUSES,
  TASK_TYPES,
} from 'constants/taskAssignment'
import { IS_MULTIPLE } from 'constants/damageRestockTaskTypes'
import { ROLES } from 'constants/roles'
import { STATUS_COMPLETED, STATUS_IN_PROGRESS } from 'constants/processStatuses'
import { updateOrderProductPickLocation } from 'store/orderProductPickLocation/actions'
import {
  fetchRestockProcessListStart,
  updateRestockLocation,
} from 'store/restockProcess/actions'
import { resetModals, showModal } from 'store/modal/actions'
import { RESTOCK_LOCATION_STATUSES } from 'constants/restockLocationStatus'
import { MODAL_CASE_QTY } from 'components/common/Modal'

const DamageRestockTaskPage = (props) => {
  const {
    user,
    match,
    taskAssignmentList,
    processList,
    scannedProcessList,

    resetModals,
    createTaskAssignment,
    updateWarehouseCase,
    updateRestockProcess,
    updateTaskAssignment,
    fetchRestockProcessList,
    updateRestockLocation,
    updateScannedRestockLocation,
    showModal,
  } = props

  const onPageLoad = () => {
    fetchRestockProcessList({
      filters: [RESTOCK_TYPES.DAMAGE_TYPE],
      conditions: {
        status: STATUS_IN_PROGRESS,
      },
    })
  }

  const ids = match.params.ids.split(',')
  const pageType = match.params.pageType

  const [selectedProcessList, setSelectedProcessList] = useState([])

  const onUpdateProcessList = () => {
    setSelectedProcessList(
      processList.filter((item) => ids.indexOf(item.id.toString()) > -1),
    )
  }

  const onUpdateRestockProcess = () => {
    if (restockProcess === undefined) {
      return
    }

    if (!restockProcess.team.length) {
      createTaskAssignment({
        request: {
          data: {
            task_id: restockProcess.tasks.find(
              (task) => task.task_type.id === TASK_TYPES.RESTOCKING_TYPE,
            ).id,
            warehouse_staff_id: user.warehouseStaffId,
            role_id: ROLES.RESTOCKER,
          },
        },
      })
    } else {
      setTaskAssignment(restockProcess.team[0].taskAssignment)
    }
  }

  const onUpdateTaskAssignmentList = () => {
    if (restockProcess === undefined) {
      return
    }

    setTaskAssignment(
      taskAssignmentList.find(
        (item) =>
          item.task.id ===
          restockProcess.tasks.find(
            (task) => task.task_type.id === TASK_TYPES.RESTOCKING_TYPE,
          ).id,
      ),
    )
  }

  useEffect(onUpdateProcessList, [processList])

  let restockProcess = selectedProcessList[0]

  useEffect(onPageLoad, [])
  useEffect(onUpdateRestockProcess, [restockProcess])
  useEffect(onUpdateTaskAssignmentList, [taskAssignmentList])

  const [statusPanel, setStatusPanel] = useState({
    isCaseScanned: pageType === IS_MULTIPLE,
    isLocationToScanned: false,
    text: pageType === IS_MULTIPLE ? 'Scan Location UPC' : 'Scan Case UPC',
    icon: <ActiveGrey />,
  })

  const [taskAssignment, setTaskAssignment] = useState({})

  if (restockProcess === undefined) {
    return ''
  }

  const restockLocation = restockProcess.entity.restock_location[0]

  const isFromScanned =
    statusPanel.isCaseScanned && statusPanel.isLocationToScanned
  const currentLoc = statusPanel.isCaseScanned
    ? 'warehouse_location_to'
    : 'warehouse_location_from'
  const locationTitle = `${restockLocation[currentLoc].regular_location.aisle_id.name}-${restockLocation[currentLoc].regular_location.bay_id.name}-${restockLocation[currentLoc].regular_location.level}`

  const onScan = (barcode) => {
    switch (true) {
      case !statusPanel.isCaseScanned &&
        restockLocation.product.products_upc_case.toString() ===
          barcode.toString(): // TODO: this will likely need to change to match the new multi-upc table data, re: wms-1835 (07.2021)
        const restockLocationFrom = restockLocation.warehouse_location_from
        const fromCaseInLocation = restockLocationFrom.warehouse_cases_in_location.find(
          (item) => item.product_id === restockLocation.product.products_id,
        )

        updateWarehouseCase({
          id: fromCaseInLocation.id,
          data: {
            id: fromCaseInLocation.id,
            location_case_qty:
              fromCaseInLocation.location_case_qty - restockLocation.quantity,
          },
        })

        setStatusPanel({ ...statusPanel, icon: <Active /> })
        setTimeout(
          () =>
            setStatusPanel({
              ...statusPanel,
              isCaseScanned: true,
              text: 'Scan Location UPC',
            }),
          1500,
        )
        break
      case !statusPanel.isLocationToScanned:
        if (
          !selectedProcessList.filter(
            (element) =>
              element.entity.restock_location[0].warehouse_location_to.upc.toString() ===
              barcode.toString(),
          ).length
        ) {
          break
        }

        const restockLocationTo = restockLocation.warehouse_location_to
        const toCaseInLocation = restockLocationTo.warehouse_cases_in_location.find(
          (item) => item.product_id === restockLocation.product.products_id,
        )

        setStatusPanel({ ...statusPanel, icon: <Active /> })

        if (pageType === IS_MULTIPLE) {
          showModal({
            name: MODAL_CASE_QTY,
            onDeny: resetModals,
            onConfirm: onConfirm,
            value: getCaseQuantity(),
            maxValue: getCaseQuantity(),
            className: '',
            allCaseQuantity: getAllCaseQuantity(),
          })
        } else {
          updateWarehouseCase({
            id: toCaseInLocation.id,
            data: {
              id: toCaseInLocation.id,
              location_case_qty:
                toCaseInLocation.location_case_qty + restockLocation.quantity,
            },
          })

          setTimeout(
            () =>
              setStatusPanel({
                ...statusPanel,
                isLocationToScanned: true,
                text: '',
              }),
            1500,
          )
        }
        break
      default:
        break
    }
  }

  const onComplete = () => {
    updateRestockLocation({
      id: restockProcess.entity.restock_location[0].id,
      data: {
        id: restockProcess.entity.restock_location[0].id,
        status_id: RESTOCK_LOCATION_STATUSES.COMPLETE,
      },
    })

    updateTaskAssignment({
      id: taskAssignment.id,
      data: {
        id: taskAssignment.id,
        status: TASK_STATUSES.FINISHED_TYPE,
      },
    })

    updateRestockProcess({
      id: restockProcess.id,
      data: {
        id: restockProcess.id,
        status: STATUS_COMPLETED,
      },
    })

    redirect(RESTOCK_TASK_LIST_PAGE)
  }

  const getCaseQuantity = () =>
    selectedProcessList.reduce((sum, currentProcess) => {
      return sum + currentProcess.entity.restock_location[0].quantity
    }, 0)

  const getAllCaseQuantity = () =>
    scannedProcessList.reduce((sum, currentProcess) => {
      return sum + currentProcess.entity.restock_location[0].quantity
    }, 0)

  const onConfirm = (quantity) => {
    let temQuantity = quantity

    selectedProcessList.forEach((element) => {
      const firstAndSingleRestockLocation = element.entity.restock_location[0]

      if (temQuantity >= firstAndSingleRestockLocation.quantity) {
        temQuantity -= firstAndSingleRestockLocation.quantity

        const warehouseCaseInLocation = firstAndSingleRestockLocation.warehouse_location_to.warehouse_cases_in_location.find(
          (item) =>
            item.product_id ===
            firstAndSingleRestockLocation.product.products_id,
        )

        updateWarehouseCase({
          id: warehouseCaseInLocation.id,
          data: {
            id: warehouseCaseInLocation.id,
            location_case_qty:
              warehouseCaseInLocation.location_case_qty + quantity,
          },
        })

        updateRestockLocation({
          id: firstAndSingleRestockLocation.id,
          data: {
            id: firstAndSingleRestockLocation.id,
            status_id: RESTOCK_LOCATION_STATUSES.COMPLETE,
          },
        })

        updateTaskAssignment({
          id: element.team[0].taskAssignment.id,
          data: {
            id: element.team[0].taskAssignment.id,
            status: TASK_STATUSES.FINISHED_TYPE,
          },
        })

        updateRestockProcess({
          id: element.id,
          data: {
            id: element.id,
            status: STATUS_COMPLETED,
          },
        })

        updateScannedRestockLocation(
          scannedProcessList.filter((item) => item.id !== element.id),
        )
      }
    })

    resetModals()

    if (quantity === getAllCaseQuantity()) {
      updateScannedRestockLocation([])
      redirect(RESTOCK_TASK_LIST_PAGE)
    } else {
      redirect(SCANNING_CASE_UPS_PAGE)
    }
  }

  return (
    <div className="tasks-page tasks-page--start-restock">
      <Scanner needToRemoveListener={false} onReceiveData={onScan} />
      <Header />
      <div className="content">
        <div className="content__section">
          <div className="dock__wrapper">
            <div className="dock">
              <span className="dock__label">LOC</span>
              <span className="dock__name">{locationTitle}</span>
            </div>
          </div>
          <h3 className="fireworks-name">{restockLocation.name}</h3>
          <ul className="fireworks">
            <li className="fireworks__item">
              <span className="fireworks__name">Model</span>
              <span className="fireworks__sub-name">
                {restockLocation.product.products_model}
              </span>
            </li>
            <li className="fireworks__item">
              <span className="fireworks__name">Manf Model</span>
              <span className="fireworks__sub-name">
                {restockLocation.product.products_manf_model}
              </span>
            </li>
            <li className="fireworks__item">
              {isFromScanned ? <LocationToIcon /> : <LocationFromIcon />}
            </li>
          </ul>
        </div>
        <div className="content__section content__section--white">
          <span className="status">
            {!statusPanel.isLocationToScanned ? (
              <>
                {statusPanel.text} {statusPanel.icon}
              </>
            ) : (
              <Button onClick={onComplete}>Complete</Button>
            )}
          </span>
        </div>
      </div>
    </div>
  )
}

DamageRestockTaskPage.propTypes = {
  restockProcess: PropTypes.object,
}

const mapStateToProps = (state) => ({
  taskAssignmentList: state.taskAssignment.items,
  restockProcess: state.restockProcess.item,
  processList: state.restockProcess.list,
  scannedProcessList: state.restockProcess.scannedItem,
})

const mapDispatchToProps = {
  updateRestockLocation: updateRestockLocation,
  resetModals: resetModals,
  showModal: showModal,
  fetchRestockProcessList: fetchRestockProcessListStart,
  fetchOneRestockProcess: fetchOneRestockProcessStart,
  updateWarehouseCase: updateWarehouseCase,
  updateTaskAssignment: updateTaskAssignment,
  updateRestockProcess: updateRestockProcess,
  createTaskAssignment: createTaskAssignment,
  updateOrderProductPickLocation: updateOrderProductPickLocation,
  updateScannedRestockLocation: updateScannedRestockLocation,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DamageRestockTaskPage)
