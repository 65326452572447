import React, { useEffect, useState } from 'react'
import ActiveGrey from 'assets/svg/active-grey.svg'
import Active from 'assets/svg/active.svg'
import Scanner from 'components/common/Scanner'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import Header from 'components/common/Header'
import Button from 'components/common/Button'
import { ORDER_PICK_STATUSES } from 'constants/order'
import { TASK_STATUSES, TASK_TYPES } from 'constants/taskAssignment'
import {
  HOME_PAGE,
  START_POWER_PICKING_PAGE,
  POWER_PICKING_LIST_PAGE,
  POWER_PICKING_PULLSHEET_PAGE,
} from 'constants/routes'
import { ORDER_PALLET_STATUSES } from 'constants/orderPalletStatuses'
import { LOCATION_TYPE } from 'constants/locationTypes'
import { closeModal, resetModals, showModal } from 'store/modal/actions'
import { fetchLocationsForLaneStart } from 'store/location/actions'
import { date, dateToFormat, isEmptyObject, redirect } from 'helpers/index'

import {
  createOrderPallet,
  createTempProductsWithQuantity,
  fetchOneOrderPalletStart,
  updateOrderPalletStart,
} from 'store/orderPallet/actions'
import {
  createShippingLane,
  validateShippingLane,
} from 'store/shippingLane/actions'
import ExclamationSmall from 'assets/svg/exclamation-sign.svg'
import { createPickupLane, validatePickupLane } from 'store/pickupLane/actions'
import { updateTaskAssignmentWithoutFetch } from 'store/taskAssignment/actions'
import { updateOrderPickStatusStart } from 'store/orderPickStatus/actions'
import { MAX_PALLETS_PER_TRUCK } from 'constants/order'

const ScanLanePage = (props) => {
  const {
    user,
    match,
    locations,
    orderPallet,

    fetchOneOrderPallet,
    updateOrderPallet,
    updateTaskAssignment,
    updateOrderPickStatus,
    createOrderPallet,
    createPickupLane,
    createShippingLane,
    validateShippingLane,
    validatePickupLane,
    createTempProductsWithQuantity,
    resetModals,
    showModal,
  } = props

  const INIT_STATUS_PANEL = {
    isLaneScanned: false,
    warehouseLocationId: null,
    fetchParams: {},
    text: 'Scan Location UPC',
    icon: <ActiveGrey className="scan-indicator" />,
  }

  const orderPalletId = match.params.orderPalletId
  const powerPickProcessId =
    match.params.powerPickProcessId === 'null'
      ? null
      : match.params.powerPickProcessId
  const nextTaskAssignmentId =
    match.params.nextTaskAssignmentId === 'null'
      ? null
      : match.params.nextTaskAssignmentId

  const [statusPanel, setStatusPanel] = useState(INIT_STATUS_PANEL)

  const onMount = () => {
    fetchOneOrderPallet(orderPalletId)
  }

  useEffect(onMount, [])

  const showScanIndicator = () => (
    <>
      {statusPanel.text} {statusPanel.icon}
    </>
  )

  const getLocationsTypeId = () => {
    if (orderPallet) {
      return 'PICKUP' === orderPallet.order.shipping_option_id
        ? LOCATION_TYPE.PICKUP_LANE_ID
        : LOCATION_TYPE.SHIPPING_TYPE_ID
    }

    return null
  }

  const endCurrentOrderTaskAssignment = () => {
    if (orderTaskAssignment) {
      updateTaskAssignment({
        id: orderTaskAssignment.id,
        data: {
          id: orderTaskAssignment.id,
          end_date: dateToFormat(date(), 'YYYY-MM-DD'),
          status: TASK_STATUSES.FINISHED_TYPE,
        },
      })
    }
  }

  const createNextOrderPallet = () => {
    //TODO: need to correctly determine nextPickPalletId if multiple users
    const nextPickPalletId = orderPallet.pallet_nbr + 1

    createOrderPallet({
      data: {
        order_id: orderPallet.order_pick_status.order_id,
        status_id: ORDER_PICK_STATUSES.STATUS_PICKING_PALLET,
        pick_pallet_id: nextPickPalletId,
        pallet_weight: null,
        warehouse_location_id: null,
      },
    })
  }

  const redirectToPicking = () => {
    if (isAllCasesPickedFromCurrentPickLocation) {
      redirect(START_POWER_PICKING_PAGE.replace(':id', nextTaskAssignmentId))
    } else {
      redirect(
        START_POWER_PICKING_PAGE.replace(
          ':id',
          orderProductPickLocation.task_assignment_id,
        ),
      )
    }
  }

  const redirectToPowerPick = () => {
    redirect(POWER_PICKING_PULLSHEET_PAGE.replace(':id', powerPickProcessId))
  }

  const isPreLastPallet = () =>
    orderPallet.pallet_nbr === MAX_PALLETS_PER_TRUCK - 1
  const isLastPallet = () => orderPallet.pallet_nbr === MAX_PALLETS_PER_TRUCK
  const isAllCasesPicked = () =>
    orderPallet.orderProductPickLocations.filter(
      (item) =>
        item.location_pick_quantity !==
        item.picked_quantity + item.unavailable_quantity,
    ).length === 0

  const isTheEndOfTheOrder = () => {
    if (!isEmptyObject(orderPallet)) {
      return isAllCasesPicked() || isLastPallet()
    }

    return false
  }

  const onDone = (scannedLocationId) => {
    updateOrderPallet({
      request: {
        id: orderPalletId,
        data: {
          id: orderPalletId,
          warehouse_location_id: scannedLocationId,
          status_id: ORDER_PALLET_STATUSES.SHIPPING_LANE_TYPE,
        },
      },
    })

    if (getLocationsTypeId() === LOCATION_TYPE.SHIPPING_TYPE_ID) {
      createShippingLane({
        data: {
          warehouse_location_id: scannedLocationId,
          order_pallet_id: orderPalletId,
          created_by_user_id: user.warehouseStaffId,
        },
      })
    } else {
      createPickupLane({
        data: {
          warehouse_location_id: scannedLocationId,
          order_pallet_id: orderPalletId,
          created_by_user_id: user.warehouseStaffId,
        },
      })
    }

    switch (true) {
      //last pallet but not all order products picked -> create new order
      case isLastPallet() && !isAllCasesPicked():
        createTempProductsWithQuantity({
          data: {
            order_pallet_id: orderPallet.id,
          },
        })

        endCurrentOrderTaskAssignment()
        endAllLocationPickingTasksAssignments()

        updateOrderPickStatus({
          id: orderPallet.order_pick_status.id,
          data: {
            id: orderPallet.order_pick_status.id,
            status_id: ORDER_PICK_STATUSES.STATUS_COMPLETED_ORDER,
            order_id: orderPallet.order_pick_status.order_id,
          },
        })

        redirect(POWER_PICKING_LIST_PAGE)
        break
      case isTheEndOfTheOrder():
        endCurrentOrderTaskAssignment()

        updateOrderPickStatus({
          id: orderPallet.order_pick_status.id,
          data: {
            id: orderPallet.order_pick_status.id,
            status_id: ORDER_PICK_STATUSES.STATUS_COMPLETED_ORDER,
            order_id: orderPallet.order_pick_status.order_id,
          },
        })

        redirect(POWER_PICKING_LIST_PAGE)

        break
      //pick pallet < 9
      case !isLastPallet() && !isPreLastPallet():
        createNextOrderPallet()

        if (powerPickProcessId) {
          redirectToPowerPick()
        } else {
          redirectToPicking()
        }
        break
      //pick pallet = 9
      case isPreLastPallet():
        if (user.isManager()) {
          createNextOrderPallet()
          if (powerPickProcessId) {
            redirectToPowerPick()
          } else {
            redirectToPicking()
          }
        }

        if (!user.isManager()) {
          endCurrentOrderTaskAssignment()
          endAllLocationPickingTasksAssignments()
          redirect(POWER_PICKING_LIST_PAGE)
        }

        break
      //pick pallet = 10
      case isLastPallet():
        endCurrentOrderTaskAssignment()
        redirect(POWER_PICKING_LIST_PAGE)

        break
      default:
        break
    }
  }

  const onValidateAfterAction = (data) => {
    resetModals()
    if (data.success) {
      setStatusPanel({
        ...statusPanel,
        isLaneScanned: true,
        warehouseLocationId: data.warehouse_location_id,
        icon: <Active className="scan-indicator" />,
      })
      onDone(data.warehouse_location_id)
    } else {
      showModal({
        name: 'modalWarning',
        text: data.message,
        onAccept: resetModals,
        onDeny: resetModals,
        className: 'modal--tasks modal--pause',
        modalIcon: <ExclamationSmall />,
      })
    }
  }

  const onScan = (upc) => {
    if (statusPanel.isLaneScanned) {
      return
    }

    showModal({ name: 'modalPending' })

    if (getLocationsTypeId() === LOCATION_TYPE.SHIPPING_TYPE_ID) {
      validateShippingLane({
        request: {
          data: {
            warehouse_location_upc: upc,
          },
        },
        afterAction: onValidateAfterAction,
      })
    } else {
      validatePickupLane({
        request: {
          data: {
            warehouse_location_upc: upc,
          },
        },
        afterAction: onValidateAfterAction,
      })
    }
  }

  if (!orderPallet) {
    return <></>
  }

  const lastOrderPallet =
    orderPallet.order_pick_pallets[orderPallet.order_pick_pallets.length - 1]

  const currentPickLocationId = lastOrderPallet.order_product_pick_location_id
  const orderProductPickLocation = orderPallet.orderProductPickLocations.find(
    (item) => item.id === currentPickLocationId,
  )

  const tasksToDo = orderPallet.tasks
    .filter((item) => item.end_date === null)
    .filter((item) => item.task_type.id === TASK_TYPES.LOCATION_PICKING_TYPE)

  const orderTaskAssignment = orderPallet.tasks.find(
    (item) => item.task_type.id === TASK_TYPES.PICKING_TYPE,
  ).task_assignments[0]

  const isAllCasesPickedFromCurrentPickLocation =
    orderProductPickLocation.location_pick_quantity ===
    orderProductPickLocation.picked_quantity +
      orderProductPickLocation.unavailable_quantity

  const endAllLocationPickingTasksAssignments = () => {
    const allTasksAssignments = tasksToDo
      .reduce(
        (accumulator, currentValue) => [
          ...accumulator,
          ...currentValue.task_assignments,
        ],
        [],
      )
      .filter((item) => item.end_date === null)

    allTasksAssignments.forEach((item) => {
      updateTaskAssignment({
        id: item.id,
        data: {
          id: item.id,
          end_date: dateToFormat(date(), 'YYYY-MM-DD'),
          status: TASK_STATUSES.FINISHED_TYPE,
        },
      })
    })
  }

  const title = orderPallet
    ? 'PICKUP' === orderPallet.order.shipping_option_id
      ? 'Select Pickup Lane'
      : 'Select Shipping Lane'
    : undefined

  if (!lastOrderPallet) {
    const redirectToHomePage = () => redirect(HOME_PAGE)

    return (
      <div className="tasks-page tasks-page--move-to-lane">
        <Header>{title}</Header>
        <div className="error-content">
          Last Order Pallet was not defined
          <Button onClick={redirectToHomePage}>Back to Home</Button>
        </div>
      </div>
    )
  }

  return (
    <div className="order-pallet-loading-page">
      <Header>
        <div className="title">{title}</div>
      </Header>
      <div className="content">
        <Scanner needToRemoveListener={false} onReceiveData={onScan} />
        <span className="status">{showScanIndicator()}</span>
      </div>
    </div>
  )
}

ScanLanePage.propTypes = {
  orderPallet: PropTypes.object,
}

const mapStateToProps = (state) => ({
  orderPallet: state.orderPallet.item,
})

const mapDispatchToProps = {
  fetchLocationsForLane: fetchLocationsForLaneStart,
  resetModals,
  closeModal,
  showModal,
  updateOrderPallet: updateOrderPalletStart,
  createPickupLane,
  createShippingLane,
  validateShippingLane,
  validatePickupLane,
  updateTaskAssignment: updateTaskAssignmentWithoutFetch,
  fetchOneOrderPallet: fetchOneOrderPalletStart,
  updateOrderPickStatus: updateOrderPickStatusStart,
  createOrderPallet,
  createTempProductsWithQuantity,
}

export default connect(mapStateToProps, mapDispatchToProps)(ScanLanePage)
