import request from 'helpers'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}order-pallet-lane-locations/`

export const update = (payload) =>
  request({
    url: `${apiEndpoint}${payload.id}`,
    method: 'PUT',
    data: payload,
  })