import {
  FETCH_ONE_ORDER_PROCESS_SUCCESS,
  FETCH_ORDERS_FAIL,
  FETCH_ORDERS_START,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_POWER_PICK_FAIL,
  FETCH_ORDERS_POWER_PICK_START,
  FETCH_ORDERS_POWER_PICK_SUCCESS,
  FETCH_ONE_ORDER_POWER_PICK_SUCCESS,
  FETCH_SCAN_TO_TRUCK_SUCCESS,
  FETCH_SCAN_LANE_SUCCESS,
  SORT_ORDERS,
  UPDATE_ORDER_START,
  UPDATE_POWER_PICK_AUTO,
  RESET_ORDERS,
} from './actions'

const INIT = {
  item: {},
  today: [],
  powerPickOrders: [],
  powerPickOrder: null,
  isPowerPickAuto: false,
  scanToTruckEnabled: false,
  scanLaneEnabled: false,
  nextDay: [],
  lastRefresh: new Date(),
  isLoading: false,
  isFetched: false,
}

export default function orderReducer(state = INIT, action) {
  const { type, payload } = action

  switch (type) {
    case FETCH_SCAN_TO_TRUCK_SUCCESS:
      return {
        ...state,
        scanToTruckEnabled: payload,
      }
    case FETCH_SCAN_LANE_SUCCESS:
      return {
        ...state,
        scanLaneEnabled: payload,
      }

    case RESET_ORDERS:
      return {
        ...INIT,
        isPowerPickAuto: state.isPowerPickAuto,
        scanToTruckEnabled: state.scanToTruckEnabled,
      }
    case FETCH_ONE_ORDER_PROCESS_SUCCESS:
      return { ...state, item: payload, lastRefresh: new Date() }
    case FETCH_ORDERS_START:
      return {
        ...state,
        isLoading: true,
        isFetched: false,
      }
    case FETCH_ORDERS_SUCCESS: {
      let items = []

      if (payload.type === 'today') {
        items = payload.items.sort(
          (prev, next) => prev.priority - next.priority,
        )
      } else {
        items = payload.items
      }

      return {
        ...state,
        isFetched: true,
        isLoading: false,
        [payload.type]: items,
      }
    }
    case FETCH_ORDERS_FAIL:
      return {
        isFetched: false,
        isLoading: false,
      }
    case FETCH_ORDERS_POWER_PICK_START:
      return {
        ...state,
        isLoading: true,
        isFetched: false,
      }
    case FETCH_ORDERS_POWER_PICK_SUCCESS: {
      let items = []

      if (payload.type === 'today') {
        items = payload.items.sort(
          (prev, next) => prev.priority - next.priority,
        )
      } else {
        items = payload.items
      }

      return {
        ...state,
        isFetched: true,
        isLoading: false,
        powerPickOrders: items,
      }
    }
    case FETCH_ORDERS_POWER_PICK_FAIL:
      return {
        isFetched: false,
        isLoading: false,
      }
    case FETCH_ONE_ORDER_POWER_PICK_SUCCESS:
      return { ...state, powerPickOrder: payload, lastRefresh: new Date() }
    case UPDATE_ORDER_START: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case SORT_ORDERS: {
      const sortedItems = state[payload.key].sort((prev, next) => {
        let quantityForPrev = 0
        let quantityForNext = 0
        const orderProductsPrev = prev.entity.orderProducts
        const orderProductsNext = prev.entity.orderProducts

        orderProductsPrev.forEach((orderProduct) => {
          quantityForPrev += orderProduct.products_quantity
        })

        orderProductsNext.forEach((orderProduct) => {
          quantityForPrev += orderProduct.products_quantity
        })

        return quantityForPrev - quantityForNext
      })

      return {
        ...state,
        [payload.type]: sortedItems,
      }
    }
    case UPDATE_POWER_PICK_AUTO: {
      return {
        ...state,
        isPowerPickAuto: payload,
      }
    }
    default:
      return state
  }
}
