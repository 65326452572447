import React, { useEffect, useState } from 'react'
import Button from 'components/common/Button'
import REGEX from 'constants/regex'
import Header from 'components/common/Header'

const PalletWeightScreen = ({ item, onConfirmAndPrint, onCancel }) => {
  const [weight, setWeight] = useState(undefined)

  const onMount = () => {
    if (item && item.pallet_weight) {
      setWeight(item.pallet_weight)
    }
  }

  useEffect(onMount, [item, setWeight])

  const onChangeHandler = (e) => {
    const value = e.target.value

    if (!value) {
      return setWeight(undefined)
    }

    if (value.match(REGEX.IS_FLOAT)) {
      return setWeight(parseFloat(value))
    }
  }

  const onConfirmAndPrintHandler = () => {
    onConfirmAndPrint(weight)
  }

  const showPrint = () => {
    return (
      <>
        <Button onClick={onConfirmAndPrintHandler}>Print Label</Button>
        <div>&nbsp;</div>
      </>
    )
  }

  return (
    <>
      <Header>
        <div className="header__action"></div>
        <div className="title">Pallet Weight Total</div>
      </Header>
      <div className="content pallet-weight-screen">
        <div className="input-box">
          <input
            autofocus="autofocus"
            inputMode="numeric"
            value={weight}
            onChange={onChangeHandler}
          />
          <div className="input-box__label">LB</div>
        </div>
        {!!weight && showPrint()}
      </div>
    </>
  )
}

export default PalletWeightScreen
