import React from 'react'
import * as PropTypes from 'prop-types'
import BulkStorageList from 'components/features/Task/BulkStorageTaskList/BulkStorageTaskContent/TabContent/BulkStorageList'
import { RESTOCK_TYPE_LABELS } from 'constants/taskAssignment'
import { redirect, date, dateToFormat } from 'helpers/index'
import { START_BULK_STORAGE_PAGE } from 'constants/routes'
import { connect } from 'react-redux'
import { STATUS_IN_PROGRESS } from 'constants/processStatuses'
import {
  updateRestockProcess,
  startBulkStorageFromTask,
} from 'store/restockProcess/actions'

const TabContent = (props) => {
  const { items, updateRestockProcess, startBulkStorageFromTask } = props

  const afterAction = ($id) => {
    redirect(START_BULK_STORAGE_PAGE.replace(':id', $id))
  }

  const onAction = (item) => {
    updateRestockProcess({
      id: item.id,
      data: {
        id: item.id,
        status: STATUS_IN_PROGRESS,
        start_date: dateToFormat(date(), 'YYYY-MM-DD HH:mm:ss'),
      },
    })

    startBulkStorageFromTask({
      request: {
        id: item.id,
        data: {
          id: item.id,
        },
      },
      afterAction: afterAction
    })
  }

  const getNormalizedItems = (items) =>
    items.map((item) => {
      const firstAndSingleRestockLocation = item.entity.restock_location
      const onClick = () => {
        onAction(item)
      }
      const fromAisle =
        firstAndSingleRestockLocation.warehouse_location_from.regular_location
          .aisle.name

      const fromBay =
        firstAndSingleRestockLocation.warehouse_location_from.regular_location
          .bay.name

      const fromLevel =
        firstAndSingleRestockLocation.warehouse_location_from.regular_location
          .level

      const fromQuantity = firstAndSingleRestockLocation.warehouse_location_from
        .warehouse_cases_in_location
        ? firstAndSingleRestockLocation.warehouse_location_from
            .warehouse_cases_in_location.location_case_qty
        : 0

      const locationFrom = (
        <>
          {fromAisle}-{fromBay}-{fromLevel}
          &nbsp;
          <span className="restock-qty">[ {fromQuantity} ]</span>
        </>
      )
      const toAisle =
        firstAndSingleRestockLocation.warehouse_location_to.regular_location
          .aisle.name

      const toBay =
        firstAndSingleRestockLocation.warehouse_location_to.regular_location.bay
          .name

      const toLevel =
        firstAndSingleRestockLocation.warehouse_location_to.regular_location
          .level

      const toQuantity = firstAndSingleRestockLocation.warehouse_location_to
        .warehouse_cases_in_location
        ? firstAndSingleRestockLocation.warehouse_location_to
            .warehouse_cases_in_location.location_case_qty
        : 0

      const locationTo = (
        <>
          {toAisle}-{toBay}-{toLevel}
          &nbsp;
          <span className="restock-qty">[ {toQuantity} ]</span>
        </>
      )

      const restockDetails = firstAndSingleRestockLocation.orders
        ? firstAndSingleRestockLocation.orders.map((order) => {
            return (
              <>
                {order.order_id}
                &nbsp;
                <span className="restock-qty">
                  [{order.location_pick_quantity}]
                </span>
                &nbsp; &nbsp;
              </>
            )
          })
        : ''

      return {
        id: item.id,
        location: locationFrom,
        locationTo: locationTo,
        total_pick_quantity: firstAndSingleRestockLocation.total_pick_quantity,
        taskType:
          RESTOCK_TYPE_LABELS[firstAndSingleRestockLocation.restock_type_id],
        model: firstAndSingleRestockLocation.product.products_model,
        details: restockDetails,
        action: onClick,
        options: {
          expandable: false,
          swipeable: false,
        },
        expandedContent: <></>,
      }
    })

  const generateNormalizedItems = () => {
    return getNormalizedItems(items)
  }

  return (
    <div className="tab__content">
      <BulkStorageList rows={generateNormalizedItems()} />
    </div>
  )
}

TabContent.propTypes = {
  items: PropTypes.array,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  updateRestockProcess: updateRestockProcess,
  startBulkStorageFromTask,
}

export default connect(mapStateToProps, mapDispatchToProps)(TabContent)
