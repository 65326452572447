import React from 'react'
import PropTypes from 'prop-types'

import UnloadDamageIcon from 'assets/svg/unload-damage.svg'
import Active from 'assets/svg/active.svg'
import ActiveGrey from 'assets/svg/active-grey.svg'

const PalletLoadingProgressItem = ({
  total,
  loaded,
  aisle_bay_level,
  is_partial,
  // onSelect,
  warehousePalletId,
  missing,
}) => {
  const loadedQty = loaded !== undefined ? loaded : 0
  const accountedFor = loadedQty + missing
  const partialClass = is_partial ? 'pallet-cell__partial' : ''
  // const onPalletSelect = is_partial
  //   ? () => {
  //       onSelect(warehousePalletId)
  //     }
  //   : null

  return (
    <div
      // onClick={onPalletSelect}
      className={`pallet-cell__list-item ${partialClass}`}
    >
      {loaded !== undefined ? (
        accountedFor === total ? (
          <>
            <span className="pallet-cell__aisle-bay-level">
              {aisle_bay_level}
            </span>
            <Active className="pallet-cell__check_icon" />
            <span className="pallet-cell__case_count">
              {loaded}/{total}
            </span>
          </>
        ) : (
          <>
            <span className="pallet-cell__aisle-bay-level">
              {aisle_bay_level}
            </span>
            <ActiveGrey className="pallet-cell__check_icon" />
            <span>
              {loaded}/{total}
            </span>
          </>
        )
      ) : (
        <>
          <span className="pallet-cell__aisle-bay-level">
            {aisle_bay_level}
          </span>
          <UnloadDamageIcon />
          <span>{total}</span>
        </>
      )}
    </div>
  )
}

PalletLoadingProgressItem.propTypes = {
  total: PropTypes.number.isRequired,
  loaded: PropTypes.number,
}

export default PalletLoadingProgressItem
