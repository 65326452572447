import { takeEvery, call, put } from 'redux-saga/effects'
import { types } from './actions'
import {
  update,
  checkStock,
  checkStockOrder,
} from 'api/orderProductPickLocation'
import { fetchOneTaskAssignmentStart } from 'store/taskAssignment/actions'
import { fetchOneOrderPowerPickStart } from 'store/order/actions'

function* updateOrderProductPickLocation(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    yield call(update, request)

    if (payload.request.task_assignment_id) {
      yield put(
        fetchOneTaskAssignmentStart({ id: payload.request.task_assignment_id }),
      )
    }

    if (afterAction) {
      yield afterAction()
    }
  } catch (error) {}
}

function* checkLocationStock(action) {
  const { payload } = action

  try {
    yield call(checkStock, payload)

    yield put(fetchOneOrderPowerPickStart({ id: payload.process_id }))
  } catch (error) {}
}

function* checkOrderStock(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    yield call(checkStockOrder, request)
    if (afterAction) {
      yield afterAction()
    }
  } catch (error) {}
}

export default function* orderProductPickLocationSaga() {
  yield takeEvery(
    types.UPDATE_ORDER_PRODUCT_PICK_LOCATION,
    updateOrderProductPickLocation,
  )

  yield takeEvery(types.CHECK_STOCK, checkLocationStock)
  yield takeEvery(types.CHECK_STOCK_ORDER, checkOrderStock)
}
