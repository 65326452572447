export const types = {
  CREATE_SHIPPING_LANE: 'CREATE_SHIPPING_LANE',
  VALIDATE_SHIPPING_LANE: 'VALIDATE_SHIPPING_LANE',
}

export const createShippingLane = (payload) => ({
  type: types.CREATE_SHIPPING_LANE,
  payload,
})

export const validateShippingLane = (payload) => ({
  type: types.VALIDATE_SHIPPING_LANE,
  payload,
})
