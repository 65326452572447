import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import Header from 'components/common/Header'
import Scanner from 'components/common/Scanner'
import { connect } from 'react-redux'
import { redirect } from 'helpers/index'
import Active from 'assets/svg/active.svg'
import ActiveGrey from 'assets/svg/active-grey.svg'
import { IS_MULTIPLE } from 'constants/damageRestockTaskTypes'
import { TASK_TYPES } from 'constants/taskAssignment'
import { ROLES } from 'constants/roles'
import { createTaskAssignment } from 'store/taskAssignment/actions'

const ScanningCaseUpsPage = (props) => {
  const { scannedProcessList, createTaskAssignment, user } = props

  const getNormalizedItems = () => {
    let normalizedItems = []
    let normalizedIds = []

    scannedProcessList.forEach((item) => {
      normalizedItems.forEach((element, index) => {
        let normalizedToLocation = `${element[0].entity.restock_location[0].warehouse_location_to.regular_location.aisle_id.name}-${element[0].entity.restock_location[0].warehouse_location_to.regular_location.bay_id.name}-${element[0].entity.restock_location[0].warehouse_location_to.regular_location.level}`
        let toLocation = `${item.entity.restock_location[0].warehouse_location_to.regular_location.aisle_id.name}-${item.entity.restock_location[0].warehouse_location_to.regular_location.bay_id.name}-${item.entity.restock_location[0].warehouse_location_to.regular_location.level}`

        if (
          normalizedToLocation === toLocation &&
          element[0].entity.restock_location[0].product.products_id ===
            item.entity.restock_location[0].product.products_id
        ) {
          normalizedItems[index] = [...normalizedItems[index], item]

          normalizedIds = [...normalizedIds, item.id]
        }
      })

      if (normalizedIds.indexOf(item.id) === -1) {
        normalizedItems = [...normalizedItems, [item]]

        normalizedIds = [...normalizedIds, item.id]
      }
    })

    return normalizedItems
  }

  const [isScanned, setIsScanned] = useState(false)

  const onScan = (barcode) => {
    getNormalizedItems().forEach((item) => {
      if (
        item[0].entity.restock_location[0].product.products_upc_case.toString() ===
        barcode.toString() // TODO: this will likely need to change to match the new multi-upc table data, re: wms-1835 (07.2021)
      ) {
        setIsScanned(true)

        item.forEach((element) => {
          if (!element.team.length) {
            createTaskAssignment({
              request: {
                data: {
                  task_id: element.tasks.find(
                    (task) => task.task_type.id === TASK_TYPES.RESTOCKING_TYPE,
                  ).id,
                  warehouse_staff_id: user.warehouseStaffId,
                  role_id: ROLES.RESTOCKER,
                },
              },
            })
          }
        })

        setTimeout(() => {
          let params = item.reduce((params, element, index) => {
            if (index === item.length - 1) {
              return params + element.id + '/'
            }
            return params + element.id + ','
          }, '')

          params += IS_MULTIPLE

          redirect('drtp/' + params)
        }, 1500)
      }
    })
  }

  return (
    <div className="page page--restock page--restock-dmg">
      <Header />
      <Scanner onReceiveData={onScan} needToRemoveListener={false} />
      <div className="content">
        <div className="status">
          <span className="status__text">Scan Case UPC</span>
          {isScanned ? <Active /> : <ActiveGrey />}
        </div>
      </div>
    </div>
  )
}

ScanningCaseUpsPage.propTypes = {
  scannedProcessList: PropTypes.array,
}

const mapStateToProps = (state) => ({
  scannedProcessList: state.restockProcess.scannedItem,
})

const mapDispatchToProps = {
  createTaskAssignment: createTaskAssignment,
}

export default connect(mapStateToProps, mapDispatchToProps)(ScanningCaseUpsPage)
