import React from 'react'
import * as PropTypes from 'prop-types'

import './style.scss'

import ModalDefault from 'components/common/Modal/ModalDefault/index'
import CancelIcon from 'assets/svg/cancel.svg'
import CancelIconIPod from 'assets/svg/cancel--ipod.svg'
import { connect } from 'react-redux'
import { GrNotes } from 'react-icons/gr'

const ModalOrderNotes = ({ isOpen, modalInfo }) => {
  if (!isOpen) {
    return <></>
  }

  return (
    <ModalDefault isOpen={isOpen} onClose={modalInfo.onClose}>
      <div
        className={`modal modal--order-notes ${
          modalInfo.className ? modalInfo.className : ''
        }`}
      >
        <div>
          <h1 className="title">Order Notes</h1>
          <GrNotes className="notes-icon"/>
          {modalInfo.order.shipping_notes && (
            <>
              <h2 className="text--note-type">Shipping Notes</h2>
              <p className="text">{modalInfo.order.shipping_notes}</p>
            </>
          )}
          {modalInfo.order.warehouse_notes && (
            <>
              <h2 className="text--note-type">Warehouse Notes</h2>
              <p className="text">{modalInfo.order.warehouse_notes}</p>
            </>
          )}
        </div>
        <div onClick={modalInfo.onClose} className="btn--close">
          {window.innerWidth < 768 ? <CancelIconIPod /> : <CancelIcon />}
        </div>
      </div>
    </ModalDefault>
  )
}

ModalOrderNotes.propTypes = {
  isOpen: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalOrderNotes',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalOrderNotes',
  ),
})

export default connect(mapStateToProps)(ModalOrderNotes)
