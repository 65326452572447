import { takeEvery, call, put } from 'redux-saga/effects'
import { fetchOneWarehouseCaseSuccess, types } from './actions'
import { create, fetchOne, update, updateSold, remove } from 'api/warehouseCase'
import { fetchLocationsStart } from 'store/location/actions'

function* updateWarehouseCase(action) {
  const { payload } = action

  try {
    yield call(update, payload)

    if (payload.location) {
      yield put(fetchLocationsStart(payload.location))
    }
  } catch (error) {}
}

function* deleteWarehouseCase(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    yield call(remove, request)

    if (afterAction) {
      afterAction()
    }
  } catch (error) {}
}

function* createWarehouseCase(action) {
  const { payload } = action

  try {
    yield call(create, payload)

    if (payload.location) {
      yield put(fetchLocationsStart(payload.location))
    }
  } catch (error) {}
}

function* createWarehouseCaseSync(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(create, request)

    if (payload.location) {
      yield put(fetchLocationsStart(payload.location))
    }

    if (afterAction) {
      afterAction(response.data.data.item)
    }
  } catch (error) {}
}

function* fetchWarehouseCaseSync(action) {
  const { payload } = action
  const { request, afterAction, onSuccess, onFail } = payload

  try {
    const response = yield call(fetchOne, request)

    yield put(fetchOneWarehouseCaseSuccess(response.data.data.item))

    if (onSuccess) {
      onSuccess(response.data.data.item)
    }
  } catch (error) {
    if (onFail) {
      onFail()
    }
  } finally {
    if (afterAction) {
      afterAction()
    }
  }
}

function* updateWarehouseCaseSold(action) {
  const { payload } = action

  try {
    yield call(updateSold, payload)
  } catch (error) {}
}

export default function* warehouseCaseSaga() {
  yield takeEvery(types.UPDATE_WAREHOUSE_CASE, updateWarehouseCase)
  yield takeEvery(types.CREATE_WAREHOUSE_CASE, createWarehouseCase)
  yield takeEvery(types.CREATE_WAREHOUSE_CASE_SYNC, createWarehouseCaseSync)
  yield takeEvery(types.FETCH_ONE_WAREHOUSE_CASE_SYNC, fetchWarehouseCaseSync)
  yield takeEvery(types.DELETE_WAREHOUSE_CASE, deleteWarehouseCase)
  yield takeEvery(types.UPDATE_WAREHOUSE_CASE_SOLD, updateWarehouseCaseSold)
}
