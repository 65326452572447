export const buildStackTraceHtml = (stackTrace) => {
  let returnHtml = ''
  stackTrace.forEach(function (traceItem, index) {
    returnHtml += buildStackTraceLine(traceItem, index)
  })
  return returnHtml
}

const buildStackTraceLine = (traceItem, index) => {
  let traceLine = `#${index} `
  traceLine += traceItem.file ?? '[internal function]'
  traceLine += traceItem.line ? `(${traceItem.line})` : ''
  traceLine += ': '
  traceLine += traceItem.class ?? ''
  traceLine += traceItem.type ?? ''
  traceLine += traceItem.function ?? ''
  traceLine += '<br/>'
  return traceLine.replace(/\\/g, '\\\\')
}
