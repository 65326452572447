import React from 'react'
import './style.scss'

const TaskTotalIcon = (props) => {
  const numberOfTasks = props.count

  let iconClass
  switch (props.styling) {
    case 'primary':
      iconClass = 'grand-total-tasks--primary'
      break
    case 'secondary':
      iconClass = 'grand-total-tasks--secondary'
      break
    default:
      iconClass = 'grand-total-tasks'
      break
  }
  
  return numberOfTasks > 0 ? (
    <div className={iconClass}>{numberOfTasks}</div>
  ) : (
    ''
  )
}

TaskTotalIcon.propTypes = {}

export default TaskTotalIcon
