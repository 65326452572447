import React from 'react'
import PropTypes from 'prop-types'
import ModalDefault from 'components/common/Modal/ModalDefault'
import CancelIcon from 'assets/svg/cancel.svg'
import CancelIconIPod from 'assets/svg/cancel--ipod.svg'
import Button from 'components/common/Button'
import ExclamationSign from 'assets/svg/exclamation--big.svg'

import './style.scss'
import { connect } from 'react-redux'

const ModalWarning = (props) => {
  const { isOpen, modalInfo } = props

  if (!isOpen) {
    return <></>
  }

  return (
    <ModalDefault isOpen={isOpen}>
      <div
        className={`modal modal--warning ${
          modalInfo.className ? modalInfo.className : ''
        }`}
      >
        {modalInfo.modalIcon ? (
          modalInfo.modalIcon
        ) : (
          <ExclamationSign className="icon--exclamation" />
        )}
        {modalInfo.children ? (
          modalInfo.children
        ) : (
          <div className="text">{modalInfo.text}</div>
        )}
        <Button onClick={modalInfo.onAccept}>OK</Button>
        {modalInfo.onDeny ? (
          <div onClick={modalInfo.onDeny} className="btn--close">
            {window.innerWidth < 768 ? <CancelIconIPod /> : <CancelIcon />}
          </div>
        ) : (
          <></>
        )}
      </div>
    </ModalDefault>
  )
}

ModalWarning.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalWarning',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalWarning',
  ),
})

export default connect(mapStateToProps)(ModalWarning)
