import { types } from '../actions'

const INIT = {
  item: {},
  isLoading: false,
}

const containerProductReducer = (state = INIT, action) => {
  const { type, payload } = action

  switch (type) {
    case types.containerProduct.FETCH_CONTAINER_PRODUCT_SUCCESS:
      return { ...state, item: payload }
    case types.containerProduct.CREATE_CONTAINER_PRODUCT:
    case types.containerProduct.UPDATE_CONTAINER_PRODUCT:
    default:
      return state
  }
}

export default containerProductReducer
