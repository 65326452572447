import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import Header from 'components/common/Header'
import {
  fetchOneRestockProcessStart,
  updateRestockLocation,
  updateRestockProcess,
} from 'store/restockProcess/actions'
import Active from 'assets/svg/active--ipad.svg'
import ActiveGrey from 'assets/svg/active-grey--ipad.svg'
import Scanner from 'components/common/Scanner'
import { closeModal, resetModals, showModal } from 'store/modal/actions'
import { fetchOneWarehousePalletStart } from 'store/warehousePallet/actions'
import { isEmptyObject, redirect } from 'helpers/index'
import Button from 'components/common/Button'
import { updateWarehouseCase } from 'store/warehouseCase/actions'
import { RESTOCK_LOCATION_STATUSES } from 'constants/restockLocationStatus'
import { STATUS_COMPLETED } from 'constants/processStatuses'
import { RESTOCK_TASK_LIST_PAGE } from 'constants/routes'
import ExclamationSign from 'assets/svg/exclamation.svg'

const CombinedPallet = (props) => {
  const {
    match,
    warehousePallet,
    currentProcess,

    fetchOneWarehousePallet,
    resetModals,
    updateWarehouseCase,
    updateRestockLocation,
    updateRestockProcess,
    showModal,
    fetchOneRestockProcess,
    closeModal,
  } = props

  const palletId = match.params.palletId
  const processId = match.params.processId

  const [warehouseCase, setWarehouseCase] = useState(undefined)
  const [isLocationScanned, setIsLocationScanned] = useState(false)
  const [isPalletScanned, setIsPalletScanned] = useState(false)
  const [isScanned, setIsScanned] = useState(false)

  const onMount = () => {
    showModal({
      name: 'modalPending',
    })
    fetchOneWarehousePallet(palletId)
    fetchOneRestockProcess({
      id: processId,
    })
  }

  const onUpdateWarehousePallet = () => {
    if (!isEmptyObject(warehousePallet)) {
      const warehouseCasesInLocations =
        warehousePallet.warehouseCase.warehouseLocation
          .warehouse_cases_in_location
      const warehouseCase = warehouseCasesInLocations.find(
        (item) => item.product_id === warehousePallet.product_id,
      )

      setWarehouseCase(warehouseCase)
    }
  }

  const closePending = () => {
    if (!isEmptyObject(warehousePallet) || !isEmptyObject(currentProcess)) {
      closeModal('modalPending')
    }
  }

  useEffect(onMount, [])
  useEffect(onUpdateWarehousePallet, [warehousePallet])
  useEffect(closePending, [warehousePallet, currentProcess])

  if (isEmptyObject(warehousePallet) || isEmptyObject(currentProcess)) {
    return <></>
  }

  const getLocationName = () => {
    const regularLocation =
      warehousePallet.warehouseCase.warehouseLocation.regular_location
    const aisle = regularLocation.aisle_id.name
    const bay = regularLocation.bay_id.name
    const level = regularLocation.level

    return `${aisle}-${bay}-${level}`
  }

  const onPalletScanned = () => {
    updateWarehouseCase({
      id: warehouseCase.id,
      data: {
        id: warehouseCase.id,
        location_case_qty:
          warehouseCase.location_case_qty + warehousePallet.qty_cases_on_pallet,
      },
    })
  }

  const onComplete = () => {
    currentProcess.entity.restock_location.forEach((item) => {
      updateRestockLocation({
        id: item.id,
        data: {
          id: item.id,
          status_id: RESTOCK_LOCATION_STATUSES.COMPLETE,
        },
      })
    })

    updateRestockProcess({
      id: currentProcess.id,
      data: {
        id: currentProcess.id,
        status: STATUS_COMPLETED,
      },
    })

    redirect(RESTOCK_TASK_LIST_PAGE)
  }

  const onScan = (barcode) => {
    if (!isLocationScanned) {
      const upc = warehousePallet.warehouseCase.warehouseLocation.upc

      if (upc.toString() === barcode.toString()) {
        setIsScanned(true)
        setTimeout(() => {
          setIsLocationScanned(true)
          setIsScanned(false)
        }, 1500)
      } else {
        showModal({
          name: 'modalWrongLocation',
          onAccept: resetModals,
          text: getLocationName(),
        })
      }
    } else if (!isPalletScanned) {
      if (warehousePallet.pallet_upc.toString() === barcode.toString()) {
        onPalletScanned()
        setIsScanned(true)
        setTimeout(() => {
          setIsPalletScanned(true)
          setIsScanned(false)
        }, 1500)
      } else {
        showModal({
          name: 'modalWarning',
          onAccept: resetModals,
          onDeny: resetModals,
          text: 'Wrong Pallet UPC',
          className: 'modal--tasks',
          modalIcon: (
            <ExclamationSign className="icon--restock icon--exclamation" />
          ),
        })
      }
    }
  }

  const getBottomContent = () =>
    !isLocationScanned || !isPalletScanned ? (
      <div className="content__section content__section--white">
        <span className="status">
          {`Scan ${!isLocationScanned ? `Location` : `Pallet`} UPC`}
          {isScanned ? <Active /> : <ActiveGrey />}
        </span>
      </div>
    ) : (
      <div className="content__section content__section--white">
        <span className="status">
          <Button onClick={onComplete}>{'Complete'}</Button>
        </span>
      </div>
    )

  return (
    <div className="tasks-page tasks-page--start-restock">
      <Header>Combined Pallet</Header>
      <Scanner needToRemoveListener={false} onReceiveData={onScan} />
      <div className="content">
        <div className="content__section">
          <div className="dock__wrapper">
            <div className="dock">
              <span className="dock__label">LOC</span>
              <span className="dock__name">{getLocationName()}</span>
            </div>
          </div>
          <h3 className="fireworks-name">
            {warehousePallet.warehouseCase.product_description.product_name}
          </h3>
          <ul className="fireworks">
            <li className="fireworks__item">
              <span className="fireworks__name">Model</span>
              <span className="fireworks__sub-name">
                {warehousePallet.warehouseCase.product.products_model}
              </span>
            </li>
            <li className="fireworks__item">
              <span className="fireworks__name">Manf Model</span>
              <span className="fireworks__sub-name">
                {warehousePallet.warehouseCase.product.products_manf_model}
              </span>
            </li>
            <li className="fireworks__item">
              <span className="fireworks__name">Case</span>
              <span className="fireworks__sub-name">
                {warehousePallet.qty_cases_on_pallet}
              </span>
            </li>
          </ul>
        </div>
        {getBottomContent()}
      </div>
    </div>
  )
}

CombinedPallet.propTypes = {
  damageLocation: PropTypes.object,
}

const mapStateToProps = (state) => ({
  currentProcess: state.restockProcess.item,
  warehousePallet: state.warehousePalletReducer.item,
})

const mapDispatchToProps = {
  fetchOneWarehousePallet: fetchOneWarehousePalletStart,
  resetModals: resetModals,
  updateWarehouseCase: updateWarehouseCase,
  updateRestockLocation: updateRestockLocation,
  updateRestockProcess: updateRestockProcess,
  showModal: showModal,
  fetchOneRestockProcess: fetchOneRestockProcessStart,
  closeModal: closeModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(CombinedPallet)
