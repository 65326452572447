import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Header from 'components/common/Header'
import ActiveGrey from 'assets/svg/active-grey.svg'
import { redirect } from 'helpers/index'
import { resetModals, showModal, closeModal } from 'store/modal/actions'
import Scanner from 'components/common/Scanner'
import Active from 'assets/svg/active.svg'
import { CONTAINER_PALLET_PUTAWAY_PAGE } from 'constants/routes'
import {
  processUnloadPutaway,
  fetchUnloadPutawayTask,
  startPutawayPalletRelocationTask,
} from 'store/unloadProcess/actions'
import { sendEmailPlog } from 'store/emailer/actions'
import './style.scss'
import { SNACK_SUCCESS, SNACK_ERROR } from 'constants/snackbar'
import { useSnackbar } from 'notistack'
import Button from 'components/common/Button'
import { openPrintDialog } from 'store/print/actions'
import Print from 'components/common/Print'
import { PALLET_LABEL } from 'constants/print'
import ExclamationSmall from 'assets/svg/exclamation-sign.svg'
import { MODAL_PENDING } from 'components/common/Modal'

const PutawayTaskPage = (props) => {
  const {
    resetModals,
    match,
    isOpenModalWrongLocation,
    showModal,
    closeModal,
    fetchUnloadPutawayTask,
    startPutawayPalletRelocationTask,
    putawayTask,
    processUnloadPutaway,
    openPrintDialog,
    sendEmailPlog,
    user,
  } = props

  const [statusPanel, setStatusPanel] = useState({
    isLocationToScanned: false,
    isRelocateConfirmed: false,
    text: 'Scan Location UPC',
    icon: <ActiveGrey className="scan-indicator" />,
  })
  const [newLocationTitle, setNewLocationTitle] = useState('')

  const onPageLoad = () => {
    if (!putawayTask || putawayTask.id !== match.params.id) {
      showModal({ name: MODAL_PENDING })
      fetchUnloadPutawayTask(match.params.id)
    }
  }

  const { enqueueSnackbar } = useSnackbar()

  const onUpdatePutawayTask = () => {
    if (!putawayTask) {
      return
    }

    resetModals()

    if (putawayTask.has_restock || putawayTask.has_insufficient_space) {
      showRelocateModal(putawayTask, pallet.warehouse_pallet.id)
    } //This gets around the reset above, which removes the desired modal in some cases.
  }

  useEffect(onPageLoad, [])
  useEffect(onUpdatePutawayTask, [putawayTask])

  if (!putawayTask) {
    return <></>
  }

  const pallet = putawayTask.container_pallet
  const toLocation = pallet.warehouse_location
  const locationTitle = toLocation.aisle_bay_level
  const isPickToLocationType =
    toLocation.location_type.is_pick > 0 ? true : false

  const onProcessAfterAction = (data) => {
    resetModals()
    if (data.success) {
      enqueueSnackbar('Putaway Successful', SNACK_SUCCESS)
    } else {
      enqueueSnackbar('Putaway Failed', SNACK_ERROR)
    }
    redirect(CONTAINER_PALLET_PUTAWAY_PAGE)
  }

  const onComplete = () => {
    showModal({ name: MODAL_PENDING })

    processUnloadPutaway({
      request: putawayTask.id,
      afterAction: onProcessAfterAction,
    })
  }

  const onClickRelocate = (putawayTask, palletId) => {
    showRelocateModal(putawayTask, palletId)
  }

  const onUnnecessaryRelocate = (putawayTask) => {
    const product = putawayTask.container_pallet.container_product.product
    const originalLocation =
      putawayTask.container_pallet.warehouse_location.aisle_bay_level
    const restockStatusText = putawayTask.has_restock
      ? 'had a restock'
      : 'did not have a restock'
    const locationDetails = `Putaway Location ${originalLocation} ${restockStatusText}. The location had ${putawayTask.current_case_level}.`
    const palletDetails = `Pallet #${putawayTask.container_pallet.warehouse_pallet.id} had ${putawayTask.container_pallet.cases_loaded} cases on it. `
    const newLocation = 'a Storage Location'

    sendEmailPlog({
      request: {
        email_address:
          'garrowc@76fireworks.com, lombardd@76fireworks.com, markesr@76fireworks.com',
        subject: `${product.products_model} Putaway was Relocated by ${user.fullName}`,
        message: ` ${product.products_model} ${product.products_name} was Relocated from ${originalLocation} to ${newLocation} by ${user.fullName}.
        ${locationDetails} ${palletDetails}`,
        hideTrace: true,
        showDefaultSubjectPrefix: false,
      },
    })
  }

  const onConfirmRelocate = (putawayTask, palletId) => {
    startPutawayPalletRelocationTask({
      request: {
        data: putawayTask,
      },
    })
    if (!putawayTask.has_restock && !putawayTask.has_insufficient_space) {
      onUnnecessaryRelocate(putawayTask)
    } // only sends email if user does an "unnecessary" Relocation

    setStatusPanel({
      ...statusPanel,
      isRelocateConfirmed: true,
      text: 'Back out, and Print updated pallet label, etc.',
      icon: '',
    })
    setNewLocationTitle('New Location')
    closeModal('modalConfirm')
    putawayTask.has_restock = false
    putawayTask.has_insufficient_space = false

    openPrintDialog(
      {
        id: palletId,
        document: PALLET_LABEL,
        onPrintDialogClose: () => {
          fetchUnloadPutawayTask(match.params.id) // calls it again to load the data with the new loc. Maybe extra. But better to update the data, and To-location. An alternative would be to nuke the putawayTask Data.

          showModal({
            name: MODAL_PENDING,
          })

          setTimeout(() => {
            redirect(CONTAINER_PALLET_PUTAWAY_PAGE)
          }, 500)
        },
      },
      { hideCancel: true },
    )
  }
  const showRelocateModal = (putawayTask, palletId) => {
    const restockMessage = putawayTask.has_restock ? (
      <>This location has a Restock.</>
    ) : (
      ''
    )
    const spaceMessage = putawayTask.has_insufficient_space ? (
      <>
        This location is FULL: &nbsp;&nbsp; {putawayTask.current_case_level}.
        <br />
      </>
    ) : (
      ''
    )
    const relocateMessage =
      putawayTask.has_restock || putawayTask.has_insufficient_space ? (
        <>
          <p>{restockMessage}</p> <br />
          <p>{spaceMessage}</p> <br />
          <p>Press "Confirm" to Relocate.</p>
        </>
      ) : (
        <>
          <p>
            This location has room: &nbsp;&nbsp;{' '}
            {putawayTask.current_case_level}. <br /> Do you really want to
            relocate?
          </p>{' '}
          <br />
          <p>Press "Confirm" to Relocate.</p>
        </>
      )

    const hideCancelButton = putawayTask.has_restock
    showModal({
      name: 'modalConfirm',
      onAccept: () => onConfirmRelocate(putawayTask, palletId),
      onDeny: resetModals,
      children: <>{relocateMessage}</>,
      hideCancel: hideCancelButton,
    })
  }
  if (putawayTask.has_restock || putawayTask.has_insufficient_space) {
    showRelocateModal(putawayTask, pallet.warehouse_pallet.id)
  }

  const onScan = (value) => {
    if (statusPanel.isLocationToScanned) {
      return
    }

    if (value === toLocation.upc) {
      fetchUnloadPutawayTask(match.params.id)
      setStatusPanel({
        ...statusPanel,
        isLocationToScanned: true,
        icon: <Active className="scan-indicator" />,
      })
    } else {
      showModal({
        name: 'modalWarning',
        text: 'Wrong Location UPC!',
        onAccept: () => closeModal('modalWarning'),
        onDeny: () => closeModal('modalWarning'),
        className: 'modal--tasks modal--pause',
        modalIcon: <ExclamationSmall />,
      })
    }
  }

  return (
    <div className="tasks-page putaway-task-page tasks-page--start-restock">
      <Header />
      <Scanner needToRemoveListener={false} onReceiveData={onScan} />
      <div className="content">
        <div className="content__section">
          <div className="dock__new-wrapper">
            <div className="dock">
              <span className="dock__label">TO</span>
              <span className="dock__name">
                {newLocationTitle.length > 0 ? newLocationTitle : locationTitle}
              </span>
            </div>
          </div>
          {isPickToLocationType &&
          !statusPanel.isRelocateConfirmed &&
          !putawayTask.has_restock ? (
            <Button
              onDoubleClick={() =>
                onClickRelocate(putawayTask, pallet.warehouse_pallet.id)
              }
            >
              Relocate
            </Button>
          ) : (
            ''
          )}
          <ul className="fireworks-new">
            <li className="fireworks-new__item">
              <span className="fireworks__name">Model</span>
              <span className="fireworks__sub-name">
                {pallet.container_product.product.products_model}
              </span>
            </li>
            <li className="fireworks-new__item">
              <span className="fireworks__name">Manf Model</span>
              <span className="fireworks__sub-name">
                {pallet.container_product.product.products_manf_model}
              </span>
            </li>
            <li className="fireworks-new__item">
              <span className="fireworks__name">Cases</span>
              <span className="fireworks__sub-name">{pallet.cases_loaded}</span>
            </li>
          </ul>
        </div>
        <div className="content__section content__section--white">
          <span className="status">
            {!statusPanel.isLocationToScanned ? (
              <>
                {statusPanel.text} {statusPanel.icon}
              </>
            ) : (
              <Button onClick={onComplete}>Complete</Button>
            )}
          </span>
        </div>
      </div>
      <Print />
    </div>
  )
}

const mapStateToProps = (state) => ({
  putawayTask: state.unloadProcess.putawayTask,
  isOpenModalWrongLocation: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalWrongLocation',
  ),
})

const mapDispatchToProps = {
  showModal: showModal,
  closeModal: closeModal,
  resetModals: resetModals,
  startPutawayPalletRelocationTask: startPutawayPalletRelocationTask,
  fetchUnloadPutawayTask: fetchUnloadPutawayTask,
  processUnloadPutaway: processUnloadPutaway,
  openPrintDialog: openPrintDialog,
  sendEmailPlog: sendEmailPlog,
}

export default connect(mapStateToProps, mapDispatchToProps)(PutawayTaskPage)
