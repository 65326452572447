import {
  types,
  fetchOneOrderPalletStart,
  fetchOneOrderPalletSuccess,
  fetchOrderPalletsFail,
  fetchPreviousOrderPalletSuccess,
} from './actions'
import * as api from 'api/orderPallet'
import { takeLatest, call, put } from 'redux-saga/effects'

function* fetchOneOrderPallet(action) {
  const { payload } = action
  try {
    const response = yield call(api.fetchOne, payload)
    yield put(fetchOneOrderPalletSuccess(response.data.data.item))
  } catch (error) {
    yield put(fetchOrderPalletsFail())
  }
}

function* createOrderPallet(action) {
  const { payload } = action

  try {
    yield call(api.create, payload)
  } catch (error) {}
}

function* updateOrderPallet(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    yield call(api.update, request)
    if (afterAction) {
      yield afterAction(request.id)
    }
    yield put(fetchOneOrderPalletStart(request.id))
  } catch (error) {
    yield put(fetchOrderPalletsFail())
  }
}

function* createTempProductsWithQty(action) {
  const { payload } = action
  try {
    yield call(api.createTempProducts, payload)
  } catch (error) {}
}

function* fetchPreviousOrderPallet(action) {
  const { payload } = action
  try {
    const response = yield call(api.fetchPreviousOrderPallet, payload)
    yield put(fetchPreviousOrderPalletSuccess(response.data.data.pallet))
  } catch (error) {}
}

export default function* orderPalletSaga() {
  yield takeLatest(types.FETCH_ONE_ORDER_PALLET_START, fetchOneOrderPallet)
  yield takeLatest(types.UPDATE_ORDER_PALLET_START, updateOrderPallet)
  yield takeLatest(types.CREATE_ORDER_PALLET, createOrderPallet)
  yield takeLatest(
    types.CREATE_TEMP_PRODUCTS_WITH_QUANTITY,
    createTempProductsWithQty,
  )
  yield takeLatest(
    types.FETCH_PREVIOUS_ORDER_PALLET_START,
    fetchPreviousOrderPallet,
  )
}
