import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import ModalDefault from 'components/common/Modal/ModalDefault'
import Button from 'components/common/Button'
import './style.scss'
import QuantityControl from 'components/common/QuantityControl'
import { connect } from 'react-redux'

const MIN_CASES_QUANTITY = 0

const ModalPalletQty = (props) => {
  const { isOpen, modalInfo } = props

  const [quantity, setQuantity] = useState(0)

  useEffect(() => {
    setQuantity(modalInfo ? modalInfo.value : 0)
  }, [modalInfo])

  if (!isOpen) {
    return <></>
  }

  const onIncrement = () => setQuantity(quantity + 1)
  const onDecrement = () => setQuantity(quantity - 1)
  const onChange = (name, value) => setQuantity(value)
  const onClick = () => modalInfo.onConfirm(quantity)

  const quantityStyles = {
    fontSize: 75,
  }

  return (
    <ModalDefault isOpen={isOpen}>
      <div
        className={`modal modal--confirm ${
          modalInfo.className ? modalInfo.className : ''
        }`}
      >
        <div className="text text--damaged">Pallet Qty</div>
        <QuantityControl
          minValue={MIN_CASES_QUANTITY + 1}
          maxValue={modalInfo.maxValue}
          onChange={onChange}
          value={quantity}
          onDecrease={onDecrement}
          onIncrease={onIncrement}
          styles={quantityStyles}
        />
        <div className="button">
          <Button disabled={quantity === MIN_CASES_QUANTITY} onClick={onClick}>
            Confirm
          </Button>
        </div>
      </div>
    </ModalDefault>
  )
}

ModalPalletQty.propTypes = {
  isOpen: PropTypes.bool,

  modalInfo: PropTypes.object,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalPalletQty',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalPalletQty',
  ),
})

export default connect(mapStateToProps)(ModalPalletQty)
