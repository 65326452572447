import { types } from '../actions'

const INIT = {
  item: {},
  isLoading: false,
}

const productInfoReducer = (state = INIT, action) => {
  const { type, payload } = action

  switch (type) {
    case types.productInfo.FETCH_PRODUCT_INFO_START:
      return { ...state, isLoading: true }
    case types.productInfo.FETCH_PRODUCT_INFO_SUCCESS:
      return { item: payload, isLoading: false }
    case types.productInfo.FETCH_PRODUCT_INFO_FAIL:
      return INIT
    default:
      return state
  }
}

export default productInfoReducer
