import React from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, List, ListItem, ListItemText } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { resetModals, showModal } from 'store/modal/actions'

const BulkStorageList = (props) => {
  const { rows, showModal, resetModals } = props

  const confirmTaskClick = (item) => {
    const confirm = () => {
      item.action()
      resetModals()
    }

    showModal({
      name: 'modalConfirm',
      onAccept: confirm,
      onDeny: resetModals,
      className: 'modal--picking-task',
      children: (
        <div className="modal--content-centered">
          <p className="text--bold">Start Task?</p>
          <div>
            <span>
              {item.taskType}, {item.model}
            </span>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '12px 0 10px 0',
              }}
            >
              <span style={{ position: 'absolute', left: '7%' }}>
                {item.location}
              </span>
              <ArrowForwardIcon
                style={{
                  position: 'absolute',
                  left: '46%',
                }}
              />
              <span
                style={{
                  position: 'absolute',
                  left: '63%',
                }}
              >
                {item.locationTo}
              </span>
            </div>
          </div>
        </div>
      ),
    })
  }

  return (
    <List>
      {rows.map((item, index) => {
        const { details, id, location, locationTo, model, taskType } = item

        const listItemText = () => {
          return (
            <div>
              <div style={{ margin: '0 0 15px 0' }}>
                {model}
                <span
                  style={{
                    position: 'absolute',
                    right: '10px',
                  }}
                >
                  {taskType}
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '2px 0 7px 0',
                }}
              >
                <span style={{ position: 'absolute', left: '7%' }}>
                  {location}
                </span>
                <ArrowForwardIcon
                  style={{
                    position: 'absolute',
                    left: '46%',
                  }}
                />
                <span
                  style={{
                    position: 'absolute',
                    left: '63%',
                  }}
                >
                  {locationTo}
                </span>
              </div>
              <div>
                {details.length > 0 ? <span> Orders: {details}</span> : ''}
              </div>
            </div>
          )
        }

        return (
          <Card raised style={{ marginBottom: '5px' }}>
            <ListItem
              button
              key={id}
              index={index}
              onDoubleClick={() => {
                confirmTaskClick(item)
              }}
            >
              <ListItemText primary={listItemText(item)} />
            </ListItem>
          </Card>
        )
      })}
    </List>
  )
}

BulkStorageList.propTypes = {
  rows: PropTypes.array.isRequired,
  modals: PropTypes.object,
  resetModals: PropTypes.func,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  showModal: showModal,
  resetModals: resetModals,
}

export default connect(mapStateToProps, mapDispatchToProps)(BulkStorageList)
