import request, { requestWithRetry } from 'helpers'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}processes/relocate`

export const startRelocate = (payload) =>
  request({
    url: `${apiEndpoint}/start-relocate`,
    method: 'POST',
    data: payload,
  })

export const fetchRelocateTask = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}/fetch-relocate-task/${payload}`,
    method: 'GET',
  })

export const validateFromBarcode = (payload) =>
  request({
    url: `${apiEndpoint}/validate-from-barcode`,
    method: 'POST',
    data: payload,
  })

export const validateToUPC = (payload) =>
  request({
    url: `${apiEndpoint}/validate-to-upc`,
    method: 'POST',
    data: payload,
  })

export const processRelocate = (payload) =>
  request({
    url: `${apiEndpoint}/process-relocate`,
    method: 'POST',
    data: payload,
  })
