export const types = {
  CREATE_TASK_START: 'CREATE_TASK_START',
  CREATE_TASK_SUCCESS: 'CREATE_TASK_SUCCESS',
  UPDATE_TASK_START: 'UPDATE_TASK_START',
}

export const createTaskStart = (payload) => ({
  type: types.CREATE_TASK_START,
  payload,
})

export const createTaskSuccess = (payload) => ({
  type: types.CREATE_TASK_SUCCESS,
  payload,
})

export const updateTaskSuccess = (payload) => ({
  type: types.UPDATE_TASK_START,
  payload,
})
