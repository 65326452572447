import { takeEvery, takeLatest, call, put } from 'redux-saga/effects'
import {
  fetchOneRestockProcessFail,
  fetchOneRestockProcessSuccess,
  fetchRestockProcessListFail,
  fetchRestockProcessListSuccess,
  fetchBulkRestockTaskFail,
  fetchBulkRestockTaskSuccess,
  types,
} from './actions'
import {
  create,
  update,
  fetchMany,
  fetchOne,
  createLocationRestock,
  fetchBulkRestockTask,
  updateLocationRestock,
  createToLocationFromStorage,
  processStorageToStorage,
  processBulkFromTask,
  processBulkToTask,
  processRestock,
  startBulkFromTask,
  startBulkToTask,
} from 'api/restockProcess'

function* processStorageToStorageRestock(action) {
  const { payload } = action

  try {
    yield call(processStorageToStorage, payload)
  } catch (error) {}
}

function* processBulkFrom(action) {
  const { payload } = action

  try {
    yield call(processBulkFromTask, payload)
  } catch (error) {}
}

function* processBulkTo(action) {
  const { payload } = action

  try {
    yield call(processBulkToTask, payload)
  } catch (error) {}
}

function* createProcess(action) {
  const { payload } = action

  try {
    yield call(create, payload)
  } catch (error) {}
}

function* fetchOneBulkRestockTask(action) {
  const { payload } = action

  try {
    const response = yield call(fetchBulkRestockTask, payload)
    yield put(fetchBulkRestockTaskSuccess(response.data.data.item))
  } catch (error) {
    yield put(fetchBulkRestockTaskFail())
  }
}

function* createRestockProcessesToLocationFromStorage(action) {
  const { payload } = action

  try {
    yield call(createToLocationFromStorage, payload)
  } catch (error) {}
}

function* updateRestockProcess(action) {
  const { payload } = action

  try {
    yield call(update, payload)
  } catch (error) {}
}

function* startBulkStorageToTask(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(startBulkToTask, request)
    if (afterAction) {
      afterAction(response.data.data)
    }
  } catch (error) {}
}

function* startBulkStorageFromTask(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(startBulkFromTask, request)
    if (afterAction) {
      afterAction(response.data.data.id)
    }
  } catch (error) {}
}

function* fetchRestockProcessList(action) {
  const { payload } = action

  try {
    const response = yield call(fetchMany, payload)
    yield put(fetchRestockProcessListSuccess(response.data.data.items))
  } catch (error) {
    yield put(fetchRestockProcessListFail())
  }
}

function* fetchOneRestockProcess(action) {
  const { payload } = action

  try {
    const response = yield call(fetchOne, payload)
    yield put(fetchOneRestockProcessSuccess(response.data.data.item))
  } catch (error) {
    yield put(fetchOneRestockProcessFail())
  }
}

function* updateRestockLocation(action) {
  const { payload } = action
  try {
    yield call(updateLocationRestock, payload)
  } catch (error) {}
}

function* createRestockLocation(action) {
  const { payload } = action

  try {
    const responseWithEntity = yield call(createLocationRestock, payload)
    const responseWithProcess = yield call(create, {
      restock_location_id: responseWithEntity.data.data.item.id,
    })
    yield put(fetchOneRestockProcessSuccess(responseWithProcess.data.data.item))
  } catch (error) {
    yield put(fetchOneRestockProcessFail())
  }
}

function* processTheRestock(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    yield call(processRestock, request)
    if (afterAction) {
      afterAction()
    }
  } catch (error) {}
}

export default function* restockProcessSaga() {
  yield takeEvery(
    types.PROCESS_STORAGE_TO_STORAGE_START,
    processStorageToStorageRestock,
  )
  yield takeEvery(
    types.PROCESS_BULK_TO_TASK,
    processBulkTo,
  )
  yield takeEvery(
    types.PROCESS_BULK_FROM_TASK,
    processBulkFrom,
  )
  yield takeEvery(types.CREATE_RESTOCK_PROCESS, createProcess)
  yield takeEvery(
    types.CREATE_RESTOCK_PROCESSES_TO_LOCATION_FROM_STORAGE,
    createRestockProcessesToLocationFromStorage,
  )
  yield takeLatest(
    types.FETCH_RESTOCK_PROCESS_LIST_START,
    fetchRestockProcessList,
  )
  yield takeEvery(types.FETCH_ONE_RESTOCK_PROCESS_START, fetchOneRestockProcess)
  yield takeEvery(types.UPDATE_RESTOCK_PROCESS, updateRestockProcess)
  yield takeEvery(types.START_BULK_STORAGE_FROM_TASK, startBulkStorageFromTask)
  yield takeEvery(types.FETCH_BULK_RESTOCK_TASK_START, fetchOneBulkRestockTask)
  yield takeEvery(types.CREATE_RESTOCK_LOCATION, createRestockLocation)
  yield takeEvery(types.UPDATE_RESTOCK_LOCATION, updateRestockLocation)
  yield takeEvery(types.PROCESS_RESTOCK, processTheRestock)
  yield takeEvery(
    types.START_BULK_STORAGE_TO_TASK,
    startBulkStorageToTask,
  )
}
