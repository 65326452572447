import { TASK_TYPES } from './taskAssignment'
import {
  AISLE_FIELD,
  BAY_FIELD,
  DEPTH_FIELD,
  DOCK_FIELD,
  DOCK_LOCATION,
  LEVEL_FIELD,
  LOCATION_TYPE,
  REGULAR_LOCATION,
  STORAGE_TYPE,
} from 'constants/formFields'
import {
  AISLE_FILTER,
  BAY_FILTER,
  DEPTH_FILTER,
  DOCK_FILTER,
  LEVEL_FILTER,
  LOCATION_TYPE_FILTER,
  STORAGE_TYPE_FILTER,
} from 'constants/filters'

export const TASK_PROGRESS_NAME_MAPPER = {
  [TASK_TYPES.AVAILABLE_TYPE]: '',
  [TASK_TYPES.CONTAINER_UNLOAD_TYPE]: 'Unloading',
  [TASK_TYPES.PUTAWAY_TYPE]: 'Putaway',
  [TASK_TYPES.PICKING_TYPE]: 'Picking',
  [TASK_TYPES.RESTOCKING_TYPE]: 'Restocking',
}

// SORTING MAPPERS

export const MAPPER_LOCATION_LIST_SORTING = {
  [AISLE_FILTER]: `${REGULAR_LOCATION}.${AISLE_FIELD}.name`,
  [BAY_FILTER]: `${REGULAR_LOCATION}.${BAY_FIELD}.name`,
  [LEVEL_FILTER]: `${REGULAR_LOCATION}.${LEVEL_FIELD}`,
  [DEPTH_FILTER]: `${REGULAR_LOCATION}.${DEPTH_FIELD}`,
  [LOCATION_TYPE_FILTER]: `${LOCATION_TYPE}.name`,
  [STORAGE_TYPE_FILTER]: `${STORAGE_TYPE}.name`,
  [DOCK_FILTER]: `${DOCK_LOCATION}.${DOCK_FIELD}.name`,
}
