import React from 'react'
import * as PropTypes from 'prop-types'
import TableListHeader from 'components/common/TableList/TableListHeader'
import TableListRow from 'components/common/TableList/TableListRow'

import './style.scss'

const TableList = (props) => {
  const {
    rows,
    columns,
    className = '',
    events = {},
    rowBackgroundElement,
  } = props

  return (
    <div className={`list ${className}`}>
      <TableListHeader columns={columns} />

      <ul className="list__body">
        {rows.map((item) => {
          return (
            <TableListRow
              key={item.id}
              row={item}
              columns={columns}
              events={events}
              swipeable
              rowBackgroundElement={rowBackgroundElement}
            />
          )
        })}
      </ul>
    </div>
  )
}

TableList.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,

  className: PropTypes.string,

  events: PropTypes.object,

  rowBackgroundElement: PropTypes.element,
}

export default TableList
