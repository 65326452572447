import React, { useState, useEffect } from 'react'
import Header from 'components/common/Header'
import CloseButton from 'components/common/CloseButton'
import ActiveGrey from 'assets/svg/active-grey.svg'
import { startRelocate, validateFromBarcode } from 'store/relocate/actions'
import './style.scss'
import { SNACK_ERROR } from 'constants/snackbar'
import { useSnackbar } from 'notistack'
import Scanner from 'components/common/Scanner'
import { connect } from 'react-redux'
import { redirect } from 'helpers/index'
import Active from 'assets/svg/active.svg'
import { resetModals, showModal, closeModal } from 'store/modal/actions'
import { RELOCATE_TASK_PAGE } from 'constants/routes'

const RelocateProductPage = (props) => {
  const {
    showModal,
    resetModals,
    closeModal,
    startRelocate,
    validateFromBarcode,
  } = props

  const [statusPanel, setStatusPanel] = useState({
    isFromBarcodeScanned: false,
    text: 'Scan Pallet or Pick Location',
    icon: <ActiveGrey className="scan-indicator" />,
  })

  const { enqueueSnackbar } = useSnackbar()

  const onLoad = () => {
    resetModals()
  }

  useEffect(onLoad, [])

  const showScanIndicator = () => (
    <>
      <div className="status__text">
        Scan Pallet
        <br />
        or Pick Location{statusPanel.icon}
      </div>
    </>
  )

  const onStartAfterAction = (data) => {
    resetModals()
    redirect(RELOCATE_TASK_PAGE.replace(':id', data.item.id))
  }

  const onScan = (value) => {
    if (statusPanel.isFromBarcodeScanned) {
      return
    }

    showModal({
      name: 'modalPending',
    })

    validateFromBarcode({
      request: {
        data: {
          barcode: value,
        },
      },
      afterAction: onValidateAfterAction,
    })
  }

  const onValidateAfterAction = (data) => {
    resetModals()
    if (data.success) {
      setStatusPanel({
        ...statusPanel,
        isFromBarcodeScanned: true,
        icon: <Active className="scan-indicator" />,
      })
      if (data.fromInfo.is_pick) {
        showModal({
          name: 'modalPickedQty',
          minValue: 1,
          model: data.fromInfo.model,
          location: data.fromInfo.location,
          value: data.fromInfo.location_case_qty,
          onConfirm: (quantity) => beginRelocate(data, quantity),
          onDeny: denyPickedQtyModal,
          maxValue: data.fromInfo.location_case_qty,
          className: 'modal--tasks modal--damaged',
        })
        return
      } else {
        return beginRelocate(data)
      }
    }

    setTimeout(() => {
      enqueueSnackbar(data.error, SNACK_ERROR)
    }, 500)
  }

  const denyPickedQtyModal = () => {
    setStatusPanel({
      ...statusPanel,
      isProductScanned: false,
    })
    resetModals()
  }

  const beginRelocate = (data, quantity = null) => {
    closeModal('modalPickedQty')
    showModal({
      name: 'modalPending',
    })

    startRelocate({
      request: {
        data: {
          fromInfo: data.fromInfo,
          quantity: quantity ?? data.fromInfo.qty_cases_on_pallet ?? null,
        },
      },
      afterAction: onStartAfterAction,
    })
  }

  return (
    <div className="relocate-product-page">
      <Header>
        <div className="title">Relocate Product</div>
        <CloseButton />
      </Header>
      <div className="content">
        <Scanner needToRemoveListener={false} onReceiveData={onScan} />
        <div className="status">{showScanIndicator()}</div>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  showModal,
  resetModals,
  startRelocate,
  validateFromBarcode,
  closeModal,
}

export default connect(null, mapDispatchToProps)(RelocateProductPage)
