import React from 'react'
import Button from 'components/common/Button'
import { PickPallet } from 'components/common/PalletIcon'
import { Card, ListItem, ListItemText } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { MdAutorenew } from 'react-icons/md'
import { ORDER_PICK_STATUSES } from 'constants/order'
import { TASK_STATUSES } from 'constants/taskAssignment'

const PullsheetListItem = ({
  item,
  onSelectWrap,
  onSelectReprint,
  onSelectCheckStock,
}) => {
  const onClickCheckStock = () => onSelectCheckStock(item)

  const renderItem = () => {
    const { task_assignment } = item
    const topStackClass =
    task_assignment.pick_location.product.is_top_stack === 1
      ? ' item--top-stack'
      : ''
    const restockClass =
      task_assignment.pick_location.restock_required === 1
        ? ' item--restock'
        : ''
    const itemClassName =
      'task-div-padding item--status-' +
      task_assignment.status.id +
      restockClass + topStackClass

    return (
      <div className={itemClassName}>
        <div className="spaced-div">
          <span className="product-list-text__bold">
            {task_assignment.pick_location.warehouse_location.name}
            {` (${task_assignment.pick_location.location_case_qty})`}
          </span>
          <span className="product-list-text__bold">
            {task_assignment.pick_location.product.products_model}
          </span>
          <span className="product-list-text__xs">
            {task_assignment.pick_location.product.products_manf_model}
          </span>
          <span className="product-list-text right">
            Qty: {task_assignment.pick_location.location_pick_quantity}
          </span>
        </div>
        <div className="spaced-div">
          <span className="product-list-text__name">
            {task_assignment.pick_location.product.orders_products_name ? (
              <>
                <span>
                  {
                    task_assignment.pick_location.product.orders_products_name.split(
                      ' - ',
                    )[1]
                  }
                </span>
                <br />
                <span style={{ color: 'red' }}>
                  {
                    task_assignment.pick_location.product.orders_products_name.split(
                      ' - ',
                    )[0]
                  }
                </span>
              </>
            ) : (
              <span>{task_assignment.pick_location.product.products_name}</span>
            )}
          </span>
        </div>
        <div className="spaced-div">
          <span className="product-list-text__xs">
            ({task_assignment.pick_location.product.products_cases_per_layer}X
            {task_assignment.pick_location.product.products_layers_per_pallet})
          </span>
          {!!task_assignment.pick_location.product.is_top_stack && (
            <span className="product-list-text red__bold">Top Stack</span>
          )}
          <span className="product-list-text red">
            Qty Remaining:{' '}
            {task_assignment.pick_location.location_pick_quantity -
              task_assignment.pick_location.picked_quantity}
          </span>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className="spaced-div__button">{palletButton}</div>
          </Grid>
          <Grid item xs={4} className="check-stock-div">
            {checkStockButton}
          </Grid>
        </Grid>
      </div>
    )
  }

  const orderPallet = item.task_assignment.pick_location.order_pick_pallet
    ? item.task_assignment.pick_location.order_pick_pallet.order_pallet
    : null

  const palletClassName = orderPallet
    ? `pallet-status-${orderPallet.status_id}`
    : ''

  const wrapOnSelect = !orderPallet
    ? null
    : orderPallet.status_id === ORDER_PICK_STATUSES.STATUS_PICKING_PALLET
    ? onSelectWrap
    : onSelectReprint

  const isEstimatedWeight = orderPallet && !orderPallet.pallet_weight

  const palletButton = orderPallet ? (
    <PickPallet
      id={orderPallet.id}
      nbr={orderPallet.pallet_nbr}
      count={orderPallet.total_cases}
      hideIcon={true}
      weight={orderPallet.pallet_weight || orderPallet.total_calc_weight}
      className={`pallet ${palletClassName}`}
      isEstimatedWeight={isEstimatedWeight}
      onSelect={wrapOnSelect}
    />
  ) : (
    ''
  )

  const checkStockButton =
    item.task_assignment.status.id < TASK_STATUSES.FINISHED_TYPE &&
    item.task_assignment.pick_location.restock_required === 1 ? (
      <>
        <MdAutorenew className="check-stock-icon" onClick={onClickCheckStock} />
      </>
    ) : (
      ''
    )

  return (
    <Card raised style={{ marginBottom: '5px' }}>
      <ListItem>
        <ListItemText primary={renderItem()} />
      </ListItem>
    </Card>
  )
}

export default PullsheetListItem
