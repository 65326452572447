import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import '../style.scss'
import Header from 'components/common/Header'
import CloseButton from 'components/common/CloseButton'
import Active from 'assets/svg/active--ipad.svg'
import ActiveGrey from 'assets/svg/active-grey--ipad.svg'
import Scanner from 'components/common/Scanner'
import { closeModal, resetModals, showModal } from 'store/modal/actions'
import * as query from 'querystringify'
import { LOCATION_TYPE_FILTER, WAREHOUSE_FILTER } from 'constants/filters'
import { LOCATION_TYPE } from 'constants/locationTypes'
import { fetchLocationsStart } from 'store/location/actions'
import { redirect } from 'helpers/index'
import { SCAN_DAMAGE_CASE_RESOLUTION_PAGE } from 'constants/routes'

const ScanDamageLocationResolutionPage = (props) => {
  const {
    user,
    damageLocation,

    closeModal,
    fetchLocations,
    showModal,
  } = props

  const onMount = () => {
    showModal({
      name: 'modalPending',
    })

    fetchLocations(getParamsForDamageLocation())
  }

  const getParamsForDamageLocation = () =>
    query.stringify(
      {
        [WAREHOUSE_FILTER]: user.warehouseId,
        [LOCATION_TYPE_FILTER]: LOCATION_TYPE.DAMAGE_TYPE_ID,
      },
      true,
    )

  const [locationTitle, setLocationTitle] = useState('')
  const [isScanned, setIsScanned] = useState(false)

  const onUpdateRestockLocation = () => {
    if (damageLocation !== undefined) {
      const aisle = damageLocation.aisle
      const bay = damageLocation.bay
      const level = damageLocation.level

      setLocationTitle(`${aisle}-${bay}-${level}`)
    }
  }

  const closePending = () => {
    if (damageLocation !== undefined) {
      closeModal('modalPending')
    }
  }

  useEffect(onMount, [])
  useEffect(onUpdateRestockLocation, [damageLocation])
  useEffect(closePending, [damageLocation])

  if (damageLocation === undefined) {
    return <></>
  }

  const onScan = (barcode) => {
    if (barcode.toString() === getDamageLocationUPC()) {
      setIsScanned(true)
      setTimeout(() => {
        redirect(SCAN_DAMAGE_CASE_RESOLUTION_PAGE)
      }, 1500)
    }
  }

  const getBottomContent = () => {
    return (
      <div className="content__section content__section--white content__section--white-loc">
        <span className="status">
          {`Scan Location UPC`}
          {isScanned ? <Active /> : <ActiveGrey />}
        </span>
      </div>
    )
  }

  const getDamageLocationUPC = () =>
    damageLocation ? damageLocation.upc.toString() : ' '

  return (
    <div className="tasks-page tasks-page--scan-resolution tasks-page--start-restock">
      <Header>
        <div className="title">Damage Resolution</div>
        <CloseButton />
      </Header>
      <Scanner needToRemoveListener={false} onReceiveData={onScan} />
      <div className="content">
        <div className="content__section">
          <div className="dock__wrapper">
            <div className="dock">
              <span className="dock__label">LOC</span>
              <span className="dock__name">{locationTitle}</span>
            </div>
          </div>
        </div>
        {getBottomContent()}
      </div>
    </div>
  )
}

ScanDamageLocationResolutionPage.propTypes = {
  damageLocation: PropTypes.object,
}

const mapStateToProps = (state) => ({
  damageLocation: state.location.list[0],
})

const mapDispatchToProps = {
  resetModals: resetModals,
  closeModal: closeModal,
  fetchLocations: fetchLocationsStart,
  showModal: showModal,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScanDamageLocationResolutionPage)
