import React from 'react'
import LocationPalletsItem from 'components/common/PalletLocationsGroup/LocationPalletsItem'
import PropTypes from 'prop-types'
import { resetModals, showModal } from 'store/modal/actions'
import { connect } from 'react-redux'
import { createDamageCases } from 'store/product/actions'
import './style.scss'
import { fetchOneUnloadProcessStart } from 'store/unloadProcess/actions'
import Alert from '@material-ui/lab/Alert'

const PalletLocationsGroup = (props) => {
  const {
    processId,
    product,
    locationTypes,
    warehouseId,

    showModal,
    resetModals,
    createDamageCases,
    fetchOneUnloadProcessStart,
    onPalletSelect,
  } = props

  if (!product) {
    return
  }
  const { container_pallets: containerPallets } = product
  const palletLocations = []
  const normalizedLocationTypes = [...locationTypes]

  locationTypes.forEach((locationType) => {
    palletLocations[locationType.key] = []

    containerPallets.forEach((item) => {
      if (
        locationType.location_type_id ===
        item.warehouse_location.location_type.id
      ) {
        palletLocations[locationType.key].push(item)
      }
    })
  })

  containerPallets.forEach((item) => {
    if (!item.warehouse_location.location_type.id && item.cases_loaded) {
      if (!palletLocations.extra) {
        palletLocations.extra = []

        normalizedLocationTypes.push({
          id: 7,
          key: 'extra',
          label: 'Extra',
          location_type_id: null,
          color: null,
        })
      }

      palletLocations.extra.push(item)
    }
  })

  const onConfirmDamagedProduct = (data) => {
    resetModals()

    setTimeout(() => {
      showModal({ name: 'modalPending' })
      createDamageCases({
        request: {
          processId,
          data,
          warehouseId,
        },
        afterAction: () => {
          fetchOneUnloadProcessStart({ id: processId })
        },
      })
    }, 100)
  }

  const showModalAddDamagedProductsWithData = () => {
    showModal({
      name: 'modalAddDamagedProducts',
      product: product,
      onDeny: resetModals,
      onAccept: onConfirmDamagedProduct,
    })
  }

  const MissingCasesAlert = () =>
    product.cases_not_on_pallets > 0 ? (
      <Alert severity="error" className="item-error">
        {product.cases_not_on_pallets} cases not assigned to a pallet.
      </Alert>
    ) : (
      <></>
    )

  return (
    <>
      <MissingCasesAlert />
      {normalizedLocationTypes.map((locationType) => {
        switch (locationType.key) {
          case 'damage':
            return (
              <LocationPalletsItem
                onAddItem={showModalAddDamagedProductsWithData}
                pallets={palletLocations[locationType.key]}
                locationType={locationType}
                key={locationType.id}
              />
            )
          case 'extra':
            return (
              <LocationPalletsItem
                pallets={palletLocations[locationType.key]}
                locationType={locationType}
                key={locationType.id}
              />
            )
          default: {
            if (palletLocations[locationType.key].length) {
              return (
                <LocationPalletsItem
                  pallets={palletLocations[locationType.key]}
                  locationType={locationType}
                  key={locationType.id}
                  onPalletSelect={onPalletSelect}
                />
              )
            } else {
              return ''
            }
          }
        }
      })}
    </>
  )
}

PalletLocationsGroup.propTypes = {
  processId: PropTypes.number.isRequired,

  locationTypes: PropTypes.array.isRequired,

  product: PropTypes.object.isRequired,

  showModal: PropTypes.func,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {
  showModal: showModal,
  resetModals: resetModals,
  createDamageCases: createDamageCases,
  fetchOneUnloadProcessStart: fetchOneUnloadProcessStart,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PalletLocationsGroup)
