import { takeEvery, call, put } from 'redux-saga/effects'
import {
  types,
  fetchDamageProductsSuccess,
  fetchDamageProductsForInventorySuccess,
} from './actions'
import {
  fetchAll,
  fetchOneForInventory,
  update,
  remove,
  create,
} from 'api/damageProduct'

function* fetchDamageProductSync(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(fetchAll, request)
    yield put(fetchDamageProductsSuccess(response.data))

    if (afterAction) {
      yield afterAction(response.data)
    }
  } catch (error) {}
}

function* fetchDamageForInventoryProduct(action) {
  const { payload } = action

  try {
    const response = yield call(fetchOneForInventory, payload)
    yield put(fetchDamageProductsForInventorySuccess(response.data))
  } catch (error) {}
}

function* updateDamageProduct(action) {
  const { payload } = action

  try {
    yield call(update, payload)
  } catch (error) {}
}

function* deleteDamageProduct(action) {
  const { payload } = action

  try {
    yield call(remove, payload)
  } catch (error) {}
}

function* createDamageProduct(action) {
  const { payload } = action

  try {
    yield call(create, payload)
  } catch (error) {}
}

export default function* damageProductSaga() {
  yield takeEvery(types.FETCH_DAMAGE_PRODUCTS_START, fetchDamageProductSync)
  yield takeEvery(
    types.FETCH_DAMAGE_PRODUCTS_FOR_INVENTORY_START,
    fetchDamageForInventoryProduct,
  )
  yield takeEvery(types.UPDATE_DAMAGE_PRODUCTS_START, updateDamageProduct)
  yield takeEvery(types.DELETE_DAMAGE_PRODUCTS_START, deleteDamageProduct)
  yield takeEvery(types.CREATE_DAMAGE_PRODUCTS_START, createDamageProduct)
}
