import React, { useEffect, useState } from 'react'
import PalletListItem from './PalletListItem'
import Header from 'components/common/Header'
import '../../style.scss'
import './style.scss'
import {
  fetchOneOrderPowerPickStart,
  resetOrders,
  startLoadingTask,
} from 'store/order/actions'
import { connect } from 'react-redux'
import {
  showPendingModal,
  resetModals,
  showModal,
  closePendingModal,
} from 'store/modal/actions'
import { redirect } from 'helpers/index'
import { ORDERS_TO_LOAD_LIST_PAGE } from 'constants/routes'
import { GrNotes } from 'react-icons/gr'
import { List } from '@material-ui/core'
import Scanner from 'components/common/Scanner'
import { PALLET_LOADING_PAGE } from 'constants/routes'
import { SNACK_ERROR } from 'constants/snackbar'
import { useSnackbar } from 'notistack'

const PalletsToLoadListPage = (props) => {
  const {
    orderProcess = null,
    match,
    fetchOrderProcess,
    resetModals,
    showModal,
    showPendingModal,
    resetOrder,
    closePendingModal,
    startLoadingTask,
  } = props

  const processId = match.params.id

  const onLeave = () => {
    resetOrder()
  }

  const onChangeOrder = () => {
    if (orderProcess) {
      closePendingModal()
    }
  }

  const onClose = () => redirect(ORDERS_TO_LOAD_LIST_PAGE)

  const onLoad = () => {
    setTimeout(() => {
      //add a slight delay to prevent modal collision
      showPendingModal()
      fetchOrderProcess({ id: processId })
    }, 100)

    return onLeave
  }

  const showModalOrderNotes = () => {
    showModal({
      name: 'modalOrderNotes',
      onClose: resetModals,
      order: orderProcess.entity.order,
    })
  }

  const { enqueueSnackbar } = useSnackbar()

  useEffect(onLoad, [])
  useEffect(onChangeOrder, [orderProcess])

  const orders_id = orderProcess ? orderProcess.entity.order.orders_id : ''
  const carrier_name = orderProcess ? orderProcess.entity.carrier.name : ''

  const total_location_load_quantity = orderProcess
    ? parseInt(orderProcess.entity.order.total_location_load_quantity)
    : ''
  const total_loaded_quantity = orderProcess
    ? parseInt(orderProcess.entity.order.total_loaded_quantity)
    : ''
  const palletStatus = orderProcess
    ? `${total_loaded_quantity}/${total_location_load_quantity} Loaded`
    : ''
  const isCompletelyLoaded =
    total_location_load_quantity === total_loaded_quantity

  const onStartAfterAction = (data) => {
    closePendingModal()
    if (data.success) {
      redirect(PALLET_LOADING_PAGE.replace(':id', data.item.task_assignment_id))
    } else {
      //pallet not found or already loaded
      setTimeout(() => {
        enqueueSnackbar(data.error, SNACK_ERROR)
      }, 100)
    }
  }

  const onScan = (value) => {
    showPendingModal()

    startLoadingTask({
      request: {
        data: {
          order_pallet_id: value,
        },
      },
      afterAction: onStartAfterAction,
    })
  }

  return (
    <div className="tasks-page pallets-to-load-list-page">
      <Header>
        {orderProcess &&
          (orderProcess.entity.order.shipping_notes ||
            orderProcess.entity.order.warehouse_notes) && (
            <GrNotes
              className="order-notes-icon-ptolp"
              onClick={showModalOrderNotes}
            />
          )}
        <div className="title">
          Order {orders_id} Pallets
          <br />
          {palletStatus}
          <br />
          {carrier_name}
        </div>
        {!isCompletelyLoaded ? (
          <div className="closeButton" onClick={(e) => onClose()}>
            X
          </div>
        ) : (
          ''
        )}
      </Header>
      <div className="content">
        {orderProcess ? (
          <>
            <Scanner needToRemoveListener={false} onReceiveData={onScan} />
            <List>
              {orderProcess.loadingTasks.map((item) => (
                <PalletListItem key={item.id} item={item}/>
              ))}
            </List>
          </>
        ) : (
          <div className="no-tasks">
            <div className="no-tasks__text"></div>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  orderProcess: state.order.powerPickOrder,
})

const mapDispatchToProps = {
  fetchOrderProcess: fetchOneOrderPowerPickStart,
  resetOrder: resetOrders,
  resetModals,
  closePendingModal,
  showModal,
  startLoadingTask,
  showPendingModal,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PalletsToLoadListPage)
