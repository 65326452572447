import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import ModalDefault from 'components/common/Modal/ModalDefault'
import { connect } from 'react-redux'

const ModalSuccessReset = ({ isOpen, text }) => {
  const defaultStyles = {
    overlay: {
      backgroundColor: '#343b41',
      zIndex: 1000,
    },
  }
  // TODO: need to rework with portal

  return (
    <ModalDefault style={defaultStyles} isOpen={isOpen}>
      <div className="modal--reset-pin">
        <div className="check-mark" />
        <div className="text">{text}</div>
      </div>
    </ModalDefault>
  )
}

ModalSuccessReset.propTypes = {
  isOpen: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalSuccessReset',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalSuccessReset',
  ),
})

export default connect(mapStateToProps)(ModalSuccessReset)
