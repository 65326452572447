import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import '../style.scss'
import Header from 'components/common/Header'
import CloseButton from 'components/common/CloseButton'
import Active from 'assets/svg/active--ipad.svg'
import ActiveGrey from 'assets/svg/active-grey--ipad.svg'
import Scanner from 'components/common/Scanner'
import { closeModal, resetModals, showModal } from 'store/modal/actions'
import * as query from 'querystringify'
import { fetchDamageProductsSyncStart } from 'store/damageProduct/actions'
import { redirect } from 'helpers/index'
import { DAMAGE_RESOLUTION_PAGE } from 'constants/routes'

const ScanDamageCaseResolutionPage = (props) => {
  const {
    damageProducts,

    closeModal,
    showModal,
    fetchDamageProducts,
  } = props

  const onMount = () => {
    showModal({
      name: 'modalPending',
    })

    fetchDamageProducts({
      request: query.stringify({ resolution_id: null }, true),
      afterAction: () => {
        closeModal('modalPending')
      },
    })
  }

  const [isScanned, setIsScanned] = useState(false)

  useEffect(onMount, [])

  const onScan = (barcode) => {
    if (!damageProducts.length) {
      return
    }

    const damageProduct = damageProducts.find(
      (item) => item.product_case.case_upc.toString() === barcode.toString(),
    )

    if (damageProduct) {
      setIsScanned(true)
      setTimeout(() => {
        redirect(DAMAGE_RESOLUTION_PAGE.replace(':id', damageProduct.id))
      }, 1500)
    }
  }

  const getBottomContent = () => {
    return (
      <div className="content__section content__section--white content__section--white-case">
        <span className="status">
          {`Scan Case UPC`}
          {isScanned ? <Active /> : <ActiveGrey />}
        </span>
      </div>
    )
  }

  return (
    <div className="tasks-page tasks-page--start-restock tasks-page--scan-resolution">
      <Header>
        <div className="title">Damage Resolution</div>
        <CloseButton />
      </Header>
      <Scanner needToRemoveListener={false} onReceiveData={onScan} />
      <div className="content">{getBottomContent()}</div>
    </div>
  )
}

ScanDamageCaseResolutionPage.propTypes = {
  damageLocation: PropTypes.object,
}

const mapStateToProps = (state) => ({
  damageProducts: state.damageProduct.items,
})

const mapDispatchToProps = {
  resetModals: resetModals,
  closeModal: closeModal,
  showModal: showModal,
  fetchDamageProducts: fetchDamageProductsSyncStart,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScanDamageCaseResolutionPage)
