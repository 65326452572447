import React, { useState } from 'react'
import '../style.scss'
import Header from 'components/common/Header'
import CloseButton from 'components/common/CloseButton'
import Scanner from 'components/common/Scanner'
import { connect } from 'react-redux'
import { redirect } from 'helpers/index'
import Active from 'assets/svg/active--ipad.svg'
import ActiveGrey from 'assets/svg/active-grey--ipad.svg'
import * as query from 'querystringify'
import { fetchOneLocationByStart } from 'store/location/actions'
import { INVENTORY_TOOL_SCAN_CASE_PAGE } from 'constants/routes'
import { closeModal, resetModals, showModal } from 'store/modal/actions'
import ExclamationSmall from 'assets/svg/exclamation-sign.svg'
import * as PropTypes from 'prop-types'

const InventoryToolScanLocationPage = (props) => {
  const { showModal, closeModal, resetModals, fetchLocations } = props

  const [isScanned, setIsScanned] = useState(false)

  const getLocationParamsForFetch = (upc) =>
    query.stringify(
      {
        upc: upc,
      },
      true,
    )

  const onScan = (barcode) => {
    showModal({
      name: 'modalPending',
    })

    const onSuccess = (location) => {
      setIsScanned(true)
      setTimeout(() => {
        redirect(INVENTORY_TOOL_SCAN_CASE_PAGE.replace(':id', location.id))
      }, 1500)
    }

    const afterAction = () => closeModal('modalPending')

    const showModalWrongLocationUpc = () => {
      showModal({
        name: 'modalWarning',
        text: 'Wrong Location UPC!',
        onAccept: resetModals,
        onDeny: resetModals,
        className: 'modal--tasks modal--pause',
        modalIcon: <ExclamationSmall />,
      })
    }

    fetchLocations({
      onSuccess: onSuccess,
      afterAction: afterAction,
      onFail: showModalWrongLocationUpc,
      request: getLocationParamsForFetch(barcode),
    })
  }

  return (
    <div className="page page--restock page--qa-custom page--inventory-scan">
      <Header>
        <div className="title">Inventory Tool</div>
        <CloseButton />
      </Header>
      <div className="content">
        <Scanner onReceiveData={onScan} needToRemoveListener={false} />
        <div className="content__section--white">
          <span className="status">
            Scan Location UPC
            {isScanned ? <Active /> : <ActiveGrey />}
          </span>
        </div>
      </div>
    </div>
  )
}

InventoryToolScanLocationPage.propTypes = {
  fetchLocations: PropTypes.func,
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  resetModals: PropTypes.func,
}

const mapDispatchToProps = {
  fetchLocations: fetchOneLocationByStart,
  showModal: showModal,
  closeModal: closeModal,
  resetModals: resetModals,
}

export default connect(null, mapDispatchToProps)(InventoryToolScanLocationPage)
