import React, { useState, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import TextInput from 'components/common/TextInput'
import REGEX from 'constants/regex'
import { isEqualObjects } from 'helpers/index'
import CheckIcon from 'assets/svg/check-icon.svg'

const MeasurementItem = ({ item, index, onCheck }) => {
  const [data, setData] = useState({ ...item })

  useEffect(() => {
    setData(item)
  }, [item])

  const onChange = (name, value) => {
    const newParams = { ...data.params, ...{ [name]: Number(value) } }

    setData({
      ...data,
      params: newParams,
      is_different: !isEqualObjects(newParams, item.params),
      is_addition: false,
    })
  }

  const isFieldDisabled = !item.is_active && !item.is_checked

  return (
    <div
      className={`modal__item${
        item.is_addition ? ' modal__item--addition' : ''
      }${item.is_active ? ' modal__item--active' : ''}`}
    >
      <div className={`badge ${item.is_addition ? 'invisible' : ''}`.trim()}>
        Case {index}
      </div>
      <div className="modal__data">
        <span className="text text--bold">L</span>
        <TextInput
          pattern={REGEX.IS_FLOAT}
          onChange={onChange}
          name="case_length"
          value={data.params.case_length}
          disabled={isFieldDisabled}
          autoScale
        />
      </div>
      <div className="modal__data">
        <span className="text text--bold">W</span>
        <TextInput
          pattern={REGEX.IS_FLOAT}
          onChange={onChange}
          name="case_width"
          value={data.params.case_width}
          disabled={isFieldDisabled}
          autoScale
        />
      </div>
      <div className="modal__data">
        <span className="text text--bold">H</span>
        <TextInput
          pattern={REGEX.IS_FLOAT}
          onChange={onChange}
          name="case_height"
          value={data.params.case_height}
          disabled={isFieldDisabled}
          autoScale
        />
      </div>
      <div className="modal__data">
        <span className="text text--bold">WT</span>
        <TextInput
          pattern={REGEX.IS_FLOAT}
          onChange={onChange}
          name="case_weight"
          value={data.params.case_weight}
          disabled={isFieldDisabled}
          autoScale
        />
      </div>
      <CheckIcon
        onClick={() => onCheck({ ...data, is_checked: true, is_active: false })}
        className={item.is_active ? '' : 'invisible'}
      />
    </div>
  )
}

MeasurementItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    index: PropTypes.number,

    params: PropTypes.object,

    is_filled: PropTypes.bool,
    is_different: PropTypes.bool,
    is_active: PropTypes.bool,
    is_addition: PropTypes.bool,
  }),

  onCheck: PropTypes.func,
}

export default MeasurementItem
