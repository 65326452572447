import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import ModalDefault from 'components/common/Modal/ModalDefault'
import Button from 'components/common/Button'
import './style.scss'
import QuantityControl from 'components/common/QuantityControl'
import CancelIcon from 'assets/svg/cancel.svg'
import CancelIconIPod from 'assets/svg/cancel--ipod.svg'
import { connect } from 'react-redux'

import { openPrintDialog } from 'store/print/actions'
// import Print from 'components/common/Print'
// import { PALLET_LABEL } from 'constants/print'

const MIN_CASES_QUANTITY = 1

const ModalQaCaseQty = (props) => {
  const { isOpen, modalInfo, openPrintDialog } = props

  const [quantity, setQuantity] = useState(modalInfo ? modalInfo.value : 0)

  useEffect(() => {
    setQuantity(modalInfo ? modalInfo.value : 0)
  }, [modalInfo])

  if (!isOpen) {
    return <></>
  }

  const onIncrement = () => setQuantity(quantity + 1)
  const onDecrement = () => setQuantity(quantity - 1)
  const onChange = (name, value) => setQuantity(value)
  const onClick = () => modalInfo.onConfirm(quantity)
  const onClose = () => {
    setQuantity(modalInfo.value)
    modalInfo.onDeny()
  }

  const quantityStyles = {
    fontSize: 75,
    btnSize: 60,
  }

  const getButton = (onPress = () => {}) => {
    return (
      <>
        <Button
          disabled={quantity === MIN_CASES_QUANTITY - 1}
          onClick={onPress}
        >
          {modalInfo.isLastPallet && modalInfo.maxValue === quantity
            ? 'Complete'
            : 'Print Label'}
        </Button>
        <div>&nbsp;</div>
      </>
    )
  }

  const onPrintPalletLabel = () => {
    //TODO QA Hold functionality in PWA seems to be in complete.
    // openPrintDialog({
    //   id: id,
    //   document: PALLET_LABEL,
    //   onPrintDialogClose: () => {},
    // })
  }

  const PrintLabelButton = () => (
    <>
      <Button
        disabled={quantity === MIN_CASES_QUANTITY - 1}
        onClick={onPrintPalletLabel}
      >
        {modalInfo.isLastPallet && modalInfo.maxValue === quantity
          ? 'Complete'
          : 'Print Label'}
      </Button>
      <div>&nbsp;</div>
    </>
  )

  return (
    <ModalDefault isOpen={isOpen}>
      <div
        className={`modal modal--confirm modal--qa ${
          modalInfo.className ? modalInfo.className : ''
        }`}
      >
        <div className="title">QA Case Qty</div>
        <QuantityControl
          minValue={MIN_CASES_QUANTITY}
          maxValue={modalInfo.maxValue}
          onChange={onChange}
          value={quantity}
          onDecrease={onDecrement}
          onIncrease={onIncrement}
          styles={quantityStyles}
          label={`/${modalInfo.maxValue}`}
        />
        {modalInfo.maxValue === quantity ? (
          getButton(onClick)
        ) : (
          <PrintLabelButton />
        )}
        <div onClick={onClose} className="btn--close">
          {window.innerWidth < 768 ? <CancelIconIPod /> : <CancelIcon />}
        </div>
      </div>
    </ModalDefault>
  )
}

ModalQaCaseQty.propTypes = {
  isOpen: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalQaCaseQty',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalQaCaseQty',
  ),
})

const mapDispatchToProps = {
  openPrintDialog: openPrintDialog,
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalQaCaseQty)
