import { MANAGER_ROLES, ROLES } from 'constants/roles'

const SPARK_PHOTO_API =
  'https://d29j2csl3b3dix.cloudfront.net/_store/images/staff/100_'
const CUSTOM_PHOTO_API = 'https://api.dicebear.com/7.x/initials/svg?seed='

class User {
  constructor(data) {
    // INT
    this.id = data.staffLogin.staff_id
    this.warehouseStaffId = data.warehouseStaff.id
    this.warehouseId = data.warehouseStaff.warehouse_id
    this.isRelocate = data.warehouseStaff.is_relocate

    // STRING
    this.email = data.staffLogin.email
    this.username = data.staffLogin.username
    this.name = data.staffLogin.name.split(' ')[0]
    this.surname = data.staffLogin.name.split(' ')[1]
    this.fullName = `${this.name} ${this.surname}`
    this.photo = this.preparePhoto(data.staffLogin.photo_url)
    this.token = data.token

    // ARRAYS
    this.taskAssignments = data.taskAssignments || []
    this.roles = data.roles

    // BOOL
    this.isAuthorized = data.isAuthorized

    // Not always filled and optional fields
    this.restockTotal = data.restockTotal
    this.taskAssignment = data.taskAssignment
    this.is_checked = data.is_checked
  }

  isManager = () => !!this.roles.find((role) => MANAGER_ROLES.includes(role.id))
  isRestocker = () => !!this.roles.find((role) => ROLES.RESTOCKER === role.id)

  preparePhoto = (photoName) =>
    photoName
      ? `${SPARK_PHOTO_API}${photoName}`
      : `${CUSTOM_PHOTO_API}${this.fullName}`
}

export default User
