import React from 'react'
import LoginPage from 'components/features/Auth/LoginPage'
import { Switch, Route, Redirect } from 'react-router-dom'
import * as ROUTES from '../constants/routes'
import MainPage from 'components/features/MainPage'
import PrepareUnloadProcessListPage from 'components/features/UnloadProcess/PrepareUnloadProcessListPage'
import UnloadContainerProductListPage from 'components/features/UnloadProcess/UnloadContainerProductListPage'
import TaskListPage from 'components/features/Task/TaskListPage'
import PowerPickingListPage from 'components/features/Task/PowerPicking/PowerPickingListPage'
import TaskPickingPage from 'components/features/Task/TaskPage/PickingTaskPage'
import PowerPickingPage from 'components/features/Task/PowerPicking/PowerPickingPage'
import StartPickingPage from 'components/features/Task/TaskPage/StartPickingPage'
import StartPowerPickingPage from 'components/features/Task/PowerPicking/StartPowerPickingPage'
import PowerPickingPullsheetPage from 'components/features/Task/PowerPicking/PowerPickingPullsheetPage'
import RestockTaskListPage from 'components/features/Task/RestockTaskListPage'
import BulkStorageTaskList from 'components/features/Task/BulkStorageTaskList'
import StartRestockPage from 'components/features/Task/TaskPage/StartRestockPage'
import StartBulkStoragePage from 'components/features/Task/TaskPage/StartBulkStoragePage'
import BulkRestockToStoragePage from 'components/features/Task/RestockTaskListPage/BulkRestockToStoragePage'
import DamageRestockTaskPage from 'components/features/Task/RestockTaskListPage/Damage/RestockTaskPage'
import ScanningDamageCasePage from 'components/features/Task/RestockTaskListPage/Damage/ScanningCasePage'
import ScanningCaseUpsPage from 'components/features/Task/RestockTaskListPage/Damage/ScanCaseUpcPage'
import ScanQuarantineLocationPage from 'components/features/QAHold/ScanQuarantineLocationPage'
import ContainerListPage from 'components/features/QAHold/ContainerListPage'
import ScanPalletUpcPage from 'components/features/QAHold/ScanPalletUpcPage'
import PartialPalletMoveToWrap from 'components/features/Task/RestockTaskListPage/Combine/MoveToWrap'
import CombinedPallet from 'components/features/Task/RestockTaskListPage/Combine/CombinedPallet'
import RestockQaHoldPage from 'components/features/Task/RestockTaskListPage/QaHold/RestockQAHold'
import ScanDamageLocationResolutionPage from 'components/features/Resolution/Damage/ScanLocation'
import ScanDamageCaseResolutionPage from 'components/features/Resolution/Damage/ScanCase'
import DamageResolutionPage from 'components/features/Resolution/Damage/Resolution'
import PickingWrapPalletListPage from 'components/features/Task/PickingWrapPalletList'
import PowerPickingWrapPalletListPage from 'components/features/Task/PowerPicking/PowerPickingWrapPalletList'
import MoveToPickUpLanePage from 'components/features/OrderPickedPallet/MoveToPickUpLane'
import PowerPickingMoveToLanePage from 'components/features/Task/PowerPicking/OrderPickedPallet/PowerPickingMoveToLanePage'
import NotFoundPage from 'components/common/NotFoundPage'
import PickToPickRestockPage from 'components/features/Task/RestockTaskListPage/PickToPick/PickToPickRestockPage'
import RestockStorageToStoragePage from 'components/features/Task/RestockTaskListPage/StorageToStorage/RestockStorageToStorage'
import RestockSmallParcelPage from 'components/features/Task/RestockTaskListPage/SmallParcel/RestockSmallParcelPage'
import InventoryToolCasePage from 'components/features/InventoryTool/InventoryToolCasePage'
import InventoryToolScanLocationPage from 'components/features/InventoryTool/ScanLocation'
import InventoryToolPage from 'components/features/InventoryTool/Inventory'
import ContainerPalletPutawayPage from 'components/features/ContainerPalletPutaway/ContainerPalletPutawayPage'
import InventoryPage from 'components/features/Inventory/InventoryPage'
import PutawayTaskPage from 'components/features/Task/Putaway/PutawayTaskPage'
import RelocateProductPage from 'components/features/RelocateProduct/RelocateProductPage'
import RelocateTaskPage from '../components/features/Task/RelocateProduct/RelocateTaskPage'
import OrdersToLoadListPage from '../components/features/Task/LoadOrders/OrdersToLoadListPage'
import PalletsToLoadListPage from '../components/features/Task/LoadOrders/PalletsToLoadListPage'
import PalletLoadingPage from '../components/features/Task/LoadOrders/PalletLoadingPage'
import LoadingPage from '../components/features/Task/LoadOrders/LoadingPage'
import ScanLanePage from '../components/features/Task/PowerPicking/OrderPickedPallet/ScanLanePage'

const routeList = [
  {
    Component: MainPage,
    path: ROUTES.HOME_PAGE,
  },
  {
    Component: PrepareUnloadProcessListPage,
    path: ROUTES.PREPARE_UNLOAD_PROCESS_LIST_PAGE,
  },
  {
    Component: UnloadContainerProductListPage,
    path: ROUTES.UNLOAD_CONTAINER_PRODUCT_LIST_PAGE,
  },
  {
    Component: TaskListPage,
    path: ROUTES.TASK_LIST_PAGE,
  },
  {
    Component: PowerPickingListPage,
    path: ROUTES.POWER_PICKING_LIST_PAGE,
  },
  {
    Component: TaskPickingPage,
    path: ROUTES.TASK_PICKING_PAGE,
  },
  {
    Component: PowerPickingPage,
    path: ROUTES.POWER_PICKING_PAGE,
  },
  {
    Component: StartPickingPage,
    path: ROUTES.START_PICKING_PAGE,
  },
  {
    Component: StartPowerPickingPage,
    path: ROUTES.START_POWER_PICKING_PAGE,
  },
  {
    Component: PowerPickingPullsheetPage,
    path: ROUTES.POWER_PICKING_PULLSHEET_PAGE,
  },
  {
    Component: RestockTaskListPage,
    path: ROUTES.RESTOCK_TASK_LIST_PAGE,
  },
  {
    Component: StartRestockPage,
    path: ROUTES.START_RESTOCK_PAGE,
  },
  {
    Component: OrdersToLoadListPage,
    path: ROUTES.ORDERS_TO_LOAD_LIST_PAGE,
  },
  {
    Component: PalletsToLoadListPage,
    path: ROUTES.PALLETS_TO_LOAD_LIST_PAGE,
  },
  {
    Component: PalletLoadingPage,
    path: ROUTES.PALLET_LOADING_PAGE,
  },
  {
    Component: LoadingPage,
    path: ROUTES.LOADING_PAGE,
  },
  {
    Component: StartBulkStoragePage,
    path: ROUTES.START_BULK_STORAGE_PAGE,
  },
  {
    Component: BulkRestockToStoragePage,
    path: ROUTES.BULK_RESTOCK_TO_STORAGE_PAGE,
  },
  {
    Component: BulkStorageTaskList,
    path: ROUTES.BULK_STORAGE_TASK_LIST,
  },
  {
    Component: PutawayTaskPage,
    path: ROUTES.PUTAWAY_TASK_PAGE,
  },
  {
    Component: ContainerPalletPutawayPage,
    path: ROUTES.CONTAINER_PALLET_PUTAWAY_PAGE,
  },
  {
    Component: InventoryPage,
    path: ROUTES.INVENTORY_PAGE,
  },
  {
    Component: DamageRestockTaskPage,
    path: ROUTES.DAMAGED_RESTOCK_TASK_PAGE,
  },
  {
    Component: ScanningDamageCasePage,
    path: ROUTES.SCANNING_DAMAGE_CASE_PAGE,
  },
  {
    Component: ScanningCaseUpsPage,
    path: ROUTES.SCANNING_CASE_UPS_PAGE,
  },
  {
    Component: ScanQuarantineLocationPage,
    path: ROUTES.SCAN_QUARANTINE_LOCATION_PAGE,
  },
  {
    Component: ContainerListPage,
    path: ROUTES.CONTAINER_LIST_PAGE,
  },
  {
    Component: ScanPalletUpcPage,
    path: ROUTES.SCAN_PALLET_UPC_PAGE,
  },
  {
    Component: PartialPalletMoveToWrap,
    path: ROUTES.PARTIAL_PALLET_MOVE_TO_WRAP_PAGE,
  },
  {
    Component: CombinedPallet,
    path: ROUTES.COMBINE_PALLET_PAGE,
  },
  {
    Component: RestockQaHoldPage,
    path: ROUTES.RESTOCK_QA_HOLD_PAGE,
  },
  {
    Component: ScanDamageLocationResolutionPage,
    path: ROUTES.SCAN_DAMAGE_LOCATION_RESOLUTION_PAGE,
  },
  {
    Component: ScanDamageCaseResolutionPage,
    path: ROUTES.SCAN_DAMAGE_CASE_RESOLUTION_PAGE,
  },
  {
    Component: DamageResolutionPage,
    path: ROUTES.DAMAGE_RESOLUTION_PAGE,
  },
  {
    Component: PickingWrapPalletListPage,
    path: ROUTES.PICKING_WRAP_PALLET_PAGE,
  },
  {
    Component: PowerPickingWrapPalletListPage,
    path: ROUTES.POWER_PICKING_WRAP_PALLET_PAGE,
  },
  {
    Component: MoveToPickUpLanePage,
    path: ROUTES.MOVE_TO_LANE_PAGE,
  },
  {
    Component: PowerPickingMoveToLanePage,
    path: ROUTES.POWER_PICKING_MOVE_TO_LANE_PAGE,
  },
  {
    Component: ScanLanePage,
    path: ROUTES.SCAN_LANE_PAGE,
  },
  {
    Component: RestockStorageToStoragePage,
    path: ROUTES.RESTOCK_STORAGE_TO_STORAGE_PAGE,
  },
  {
    Component: PickToPickRestockPage,
    path: ROUTES.PICK_TO_PICK_RESTOCK_TASK_PAGE,
  },
  {
    Component: RestockSmallParcelPage,
    path: ROUTES.RESTOCK_SMALL_PARCEL_PAGE,
  },
  {
    Component: InventoryToolScanLocationPage,
    path: ROUTES.INVENTORY_TOOL_SCAN_LOCATION_PAGE,
  },
  {
    Component: InventoryToolCasePage,
    path: ROUTES.INVENTORY_TOOL_SCAN_CASE_PAGE,
  },
  {
    Component: InventoryToolPage,
    path: ROUTES.INVENTORY_TOOL_PAGE,
  },
  {
    Component: RelocateProductPage,
    path: ROUTES.RELOCATE_PRODUCT_PAGE,
  },
  {
    Component: RelocateTaskPage,
    path: ROUTES.RELOCATE_TASK_PAGE,
  },
  {
    Component: NotFoundPage,
    path: '*',
  },
]

export const useRouter = (user, isAuthenticated) => (
  <>
    <Switch>
      {isAuthenticated ? (
        routeList.map(({ Component, path, exact = true }, idx) => (
          <Route
            key={idx}
            exact={exact}
            path={path}
            render={(props) => <Component {...props} user={user} />}
          />
        ))
      ) : (
        <>
          <Route exact path={ROUTES.LOGIN_PAGE} component={LoginPage} />
          <Route path="*">
            <Redirect to={ROUTES.LOGIN_PAGE} />
          </Route>
        </>
      )}
    </Switch>
  </>
)
