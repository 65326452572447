export const types = {
  FETCH_ORDERS_PALLET_LIST_START: 'FETCH_ORDERS_PALLET_LIST_START',
  FETCH_ORDERS_PALLET_LIST_SUCCESS: 'FETCH_ORDERS_PALLET_LIST_SUCCESS',

  FETCH_ONE_ORDER_PALLET_START: 'FETCH_ONE_ORDER_PALLET_START',
  FETCH_ONE_ORDER_PALLET_SUCCESS: 'FETCH_ONE_ORDER_PALLET_SUCCESS',

  UPDATE_ORDER_PALLET_START: 'UPDATE_ORDER_PALLET_START',

  CREATE_ORDER_PALLET: 'CREATE_ORDER_PALLET',

  FETCH_ORDERS_PALLET_FAIL: 'FETCH_ORDERS_PALLET_FAIL',

  CREATE_TEMP_PRODUCTS_WITH_QUANTITY: 'CREATE_TEMP_PRODUCTS_WITH_QUANTITY',
  SET_POWER_PICK: 'SET_POWER_PICK',

  FETCH_PREVIOUS_ORDER_PALLET_START: 'FETCH_PREVIOUS_ORDER_PALLET_START',
  FETCH_PREVIOUS_ORDER_PALLET_SUCCESS: 'FETCH_PREVIOUS_ORDER_PALLET_SUCCESS',
}

export const fetchOrderPalletListStart = (payload) => ({
  type: types.FETCH_ORDERS_PALLET_LIST_START,
  payload,
})

export const fetchOrderPalletListSuccess = (payload) => ({
  type: types.FETCH_ORDERS_PALLET_LIST_SUCCESS,
  payload,
})

export const fetchOneOrderPalletStart = (payload) => ({
  type: types.FETCH_ONE_ORDER_PALLET_START,
  payload,
})

export const fetchOneOrderPalletSuccess = (payload) => ({
  type: types.FETCH_ONE_ORDER_PALLET_SUCCESS,
  payload,
})

export const createOrderPallet = (payload) => ({
  type: types.CREATE_ORDER_PALLET,
  payload,
})

export const updateOrderPalletStart = (payload) => ({
  type: types.UPDATE_ORDER_PALLET_START,
  payload,
})

export const fetchOrderPalletsFail = (payload) => ({
  type: types.FETCH_ORDERS_PALLET_FAIL,
  payload,
})

export const createTempProductsWithQuantity = (payload) => {
  return {
    type: types.CREATE_TEMP_PRODUCTS_WITH_QUANTITY,
    payload,
  }
}

export const setPowerPick = (payload) => ({
  type: types.SET_POWER_PICK,
  payload,
})

export const fetchPreviousOrderPalletStart = (payload) => ({
  type: types.FETCH_PREVIOUS_ORDER_PALLET_START,
  payload,
})

export const fetchPreviousOrderPalletSuccess = (payload) => ({
  type: types.FETCH_PREVIOUS_ORDER_PALLET_SUCCESS,
  payload,
})
