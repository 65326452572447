import React, { useEffect } from 'react'
import {
  closeModal,
  showModal,
  showPendingModal,
  closePendingModal,
} from 'store/modal/actions'
import { SNACK_ERROR, SNACK_INFO } from 'constants/snackbar'
import { useSnackbar } from 'notistack'
import { connect } from 'react-redux'
import {
  printStart,
  closePrintDialog,
  fetchPrinterLibraryStart,
} from 'store/print/actions'
import { MODAL_CHOOSE_PRINTER } from 'components/common/Modal'

const Print = (props) => {
  const {
    printDialogOpen,
    printData,
    hideCancel,
    printerLibrary,
    closePrintDialog,
    printStart,
    closeModal,
    showModal,
    showPendingModal,
    closePendingModal,
    fetchPrinterLibraryStart,
  } = props

  const { enqueueSnackbar } = useSnackbar()

  const onCloseModal = () => {
    closeModal(MODAL_CHOOSE_PRINTER)
    closePrintDialog()
  }

  const onChangePrintDialogOpen = () => {
    if (printDialogOpen && printData) {
      showPendingModal()
      fetchPrinterLibraryStart({
        afterAction: () => {},
      })
    }
  }

  const onChangePrinterLibrary = () => {
    if (printerLibrary && printDialogOpen && printData) {
      closePendingModal()

      showModal({
        printData: printData,
        hideCancel: hideCancel,
        printers: printerLibrary[printData.document.printerStockId],
        name: MODAL_CHOOSE_PRINTER,
        onClose: onCloseModal,
        onPrinterSelect: onPrint,
      })
    }
  }

  useEffect(onChangePrintDialogOpen, [printDialogOpen])
  useEffect(onChangePrinterLibrary, [printerLibrary])

  const onPrint = (printInfo) => {
    closeModal(MODAL_CHOOSE_PRINTER)

    printStart({
      request: {
        data: {
          id: printInfo.id,
          printer_id: printInfo.printer_id,
          path: printInfo.document.path,
          qty: printInfo.qty !== undefined ? printInfo.qty : 1,
        },
      },
      onSuccess: () => {
        enqueueSnackbar(
          `Printing to ${printInfo.printer_location_name}`,
          SNACK_INFO,
        )
      },
      onError: () => {
        enqueueSnackbar(
          `Error Printing to ${printInfo.printer_location_name}`,
          SNACK_ERROR,
        )
      },
    })

    printInfo.onPrintDialogClose === undefined || printInfo.onPrintDialogClose()
    closePrintDialog()
  }

  return <></>
}

const mapStateToProps = (state) => ({
  printDialogOpen: state.print.printDialogOpen,
  printData: state.print.printData,
  hideCancel: state.print.hideCancel,
  printerLibrary: state.print.printerLibrary,
})

const mapDispatchToProps = {
  printStart: printStart,
  closePrintDialog: closePrintDialog,
  closeModal: closeModal,
  showModal: showModal,
  showPendingModal: showPendingModal,
  closePendingModal: closePendingModal,
  fetchPrinterLibraryStart: fetchPrinterLibraryStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(Print)
