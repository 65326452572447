import React, { useState, useEffect } from 'react'
import { PIN_FIELD, USERNAME_FIELD } from 'constants/fields'
import Button from 'components/common/Button'
import { connect } from 'react-redux'
import { checkUserStart, login } from 'store/auth/actions'
import { Formik } from 'formik'
import { TextField, Card, InputAdornment } from '@material-ui/core'
import { RemoveRedEye, Cancel } from '@material-ui/icons'

const LoginForm = (props) => {
  const { checkUser, login, errors } = props

  const [inputErrors, setInputErrors] = useState({})
  const [localUsername] = useState(
    localStorage.getItem([USERNAME_FIELD])
      ? localStorage.getItem([USERNAME_FIELD])
      : '',
  )
  const [passwordIsMasked, setPasswordIsMasked] = useState(true)
  const togglePasswordMask = () => {
    setPasswordIsMasked((value) => !value)
  }
  const onRecieveErrors = () => {
    setInputErrors({
      ...inputErrors,
      ...errors,
    })
  }

  useEffect(onRecieveErrors, [errors, checkUser, setInputErrors])

  const onLoginHandle = (data) => {
    localStorage.setItem([USERNAME_FIELD], data[USERNAME_FIELD])
    login({ data })
  }

  const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '45%',
    margin: '15px',
  }

  const isFormFilled = (values) => {
    return !values[PIN_FIELD] || !values[USERNAME_FIELD]
  }

  return (
    <Formik
      initialValues={{ [USERNAME_FIELD]: localUsername, [PIN_FIELD]: '' }}
      onSubmit={(data) => {
        onLoginHandle(data)
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <Card style={cardStyle}>
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="centered padding">
              <TextField
                name="username"
                placeholder="username"
                value={values[USERNAME_FIELD]}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={() => setFieldValue(USERNAME_FIELD, '')}
                autoFocus={localUsername ? false : true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Cancel style={{ color: 'rgba(0, 0, 0, 0)' }} />
                    </InputAdornment>
                  ),
                  inputProps: {
                    style: { textTransform: 'uppercase' },
                  },
                }}
              />
            </div>
            <div className="centered padding">
              <TextField
                type="text" // a hack so Chrome does not offer saved credentials; should be "password" otherwise
                name="pincode"
                placeholder="credential"
                value={values[PIN_FIELD]}
                onChange={handleChange}
                onBlur={handleBlur}
                autoFocus={localUsername ? true : false}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <RemoveRedEye
                        color="action"
                        onClick={togglePasswordMask}
                      />
                    </InputAdornment>
                  ),
                  inputProps: {
                    inputMode: 'numeric',
                    style: {
                      textSecurity: `${passwordIsMasked ? 'disc' : ''} `, // part of hack described above. this disc mimics the password *** appearance
                      WebkitTextSecurity: `${passwordIsMasked ? 'disc' : ''} `, // same hack
                    },
                  },
                }}
              />
            </div>
            <div className="centered margin-bottom">
              <Button
                type="submit"
                disabled={isFormFilled(values)}
                className={isFormFilled(values) ? 'btn--disabled' : ''}
              >
                Login
              </Button>
            </div>
          </form>
        </Card>
      )}
    </Formik>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  errors: state.auth.errors,
})

const mapDispatchToProps = {
  checkUser: checkUserStart,
  login,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
