export const FETCH_ORDERS_START = 'FETCH_ORDERS_START'
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS'
export const FETCH_ORDERS_FAIL = 'FETCH_ORDERS_FAIL'
export const FETCH_ORDERS_POWER_PICK_START = 'FETCH_ORDERS_POWER_PICK_START'
export const FETCH_ORDERS_POWER_PICK_SUCCESS = 'FETCH_ORDERS_POWER_PICK_SUCCESS'
export const FETCH_ORDERS_POWER_PICK_FAIL = 'FETCH_ORDERS_POWER_PICK_FAIL'
export const SORT_ORDERS = 'SORT_ORDERS'
export const FETCH_SCAN_TO_TRUCK_START = 'FETCH_SCAN_TO_TRUCK_START'
export const FETCH_SCAN_TO_TRUCK_SUCCESS = 'FETCH_SCAN_TO_TRUCK_SUCCESS'
export const FETCH_SCAN_LANE_START = 'FETCH_SCAN_LANE_START'
export const FETCH_SCAN_LANE_SUCCESS = 'FETCH_SCAN_LANE_SUCCESS'

export const FETCH_ONE_ORDER_PROCESS_START = 'FETCH_ONE_ORDER_PROCESS_START'
export const FETCH_ONE_ORDER_PROCESS_SUCCESS = 'FETCH_ONE_ORDER_PROCESS_SUCCESS'
export const FETCH_ONE_ORDER_PROCESS_FAIL = 'FETCH_ONE_ORDER_PROCESS_FAIL'
export const FETCH_ONE_ORDER_POWER_PICK_START =
  'FETCH_ONE_ORDER_POWER_PICK_START'
export const FETCH_ONE_ORDER_POWER_PICK_SUCCESS =
  'FETCH_ONE_ORDER_POWER_PICK_SUCCESS'
export const FETCH_ONE_ORDER_POWER_PICK_FAIL = 'FETCH_ONE_ORDER_POWER_PICK_FAIL'

export const UPDATE_ORDER_START = 'UPDATE_ORDER_START'
export const UPDATE_POWER_PICK_AUTO = 'UPDATE_POWER_PICK_AUTO'

export const RESET_ORDERS = 'RESET_ORDERS'

export const PROCESS_PICK = 'PROCESS_PICK'
export const PROCESS_LOAD = 'PROCESS_LOAD'
export const AUTO_ASSIGN = 'AUTO_ASSIGN'

export const VALIDATE_CLAIM = 'VALIDATE_CLAIM'
export const VALIDATE_START = 'VALIDATE_START'

export const START_LOADING_TASK = 'START_LOADING_TASK'

export const validateStart = (payload) => ({
  type: VALIDATE_START,
  payload,
})

export const validateClaim = (payload) => ({
  type: VALIDATE_CLAIM,
  payload,
})

export const fetchScanToTruckEnabled = (payload) => ({
  type: FETCH_SCAN_TO_TRUCK_START,
  payload,
})

export const fetchScanToTruckEnabledSuccess = (payload) => ({
  type: FETCH_SCAN_TO_TRUCK_SUCCESS,
  payload,
})

export const fetchScanLaneEnabled = (payload) => ({
  type: FETCH_SCAN_LANE_START,
  payload,
})

export const fetchScanLaneEnabledSuccess = (payload) => ({
  type: FETCH_SCAN_LANE_SUCCESS,
  payload,
})

export const fetchOneOrderProcessStart = (payload) => ({
  type: FETCH_ONE_ORDER_PROCESS_START,
  payload,
})

export const fetchOneOrderProcessSuccess = (payload) => ({
  type: FETCH_ONE_ORDER_PROCESS_SUCCESS,
  payload,
})

export const fetchOneOrderProcessFail = (payload) => ({
  type: FETCH_ONE_ORDER_PROCESS_FAIL,
  payload,
})

export const fetchOrdersStart = (payload) => ({
  type: FETCH_ORDERS_START,
  payload,
})

export const fetchOrdersSuccess = (payload) => ({
  type: FETCH_ORDERS_SUCCESS,
  payload,
})

export const fetchOrdersFail = (payload) => ({
  type: FETCH_ORDERS_FAIL,
  payload,
})

export const fetchOrdersPowerPickStart = (payload) => {
  return {
    type: FETCH_ORDERS_POWER_PICK_START,
    payload,
  }
}

export const fetchOrdersPowerPickSuccess = (payload) => ({
  type: FETCH_ORDERS_POWER_PICK_SUCCESS,
  payload,
})

export const fetchOrdersPowerPickFail = (payload) => ({
  type: FETCH_ORDERS_POWER_PICK_FAIL,
  payload,
})

export const fetchOneOrderPowerPickStart = (payload) => {
  return {
    type: FETCH_ONE_ORDER_POWER_PICK_START,
    payload,
  }
}

export const startLoadingTask = (payload) => ({
  type: START_LOADING_TASK,
  payload,
})

export const fetchOneOrderPowerPickSuccess = (payload) => ({
  type: FETCH_ONE_ORDER_POWER_PICK_SUCCESS,
  payload,
})

export const fetchOneOrderPowerPickFail = (payload) => ({
  type: FETCH_ONE_ORDER_POWER_PICK_FAIL,
  payload,
})

export const updateOrdersStart = (payload) => ({
  type: UPDATE_ORDER_START,
  payload,
})

export const updatePowerPickAuto = (payload) => ({
  type: UPDATE_POWER_PICK_AUTO,
  payload,
})

export const sortOrders = (payload) => ({
  type: SORT_ORDERS,
  payload,
})

export const resetOrders = (payload) => ({
  type: RESET_ORDERS,
  payload,
})

export const processPick = (payload) => ({
  type: PROCESS_PICK,
  payload,
})

export const processLoad = (payload) => ({
  type: PROCESS_LOAD,
  payload,
})

export const autoAssign = (payload) => ({
  type: AUTO_ASSIGN,
  payload,
})
