import React from 'react'
import * as PropTypes from 'prop-types'

import './style.scss'

const Icon = ({ src, className, children, style, onClick }) => (
  <div className={className} style={style} onClick={onClick}>
    {src ? <img src={src} className="icon__img" alt="avatar" /> : null}
    {children}
  </div>
)

Icon.propTypes = {
  children: PropTypes.any,

  className: PropTypes.string,
  src: PropTypes.string,

  style: PropTypes.object,

  onClick: PropTypes.func,
}

export default Icon
