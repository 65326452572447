import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import ModalDefault from 'components/common/Modal/ModalDefault'
import Button from 'components/common/Button'
import './style.scss'
import InputQuantityControl from 'components/common/InputQuantityControl'
import CancelIcon from 'assets/svg/cancel.svg'
import CancelIconIPod from 'assets/svg/cancel--ipod.svg'
import { connect } from 'react-redux'
import { MODAL_INPUT_QUANTITY_CONTROL } from 'components/common/Modal'

const MIN_CASES_QUANTITY = 0

const ModalInputQuantityControl = (props) => {
  const { isOpen, modalInfo } = props

  const [quantity, setQuantity] = useState(0)

  useEffect(() => {
    setQuantity(modalInfo ? modalInfo.value : 0)
  }, [modalInfo])

  if (!isOpen) {
    return <></>
  }

  const onIncrement = () => setQuantity(quantity + 1)
  const onDecrement = () => setQuantity(quantity - 1)
  const onChange = (name, value) => {
    modalInfo.onChange(value)
    setQuantity(value)
  }
  const onClick = () => modalInfo.onConfirm(quantity)
  const onClose = () => {
    setQuantity(modalInfo.value)
    modalInfo.onDeny()
  }

  const quantityStyles = {
    fontSize: 32,
  }

  return (
    <ModalDefault isOpen={isOpen}>
      <div
        className={`modal modal--confirm ${
          modalInfo.className ? modalInfo.className : ''
        }`}
      >
        <div className="text text--damaged">Case Qty</div>
        <InputQuantityControl
          minValue={MIN_CASES_QUANTITY}
          maxValue={modalInfo.maxValue}
          onChange={onChange}
          value={quantity}
          onDecrease={onDecrement}
          onIncrease={onIncrement}
          styles={quantityStyles}
        />
        <div className="button">
          <Button disabled={quantity < MIN_CASES_QUANTITY} onClick={onClick}>
            {modalInfo.allCaseQuantity
              ? modalInfo.allCaseQuantity === quantity
                ? 'Complete'
                : 'Next'
              : 'Confirm'}
          </Button>
        </div>
        <div onClick={onClose} className="btn--close">
          {window.innerWidth < 768 ? <CancelIconIPod /> : <CancelIcon />}
        </div>
      </div>
    </ModalDefault>
  )
}

ModalInputQuantityControl.propTypes = {
  isOpen: PropTypes.bool,
  modalInfo: PropTypes.object,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === MODAL_INPUT_QUANTITY_CONTROL,
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === MODAL_INPUT_QUANTITY_CONTROL,
  ),
})

export default connect(mapStateToProps)(ModalInputQuantityControl)
