import React from 'react'
import * as PropTypes from 'prop-types'
import Avatar from '../Avatar'
import Icon from 'components/common/Icon'
import { getPhotoLink } from 'helpers/index'

const CARDS_TO_SHOW_COUNT = 3
const CUSTOM_PHOTO_API = 'https://api.dicebear.com/7.x/initials/svg?seed='

const AvatarList = ({ items, onClick = () => false }) => {
  const countOfNonShownItems = items.length - CARDS_TO_SHOW_COUNT

  return (
    <div className="avatar-list" onClick={onClick}>
      {countOfNonShownItems > 0 && (
        <Icon
          className="avatar-list__count"
          key={CARDS_TO_SHOW_COUNT + 1}
        >{`+${countOfNonShownItems}`}</Icon>
      )}
      {items.map((item, index) =>
        index < CARDS_TO_SHOW_COUNT ? (
          <div key={item.staffLogin.staff_id}>
            <Avatar
              subText={item.staffLogin.name}
              link={
                item.staffLogin.photo_url
                  ? getPhotoLink(item.staffLogin.photo_url)
                  : `${CUSTOM_PHOTO_API}${item.staffLogin.name}`
              }
            />
          </div>
        ) : (
          ''
        ),
      )}
    </div>
  )
}

AvatarList.propTypes = {
  items: PropTypes.array.isRequired,

  onClick: PropTypes.func,
}

export default AvatarList
