import { takeEvery, call, put } from 'redux-saga/effects'
import { types } from './actions'
import {
  update,
} from 'api/orderPalletLaneLocation'
import { fetchOneTaskAssignmentStart } from 'store/taskAssignment/actions'

function* updateOrderPalletLaneLocation(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    yield call(update, request)

    if (payload.request.task_assignment_id) {
      yield put(
        fetchOneTaskAssignmentStart({ id: payload.request.task_assignment_id }),
      )
    }

    if (afterAction) {
      yield afterAction()
    }
  } catch (error) {}
}

export default function* orderPalletLaneLocationSaga() {
  yield takeEvery(
    types.UPDATE_ORDER_PALLET_LANE_LOCATION,
    updateOrderPalletLaneLocation,
  )
}
