import React from 'react'
import PalletSm from '../../../assets/svg/pallet-sm.svg'
import './pallet_icon_style.scss'

export function PickPallet(props) {
  const {
    id,
    nbr,
    count,
    weight,
    className,
    hideIcon,
    onSelect,
    isEstimatedWeight,
  } = props
  const countDisplay = count ? `${count} cs` : ''
  const estimateInd = isEstimatedWeight ? '~' : ''
  const weightDisplay = weight ? ` @ ${estimateInd}${weight} lbs` : ''
  const palletText = `Wrap Pallet ${nbr}`

  return (
    <div onClick={() => (onSelect ? onSelect(id) : '')} className={className}>
      <div className="pallet-icon-image-wrapper">
        <div className="pallet-text-count">{palletText}</div>

        {hideIcon ? '' : <PalletSm className="pallet-icon-svg"></PalletSm>}
        <div className="pallet-icon-weight">
          {countDisplay}
          {weightDisplay}
        </div>
      </div>
    </div>
  )
}

export default PickPallet
