import request from 'helpers'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}shipping-lane`

export const create = (payload) =>
  request({
    url: `${apiEndpoint}`,
    method: 'POST',
    data: payload,
  })

export const confirmEmpty = (payload) =>
  request({
    url: `${apiEndpoint}/confirm-empty`,
    method: 'POST',
    data: payload,
  })