import React, { useEffect, useState } from 'react'
import Header from 'components/common/Header'
import Scanner from 'components/common/Scanner'
import ActiveGrey from 'assets/svg/active-grey.svg'
import Button from 'components/common/Button'
import { redirect } from 'helpers/index'
import { resetModals, showModal, closeModal } from 'store/modal/actions'
import './style.scss'
import { connect } from 'react-redux'
import { MdWarning } from 'react-icons/md'
import Active from 'assets/svg/active.svg'
import { RELOCATE_PRODUCT_PAGE } from 'constants/routes'
import {
  fetchRelocateTask,
  validateToUPC,
  processRelocate,
} from 'store/relocate/actions'
import { MODAL_PENDING } from 'components/common/Modal'
import { SNACK_SUCCESS, SNACK_ERROR } from 'constants/snackbar'
import { useSnackbar } from 'notistack'
import ExclamationSmall from 'assets/svg/exclamation-sign.svg'

const RelocateTaskPage = (props) => {
  const {
    showModal,
    resetModals,
    closeModal,
    match,
    relocateTask,
    fetchRelocateTask,
    validateToUPC,
    processRelocate,
  } = props

  const successfulScanHandler = (upc, case_qty, aisle_bay_level) => {
    resetModals()
    setStatusPanel({
      ...statusPanel,
      scannedToUPC: upc,
      toLocationCaseQty: case_qty,
      isToUPCScanned: true,
      scannedToAisleBayLevel: aisle_bay_level,
      icon: <Active className="scan-indicator" />,
    })
  }

  const deniedScanHandler = () => {
    resetModals()
    setStatusPanel({
      ...statusPanel,
      isToUPCScanned: false,
      scannedToAisleBayLevel: null,
    })
  }

  const onValidateAfterAction = (data) => {
    resetModals()

    setStatusPanel({
      ...statusPanel,
      isToUPCScanned: true,
      scannedToAisleBayLevel: data.item.aisle_bay_level,
    })

    if (data.success) {
      successfulScanHandler(
        data.item.upc,
        data.item.new_num_of_cases,
        data.item.aisle_bay_level,
      )
      return
    }

    if (
      data.item.has_insufficient_space &&
      data.item.is_pick &&
      !data.item.is_wrong_pick_location
    ) {
      showModal({
        name: 'modalConfirm',
        onAccept: () =>
          successfulScanHandler(
            data.item.upc,
            data.item.new_num_of_cases,
            data.item.aisle_bay_level,
          ),
        onDeny: deniedScanHandler,
        className: 'modal--picking-task',
        children: (
          <div className="modal--content-centered">
            <MdWarning style={{ fontSize: '75px', color: '#ffbf00' }} />
            <p className="text--bold">Warning</p>
            <div className="text">
              Relocating will exceed the location case capacity.
            </div>
            <p className="text location-detail">
              <span className="new-num-of-cases">
                {data.item.new_num_of_cases}
              </span>
              /<span className="case-capacity">{data.item.case_capacity}</span>
            </p>
          </div>
        ),
      })
      return
    }

    if (data.item.lane_has_pallet) {
      showModal({
        name: 'modalYesOrNo',
        onYes: deniedScanHandler,
        onNo: () =>
          successfulScanHandler(
            data.item.upc,
            data.item.new_num_of_cases,
            data.item.aisle_bay_level,
          ),
        onCancel: deniedScanHandler,
        className: 'modal--picking-task',
        children: (
          <div className="modal--content-centered">
            <MdWarning style={{ fontSize: '50px', color: '#ffbf00' }} />
            <p className="text--bold">Warning</p>
            <div className="text">
              The database indicates that this lane is full, but there might be
              an inconsistency. Would you like to confirm if the lane is indeed
              full?
            </div>
          </div>
        ),
      })
      return
    }

    if (data.error) {
      showModal({
        name: 'modalWarning',
        text: data.error,
        onAccept: deniedScanHandler,
        onDeny: deniedScanHandler,
        className: 'modal--tasks modal--pause',
        modalIcon: <ExclamationSmall />,
      })
      return
    }
  }

  const onScan = (value) => {
    if (statusPanel.isToUPCScanned) {
      return
    }

    showModal({
      name: 'modalPending',
    })

    validateToUPC({
      request: {
        data: {
          location_upc: value,
          task_id: relocateTask.id,
        },
      },
      afterAction: onValidateAfterAction,
    })
  }

  const onProcessAfterAction = (data) => {
    resetModals()

    const snackStyle = data.success ? SNACK_SUCCESS : SNACK_ERROR
    enqueueSnackbar(data.message, snackStyle)

    redirect(RELOCATE_PRODUCT_PAGE)
  }

  const onComplete = () => {
    showModal({ name: MODAL_PENDING })

    processRelocate({
      request: {
        data: {
          location_upc: statusPanel.scannedToUPC,
          task_id: relocateTask.id,
        },
      },
      afterAction: onProcessAfterAction,
    })
  }

  const onPageLoad = () => {
    showModal({ name: MODAL_PENDING })
    fetchRelocateTask(match.params.id)
  }

  const { enqueueSnackbar } = useSnackbar()

  const INIT_STATUS_PANEL = {
    isToUPCScanned: false,
    scannedToUPC: null,
    scannedToAisleBayLevel: null,
    toLocationCaseQty: null,
    text: 'Scan To Location UPC',
    icon: <ActiveGrey className="scan-indicator" />,
  }

  const [statusPanel, setStatusPanel] = useState(INIT_STATUS_PANEL)

  const onLoadRelocateTask = () => {
    if (!relocateTask) {
      return
    }

    resetModals()
  }

  useEffect(onPageLoad, [])
  useEffect(onLoadRelocateTask, [relocateTask])

  if (!relocateTask) {
    return <></>
  }

  const locationLabel = statusPanel.isToUPCScanned ? 'TO' : 'FROM'
  const locationTitle =
    statusPanel.scannedToAisleBayLevel ??
    relocateTask.relocate_location.from_location.aisle_bay_level
  const locationQty =
    statusPanel.toLocationCaseQty ?? relocateTask.relocate_location.quantity

  return (
    <div className="tasks-page relocate-task-page tasks-page--start-relocate">
      <Header />
      <Scanner needToRemoveListener={false} onReceiveData={onScan} />
      <div className="content">
        <div className="content__section">
          <div className="from-location__new-wrapper">
            <div className="from-location">
              <span className="from-location__label">{locationLabel}</span>
              <span className="from-location__name">{locationTitle}</span>
            </div>
          </div>
          <ul className="from-location-fireworks">
            <li className="from-location-fireworks__item">
              {relocateTask?.relocate_location?.from_location?.product
                ?.products_model && (
                <>
                  <span className="fireworks__name">Model</span>
                  <span className="fireworks__sub-name">
                    {
                      relocateTask.relocate_location.from_location.product
                        .products_model
                    }
                  </span>
                </>
              )}
            </li>
            <li className="from-location-fireworks__item">
              {relocateTask?.relocate_location?.from_location?.product
                ?.products_manf_model && (
                <>
                  <span className="fireworks__name">Manf Model</span>
                  <span className="fireworks__sub-name">
                    {
                      relocateTask.relocate_location.from_location.product
                        .products_manf_model
                    }
                  </span>
                </>
              )}
            </li>
            {locationQty && (
              <li className="from-location-fireworks__item">
                <span className="fireworks__name">Cases</span>
                <span className="fireworks__sub-name">{locationQty}</span>
              </li>
            )}
            <li className="from-location-fireworks__item">
              {relocateTask?.relocate_location?.from_location?.product
                ?.pick_location?.aisle_bay_level &&
                relocateTask?.relocate_location
                  ?.warehouse_location_from_pallet_id && (
                  <>
                    <span className="fireworks__name">Pick Location</span>
                    <span className="fireworks__sub-name">
                      {
                        relocateTask.relocate_location.from_location.product
                          .pick_location.aisle_bay_level
                      }
                    </span>
                  </>
                )}
            </li>
          </ul>
        </div>
        <div className="content__section content__section--white">
          <span className="status">
            {!statusPanel.scannedToUPC ? (
              <>
                {statusPanel.text} {statusPanel.icon}
              </>
            ) : (
              <Button onClick={onComplete}>Complete</Button>
            )}
          </span>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  relocateTask: state.relocate.relocateTask,
})

const mapDispatchToProps = {
  showModal,
  resetModals,
  fetchRelocateTask,
  closeModal,
  validateToUPC,
  processRelocate,
}

export default connect(mapStateToProps, mapDispatchToProps)(RelocateTaskPage)
