import { requestWithRetry, requestWithIgnore } from 'helpers'
import { API_URL } from 'constants/apiconfig'

const apiEndpointOrdersPowerPick = `${API_URL}processes/orders-power-pick`

export const fetchAllForPowerPick = (payload) =>
  requestWithRetry({
    url: `${apiEndpointOrdersPowerPick}`,
    method: 'GET',
    params: payload,
  })

export const fetchOnePowerPick = (payload) =>
  requestWithIgnore(
    {
      url: `${apiEndpointOrdersPowerPick}/${payload.id}`,
      method: 'GET',
    },
    {
      maxIgnores: 1,
    },
  )
