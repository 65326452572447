import React from 'react'
import { Card, ListItem, ListItemText } from '@material-ui/core'

const PalletListItem = ({ item, onSelect }) => {
  const renderItem = () => {
    const { task_assignment } = item
    const itemClassName =
      'task-div-padding item--status-' + task_assignment.status.id
    const palletNumber =
      'Pallet ' + task_assignment.lane_location.order_pallet.pallet_nbr

    return (
      <div className={itemClassName}>
        <span className="location-name">
          {task_assignment.lane_location.warehouse_location.name}
        </span>
        <br />
        {palletNumber}
      </div>
    )
  }

  return (
    <Card raised style={{ marginBottom: '5px' }}>
      <ListItem>
        <ListItemText primary={renderItem()} />
      </ListItem>
    </Card>
  )
}

export default PalletListItem
