import request, { requestWithRetry, requestWithIgnore } from 'helpers'
import { API_URL } from 'constants/apiconfig'

const apiEndpointOrders = `${API_URL}processes/orders`

export const fetchScanToTruckAppSetting = (payload) =>
  requestWithIgnore({
    url: `${apiEndpointOrders}/scan-to-truck`,
    method: 'GET',
  })

export const fetchScanLaneAppSetting = (payload) =>
  requestWithRetry({
    url: `${apiEndpointOrders}/scan-lane`,
    method: 'GET',
  })

export const fetchOne = (payload) =>
  requestWithRetry({
    url: `${apiEndpointOrders}/${payload.id}`,
    method: 'GET',
  })

export const fetchAll = (payload) =>
  requestWithRetry({
    url: `${apiEndpointOrders}/queue`,
    method: 'GET',
    params: payload,
  })

export const update = (payload) =>
  request({
    url: `${apiEndpointOrders}`,
    method: 'PUT',
    data: payload,
  })

export const processPick = (payload) =>
  request({
    url: `${API_URL}processes/orders-pick/process-pick`,
    method: 'POST',
    data: payload,
  })

export const processLoad = (payload) =>
  request({
    url: `${API_URL}processes/orders-load/process-load`,
    method: 'POST',
    data: payload,
  })

export const startLoadingTask = (payload) =>
  request({
    url: `${API_URL}processes/orders-load/start-loading-task`,
    method: 'POST',
    data: payload,
  })

export const autoAssign = (payload) =>
  request({
    url: `${API_URL}processes/getPickTaskAutoAssignment`,
    method: 'POST',
    data: payload,
  })

export const validateClaim = (payload) =>
  request({
    url: `${apiEndpointOrders}/validate-claim`,
    method: 'POST',
    data: payload,
  })

  export const validateStart = (payload) =>
  request({
    url: `${apiEndpointOrders}/validate-start`,
    method: 'POST',
    data: payload,
  })
