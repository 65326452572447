import request from 'helpers/index'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}login`

export const checkUser = (payload) =>
  request({
    url: `${apiEndpoint}/check-user`,
    method: 'POST',
    data: payload,
  })

export const login = (payload) =>
  request({
    url: `${apiEndpoint}`,
    method: 'POST',
    data: payload,
  })

export const logout = (payload) =>
  request({
    url: `${API_URL}logout`,
    method: 'POST',
    data: payload,
  })

export const getUserData = (payload) =>
  request({
    url: `${API_URL}current-user`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${payload}`,
    },
  })
