import { createSelector } from 'reselect'

const getStaffAssignments = (state) => state.staff.item

export const getNormalizedStaffAssignments = createSelector(
  [getStaffAssignments],
  (item) => {
    if (!item.staff) {
      return []
    }
    if (!Object.entries || !Object.fromEntries) {
      Object.entries = function (obj) {
        let ownProps = Object.keys(obj),
          i = ownProps.length,
          resArray = new Array(i)
        while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]]

        return resArray
      }
      Object.fromEntries = function (iterable) {
        return [...iterable].reduce((obj, [key, val]) => {
          obj[key] = val
          return obj
        }, {})
      }
    }

    const typesWithList = Object.entries(item.staff)
    const allAssignments = item.taskAssignments

    const prepareStaff = (list) =>
      list.map((listItem) => ({
        ...listItem,
        taskAssignments: allAssignments.filter(
          (i) => listItem.taskAssignments.indexOf(i.id) > -1,
        ),
      }))

    return Object.fromEntries(
      typesWithList.map(([typeId, list]) => {
        return [
          parseInt(typeId),
          parseInt(typeId) === 0 ? list : prepareStaff(list),
        ]
      }),
    )
  },
)
