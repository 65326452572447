import React from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CardList from 'components/features/StaffCard/CardList'
import { closeModal, showModal } from 'store/modal/actions'

const StaffEngagementBlock = ({
  item,
  onAssign,
  processInfo,
  showModal,
  closeModal,
}) => {
  const closeModalStaffDetails = () => {
    closeModal('popupStaffDetails')
  }

  const onView = (item) => {
    showModal({
      name: 'popupStaffDetails',
      onDeny: closeModalStaffDetails,
      processInfo: processInfo,
      staffItem: item,
    })
  }

  return (
    <div className={`staff-engagement__block`}>
      <div id={item.name} className="title">
        {item.label}
      </div>
      <CardList
        items={item.staff}
        options={{
          selectable: true,
          viewable: true,
        }}
        events={{
          onSelect: (item) => onAssign(item),
          onView: (item) => onView(item),
        }}
      />
    </div>
  )
}

StaffEngagementBlock.propTypes = {
  item: PropTypes.object.isRequired,
}

const mapDispatchToProps = {
  showModal,
  closeModal,
}

export default connect(null, mapDispatchToProps)(StaffEngagementBlock)
