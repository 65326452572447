import React from 'react'
import * as PropTypes from 'prop-types'

import TabContent from './TabContent'

const BulkStorageTaskContent = ({ items }) => {
  return (
    <div className="tab">
      <TabContent items={items} />
    </div>
  )
}

BulkStorageTaskContent.propTypes = {
  items: PropTypes.array,
}

export default BulkStorageTaskContent
