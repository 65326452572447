import React from 'react'
import * as PropTypes from 'prop-types'
import { Card, ListItem, ListItemText } from '@material-ui/core'
import { ORDER_LOAD_STATUSES } from 'constants/order'
import AvatarList from 'components/features/StaffCard/AvatarList'
import { dateToFormat } from 'helpers/index'
import { BiRun } from 'react-icons/bi'
import { GrNotes } from 'react-icons/gr'

const OrderListItem = ({ item, onSelect, showModalOrderNotes, onUnclaim }) => {
  const onClickSelect = () => onSelect(item)
  const onClickOrderNotes = (item) => showModalOrderNotes(item)
  const onClickUnclaim = () => onUnclaim(item)
  const buttonText = item.taskAssignment.id ? (
    item.entity.orderLoadStatus.status_id ===
    ORDER_LOAD_STATUSES.STATUS_LOADING ? (
      <span style={{ color: 'orange' }} className="status-text">
        Resume
      </span>
    ) : (
      <span style={{ color: 'green' }} className="status-text">
        Start
      </span>
    )
  ) : (
    <span style={{ color: 'red' }} className="status-text">
      Claim
    </span>
  )

  const renderItem = () => {
    const { entity, team } = item

    const shipDate = entity.order.shipping_date
      ? dateToFormat(entity.order.shipping_date, 'MM/DD/YYYY')
      : dateToFormat(entity.order.pickup_date, 'MM/DD/YYYY')

    const unclaimButton = item.taskAssignment.id ? (
      entity.orderLoadStatus.status_id !== ORDER_LOAD_STATUSES.STATUS_LOADING ||
      (entity.orderLoadStatus.status_id ===
        ORDER_LOAD_STATUSES.STATUS_LOADING &&
        entity.order.total_loaded_quantity === 0) ? (
        <span
          style={{ color: 'red', cursor: 'pointer' }}
          className="status-text"
          onClick={onClickUnclaim}
        >
          Unclaim
        </span>
      ) : null
    ) : null

    const rushIcon =
      entity.order.rush === 1 ? <BiRun className="rush-icon" /> : ''

    const loadedCount =
      entity.orderLoadStatus.status_id ===
      ORDER_LOAD_STATUSES.STATUS_LOADING ? (
        <span className="secondary-details-text">
          {entity.order.total_loaded_quantity}/
          {entity.order.total_location_load_quantity}
          &nbsp; pallets
        </span>
      ) : (
        <span className="secondary-details-text">
        {entity.order.total_location_load_quantity}
        &nbsp; pallets
      </span>
      )

    const statusClassName = `item--status-${entity.orderLoadStatus.status_id}`
    const itemClassName = `${statusClassName} task-div-padding`

    return (
      <div className={itemClassName}>
        {rushIcon}
        <div className="spaced-div">
          <span></span>
          <span className="status-text">
            {(entity.order.shipping_notes || entity.order.warehouse_notes) && (
              <GrNotes
                className="order-notes-icon-pp"
                onClick={() => {
                  onClickOrderNotes(item)
                }}
              />
            )}
            {entity.order.orders_id}
          </span>
          <span className="primary-details-text closer-vert"></span>
          <div>
            <AvatarList items={team} />
          </div>
        </div>
        <div className="spaced-div">
          <span></span>
          <span className="secondary-details-text position-left">
            {entity.order.customers_name}
            <br />
            {entity.carrier ? entity.carrier.real_name : 'No Carrier'}
            <br />
            {shipDate}
          </span>
          <span className="closer-vert">
            {buttonText}
            <br />
            {loadedCount}
            <br />
            <br />
            {unclaimButton}
          </span>
        </div>
      </div>
    )
  }

  return (
    <Card raised style={{ marginBottom: '5px' }}>
      <ListItem
        button
        onDoubleClick={(e) => {
          onClickSelect()
        }}
      >
        <ListItemText primary={renderItem()} />
      </ListItem>
    </Card>
  )
}

OrderListItem.propTypes = {
  item: PropTypes.object,

  onSelect: PropTypes.func,
}

export default OrderListItem
