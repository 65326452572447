import React from 'react'
import Header from 'components/common/Header'
import TableList from 'components/common/TableList'
import Button from 'components/common/Button'
import '../style.scss'

const columns = [
  {
    id: 1,
    label: 'Model',
    value: 'model',
  },
  {
    id: 2,
    label: 'Qty',
    value: 'qty',
  },
  {
    id: 3,
    label: 'Manf Model',
    value: 'manf_model',
  },
  {
    id: 4,
    label: 'Product Name',
    value: 'product_name',
  },
]

const PalletProductListContent = ({
  orderPallet,
  onClickEnterPalletWeight,
}) => {
  if (!orderPallet) {
    return <></>
  }

  const getNormalizedItems = (items) =>
    items.map((item) => {
      return {
        id: item.id,
        model: item.product.products_model,
        qty: item.pick_pallet_quantity,
        manf_model: item.product.products_manf_model,
        product_name: item.product_description.products_name,
        options: {
          selectable: false,
        },
      }
    })

  const getAllCasesCount = () =>
    orderPallet.order_pick_pallets.reduce(
      (total, item) => total + item.pick_pallet_quantity,
      0,
    )

  const totalCasesQuantity = getAllCasesCount()
  const title = `Pallet ${
    orderPallet.pick_pallet_id
  }(${totalCasesQuantity} case${totalCasesQuantity > 1 ? 's' : ''})`

  return (
    <>
      <Header>
        <div className="title">{title}</div>
      </Header>
      <div className="content">
        <TableList
          rows={getNormalizedItems(orderPallet.order_pick_pallets)}
          columns={columns}
        />
        <Button onClick={onClickEnterPalletWeight}>Enter Pallet Weight</Button>
        <div>&nbsp;</div>
      </div>
    </>
  )
}

export default PalletProductListContent
