import { takeEvery, call } from 'redux-saga/effects'
import { types } from './actions'
import {
  recountForContainerProduct,
  markPalletComplete,
} from 'api/containerPallet'

function* recountPalletsForContainerProduct(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    yield call(recountForContainerProduct, request)

    if (afterAction) {
      afterAction()
    }
  } catch (error) {}
}

function* markComplete(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    yield call(markPalletComplete, request)

    if (afterAction) {
      afterAction()
    }
  } catch (error) {}
}

export default function* containerPalletSaga() {
  yield takeEvery(
    types.RECOUNT_CONTAINER_PALLETS,
    recountPalletsForContainerProduct,
  )
  yield takeEvery(types.MARK_PALLET_COMPLETE, markComplete)
}
