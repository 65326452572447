import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import './style.scss'
import PalletProductListContent from 'components/features/Task/PickingWrapPalletList/PalletListContent'
import {
  fetchOneOrderPalletStart,
  updateOrderPalletStart,
} from 'store/orderPallet/actions'
import { showPendingModal } from 'store/modal/actions'

import PalletWeightScreen from 'components/features/Task/PickingWrapPalletList/PalletWeightScreen'
import { redirect, route } from 'helpers/index'
import { MOVE_TO_LANE_PAGE } from 'constants/routes'
import { ORDER_PALLET_LABEL } from 'constants/print'
import { openPrintDialog } from 'store/print/actions'
import Print from 'components/common/Print'

const screens = {
  PRODUCT_LIST: 'PRODUCT_LIST',
  PALLET_WEIGHT: 'PALLET_WEIGHT',
}

const PickingWrapPalletListPage = (props) => {
  const {
    fetchOneOrderPalletStart,
    updateOrderPalletStart,
    showPendingModal,
    openPrintDialog,

    item,
    match,
  } = props

  const [currentScreen, setCurrentScreen] = useState(screens.PRODUCT_LIST)
  const [orderPalletId, setOrderPalletId] = useState(null)

  const onMount = () => {
    fetchOneOrderPalletStart(match.params.orderPalletId)
    setOrderPalletId(match.params.orderPalletId)
  }

  const printLabel = (id) => {
    openPrintDialog({
      id: id,
      document: ORDER_PALLET_LABEL,
      onPrintDialogClose: () => {
        redirectToShippingLane()
      },
    })
  }

  useEffect(onMount, [fetchOneOrderPalletStart])

  const onClickEnterPalletWeightHandler = () =>
    setCurrentScreen(screens.PALLET_WEIGHT)
  const onCancelPalletWeightHandler = () =>
    setCurrentScreen(screens.PRODUCT_LIST)

  const onConfirmAndPrintHandler = (palletWeight) => {
    setTimeout(() => {
      //add a slight delay to prevent modal collision
      showPendingModal()

      updateOrderPalletStart({
        request: {
          id: item.id,
          data: {
            id: item.id,
            pallet_weight: palletWeight,
          },
        },
        afterAction: (id) => {
          printLabel(id)
        },
      })
    }, 100)
  }

  const redirectToShippingLane = () => {
    redirect(route(MOVE_TO_LANE_PAGE, { orderPalletId: orderPalletId }))
  }

  const showProductListContent = () => {
    return (
      <PalletProductListContent
        onClickEnterPalletWeight={onClickEnterPalletWeightHandler}
        orderPallet={item}
      />
    )
  }

  const showPalletWeightScreen = () => {
    return (
      <PalletWeightScreen
        item={item}
        onConfirmAndPrint={onConfirmAndPrintHandler}
        onCancel={onCancelPalletWeightHandler}
      />
    )
  }

  const showCurrentScreen = () => {
    switch (currentScreen) {
      case screens.PALLET_WEIGHT:
        return showPalletWeightScreen()
      case screens.PRODUCT_LIST:
      default:
        return showProductListContent()
    }
  }

  return (
    <div className="tasks-page tasks-page--wrap">
      {showCurrentScreen()}
      <Print />
    </div>
  )
}

const mapStateToProps = (state) => ({
  item: state.orderPallet.item,
})

const mapDispatchToProps = {
  fetchOneOrderPalletStart,
  updateOrderPalletStart,
  showPendingModal,
  openPrintDialog,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PickingWrapPalletListPage)
