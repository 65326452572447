import request, { requestWithRetry } from 'helpers'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}order-pallets`

export const fetchOne = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}/${payload}`,
    method: 'GET',
  })

export const update = (payload) =>
  request({
    url: `${apiEndpoint}/${payload.id}`,
    method: 'PUT',
    data: payload,
  })

export const create = (payload) =>
  request({
    url: `${apiEndpoint}`,
    method: 'POST',
    data: payload,
  })

export const createTempProducts = (payload) =>
  request({
    url: `${apiEndpoint}/temp-products`,
    method: 'POST',
    data: payload,
  })

export const fetchPreviousOrderPallet = (payload) =>
  request({
    url: `${apiEndpoint}/previous/${payload}`,
    method: 'GET',
  })
