import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import ModalDefault from 'components/common/Modal/ModalDefault'
import Button from 'components/common/Button'
import Active from 'assets/svg/active.svg'
import ActiveGrey from 'assets/svg/active-grey.svg'
import Scanner from 'components/common/Scanner'
import { connect } from 'react-redux'

const ModalDamagePutawayLocation = (props) => {
  const { isOpen, modalInfo } = props

  const [isDamageLocationScanned, setIsDamageLocationScanned] = useState(false)

  if (!isOpen) {
    return <></>
  }

  const onScan = (barcode) => {
    if (barcode.toString() === modalInfo.upc.toString()) {
      setIsDamageLocationScanned(true)
      setTimeout(
        () => modalInfo.onCaseUpcCorrect(modalInfo.damageQuantity),
        1500,
      )
    } else {
      setIsDamageLocationScanned(false)
      modalInfo.onCaseUpcNotCorrect()
    }
  }

  const onBackClick = () => {
    modalInfo.onBack()
    setIsDamageLocationScanned(false)
  }

  return (
    <ModalDefault isOpen={isOpen}>
      <Scanner onReceiveData={onScan} />
      <div
        className={`modal modal--confirm modal--start-picking ${
          modalInfo.className ? modalInfo.className : ''
        }`}
      >
        <div className="text text--damaged">Damage Putaway Location</div>
        <div className="dock">
          <span className="dock__label">LOC</span>
          <span className="dock__name">{modalInfo.text}</span>
          {isDamageLocationScanned ? (
            <Active className="dock__icon" />
          ) : (
            <ActiveGrey className="dock__icon" />
          )}
        </div>
        <div className="button">
          <Button onClick={onBackClick}>Back</Button>
        </div>
      </div>
    </ModalDefault>
  )
}

ModalDamagePutawayLocation.propTypes = {
  isOpen: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalDamagePutawayLocation',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalDamagePutawayLocation',
  ),
})

export default connect(mapStateToProps)(ModalDamagePutawayLocation)
