import React, { useState } from 'react'
import * as PropTypes from 'prop-types'

import TableListCell from 'components/common/TableList/TableListCell'
import Swipe from 'components/common/Swipe'

import HighPriorityIcon from 'assets/svg/high-priority.svg'

const TableListRow = ({ columns, row, events, rowBackgroundElement }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleRow = () =>
    row.options.expandable ? setIsExpanded(!isExpanded) : false

  const selectRow = (id) =>
    row.options.selectable ? events.onSelect(id) : false

  const onClickRow = (id) => {
    toggleRow()
    selectRow(id)
  }

  const generateRowClass = () => {
    return `list__row list__row--${row.highlightClassName} list__row--${
      row.backgroundClassName
    }
    ${isExpanded ? 'list__row--expanded' : ''}
    ${
      row.options.selectable && row.options.selected
        ? 'list__row--selected'
        : ''
    }`.trim()
  }

  const content = (
    <li className={generateRowClass()}>
      <div className="list__item" onClick={() => onClickRow(row.id)}>
        {columns.map((item) => {
          const itemValue =
            row[item.value] === 'high' ? <HighPriorityIcon /> : row[item.value]
          return <TableListCell item={itemValue} key={item.value} />
        })}
      </div>

      {row.expanded || (row.options.expandable && isExpanded) ? (
        <div className="list__item--detail">{row.expandedContent}</div>
      ) : null}
    </li>
  )

  return row.options.swipeable ? (
    <Swipe
      rowBackgroundElement={rowBackgroundElement}
      animated={row.options.animated}
      onSwipe={() => events.onSwipe(row.id)}
    >
      {content}
    </Swipe>
  ) : (
    content
  )
}

TableListRow.propTypes = {
  row: PropTypes.object.isRequired,

  columns: PropTypes.array.isRequired,

  events: PropTypes.object,

  rowBackgroundElement: PropTypes.element,
}

export default TableListRow
