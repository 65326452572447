import React, { Component } from 'react'
import './style.scss'
import * as Sentry from '@sentry/browser'
import Button from 'components/common/Button'
import ClearCache from 'react-clear-cache'
import { redirect } from 'helpers/index'
import { LOGIN_PAGE } from 'constants/routes'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)

    this.state = {
      eventId: null,
    }
  }

  static getDerivedStateFromError = (error) => ({ hasError: true })

  componentDidCatch = (error, errorInfo) =>
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)

      this.setState({ eventId: Sentry.captureException(error) })
    })

  render() {
    return this.state.hasError ? (
      <div className="white-screen">
        <b className="white-screen__text">White screen?</b>
        <div className="white-screen__text">
          Something went wrong! The developers got an error log :)
        </div>
        <ClearCache>
          {(args) => (
            <div>
              <Button
                onClick={(e) => {
                  e.preventDefault()
                  args.emptyCacheStorage()
                  localStorage.clear()
                  redirect(LOGIN_PAGE)
                }}
              >
                Clear cache
              </Button>
            </div>
          )}
        </ClearCache>
      </div>
    ) : (
      this.props.children
    )
  }
}

export default ErrorBoundary
