import { takeEvery, call, put } from 'redux-saga/effects'
import {
  FETCH_ORDERS_START,
  FETCH_ORDERS_POWER_PICK_START,
  UPDATE_ORDER_START,
  FETCH_ONE_ORDER_PROCESS_START,
  FETCH_ONE_ORDER_POWER_PICK_START,
  FETCH_SCAN_TO_TRUCK_START,
  FETCH_SCAN_LANE_START,
  PROCESS_PICK,
  PROCESS_LOAD,
  fetchOrdersFail,
  fetchOrdersStart,
  fetchOrdersSuccess,
  fetchOrdersPowerPickFail,
  fetchOrdersPowerPickSuccess,
  fetchOneOrderProcessFail,
  fetchOneOrderProcessSuccess,
  fetchOneOrderPowerPickFail,
  fetchOneOrderPowerPickSuccess,
  fetchScanToTruckEnabledSuccess,
  fetchScanLaneEnabledSuccess,
  AUTO_ASSIGN,
  VALIDATE_CLAIM,
  VALIDATE_START,
  START_LOADING_TASK,
} from './actions'
import {
  fetchOne,
  fetchAll,
  update,
  processPick,
  startLoadingTask,
  fetchScanToTruckAppSetting,
  fetchScanLaneAppSetting,
  processLoad,
} from 'api/order'
import { fetchAllForPowerPick, fetchOnePowerPick } from 'api/orderPowerPick'
import { ALL_ORDERS_STATUSES } from '../../constants/order'
import { redirect } from '../../helpers/index'
import { LOGIN_PAGE } from '../../constants/routes'
import {
  fetchOneTaskAssignmentStart,
  fetchTaskAssignmentListStart,
} from 'store/taskAssignment/actions'
import { autoAssign, validateClaim, validateStart } from '../../api/order'

function* fetchScanLaneEnabled(action) {
  const { payload } = action

  try {
    const response = yield call(fetchScanLaneAppSetting, payload)
    response &&
      (yield put(
        fetchScanLaneEnabledSuccess(response.data.data.scanLaneEnabled),
      ))
  } catch (error) {}
}

function* fetchScanToTruckEnabled(action) {
  const { payload } = action

  try {
    const response = yield call(fetchScanToTruckAppSetting, payload)
    response &&
      (yield put(
        fetchScanToTruckEnabledSuccess(response.data.data.scanToTruckEnabled),
      ))
  } catch (error) {}
}

function* fetchOneOrderProcess(action) {
  const { payload } = action

  try {
    const response = yield call(fetchOne, payload)
    yield put(fetchOneOrderProcessSuccess(response.data.data.item))
  } catch (error) {
    yield put(fetchOneOrderProcessFail())
  }
}

function* startPalletLoadingTask(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(startLoadingTask, request)
    if (afterAction) {
      afterAction(response.data.data)
    }
  } catch (error) {}
}


function* validateOrderClaim(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(validateClaim, request)

    if (afterAction) {
      yield afterAction(response.data.data)
    }
  } catch (error) {}
}

function* validateOrderStart(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(validateStart, request)

    if (afterAction) {
      yield afterAction(response.data.data)
    }
  } catch (error) {}
}

function* fetchOrders(action) {
  const { payload } = action

  try {
    const responseWithOrderList = yield call(fetchAll, payload.params)
    yield put(
      fetchOrdersSuccess({
        items: responseWithOrderList.data.data.items,
        type: payload.type,
      }),
    )
  } catch (error) {
    redirect(LOGIN_PAGE)

    yield put(fetchOrdersFail())
  }
}

function* fetchOrdersPowerPick(action) {
  const { payload } = action
  try {
    const responseWithOrderList = yield call(
      fetchAllForPowerPick,
      payload.params,
    )
    yield put(
      fetchOrdersPowerPickSuccess({
        items: responseWithOrderList.data.data.items,
        type: payload.type,
      }),
    )
  } catch (error) {
    redirect(LOGIN_PAGE)

    yield put(fetchOrdersPowerPickFail())
  }
}

function* fetchOneOrderPowerPick(action) {
  const { payload } = action

  try {
    const response = yield call(fetchOnePowerPick, payload)
    yield put(fetchOneOrderPowerPickSuccess(response.data.data.item))
  } catch (error) {
    yield put(fetchOneOrderPowerPickFail())
  }
}

function* updateOrder(action) {
  const { payload } = action

  try {
    yield call(update, payload)
    yield put(
      fetchOrdersStart({
        params: {
          'filters[order_statuses]': ALL_ORDERS_STATUSES.today,
        },
        type: 'today',
      }),
    )
    yield put(
      fetchOrdersStart({
        params: {
          'filters[order_statuses]': ALL_ORDERS_STATUSES.nextDay,
        },
        type: 'nextDay',
      }),
    )
  } catch (error) {
    yield put(fetchOrdersFail())
  }
}

function* processThePick(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(processPick, request)

    if (
      request.data.task_assignment_id &&
      response.data.data.message === 'SUCCESS'
    ) {
      yield put(
        fetchOneTaskAssignmentStart({ id: request.data.task_assignment_id }),
      )
    }
    if (afterAction) {
      afterAction(response.data.data)
    }
  } catch (error) {}
}

function* processTheLoad(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(processLoad, request)

    if (afterAction) {
      afterAction(response.data.data.isLastPallet)
    }
  } catch (error) {}
}

function* autoAssignTask(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    yield call(autoAssign, request)

    yield put(
      fetchTaskAssignmentListStart({
        warehouse_staff_id: request.data.warehouse_staff_id,
      }),
    )

    if (afterAction) {
      afterAction()
    }
  } catch (error) {}
}

export default function* orderSaga() {
  yield takeEvery(FETCH_ORDERS_START, fetchOrders)
  yield takeEvery(FETCH_ORDERS_POWER_PICK_START, fetchOrdersPowerPick)
  yield takeEvery(FETCH_ONE_ORDER_PROCESS_START, fetchOneOrderProcess)
  yield takeEvery(FETCH_ONE_ORDER_POWER_PICK_START, fetchOneOrderPowerPick)
  yield takeEvery(FETCH_SCAN_TO_TRUCK_START, fetchScanToTruckEnabled)
  yield takeEvery(FETCH_SCAN_LANE_START, fetchScanLaneEnabled)
  yield takeEvery(START_LOADING_TASK, startPalletLoadingTask)
  yield takeEvery(UPDATE_ORDER_START, updateOrder)
  yield takeEvery(PROCESS_PICK, processThePick)
  yield takeEvery(PROCESS_LOAD, processTheLoad)
  yield takeEvery(VALIDATE_CLAIM, validateOrderClaim)
  yield takeEvery(VALIDATE_START, validateOrderStart)
  yield takeEvery(AUTO_ASSIGN, autoAssignTask)
}
