import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import ModalDefault from 'components/common/Modal/ModalDefault'
import Button from 'components/common/Button'
import Active from 'assets/svg/active.svg'
import ActiveGrey from 'assets/svg/active-grey.svg'
import Scanner from 'components/common/Scanner'
import { connect } from 'react-redux'
import './style.scss'

const ModalScanDamageLocationForMultipleCase = (props) => {
  const { isOpen, modalInfo } = props

  const [isScanned, setIsScanned] = useState(false)

  if (!isOpen) {
    return <></>
  }

  const onScan = (barcode) => {
    if (barcode.toString() === modalInfo.damageLocation.upc.toString()) {
      setIsScanned(true)
      setTimeout(modalInfo.onSuccess, 1500)
    } else {
      setIsScanned(false)
      modalInfo.onFailure()
    }
  }

  const onClick = () =>
    isScanned ? modalInfo.onStartScanning() : modalInfo.onBack()

  return (
    <ModalDefault isOpen={isOpen}>
      <Scanner needToRemoveListener={isScanned} onReceiveData={onScan} />
      <div className="modal modal--scan-damaged-location">
        <div className="modal--scan-damaged-location__text">
          Scan Damage Location
        </div>
        <div className="dock">
          <span className="dock__label">LOC</span>
          <span className="dock__name">{`${modalInfo.damageLocation.aisle}-${modalInfo.damageLocation.bay}`}</span>
          {isScanned ? (
            <Active className="dock__icon" />
          ) : (
            <ActiveGrey className="dock__icon" />
          )}
        </div>
        <div className="button">
          <Button onClick={onClick}>
            {isScanned ? 'Start Case Scanning' : 'Back'}
          </Button>
        </div>
      </div>
    </ModalDefault>
  )
}

ModalScanDamageLocationForMultipleCase.propTypes = {
  isOpen: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalScanDamageLocationForMultipleCase',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalScanDamageLocationForMultipleCase',
  ),
})

export default connect(mapStateToProps)(ModalScanDamageLocationForMultipleCase)
