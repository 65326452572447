import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Scanner from 'components/common/Scanner'
import { startUnloadPutawayTask } from 'store/unloadProcess/actions'
import Header from 'components/common/Header'
import ActiveGrey from 'assets/svg/active-grey.svg'
import { PUTAWAY_TASK_PAGE } from 'constants/routes'
import { redirect } from 'helpers/index'
import './style.scss'
import { resetModals, showModal } from 'store/modal/actions'
import { SNACK_SUCCESS, SNACK_ERROR } from 'constants/snackbar'
import { useSnackbar } from 'notistack'
import Badge from '@material-ui/core/Badge'
import { fetchPutawayCount } from 'store/homeAnalytics/actions'
import CloseButton from 'components/common/CloseButton'

const ContainerPalletPutawayPage = (props) => {
  const {
    startUnloadPutawayTask,
    showModal,
    resetModals,
    putawayCount,
    fetchPutawayCount,
  } = props

  const [statusPanel] = useState({
    isProductScanned: false,
    scannedItem: {},
    fetchParams: {},
    text: 'Scan Pallet',
    icon: <ActiveGrey className="scan-indicator" />,
  })

  const { enqueueSnackbar } = useSnackbar()

  const onLoad = () => {
    fetchPutawayCount()
    resetModals()
  }

  let timeoutId
  const reloadAnalytics = () => {
    timeoutId = setTimeout(() => {
      fetchPutawayCount()
      reloadAnalytics()
    }, 30000)
  }

  useEffect(() => {
    reloadAnalytics()
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(onLoad, [])

  const showScanIndicator = () => (
    <>
      {statusPanel.text} {statusPanel.icon}
    </>
  )

  const onStartAfterAction = (data) => {
    resetModals()
    if (data.success) {
      if (data.item.is_completed) {
        enqueueSnackbar('Putaway Successful', SNACK_SUCCESS)
        onLoad()
        return
      } else {
        return redirect(PUTAWAY_TASK_PAGE.replace(':id', data.item.id))
      }
    }

    //pallet not found or already put away
    setTimeout(() => {
      enqueueSnackbar(data.error, SNACK_ERROR)
    }, 100)
  }

  const onScan = (value) => {
    console.log('onScan value', value)
    showModal({
      name: 'modalPending',
    })

    startUnloadPutawayTask({
      request: {
        data: {
          pallet_upc: value,
        },
      },
      afterAction: onStartAfterAction,
    })
  }

  return (
    <div className="container-pallet-putaway-page">
      <Header>
        <div className="title">
          <Badge
            badgeContent={putawayCount}
            color="error"
            overlap="rectangle"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            Container Putaway
          </Badge>
        </div>
        <CloseButton />
      </Header>
      <div className="content">
        <Scanner needToRemoveListener={false} onReceiveData={onScan} />
        <span className="status">{showScanIndicator()}</span>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  putawayCount: state.homeAnalyticsReducer.putawayCount,
})

const mapDispatchToProps = {
  startUnloadPutawayTask,
  showModal,
  resetModals,
  fetchPutawayCount,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContainerPalletPutawayPage)
