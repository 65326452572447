import request, { requestWithRetry } from 'helpers/index'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}qa-holds`

export const fetchMany = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}${payload}`,
    method: 'GET',
  })

export const remove = (payload) =>
  request({
    url: `${apiEndpoint}/${payload.id}`,
    method: 'DELETE',
    data: payload,
  })

export const update = (payload) =>
  request({
    url: `${apiEndpoint}/${payload.id}`,
    method: 'PUT',
    data: payload,
  })
