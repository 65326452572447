import React from 'react'
import * as PropTypes from 'prop-types'
import Modal from 'react-modal'
import './style.scss'

const ModalDefault = ({ isOpen, children, style, animation }) => {
  const INITIAL_TOP_POS = animation === 'top' ? '100%' : 0
  const FINISH_TOP_POS = '0'
  const styleProps = style ? style : {}

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.48)',
      zIndex: 1000,
      ...styleProps.overlay,
    },
    content: {
      background: 'none',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      top: INITIAL_TOP_POS,
      left: 0,
      right: 0,
      bottom: 0,
      border: 'none',
      ...styleProps.content,
    },
  }

  const handleContentRef = (node) => {
    if (animation === 'top' && node) {
      setTimeout(() => {
        node.style.top = FINISH_TOP_POS
      }, 100)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      contentRef={handleContentRef}
    >
      {children}
    </Modal>
  )
}
ModalDefault.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool,
  animation: PropTypes.string,
}

export default ModalDefault
