import React from 'react'
import LinearProgressBar from 'components/common/LinearProgressBar'
import * as PropTypes from 'prop-types'
import { PROCESS_TYPE_LABELS, PROCESS_TYPES } from 'constants/process'
import { TASK_ASSIGNMENT_TYPES } from 'constants/taskAssignment'
import { TASK_PROGRESS_NAME_MAPPER } from 'constants/mappers'

const StaffTaskAssignmentItem = ({ item }) => {
  const { entity, process } = item

  const label = TASK_ASSIGNMENT_TYPES.find(
    (type) => type.id === item.taskType.id,
  ).shortName
  const entityName = PROCESS_TYPE_LABELS[process.process_type_id]

  const getRow = () => {
    switch (process.process_type_id) {
      case PROCESS_TYPES.CONTAINER_UNLOAD_PROCESS_TYPE:
        const received = entity.container_products
          .map((item) =>
            item.received > item.quantity ? item.quantity : item.received,
          )
          .reduce((sum, item) => sum + item)

        return (
          <>
            <div className="tasks-item__title">
              <span className="label">{label}</span>
              <span className="tasks-item__title-text">
                {TASK_PROGRESS_NAME_MAPPER[process.process_type_id]}
              </span>
              <span className="tasks-item__title-desk">{`(${received}/${entity.invoice_cases_quantity})`}</span>
            </div>
            <div className="tasks-item__body">
              <div className="text-wrapper">
                <span className="text text--medium">{entityName}</span>
                <span>{entity.name}</span>
              </div>
              <div className="text-wrapper">
                <span className="text text--medium">Dock</span>
                <span>{entity.dock.name}</span>
              </div>
              <div className="text-wrapper">
                <span className="text text--medium">Case</span>
                <span>{entity.invoice_cases_quantity}</span>
              </div>
            </div>
            <LinearProgressBar
              progress={(received / entity.invoice_cases_quantity) * 100}
            />
          </>
        )
      default:
        return ''
    }
  }

  return <li className="tasks__item tasks-item">{getRow()}</li>
}

StaffTaskAssignmentItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default StaffTaskAssignmentItem
