import { takeEvery, call, put } from 'redux-saga/effects'
import { createTaskSuccess, types } from './actions'
import { create } from 'api/task'

function* createTask(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(create, request)
    yield put(createTaskSuccess(response.data.data.item))

    if (afterAction) {
      afterAction(response.data.data.item)
    }
  } catch (error) {}
}

export default function* taskSaga() {
  yield takeEvery(types.CREATE_TASK_START, createTask)
}
