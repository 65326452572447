import React from 'react'
import Switch from 'react-switch'
import * as PropTypes from 'prop-types'
import './style.scss'

const isSmallScreenOnLoad = window.matchMedia('(max-width: 480px)').matches
const responsiveDiameter = isSmallScreenOnLoad ? 17 : 34
const responsiveHeight = isSmallScreenOnLoad ? 14 : 28
const responsiveWidth = isSmallScreenOnLoad ? 26 : 52

const DEFAULT_STYLES = {
  boxShadow: '0 1px 5px 0 rgba(168, 162, 150, 0.68)',
  activeBoxShadow: '0 1px 5px 0 rgba(168, 162, 150, 0.68)',
  offColor: '#e6e6e6',
  onColor: '#7ed321',
  offCircleColor: '#ffffff',
  onCircleColor: '#ffffff',

  circleDiameter: responsiveDiameter,
  height: responsiveHeight,
  width: responsiveWidth,
}

const Toggle = (props) => {
  const {
    text = '',
    checked,
    className = '',
    style = DEFAULT_STYLES,
    onChange,
    disableSwitch,
  } = props

  return (
    <label className="toggle">
      <span className="toggle__text">{text}</span>
      <Switch
        disabled={disableSwitch}
        checked={checked}
        className={className}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow={style.boxShadow}
        activeBoxShadow={style.activeBoxShadow}
        onColor={style.onColor}
        offColor={style.offColor}
        onHandleColor={style.onCircleColor}
        offHandleColor={style.offCircleColor}
        handleDiameter={style.circleDiameter}
        height={style.height}
        width={style.width}
        onChange={onChange}
      />
    </label>
  )
}

Toggle.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,

  style: PropTypes.shape({
    boxShadow: PropTypes.string,
    activeBoxShadow: PropTypes.string,
    offColor: PropTypes.string,
    onColor: PropTypes.string,
    offCircleColor: PropTypes.string,
    onCircleColor: PropTypes.string,

    circleDiameter: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
  }),

  onChange: PropTypes.func.isRequired,
}

export default Toggle
