import { takeEvery, takeLatest, call, put } from 'redux-saga/effects'
import { types } from './actions'
import {
  getContainerUnloadCount,
  getMyiPodTaskCount,
  getPowerPickingCount,
  getRestockCount,
  getBulkStorageFromCount,
  getBulkStorageToCount,
  getPutawayCount,
  getLoadingCount,
  getInventoryCount,
} from 'api/homeAnalytics'
import {
  fetchContainerUnloadCountSuccess,
  fetchMyiPodTaskCountSuccess,
  fetchPowerPickingCountSuccess,
  fetchRestockCountSuccess,
  fetchBulkRestockFromCountSuccess,
  fetchBulkRestockToCountSuccess,
  fetchPutawayCountSuccess,
  fetchLoadingCountSuccess,
  fetchInventoryCountSuccess,
} from './actions'

function* fetchBulkRestockFromCount(action) {
  const { payload } = action

  try {
    const response = yield call(getBulkStorageFromCount, payload)
    response &&
      (yield put(fetchBulkRestockFromCountSuccess(response.data.data.count)))
  } catch (error) {}
}

function* fetchBulkRestockToCount(action) {
  const { payload } = action

  try {
    const response = yield call(getBulkStorageToCount, payload)
    response &&
      (yield put(fetchBulkRestockToCountSuccess(response.data.data.count)))
  } catch (error) {}
}

function* fetchContainerUnloadCount(action) {
  const { payload } = action

  try {
    const response = yield call(getContainerUnloadCount, payload)
    response &&
      (yield put(fetchContainerUnloadCountSuccess(response.data.data.count)))
  } catch (error) {}
}

function* fetchMyiPodTaskCount(action) {
  const { payload } = action

  try {
    const response = yield call(getMyiPodTaskCount, payload)
    response &&
      (yield put(fetchMyiPodTaskCountSuccess(response.data.data.count)))
  } catch (error) {}
}

function* fetchPowerPickingCount(action) {
  const { payload } = action

  try {
    const response = yield call(getPowerPickingCount, payload)
    response &&
      (yield put(fetchPowerPickingCountSuccess(response.data.data.count)))
  } catch (error) {}
}

function* fetchRestockCount(action) {
  const { payload } = action

  try {
    const response = yield call(getRestockCount, payload)
    response && (yield put(fetchRestockCountSuccess(response.data.data.count)))
  } catch (error) {}
}

function* fetchPutawayCount(action) {
  const { payload } = action

  try {
    const response = yield call(getPutawayCount, payload)
    response && (yield put(fetchPutawayCountSuccess(response.data.data.count)))
  } catch (error) {}
}

function* fetchLoadingCount(action) {
  const { payload } = action

  try {
    const response = yield call(getLoadingCount, payload)
    response && (yield put(fetchLoadingCountSuccess(response.data.data.count)))
  } catch (error) {}
}

function* fetchInventoryCount(action) {
  const { payload } = action

  try {
    const response = yield call(getInventoryCount, payload)
    response &&
      (yield put(fetchInventoryCountSuccess(response.data.data.count)))
  } catch (error) {}
}

export default function* homeAnalyticsSaga() {
  yield takeEvery(
    types.FETCH_CONTAINER_UNLOAD_COUNT_START,
    fetchContainerUnloadCount,
  )
  yield takeEvery(types.FETCH_MY_IPOD_TASK_COUNT_START, fetchMyiPodTaskCount)
  yield takeEvery(types.FETCH_POWER_PICKING_COUNT_START, fetchPowerPickingCount)
  yield takeEvery(types.FETCH_RESTOCKING_COUNT_START, fetchRestockCount)
  yield takeLatest(
    types.FETCH_BULK_RESTOCK_FROM_COUNT_START,
    fetchBulkRestockFromCount,
  )
  yield takeLatest(
    types.FETCH_BULK_RESTOCK_TO_COUNT_START,
    fetchBulkRestockToCount,
  )
  yield takeEvery(types.FETCH_PUTAWAY_COUNT_START, fetchPutawayCount)
  yield takeEvery(types.FETCH_LOADING_COUNT_START, fetchLoadingCount)
  yield takeEvery(types.FETCH_INVENTORY_COUNT_START, fetchInventoryCount)
}
