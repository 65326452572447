import { call, put } from 'redux-saga/effects'
import {
  fetchLocation,
  fetchPallet,
  savePallet,
  fetchProduct,
  saveProduct,
  deletePallet,
  resetLocation,
  copyProductToStage,
} from 'api/inventory'
import {
  clearInventoryLocation,
  fetchInventoryLocationSuccess,
  fetchInventoryLocationFail,
  fetchPalletSuccess,
  fetchPalletFail,
  deletePalletSuccess,
  deletePalletFail,
  resetInventoryLocationSuccess,
  resetInventoryLocationFail,
  fetchProductSuccess,
  fetchProductFail,
  saveProductSuccess,
  saveProductFail,
  savePalletSuccess,
  savePalletFail,
  copyProductToStageSuccess,
  copyProductToStageFail,
  types,
} from './actions'
import { resetModals } from 'store/modal/actions'
import {
  // STATUS_COMPLETED,
  STATUS_IN_PROGRESS,
} from 'constants/processStatuses'
import { redirect } from 'helpers/index'
import { takeLatest } from '@redux-saga/core/effects'

function* clearLocation(action) {
  yield put(clearInventoryLocation(null))
}

function* fetchInventoryLocation(action) {
  const { payload } = action
  try {
    const response = yield call(fetchLocation, payload.upc)

    if (response) {
      if (response.data.data.item.success) {
        yield put(
          fetchInventoryLocationSuccess(response.data.data.item.location),
        )
      }
      payload.afterAction(response.data.data.item)
    } else {
      payload.afterAction({ success: false })
      return
    }
  } catch (error) {
    yield put(fetchInventoryLocationFail())
    payload.afterAction({ success: false })
  }
}

function* fetchWarehousePallet(action) {
  const { payload } = action

  try {
    const response = yield call(fetchPallet, payload)

    if (response) {
      yield put(fetchPalletSuccess(response.data.data.item))
      payload.afterAction(response.data.data.item)
    } else {
      payload.afterAction({ success: false })
      return
    }
  } catch (error) {
    yield put(fetchPalletFail())
    payload.afterAction({ success: false })
  }
}

function* saveWarehousePallet(action) {
  const { payload } = action

  try {
    const response = yield call(savePallet, payload)

    if (response) {
      yield put(savePalletSuccess(response.data.data.item.location))
      payload.afterAction(response.data.data.item)
    } else {
      payload.afterAction({ success: false })
      return
    }
  } catch (error) {
    yield put(savePalletFail())
    payload.afterAction({ success: false })
  }
}

function* fetchWarehouseProduct(action) {
  const { payload } = action

  try {
    const response = yield call(fetchProduct, payload)

    if (response) {
      yield put(fetchProductSuccess(response.data.data.item.location))
      payload.afterAction(response.data.data.item)
    } else {
      payload.afterAction({ success: false })
      return
    }
  } catch (error) {
    yield put(fetchProductFail())
    payload.afterAction({ success: false })
  }
}

function* saveWarehouseProduct(action) {
  const { payload } = action

  try {
    const response = yield call(saveProduct, payload)

    if (response) {
      yield put(saveProductSuccess(response.data.data.item.location))
      payload.afterAction(response.data.data.item)
    } else {
      payload.afterAction({ success: false })
      return
    }
  } catch (error) {
    yield put(saveProductFail())
    payload.afterAction({ success: false })
  }
}

function* deleteWarehousePallet(action) {
  const { payload } = action

  try {
    const response = yield call(deletePallet, payload)

    if (response) {
      yield put(deletePalletSuccess(response.data.data.item.location))
      payload.afterAction(response.data.data.item)
    } else {
      payload.afterAction({ success: false })
      return
    }
  } catch (error) {
    yield put(deletePalletFail())
    payload.afterAction({ success: false })
  }
}

function* resetInventoryLocation(action) {
  const { payload } = action
  try {
    const response = yield call(resetLocation, payload.upc)

    if (response) {
      yield put(resetInventoryLocationSuccess({}))
      payload.afterAction({ success: true })
    } else {
      payload.afterAction({ success: false })
      return
    }
  } catch (error) {
    yield put(resetInventoryLocationFail())
    payload.afterAction({ success: false })
  }
}

function* copyProduct(action) {
  const { payload } = action
  try {
    const response = yield call(copyProductToStage, payload.upc)

    if (response) {
      yield put(copyProductToStageSuccess({}))
      payload.afterAction({ success: true })
    } else {
      payload.afterAction({ success: false })
      return
    }
  } catch (error) {
    yield put(copyProductToStageFail())
    payload.afterAction({ success: false })
  }
}

export default function* inventorySaga() {
  yield takeLatest(types.CLEAR_INVENTORY_LOCATION, clearInventoryLocation)
  yield takeLatest(types.FETCH_INVENTORY_LOCATION_START, fetchInventoryLocation)
  yield takeLatest(types.FETCH_PALLET_START, fetchWarehousePallet)
  yield takeLatest(types.DELETE_PALLET_START, deleteWarehousePallet)
  yield takeLatest(types.RESET_INVENTORY_LOCATION_START, resetInventoryLocation)
  yield takeLatest(types.FETCH_PRODUCT_START, fetchWarehouseProduct)
  yield takeLatest(types.SAVE_PRODUCT_START, saveWarehouseProduct)
  yield takeLatest(types.SAVE_PALLET_START, saveWarehousePallet)
  yield takeLatest(types.COPY_PRODUCT_TO_STAGE_START, copyProduct)
}
