export const HOME_PAGE = '/'
export const LOGIN_PAGE = '/login'
export const PREPARE_UNLOAD_PROCESS_LIST_PAGE = '/pupl'
export const UNLOAD_CONTAINER_PRODUCT_LIST_PAGE = '/ucpl/:id'

export const TASK_LIST_PAGE = '/ta'
export const TASK_PICKING_PAGE = '/tap/:id'
export const TASK_PUTAWAY_PAGE = '/tapa/:id'
export const CONTAINER_PALLET_PUTAWAY_PAGE = '/cppp'

export const INVENTORY_PAGE = '/inv-scan'

export const POWER_PICKING_LIST_PAGE = '/pp'
export const POWER_PICKING_PAGE = '/ppp/:id'
export const START_POWER_PICKING_PAGE = '/spp/:id'
export const POWER_PICKING_PULLSHEET_PAGE = '/ppps/:id'

export const RELOCATE_PRODUCT_PAGE = '/rpp'
export const RELOCATE_TASK_PAGE = '/rtp/:id'

export const START_PICKING_PAGE = '/sp/:id'
export const START_RESTOCK_PAGE = '/sr/:id'
export const START_BULK_STORAGE_PAGE = '/sbs/:id'
export const BULK_RESTOCK_TO_STORAGE_PAGE = '/brts/:id'
export const RESTOCK_TASK_LIST_PAGE = '/rtl'
export const BULK_STORAGE_TASK_LIST = '/bstl'
export const DAMAGED_RESTOCK_TASK_PAGE = '/drtp/:ids/:pageType'
export const PICK_TO_PICK_RESTOCK_TASK_PAGE = '/ptprtp/:id'
export const SCANNING_DAMAGE_CASE_PAGE = '/sdcp'
export const SCANNING_CASE_UPS_PAGE = '/scup'

export const ORDERS_TO_LOAD_LIST_PAGE = '/otllp'
export const PALLETS_TO_LOAD_LIST_PAGE = '/ptolp/:id'
export const PALLET_LOADING_PAGE = '/plp/:id'
export const LOADING_PAGE = '/lp/:id'

export const PUTAWAY_TASK_PAGE = '/ptp/:id'

export const SCAN_QUARANTINE_LOCATION_PAGE = '/sclp'
export const CONTAINER_LIST_PAGE = '/clp'
export const SCAN_PALLET_UPC_PAGE = '/spup/:id'

export const PARTIAL_PALLET_MOVE_TO_WRAP_PAGE = '/ppmtwp/:processId/:palletId'
export const COMBINE_PALLET_PAGE = '/cpp/:processId/:palletId'

export const RESTOCK_QA_HOLD_PAGE = '/rqh/:processId'

export const SCAN_DAMAGE_LOCATION_RESOLUTION_PAGE = '/sdlr'
export const SCAN_DAMAGE_CASE_RESOLUTION_PAGE = '/sdcr'
export const DAMAGE_RESOLUTION_PAGE = '/dr/:id'

export const PICKING_WRAP_PALLET_PAGE = '/pwp/:orderPalletId'
export const POWER_PICKING_WRAP_PALLET_PAGE =
  '/ppwp/:orderPalletId/:nextTaskAssignmentId'

export const MOVE_TO_LANE_PAGE = '/mtl/:orderPalletId'
export const POWER_PICKING_MOVE_TO_LANE_PAGE =
  '/ppmtl/:orderPalletId/:powerPickProcessId/:nextTaskAssignmentId'
export const SCAN_LANE_PAGE =
  '/sl/:orderPalletId/:powerPickProcessId/:nextTaskAssignmentId'

export const RESTOCK_STORAGE_TO_STORAGE_PAGE = '/rsts/:id'

export const RESTOCK_SMALL_PARCEL_PAGE = '/rspp/:id'

export const INVENTORY_TOOL_SCAN_LOCATION_PAGE = '/itslp'
export const INVENTORY_TOOL_SCAN_CASE_PAGE = '/itscp/:id'
export const INVENTORY_TOOL_PAGE = '/itp/:id'
