export const CARRIERS = {
  CARRIER_FEDEX_E: 1,
  CARRIER_FEDEX_P: 2,
  CARRIER_TFORCE: 3,
  CARRIER_ESTES: 4,
  CARRIER_YRC: 5,
  CARRIER_FEDEX_G: 6,
  CARRIER_UPS_G: 7,
  CARRIER_UPS_NEXT_DAY_AIR: 8,
  CARRIER_UPS_SECOND_DAY_AIR: 9,
  CARRIER_UPS_THREE_DAY: 10,
  CARRIER_USPS_P: 11,
  CARRIER_USPS_P_EX: 12,
  CARRIER_USPS_PARCEL: 13,
  CARRIER_76DELIVERY: 14,
  CARRIER_PICKUP: 15,
  CARRIER_OTHER: 16,
  CARRIER_USPS_FLAT_RATE: 17,
  CARRIER_GROUP_BUY: 18,
  CARRIER_XPO: 19,
  CARRIER_FTL_OTHER: 20,
  CARRIER_FTL_LANDSTAR: 21,
  CARRIER_FTL_CFI: 22,
  CARRIER_FTL_FEDEX_VOLUME: 23,
  CARRIER_ABF: 25,
  CARRIER_RL_STANDARD: 27,
  CARRIER_RL_GAURANTEED: 28,
}

export const CARRIER_GROUPS = {
  ALL_CARRIERS_FILTER: [
    CARRIERS.CARRIER_FEDEX_E,
    CARRIERS.CARRIER_FEDEX_P,
    CARRIERS.CARRIER_FTL_FEDEX_VOLUME,
    CARRIERS.CARRIER_TFORCE,
    CARRIERS.CARRIER_ABF,
    CARRIERS.CARRIER_XPO,
    CARRIERS.CARRIER_76DELIVERY,
    CARRIERS.CARRIER_PICKUP,
    CARRIERS.CARRIER_FTL_LANDSTAR,
    CARRIERS.CARRIER_FTL_CFI,
    CARRIERS.CARRIER_FTL_OTHER,
    CARRIERS.CARRIER_OTHER,
    CARRIERS.CARRIER_RL_STANDARD,
    CARRIERS.CARRIER_RL_GAURANTEED,
  ],
  FEDEX_FILTER: [
    CARRIERS.CARRIER_FEDEX_E,
    CARRIERS.CARRIER_FEDEX_P,
    CARRIERS.CARRIER_FTL_FEDEX_VOLUME,
  ],
  TFORCE_FILTER: [CARRIERS.CARRIER_TFORCE],
  ABF_FILTER: [CARRIERS.CARRIER_ABF],
  XPO_FILTER: [CARRIERS.CARRIER_XPO],
  DELIVERY_FILTER: [CARRIERS.CARRIER_76DELIVERY],
  PICKUP_FILTER: [CARRIERS.CARRIER_PICKUP],
  LANDSTAR_FILTER: [CARRIERS.CARRIER_FTL_LANDSTAR],
  CFI_FILTER: [CARRIERS.CARRIER_FTL_CFI],
  FTL_OTHER_FILTER: [CARRIERS.CARRIER_FTL_OTHER],
  LTL_OTHER_FILTER: [CARRIERS.CARRIER_OTHER],
  RL_FILTER: [CARRIERS.CARRIER_RL_STANDARD, CARRIERS.CARRIER_RL_GAURANTEED],
}
