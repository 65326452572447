import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import './style.scss'
import TableList from 'components/common/TableList'
import Button from 'components/common/Button'
import AvatarList from 'components/features/StaffCard/AvatarList'
import Header from 'components/common/Header'
import CloseButton from 'components/common/CloseButton'
import {
  fetchUnloadProcessListStart,
  updateUnloadProcess,
} from 'store/unloadProcess/actions'
import { redirect } from 'helpers/index'
import { resetModals, showModal as showModalAction } from 'store/modal/actions'
import { STATUS_CREATED, STATUS_IN_PROGRESS } from 'constants/processStatuses'
import TrackIcon from 'assets/svg/track.svg'
import { assignTeamToUnload } from 'store/taskAssignment/actions'
import ExclamationSmall from 'assets/svg/exclamation-sign.svg'
import { UNLOAD_CONTAINER_PRODUCT_LIST_PAGE } from 'constants/routes'

const columnsContainerUnload = [
  {
    id: 1,
    label: 'Container',
    value: 'containerName',
  },
  {
    id: 2,
    label: 'Vendor',
    value: 'vendor',
  },
  {
    id: 3,
    label: 'Dock',
    value: 'dockId',
  },
  {
    id: 4,
    label: 'Cases Loaded',
    value: 'caseQuantity',
  },
  {
    id: 5,
    label: 'Pallets Loaded',
    value: 'invoicePalletsQuantity',
  },
  {
    id: 6,
    label: 'Pallets Put Away',
    value: 'palletsPutAway',
  },
  {
    id: 7,
    label: 'Team',
    value: 'team',
  },
  {
    id: 8,
    label: '',
    value: 'action',
  },
]

const columnsContainerReport = [
  {
    id: 1,
    label: 'Container',
    value: 'containerName',
  },
  {
    id: 2,
    label: 'Vendor',
    value: 'vendor',
  },
  {
    id: 3,
    label: 'Model',
    value: 'model',
  },
  {
    id: 4,
    label: 'Product Name',
    value: 'name',
  },
  {
    id: 5,
    label: 'Missing Information',
    value: 'missing_info',
  },
]

const PrepareUnloadProcessListPage = (props) => {
  const {
    isFetchedItems,
    items = [],
    showModalAction,
    resetModals,
    fetchList,
    startProcess,
    assignTeamToUnload,
  } = props

  const [itemList, setItemList] = useState([])

  const onLoad = () => {
    showModalAction({ name: 'modalPending' })
  }

  const onChangeItems = () => {
    if (items.length > 0 || isFetchedItems) {
      resetModals()
    }
  }

  useEffect(onLoad, [])
  useEffect(onChangeItems, [items])

  useEffect(() => {
    fetchList({
      statuses: [STATUS_CREATED, STATUS_IN_PROGRESS],
    })
  }, [fetchList])

  useEffect(() => {
    setItemList(items.length ? items : itemList)
  }, [items, itemList])

  // const afterAction = () => {
  //   setTimeout(() => {
  //     fetchList({
  //       statuses: [STATUS_CREATED, STATUS_IN_PROGRESS],
  //     })
  //   }, 100)
  // }

  const getNormalizedContainerProductsWithMissingInfo = (items) =>
    items.map((item) => ({
      id: item.products_id,
      containerName: item.products_container_number,
      vendor: item.products_manufacturer_name,
      model: item.products_model,
      name: item.products_name,
      missing_info: item.missing_info,
      options: {
        selectable: false,
        expandable: true,
        swipeable: false,
        scrollable: true,
      },
    }))

  const getMissingProductsReport = (containerList) => {
    let productsWithMissingInfo = []
    let products = []
    for (let i = 0; i < containerList.length; i++) {
      products = getNormalizedContainerProductsWithMissingInfo(
        containerList[i].products_with_missing_info,
      )
      for (let j = 0; j < products.length; j++) {
        productsWithMissingInfo.push(products[j])
      }
    }
    return productsWithMissingInfo
  }

  const getNormalizedItems = (items) =>
    items.map((item) => ({
      id: item.id,
      priority: item.container.container_priority ? 'high' : '',
      containerName: item.container.name,
      vendor: item.supplier_name,
      dockId: item.dock_id,
      invoicePalletsQuantity:
        item.status === STATUS_IN_PROGRESS
          ? `${item.pallets_loaded}/${item.pallets_to_load}`
          : `~ 0/${item.invoice_pallets_quantity}`,
      caseQuantity:
        item.status === STATUS_IN_PROGRESS
          ? `${item.cases_loaded}/${item.cases_to_load}`
          : `~ 0/${item.container.invoice_cases_quantity}`,
      palletsPutAway:
        item.status === STATUS_IN_PROGRESS
          ? `${item.pallets_put_away}/${item.pallets_to_load}`
          : 0,
      team: !item.team.length ? (
        <Button onClick={() => onTapAssign(item.id)} className="btn">
          Assign
        </Button>
      ) : (
        <AvatarList onClick={() => onTapAssign(item.id)} items={item.team} />
      ),
      action:
        item.status === STATUS_CREATED &&
        item.team.length &&
        !item.has_products_with_missing_info ? (
          <Button onClick={() => onSelectStartUnload(item.id)} className="btn">
            Start
          </Button>
        ) : item.status === STATUS_IN_PROGRESS && item.fully_loaded ? (
          <Button onClick={() => onSelectResumeUnload(item.id)} className="btn">
            Reprint
          </Button>
        ) : item.status === STATUS_IN_PROGRESS ? (
          <Button onClick={() => onSelectResumeUnload(item.id)} className="btn">
            Resume
          </Button>
        ) : item.has_products_with_missing_info ? (
          <Button
            onClick={() => {
              showModalAction({ name: 'modalPending' })
              fetchList({
                statuses: [STATUS_CREATED, STATUS_IN_PROGRESS],
              })
            }}
            className="btn"
          >
            Refresh
          </Button>
        ) : (
          ''
        ),
      options: {
        expandable: false,
      },
    }))

  const onTapAssign = (processId) => {
    const item = items.find((item) => item.id === processId)

    showModal(item, 'workersAssignment')
  }

  const onSelectResumeUnload = (processId) => {
    redirect(UNLOAD_CONTAINER_PRODUCT_LIST_PAGE.replace(':id', processId))
  }

  const onSelectStartUnload = (processId) => {
    const item = items.find((item) => item.id === processId)

    showModal(item, 'confirm')
  }

  const showModal = (item, modalType) => {
    switch (modalType) {
      case 'confirm':
        showModalAction({
          name: 'modalConfirm',
          className: 'modal--unload-start',
          onAccept: () => onStartUnload(item),
          onDeny: resetModals,
          children: (
            <div className="modal--content-centered">
              <TrackIcon
                className="icon--track"
                height={68}
                width={112}
                viewBox="0 0 38 23"
              />
              <div className="text--bold">Start Unload</div>
              <div className="text">{`Container ${item.container.name} | Dock ${item.dock_id}`}</div>
            </div>
          ),
        })
        break

      case 'workersAssignment':
        showModalAction({
          name: 'modalWorkersAssignment',
          processInfo: item,
          onAccept: onAssign,
          onDeny: resetModals,
          children: <div className="text--bold">Workers assignment</div>,
        })
        break

      default:
        return
    }
  }

  const onAssign = (processInfo, items) => {
    let teamMembers = items.map((item) => {
      return {
        warehouse_staff_id: item.warehouseStaff.id,
        role_id: item.role_id,
      }
    })

    assignTeamToUnload({
      request: {
        data: {
          process_id: processInfo.id,
          team_members: teamMembers,
        },
      },
      afterAction: () => {
        fetchList({
          statuses: [STATUS_CREATED, STATUS_IN_PROGRESS],
        })
      },
    })

    resetModals()
    showModalAction({ name: 'modalPending' })
  }

  const onStartUnload = (processItem) => {
    resetModals()
    const team = processItem.team

    if (!team) {
      return showModalAction({
        name: 'modalWarning',
        onAccept: resetModals,
        text: (
          <div className="text">Assign worker(s) prior to Start Unload</div>
        ),
        className: 'modal--tasks',
        modalIcon: <ExclamationSmall />,
      })
    }

    if (!processItem.team_has_dock_lead) {
      return showModalAction({
        name: 'modalWarning',
        onAccept: resetModals,
        text: (
          <div className="text">Assign dock lead prior to Start Unload</div>
        ),
        className: 'modal--tasks',
        modalIcon: <ExclamationSmall />,
      })
    }
    
    if (processItem.main_and_bulk_storage_are_full) {
      return showModalAction({
        name: 'modalWarning',
        onAccept: resetModals,
        children: (
          <div className="modal--content-centered">
            <div className="text">There is not enough room in both Main and Bulk Storage to fully unload this container.</div> 
            <div className="text">Unable to Start the Unload Process</div>
          </div>
        ),
        className: 'modal--tasks',
        modalIcon: <ExclamationSmall/>,
      })
    }

    showModalAction({ name: 'modalPending' })

    return startProcess({
      id: processItem.id,
      data: {
        id: processItem.id,
        status: STATUS_IN_PROGRESS,
      },
    })
  }

  const ContainerReport = (props) => {
    return props.productsWithMissingInfo.length > 0 ? (
      <>
        <div className="red banner">
          <div className="title">Daily Container Report</div>
        </div>
        <TableList
          columns={columnsContainerReport}
          rows={props.productsWithMissingInfo}
          rowBackgroundElement={<TrackIcon />}
          className="list--products"
        />
      </>
    ) : (
      <>
        <div className="green banner">
          <div className="title">Daily Container Report</div>
        </div>
        <div className="no-products-with-missing-info">
          <div className="no-products-with-missing-info__text">
            No Products with Missing Information
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="page page--process-list">
      <Header>
        <div className="title">Container Unload List</div>
        <CloseButton />
      </Header>

      <div className="content">
        <TableList
          columns={columnsContainerUnload}
          rows={getNormalizedItems(itemList)}
          rowBackgroundElement={<TrackIcon />}
        />
        <ContainerReport
          productsWithMissingInfo={getMissingProductsReport(itemList)}
        />
      </div>
    </div>
  )
}

PrepareUnloadProcessListPage.propTypes = {
  items: PropTypes.array.isRequired,

  fetchList: PropTypes.func,
}

const mapStateToProps = (state) => ({
  isFetchedItems: state.unloadProcess.isFetched,
  items: state.unloadProcess.list,
})

const mapDispatchToProps = {
  fetchList: fetchUnloadProcessListStart,
  showModalAction: showModalAction,
  resetModals: resetModals,
  startProcess: updateUnloadProcess,
  assignTeamToUnload: assignTeamToUnload,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrepareUnloadProcessListPage)
