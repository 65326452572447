export const TASK_TYPES = {
  AVAILABLE_TYPE: 0,
  CONTAINER_UNLOAD_TYPE: 1,
  UNLOAD_WRAP_TYPE: 2,
  UNLOAD_PUTAWAY_TYPE: 3,
  PUTAWAY_TYPE: 7,
  LOCATION_PICKING_TYPE: 5,
  PICKING_TYPE: 8,
  RESTOCKING_TYPE: 9,
  RELOCATE_TYPE: 10,
  PALLET_LOADING_TYPE: 11,
  LOADING_TYPE: 12,
}

export const TASK_STATUSES = {
  CREATED_TYPE: 1,
  STARTED_TYPE: 2,
  PAUSED_TYPE: 3,
  STOPPED_TYPE: 4,
  FINISHED_TYPE: 5,
  FAILED_TYPE: 6,
}

export const RESTOCK_TYPES = {
  STAGING_TYPE: 1,
  PICK_SELECT_TYPE: 2,
  ZONE_PICK_TYPE: 3,
  SMALL_PARCEL_TYPE: 4,
  DAMAGE_TYPE: 5,
  QA_HOLD_TYPE: 6,
  REMOVED_TYPE: 7,
  COMBINE_TYPE: 8,
  PICK_TO_PICK_TYPE: 9,
  STORAGE_TO_STORAGE_TYPE: 10,
  BULK_STORAGE_TO_STORAGE_TYPE: 13,
}

export const RESTOCK_TYPE_LABELS = {
  [RESTOCK_TYPES.STAGING_TYPE]: 'Staging',
  [RESTOCK_TYPES.SMALL_PARCEL_TYPE]: 'Small Parcel',
  [RESTOCK_TYPES.ZONE_PICK_TYPE]: 'Zone Pick',
  [RESTOCK_TYPES.PICK_SELECT_TYPE]: 'Pick Select Restock',
  [RESTOCK_TYPES.COMBINE_TYPE]: 'Combine',
  [RESTOCK_TYPES.PICK_TO_PICK_TYPE]: 'Pick to Pick',
  [RESTOCK_TYPES.STORAGE_TO_STORAGE_TYPE]: 'Storage to Storage',
  [RESTOCK_TYPES.DAMAGE_TYPE]: 'Damage',
  [RESTOCK_TYPES.QA_HOLD_TYPE]: 'QA Hold',
  [RESTOCK_TYPES.REMOVED_TYPE]: 'Removed',
  [RESTOCK_TYPES.BULK_STORAGE_TO_STORAGE_TYPE]: 'Bulk Storage to Main',
}

export const RESTOCK_TYPE_GROUPS = {
  BULK_STORAGE_FILTER: [RESTOCK_TYPES.BULK_STORAGE_TO_STORAGE_TYPE],
  ALL_RESTOCK_FILTER: [
    RESTOCK_TYPES.STAGING_TYPE,
    RESTOCK_TYPES.PICK_SELECT_TYPE,
    RESTOCK_TYPES.ZONE_PICK_TYPE,
    RESTOCK_TYPES.SMALL_PARCEL_TYPE,

    RESTOCK_TYPES.DAMAGE_TYPE,
    RESTOCK_TYPES.QA_HOLD_TYPE,
    RESTOCK_TYPES.REMOVED_TYPE,

    RESTOCK_TYPES.COMBINE_TYPE,
    RESTOCK_TYPES.PICK_TO_PICK_TYPE,
    RESTOCK_TYPES.STORAGE_TO_STORAGE_TYPE,
  ],
  RESTOCK_FILTER: [
    RESTOCK_TYPES.STAGING_TYPE,
    RESTOCK_TYPES.PICK_SELECT_TYPE,
    RESTOCK_TYPES.ZONE_PICK_TYPE,
    RESTOCK_TYPES.SMALL_PARCEL_TYPE,
  ],
  RELOCATE_FILTER: [
    RESTOCK_TYPES.COMBINE_TYPE,
    RESTOCK_TYPES.PICK_TO_PICK_TYPE,
    RESTOCK_TYPES.STORAGE_TO_STORAGE_TYPE,
  ],
  TO_INV_FILTER: [
    RESTOCK_TYPES.DAMAGE_TYPE,
    RESTOCK_TYPES.QA_HOLD_TYPE,
    RESTOCK_TYPES.REMOVED_TYPE,
  ],
}

export const TASK_ASSIGNMENT_TYPES = [
  {
    id: TASK_TYPES.AVAILABLE_TYPE,
    name: 'available',
    label: 'Available',
    shortName: 'A',
  },
  {
    id: TASK_TYPES.CONTAINER_UNLOAD_TYPE,
    name: 'unload',
    label: 'Unload',
    shortName: 'UL',
  },
  {
    id: TASK_TYPES.PUTAWAY_TYPE,
    name: 'putaway',
    label: 'Putaway',
    shortName: 'Pa',
  },
  {
    id: TASK_TYPES.PICKING_TYPE,
    name: 'picking',
    label: 'Picking',
    shortName: 'P',
  },
  {
    id: TASK_TYPES.RESTOCKING_TYPE,
    name: 'restocking',
    label: 'Restocking',
    shortName: 'Res',
  },
  {
    id: TASK_TYPES.RELOCATE_TYPE,
    name: 'relocate',
    label: 'Relocate',
    shortName: 'Rel',
  },
  {
    id: TASK_TYPES.LOADING_TYPE,
    name: 'loading',
    label: 'Loading',
    shortName: 'L',
  },
]
