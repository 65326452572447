import { fetchOneOrderPickStatusSuccess, types } from './actions'
import * as api from 'api/orderPickStatus'
import { takeLatest, call, put } from 'redux-saga/effects'

function* fetchOneOrderPickStatus(action) {
  const { payload } = action

  try {
    const response = yield call(api.fetchOneBy, payload)
    yield put(fetchOneOrderPickStatusSuccess(response.data.data))
  } catch (error) {}
}

function* updateOrderPickStatus(action) {
  const { payload } = action

  try {
    yield call(api.update, payload)
  } catch (error) {}
}

export default function* orderPickStatusSaga() {
  yield takeLatest(
    types.FETCH_ONE_ORDER_PICK_STATUS_START,
    fetchOneOrderPickStatus,
  )
  yield takeLatest(types.UPDATE_ORDER_PICK_STATUS_START, updateOrderPickStatus)
}
