// note: new Location Type updates here should also be considered for frontend/src/constants/locationTypes.js AND pwa_frontend/src/components/features/UnloadProcess/UnloadContainerProductListPage/index.js
export const LOCATION_TYPE = {
  PICK_SELECT_TYPE_ID: 1,
  STORAGE_TYPE_ID: 2,
  ZONE_TYPE_ID: 3,
  STAGING_TYPE_ID: 5,
  SHIPPING_TYPE_ID: 6,
  CROSSDOCK_TYPE_ID: 7,
  DAMAGE_TYPE_ID: 8,
  DOCK_TYPE_ID: 9,
  QUARANTINE_TYPE_ID: 10,
  SMALL_PARCEL_PICK_SELECT_TYPE_ID: 11,
  OVERSIZED_PICK_TYPE_ID: 12,
  OVERSIZED_STORAGE_TYPE_ID: 13,
  OFFSITE_PICK_SELECT_TYPE_ID: 14,
  PICK_EXTRA_TYPE_ID: 15,
  PICKUP_LANE_ID: 16,
  SMALL_PARCEL_PIECE_ID: 17,
  RETURNS_TYPE_ID: 18,
  INVENTORY_HOLD_TYPE_ID: 19,
  LEVEL_1_STORAGE_TYPE_ID: 20,
  LEVEL_1_BULK_STORAGE_TYPE_ID: 21,
  STORAGE_4_DEEP_TYPE_ID: 22,
  BULK_STORAGE_TYPE_ID: 23,
  INVENTORY_HOLD_BULK_STORAGE_TYPE_ID: 24,
}

export const ANY_STORAGE_TYPE = [
  LOCATION_TYPE.STORAGE_TYPE_ID,
  LOCATION_TYPE.OVERSIZED_STORAGE_TYPE_ID,
  LOCATION_TYPE.INVENTORY_HOLD_TYPE_ID,
  LOCATION_TYPE.LEVEL_1_STORAGE_TYPE_ID,
  LOCATION_TYPE.LEVEL_1_BULK_STORAGE_TYPE_ID,
  LOCATION_TYPE.STORAGE_4_DEEP_TYPE_ID,
  LOCATION_TYPE.BULK_STORAGE_TYPE_ID,
  LOCATION_TYPE.INVENTORY_HOLD_BULK_STORAGE_TYPE_ID,
]
export const ANY_PICK_TYPE = [
  LOCATION_TYPE.PICK_SELECT_TYPE_ID,
  LOCATION_TYPE.ZONE_TYPE_ID,
  LOCATION_TYPE.SMALL_PARCEL_PICK_SELECT_TYPE_ID,
  LOCATION_TYPE.OVERSIZED_PICK_TYPE_ID,
  LOCATION_TYPE.OFFSITE_PICK_SELECT_TYPE_ID,
  LOCATION_TYPE.PICK_EXTRA_TYPE_ID,
  LOCATION_TYPE.SMALL_PARCEL_PIECE_ID,
]
