import { types } from '../actions'

const INIT = {}

const productReducer = (state = INIT, action) => {
  const { type, payload } = action

  switch (type) {
    default:
      return state
  }
}

export default productReducer
