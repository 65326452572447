export const types = {
  FETCH_LOCATIONS_START: 'FETCH_LOCATIONS_START',
  FETCH_LOCATIONS_SUCCESS: 'FETCH_LOCATIONS_SUCCESS',
  FETCH_LOCATIONS_FAIL: 'FETCH_LOCATIONS_FAIL',

  FETCH_ONE_LOCATION_START: 'FETCH_ONE_LOCATION_START',
  FETCH_ONE_LOCATION_BY_START: 'FETCH_ONE_LOCATION_BY_START',
  FETCH_ONE_LOCATION_SUCCESS: 'FETCH_ONE_LOCATION_SUCCESS',

  CREATE_LOCATION_START: 'CREATE_LOCATION_START',
  CREATE_LOCATION_SUCCESS: 'CREATE_LOCATION_SUCCESS',
  CREATE_LOCATION_FAIL: 'CREATE_LOCATION_FAIL',

  RESET_LOCATION_STATUSES: 'RESET_LOCATION_STATUSES',
  RESET_CURRENT_LOCATION: 'RESET_CURRENT_LOCATION',

  CREATE_GROUP_LOCATION_START: 'CREATE_GROUP_LOCATION_START',
  CREATE_GROUP_LOCATION_SUCCESS: 'CREATE_GROUP_LOCATION_SUCCESS',
  CREATE_GROUP_LOCATION_FAIL: 'CREATE_GROUP_LOCATION_FAIL',

  DELETE_LOCATION: 'DELETE_LOCATION',

  UPDATE_LOCATION_START: 'UPDATE_LOCATION_START',
  UPDATE_LOCATION_SUCCESS: 'UPDATE_LOCATION_SUCCESS',
  UPDATE_LOCATION_FAIL: 'UPDATE_LOCATION_FAIL',

  SORT_LOCATIONS: 'SORT_LOCATIONS',

  FETCH_AVAILABLE_BAYS_START: 'FETCH_AVAILABLE_BAYS_START',
  FETCH_AVAILABLE_BAYS_SUCCESS: 'FETCH_AVAILABLE_BAYS_SUCCESS',
  FETCH_AVAILABLE_BAYS_FAIL: 'FETCH_AVAILABLE_BAYS_FAIL',

  FETCH_LOCATIONS_FOR_LANE_START: 'FETCH_LOCATIONS_FO_LANE_START',
  FETCH_LOCATIONS_FOR_LANE_SUCCESS: 'FETCH_LOCATIONS_FO_LANE_SUCCESS',
}

export const fetchLocationsStart = (payload) => ({
  type: types.FETCH_LOCATIONS_START,
  payload,
})

export const fetchLocationsSuccess = (payload) => ({
  type: types.FETCH_LOCATIONS_SUCCESS,
  payload,
})

export const fetchLocationsForLaneStart = (payload) => ({
  type: types.FETCH_LOCATIONS_FOR_LANE_START,
  payload,
})

export const fetchLocationsForLaneSuccess = (payload) => ({
  type: types.FETCH_LOCATIONS_FOR_LANE_SUCCESS,
  payload,
})

export const fetchLocationsFail = (payload) => ({
  type: types.FETCH_LOCATIONS_FAIL,
  payload,
})

export const createLocationStart = (payload) => ({
  type: types.CREATE_LOCATION_START,
  payload,
})

export const createLocationSuccess = (payload) => ({
  type: types.CREATE_LOCATION_SUCCESS,
  payload,
})

export const createLocationFail = (payload) => ({
  type: types.CREATE_LOCATION_FAIL,
  payload,
})

export const resetLocationStatuses = (payload) => ({
  type: types.RESET_LOCATION_STATUSES,
  payload,
})

export const resetCurrentLocation = (payload) => ({
  type: types.RESET_CURRENT_LOCATION,
  payload,
})

export const deleteLocation = (payload) => ({
  type: types.DELETE_LOCATION,
  payload,
})

export const sortLocations = (payload) => ({
  type: types.SORT_LOCATIONS,
  payload,
})

export const fetchAvailableBaysStart = (payload) => ({
  type: types.FETCH_AVAILABLE_BAYS_START,
  payload,
})

export const fetchAvailableBaysSuccess = (payload) => ({
  type: types.FETCH_AVAILABLE_BAYS_SUCCESS,
  payload,
})

export const fetchAvailableBaysFail = (payload) => ({
  type: types.FETCH_AVAILABLE_BAYS_FAIL,
  payload,
})

export const fetchOneLocationStart = (payload) => ({
  type: types.FETCH_ONE_LOCATION_START,
  payload,
})

export const fetchOneLocationByStart = (payload) => ({
  type: types.FETCH_ONE_LOCATION_BY_START,
  payload,
})

export const fetchOneLocationSuccess = (payload) => ({
  type: types.FETCH_ONE_LOCATION_SUCCESS,
  payload,
})

export const updateLocationStart = (payload) => ({
  type: types.UPDATE_LOCATION_START,
  payload,
})

export const updateLocationSuccess = (payload) => ({
  type: types.UPDATE_LOCATION_SUCCESS,
  payload,
})

export const updateLocationFail = (payload) => ({
  type: types.UPDATE_LOCATION_FAIL,
  payload,
})

export const createGroupLocationStart = (payload) => ({
  type: types.CREATE_GROUP_LOCATION_START,
  payload,
})

export const createGroupLocationSuccess = (payload) => ({
  type: types.CREATE_GROUP_LOCATION_SUCCESS,
  payload,
})

export const createGroupLocationFail = (payload) => ({
  type: types.CREATE_GROUP_LOCATION_FAIL,
  payload,
})
