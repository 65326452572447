export const types = {
  UPDATE_ORDER_PRODUCT_PICK_LOCATION: 'UPDATE_ORDER_PRODUCT_PICK_LOCATION',
  CHECK_STOCK: 'CHECK_STOCK',
  CHECK_STOCK_ORDER: 'CHECK_STOCK_ORDER',
}

export const updateOrderProductPickLocation = (payload) => ({
  type: types.UPDATE_ORDER_PRODUCT_PICK_LOCATION,
  payload,
})

export const checkStock = (payload) => ({
  type: types.CHECK_STOCK,
  payload,
})

export const checkStockOrder = (payload) => ({
  type: types.CHECK_STOCK_ORDER,
  payload,
})
