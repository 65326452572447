export const types = {
  HIDE_TEMPORARY: 'CHANGE_TEMPORARY_HIDDEN',
  HIDE_PERMANENTLY: 'FETCH_DAMAGE_PRODUCTS_SUCCESS',
  RESET_TEMPORARY: 'RESET_TEMPORARY',
}

export const hideTemporary = (payload) => ({
  type: types.HIDE_TEMPORARY,
  payload,
})

export const hidePermanently = (payload) => ({
  type: types.HIDE_PERMANENTLY,
  payload,
})

export const resetTemporary = (payload) => ({
  type: types.RESET_TEMPORARY,
  payload,
})
