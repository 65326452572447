import request, { requestWithRetry } from 'helpers/index'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}order-pick-status`

export const fetchOneBy = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}/get-one-by${payload}`,
    method: 'GET',
  })

export const update = (payload) =>
  request({
    url: `${apiEndpoint}/${payload.id}`,
    method: 'PUT',
    data: payload,
  })
