export const CARRIERS = {
  CARRIER_UPS: 1,
  CARRIER_FEDEX_P: 2,
  CARRIER_FEDEX_E: 3,
  CARRIER_PICKUP: 4,
  CARRIER_76DELIVERY: 5,
}

export const ORDER_PRICE_LEVELS = {
  SILVER: 16,
  GOLD: 17,
  WHOLESALE: 18,
  PLATINUM: 19,
  DIAMOND: 20,
  ELITE: 21,
}

export const ORDER_PICK_STATUSES = {
  STATUS_ASSIGNED: 8,
  STATUS_COMPLETED_ORDER: 9,
  STATUS_LOADING: 10,
  STATUS_NEW: 11,
  STATUS_NEXT_DAY: 12,
  STATUS_PICKING: 13,
  STATUS_QUEUE: 14,
  STATUS_REMOVE: 15,
  STATUS_REMOVE_BACK_TO_INV: 16,
  STATUS_REMOVE_HOLD: 17,
  STATUS_SHIPPING: 18,
  STATUS_PICKING_PALLET: 19,
  STATUS_FULL: 20,
  STATUS_WRAP: 21,
  STATUS_SHIPPING_LANE: 22,
  STATUS_REMOVE_PALLET: 23,
  STATUS_PENDING: 24,
  STATUS_ACTIVE: 25,
  STATUS_IN_PROGRESS: 26,
  STATUS_COMPLETE: 27,
  STATUS_LOADED: 28,
  STATUS_CANCEL: 29,
}

export const ORDER_LOAD_STATUSES = {
  STATUS_ASSIGNED: 8,
  STATUS_LOADING: 10,
  STATUS_QUEUE: 14,
  STATUS_LOADED: 28,
  STATUS_CANCEL: 29,
}

export const PICKING_PAGE_STATUSES = [
  ORDER_PICK_STATUSES.STATUS_NEW,
  ORDER_PICK_STATUSES.STATUS_ASSIGNED,
  ORDER_PICK_STATUSES.STATUS_QUEUE,
  ORDER_PICK_STATUSES.STATUS_PICKING,
]

export const LOADING_PAGE_STATUSES = [
  ORDER_LOAD_STATUSES.STATUS_ASSIGNED,
  ORDER_LOAD_STATUSES.STATUS_QUEUE,
  ORDER_LOAD_STATUSES.STATUS_LOADING,
]

export const ALL_ORDERS_STATUSES = {
  today: [
    ORDER_PICK_STATUSES.STATUS_NEW,
    ORDER_PICK_STATUSES.STATUS_ASSIGNED,
    ORDER_PICK_STATUSES.STATUS_QUEUE,
    ORDER_PICK_STATUSES.STATUS_REMOVE,
    ORDER_PICK_STATUSES.STATUS_PICKING,
  ],
  nextDay: [ORDER_PICK_STATUSES.STATUS_NEXT_DAY],
}

export const ORDER_STATUSES = {
  STATUS_SCHEDULED_TO_SHIP: 20,
  STATUS_SCHEDULED_TO_PICKUP: 21,
  STATUS_SCHEDULED_TO_DELIVERY: 21,
}

export const CARRIER_SERVICE_TYPE = {
  PARCEL: 'Parcel',
  FREIGHT: 'Freight',
}

export const MAX_PALLETS_PER_TRUCK = 999 // note: also need to adjust wms_pick_pallet_ids table to match MAX (under current code & sql structure)

// TODO - Remove if still commented out after 2023-10-20
// export const QUANTITY_LABELS_TO_PRINT_ON_WRAP_PICKUP_ORDER = 2
export const DEFAULT_ORDER_PALLET_LABEL_PRINT_QUANTITY = 2
