import request, { requestWithRetry } from 'helpers'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}measurement`

export const fetchMany = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}/case`,
    method: 'GET',
    params: payload,
  })

export const create = (payload) =>
  request({
    url: `${apiEndpoint}/case`,
    method: 'POST',
    data: payload,
  })

export const update = (payload) =>
  request({
    url: `${apiEndpoint}/case/${payload.id}`,
    method: 'PUT',
    data: payload,
  })

export const updateMany = (payload) =>
  request({
    url: `${apiEndpoint}/case`,
    method: 'PUT',
    data: payload,
  })
