import React from 'react'
import * as PropTypes from 'prop-types'
import './style.scss'
import ModalDefault from 'components/common/Modal/ModalDefault'
import Button from 'components/common/Button'
import { connect } from 'react-redux'
import {
  setIsOffline,
  setIgnoreCount,
  clearSystemError,
} from 'store/appInfo/actions'
import { sendEmailPlog } from 'store/emailer/actions'
import { IT_NOTIFY } from '../../../../constants/email'
import { buildStackTraceHtml } from '../../../../helpers/exception'

const ModalSystemError = ({
  isOpen,
  systemError,
  user,
  setIsOffline,
  setIgnoreCount,
  clearSystemError,
  sendEmailPlog,
}) => {
  if (!isOpen) {
    return <></>
  }

  const onAcceptHandle = () => {
    const stackTrace = buildStackTraceHtml(systemError.trace ?? [])
    sendEmailPlog({
      request: {
        email_address: IT_NOTIFY,
        subject: `Backend System Error ${systemError.message}`,
        message: `
          User Name: ${user.user.staffLogin.name}<br/>
          User Email: ${user.user.staffLogin.email}<br/>
          Staff ID: ${user.user.staffLogin.staff_id}<br/>
          Username: ${user.user.staffLogin.username}<br/>
          <br/>
          API Route: ${systemError.url}<br/>
          ${systemError.code ? `HTTP Status Code: ${systemError.code}<br/>` : ''}
          ${systemError.message ? `Message: ${systemError.message}<br/>` : ''}
          ${systemError.exception ?? ''}<br/>
          ${systemError.file ?? ''}<br/>
          ${systemError.line ? `Line: ${systemError.line}<br/>` : ''}
          ${stackTrace}
        `,
        hideTrace: true,
        showDefaultSubjectPrefix: false,
      },
    })
    setIsOffline(false)
    setIgnoreCount(0)
    clearSystemError()
  }
  console.log('system error is here', systemError)
  return (
    <ModalDefault isOpen={isOpen}>
      <div className="modal modal--system-error">
        <div className="modal__header">
          <div className="title">Backend System Error</div>
          <div className="sub-title">{systemError.url}</div>
          <div className="sub-title">{systemError.message}</div>
          <div className="sub-title">{systemError.exception ?? ''}</div>
          {systemError.code && <div className="sub-title">HTTP Status Code: {systemError.code}</div>}
          <div className="sub-title">{systemError.file ?? ''}</div>
          {systemError.line && <div className="sub-title">Line: {systemError.line}</div>}
        </div>
        <Button onClick={() => onAcceptHandle()}>OK</Button>
      </div>
    </ModalDefault>
  )
}

ModalSystemError.propTypes = {
  isOpen: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.appInfo.isSystemError,
  systemError: state.appInfo.systemError,
  user: state.auth.user,
})

const mapDispatchToProps = {
  setIsOffline,
  setIgnoreCount,
  clearSystemError,
  sendEmailPlog,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalSystemError)
