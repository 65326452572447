import { isEmptyObject } from 'helpers/index'
import User from 'services/User'

export const useAuth = (auth) => {
  const isAuthenticated =
    !isEmptyObject(auth.user) && !!auth.isAuthorized && !!auth.user.token
  const user = isAuthenticated
    ? new User({
        ...auth.user.user,
        isAuthorized: auth.isAuthorized,
        token: auth.user.token,
      })
    : {}

  return [user, isAuthenticated]
}
