export const types = {
  PRINT_START: 'PRINT_START',
  OPEN_PRINT_DIALOG: 'OPEN_PRINT_DIALOG',
  CLOSE_PRINT_DIALOG: 'CLOSE_PRINT_DIALOG',
  FETCH_PRINTER_LIBRARY_START: 'FETCH_PRINTER_LIBRARY_START',
  FETCH_PRINTER_LIBRARY_SUCCESS: 'FETCH_PRINTER_LIBRARY_SUCCESS',
}

export const openPrintDialog = (payload, additionalPayload) => ({
  type: types.OPEN_PRINT_DIALOG,
  payload,
  additionalPayload,
})

export const closePrintDialog = (payload) => ({
  type: types.CLOSE_PRINT_DIALOG,
  payload,
})

export const printStart = (payload) => ({
  type: types.PRINT_START,
  payload,
})

export const fetchPrinterLibraryStart = (payload) => ({
  type: types.FETCH_PRINTER_LIBRARY_START,
  payload,
})

export const fetchPrinterLibrarySuccess = (payload) => ({
  type: types.FETCH_PRINTER_LIBRARY_SUCCESS,
  payload,
})
