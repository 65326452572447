import { types } from './actions'

const INIT = {
  list: [],
  item: null,
  isLoading: false,
  isPowerPick: false,
  powerPickProcessId: null,
  previousOrderPallet: null,
}

export default function orderPalletReducer(state = INIT, action) {
  const { type, payload } = action

  switch (type) {
    case types.FETCH_ONE_ORDER_PALLET_START:
    case types.FETCH_ORDERS_PALLET_LIST_START:
      return {
        ...state,
        list: [],
        item: null,
        isLoading: true,
      }
    case types.FETCH_ORDERS_PALLET_LIST_SUCCESS:
      return {
        ...state,
        list: payload,
        isLoading: false,
      }
    case types.FETCH_ONE_ORDER_PALLET_SUCCESS:
      return {
        ...state,
        item: payload,
        isLoading: false,
      }
    case types.UPDATE_ORDER_PALLET_START:
      return {
        ...state,
        isLoading: true,
      }
    case types.SET_POWER_PICK:
      return {
        ...state,
        isPowerPick: payload.isPowerPick,
        powerPickProcessId: payload.powerPickProcessId,
      }
    case types.FETCH_ORDERS_PALLET_FAIL:
      return INIT
    case types.FETCH_PREVIOUS_ORDER_PALLET_SUCCESS:
      return {
        ...state,
        previousOrderPallet: payload,
      }
    default:
      return state
  }
}
