import React from 'react'
import * as PropTypes from 'prop-types'

import './style.scss'

import ModalDefault from 'components/common/Modal/ModalDefault/index'
import CancelIcon from 'assets/svg/cancel.svg'
import CancelIconIPod from 'assets/svg/cancel--ipod.svg'
import { connect } from 'react-redux'

const ModalChoose = ({ isOpen, modalInfo }) => {
  if (!isOpen) {
    return <></>
  }

  return (
    <ModalDefault isOpen={isOpen} onClose={modalInfo.onDeny}>
      <div
        className={`modal modal--choose ${
          modalInfo.className ? modalInfo.className : ''
        }`}
      >
        <ul className="choose-list">
          {modalInfo.chooses.map((item) => (
            <li
              key={item.id}
              className="choose-item"
              onClick={() => modalInfo.onChoose(item.id)}
            >
              {item.label}
            </li>
          ))}
        </ul>
        <div onClick={modalInfo.onDeny} className="btn--close">
          {window.innerWidth < 768 ? <CancelIconIPod /> : <CancelIcon />}
        </div>
      </div>
    </ModalDefault>
  )
}

ModalChoose.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalChoose',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalChoose',
  ),
})

export default connect(mapStateToProps)(ModalChoose)
