import { types } from './actions'

const INIT = {
  item: {},
  task: {},
  list: [],
  scannedItem: [],
  scannedPallet: {},
}

const restockProcessReducer = (state = INIT, action) => {
  const { type, payload } = action

  switch (type) {
    case types.PROCESS_STORAGE_TO_STORAGE_SUCCESS:
      return {
        ...state,
      }
    case types.FETCH_RESTOCK_PROCESS_LIST_SUCCESS:
      return {
        ...state,
        list: payload,
      }
    case types.FETCH_RESTOCK_PROCESS_LIST_FAIL:
      return INIT
    case types.FETCH_BULK_RESTOCK_TASK_START:
      return { ...state }
    case types.FETCH_BULK_RESTOCK_TASK_SUCCESS:
      return {
        ...state,
        task: payload,
      }
    case types.FETCH_BULK_RESTOCK_TASK_FAIL:
      return {
        ...state,
        task: {},
      }
    case types.FETCH_ONE_RESTOCK_PROCESS_START:
      return { ...state }
    case types.FETCH_ONE_RESTOCK_PROCESS_SUCCESS:
      return {
        ...state,
        item: payload,
      }
    case types.FETCH_ONE_RESTOCK_PROCESS_FAIL:
      return {
        ...state,
        item: {},
      }
    case types.UPDATE_SCANNED_RESTOCK_LOCATION:
      return {
        ...state,
        scannedItem: payload,
      }
    case types.UPDATE_SCANNED_PALLET:
      return {
        ...state,
        scannedPallet: payload,
      }
    case type.RESET_RESTOCK_PROCESS:
      return INIT
    default:
      return state
  }
}

export default restockProcessReducer
