import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import Header from 'components/common/Header'
import LocationToIcon from 'assets/svg/to-location.svg'
import LocationFromIcon from 'assets/svg/from-location.svg'
import {
  fetchOneRestockProcessStart,
  updateRestockProcess,
} from 'store/restockProcess/actions'
import Active from 'assets/svg/active--ipad.svg'
import ActiveGrey from 'assets/svg/active-grey--ipad.svg'
import Scanner from 'components/common/Scanner'
import { closeModal, resetModals, showModal } from 'store/modal/actions'
import Button from 'components/common/Button'
import { isEmptyObject, redirect } from 'helpers/index'
import {
  createWarehouseCaseSync,
  updateWarehouseCase,
} from 'store/warehouseCase/actions'
import { LOCATION_TYPE } from 'constants/locationTypes'
import { RESTOCK_TASK_LIST_PAGE } from 'constants/routes'
import { STATUS_COMPLETED } from 'constants/processStatuses'
import Tooltip from 'components/common/Tooltip'
import MoreIcon from 'assets/svg/more-icon.svg'
import Report from 'assets/svg/report.svg'
import { fetchLocationsStart } from 'store/location/actions'
import * as query from 'querystringify'
import { LOCATION_TYPE_FILTER, WAREHOUSE_FILTER } from 'constants/filters'
import { PROCESS_TYPES } from 'constants/process'
import { createDamageCases } from 'store/product/actions'

const RestockSmallParcelPage = (props) => {
  const {
    user,
    match,
    currentProcess,
    damageLocation,

    resetModals,
    updateWarehouseCase,
    closeModal,
    updateRestockProcess,
    createWarehouseCase,
    showModal,
    fetchOneRestockProcess,
    fetchLocations,
    createDamageCases,
  } = props

  const processId = match.params.id
  const caseCount = 1

  const getLocationParamsForFetch = () =>
    query.stringify(
      {
        [WAREHOUSE_FILTER]: user.warehouseId,
        [LOCATION_TYPE_FILTER]: LOCATION_TYPE.DAMAGE_TYPE_ID,
      },
      true,
    )

  const onMount = () => {
    showModal({
      name: 'modalPending',
    })
    fetchOneRestockProcess({
      id: processId,
    })

    fetchLocations(getLocationParamsForFetch())
  }

  const [locationTitle, setLocationTitle] = useState('')
  const [restockLocation, setRestockLocation] = useState({})
  const [isFromLocationScanned, setIsFromLocationScanned] = useState(false)
  const [isCaseScanned, setIsCaseScanned] = useState(false)
  const [isToLocationScanned, setIsToLocationScanned] = useState(false)

  const [toWarehouseCase, setToWarehouseCase] = useState(undefined)

  const [isScanned, setIsScanned] = useState(false)

  const onUpdateCurrentProcess = () => {
    if (!isEmptyObject(currentProcess)) {
      const firstAndSingleRestockLocation =
        currentProcess.entity.restock_location[0]
      setRestockLocation(firstAndSingleRestockLocation)
    }
  }

  const onUpdateRestockLocation = () => {
    if (!isEmptyObject(restockLocation)) {
      const type = isCaseScanned
        ? 'warehouse_location_to'
        : 'warehouse_location_from'

      setLocationTitle(
        [
          restockLocation[type].regular_location.aisle_id.name,
          restockLocation[type].regular_location.bay_id.name,
          restockLocation[type].regular_location.level,
        ].join('-'),
      )
    }
  }

  const onUpdate = () => {
    if (locationTitle || damageLocation) {
      closeModal('modalPending')
    }
  }

  useEffect(onMount, [])
  useEffect(onUpdateCurrentProcess, [currentProcess])
  useEffect(onUpdateRestockLocation, [restockLocation])
  useEffect(onUpdate, [locationTitle, damageLocation])

  if (
    isEmptyObject(currentProcess) ||
    isEmptyObject(restockLocation) ||
    !locationTitle ||
    !damageLocation
  ) {
    return <></>
  }

  const onScanFromLocation = (barcode) => {
    if (
      restockLocation.warehouse_location_from.upc.toString() ===
      barcode.toString()
    ) {
      setIsScanned(true)
      setTimeout(() => {
        setIsFromLocationScanned(true)
        setIsScanned(false)
      }, 1500)
    } else {
      showModal({
        name: 'modalWrongLocation',
        onAccept: resetModals,
        text: locationTitle,
      })
    }
  }

  const onScanCase = (barcode) => {
    let productCase = restockLocation.product.product_case

    if (productCase.case_upc.toString() === barcode.toString()) {
      setIsScanned(true)

      setTimeout(() => {
        setIsCaseScanned(true)
        setIsScanned(false)
      }, 1500)
    }
  }

  const onScanToLocation = (barcode) => {
    if (
      restockLocation.warehouse_location_to.upc.toString() ===
      barcode.toString()
    ) {
      const productId = restockLocation.product.products_id
      const packQty =
        restockLocation.product.product_case.smallParcel.qty_packs_in_case
      const warehouseCases = restockLocation.warehouse_location_to.warehouse_cases_in_location.find(
        (item) => item.product_id === productId,
      )

      if (warehouseCases) {
        updateWarehouseCase({
          id: warehouseCases.id,
          data: {
            id: warehouseCases.id,
            updated_by_user_id: user.warehouseStaffId,
            location_case_qty:
              warehouseCases.location_case_qty + caseCount * packQty,
          },
        })
        setToWarehouseCase({
          ...warehouseCases,
          location_case_qty:
            warehouseCases.location_case_qty + caseCount * packQty,
        })
      } else {
        createWarehouseCase({
          request: {
            data: {
              product_id: productId,
              updated_by_user_id: user.warehouseStaffId,
              created_by_user_id: user.warehouseStaffId,
              location_case_qty: caseCount * packQty,
              warehouse_location_id: restockLocation.warehouse_location_to.id,
            },
          },
          afterAction: setToWarehouseCase,
        })
      }

      setIsScanned(true)

      setTimeout(() => {
        setIsToLocationScanned(true)
        setIsScanned(false)
      }, 1500)
    } else {
      showModal({
        name: 'modalWrongLocation',
        onAccept: resetModals,
        text: locationTitle,
      })
    }
  }

  const onScan = (barcode) => {
    if (!isFromLocationScanned) {
      onScanFromLocation(barcode)
    } else if (!isCaseScanned) {
      onScanCase(barcode)
    } else if (!isToLocationScanned) {
      onScanToLocation(barcode)
    }
  }

  const onComplete = () => {
    updateRestockProcess({
      id: processId,
      data: {
        id: processId,
        status: STATUS_COMPLETED,
      },
    })

    redirect(RESTOCK_TASK_LIST_PAGE)
  }

  const getBottomContent = () => {
    const statusIcon = isScanned ? <Active /> : <ActiveGrey />
    let content = ''

    switch (true) {
      case isToLocationScanned:
        content = <Button onClick={onComplete}>{'Complete'}</Button>
        break
      case isCaseScanned:
        content = <>Scan Location UPC {statusIcon}</>
        break
      case isFromLocationScanned:
        content = <>Scan Case UPC {statusIcon}</>
        break
      case !isFromLocationScanned:
        content = <>Scan Location UPC {statusIcon}</>
        break
      default:
        break
    }

    return (
      <div className="content__section--white">
        <span className="status">{content}</span>
      </div>
    )
  }

  const onFailureScanDamageLocation = () => {
    const damageLocationTitle = `${damageLocation.aisle}-${damageLocation.bay}`

    const onAccept = () => closeModal('modalWrongLocation')

    showModal({
      name: 'modalWrongLocation',
      onAccept: onAccept,
      text: damageLocationTitle,
      className: 'modal--tasks',
    })
  }

  const onSuccessScanDamageLocation = () => {
    const damageCaseInLocation = damageLocation.warehouse_cases_in_location.find(
      (item) => item.product_id === restockLocation.product.products_id,
    )
    const packQty =
      restockLocation.product.product_case.smallParcel.qty_packs_in_case

    if (isToLocationScanned) {
      updateWarehouseCase({
        id: toWarehouseCase.id,
        data: {
          id: toWarehouseCase.id,
          updated_by_user_id: user.warehouseStaffId,
          location_case_qty: toWarehouseCase.location_case_qty - packQty,
        },
      })
    }

    if (!damageCaseInLocation) {
      createWarehouseCase({
        request: {
          data: {
            product_id: restockLocation.product.products_id,
            updated_by_user_id: user.warehouseStaffId,
            created_by_user_id: user.warehouseStaffId,
            location_case_qty: caseCount,
            warehouse_location_id: damageLocation.id,
          },
        },
      })
    } else {
      updateWarehouseCase({
        id: damageCaseInLocation.id,
        data: {
          id: damageCaseInLocation.id,
          location_case_qty: damageCaseInLocation.location_case_qty + caseCount,
        },
      })

      createDamageCases({
        request: {
          data: {
            process_type: PROCESS_TYPES.RESTOCK_PROCESS_TYPE,
            warehouse_location_id: restockLocation.warehouseLocation.id,
            count_damaged_cases: caseCount,
          },
        },
      })
    }

    onComplete()
  }

  const showModalScanDamageLocationWithData = () => {
    showModal({
      name: 'modalScanDamageLocation',
      damageLocation: damageLocation,
      onSuccess: onSuccessScanDamageLocation,
      onFailure: onFailureScanDamageLocation,
      onBack: resetModals,
      className: 'modal--tasks modal--damaged',
    })
  }

  const tooltipItems = [
    {
      id: 1,
      icon: <Report className="tooltip__icon" />,
      label: 'Report Case Damage',
      className: `tooltip__item `,
      onClick: showModalScanDamageLocationWithData,
    },
    {
      id: 2,
      icon: <Report className="tooltip__icon" />,
      label: 'Report Pallet Damage',
      className: 'tooltip__item tooltip__item--disabled',
      onClick: () => {},
    },
  ]

  return (
    <div className="tasks-page tasks-page--start-restock page--restock-relocation">
      <Header>
        {isCaseScanned && (
          <Tooltip button={<MoreIcon />}>
            <ul>
              {tooltipItems.map((item) => (
                <li
                  key={item.id}
                  className={item.className}
                  onClick={item.onClick}
                >
                  {item.icon ? item.icon : ''}
                  {item.label}
                </li>
              ))}
            </ul>
            <span className="triangle" />
          </Tooltip>
        )}
      </Header>
      <Scanner needToRemoveListener={false} onReceiveData={onScan} />
      <div className="content">
        <div className="content__section">
          <div className="dock__wrapper">
            <div className="dock">
              <span className="dock__label">LOC</span>
              <span className="dock__name">{locationTitle}</span>
            </div>
          </div>
          <h3 className="fireworks-name">{restockLocation.product_name}</h3>
          <ul className="fireworks">
            <li className="fireworks__item">
              <span className="fireworks__name">Model</span>
              <span className="fireworks__sub-name">
                {restockLocation.product.products_model}
              </span>
            </li>
            <li className="fireworks__item">
              <span className="fireworks__name">Manf Model</span>
              <span className="fireworks__sub-name">
                {restockLocation.product.products_manf_model}
              </span>
            </li>
            <li className="fireworks__item">
              {isCaseScanned ? <LocationToIcon /> : <LocationFromIcon />}
            </li>
          </ul>
        </div>
        {getBottomContent()}
      </div>
    </div>
  )
}

RestockSmallParcelPage.propTypes = {
  damageLocation: PropTypes.object,
  currentProcess: PropTypes.object,

  resetModals: PropTypes.func,
  updateWarehouseCase: PropTypes.func,
  closeModal: PropTypes.func,
  showModal: PropTypes.func,
  fetchOneRestockProcess: PropTypes.func,
  updateRestockProcess: PropTypes.func,
  createWarehouseCase: PropTypes.func,
  fetchLocations: PropTypes.func,
  createDamageCases: PropTypes.func,
}

const mapStateToProps = (state) => ({
  damageLocation: state.location.list[0],
  currentProcess: state.restockProcess.item,
})

const mapDispatchToProps = {
  fetchLocations: fetchLocationsStart,
  fetchOneRestockProcess: fetchOneRestockProcessStart,

  createDamageCases: createDamageCases,
  createWarehouseCase: createWarehouseCaseSync,

  updateWarehouseCase: updateWarehouseCase,
  updateRestockProcess: updateRestockProcess,

  showModal: showModal,
  closeModal: closeModal,
  resetModals: resetModals,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RestockSmallParcelPage)
