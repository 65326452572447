import { types } from './actions'
import { sortObjectsList } from 'helpers/sorting'

const INIT = {
  list: [],
  editedItem: {},
  availableBays: [],
  isLoading: false,
  isLocationCreated: false,
  isLocationUpdated: false,
  isGroupLocationCreated: false,
  isGroupLocationExists: false,
  forLane: [],
}

export default function locationReducer(state = INIT, action) {
  const { type, payload } = action

  switch (type) {
    case types.FETCH_LOCATIONS_START:
      return {
        ...state,
        isLoading: true,
      }
    case types.FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: payload,
      }
    case types.FETCH_ONE_LOCATION_START:
      return {
        ...state,
        isLoading: true,
      }
    case types.FETCH_ONE_LOCATION_SUCCESS:
      return {
        ...state,
        editedItem: payload,
        isLoading: false,
      }
    case types.FETCH_AVAILABLE_BAYS_SUCCESS:
      return {
        ...state,
        availableBays: payload,
      }
    case types.FETCH_LOCATIONS_FAIL:
      return INIT
    case types.SORT_LOCATIONS:
      const { list } = state
      const { key, direction } = payload

      const sortedList = sortObjectsList(list, key, direction)

      return {
        ...state,
        list: sortedList,
      }
    case types.CREATE_LOCATION_SUCCESS:
      return {
        ...state,
        isLocationCreated: true,
      }
    case types.CREATE_LOCATION_FAIL:
      return {
        ...state,
        isLocationCreated: false,
      }
    case types.CREATE_GROUP_LOCATION_START:
      return {
        ...state,
        isGroupLocationCreated: false,
        isGroupLocationExists: false,
      }
    case types.CREATE_GROUP_LOCATION_SUCCESS:
      return {
        ...state,
        isGroupLocationCreated: true,
      }
    case types.CREATE_GROUP_LOCATION_FAIL:
      return {
        ...state,
        isGroupLocationCreated: false,
        isGroupLocationExists: true,
      }
    case types.UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        isLocationUpdated: true,
      }
    case types.UPDATE_LOCATION_FAIL:
      return {
        ...state,
        isLocationUpdated: false,
      }
    case types.FETCH_LOCATIONS_FOR_LANE_SUCCESS:
      return {
        ...state,
        forLane: payload,
      }
    case types.RESET_LOCATION_STATUSES:
      return {
        ...state,
        isLocationCreated: false,
        isLocationUpdated: false,
        isLoading: false,
        isGroupLocationCreated: false,
        isGroupLocationExists: false,
      }
    case types.RESET_CURRENT_LOCATION:
      return {
        ...state,
        editedItem: {},
      }
    default:
      return state
  }
}
