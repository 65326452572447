import React, { useState, useEffect } from 'react'
import ModalDefault from 'components/common/Modal/ModalDefault'
import * as PropTypes from 'prop-types'
import Switch from 'components/common/Switch/component'
import { isEmptyObject } from 'helpers/index'
import StaffEngagement from './StaffEngagement'
import './style.scss'
import { fetchStaffAssignmentStart } from 'store/staff/actions'
import { connect } from 'react-redux'
import AssignToBlock from 'components/features/UnloadProcess/PrepareUnloadProcessListPage/PopupAssignment/AssignToBlock'
import { ROLES as roles } from 'constants/roles'
import { getNormalizedStaffAssignments } from 'store/staff/selectors'

const customStyles = {
  content: {
    backgroundColor: 'transparent',
    border: 'none',
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
    transition: 'all 0.5s ease',
    padding: 0,
  },
}

const ROLES = [
  { value: roles.DOCK_LEAD, label: 'Dock Lead' },
  { value: roles.UNLOAD_WORKER, label: 'Unload Worker' },
]

const PopupAssignment = (props) => {
  const {
    isOpen,
    staffAssignment,
    modalInfo,

    fetchStaffAssignment,
  } = props

  const [selectedRole, setSelectedRole] = useState(ROLES[0])

  const [assignTo, setAssignTo] = useState({
    [roles.DOCK_LEAD]: [],
    [roles.UNLOAD_WORKER]: [],
  })

  const [assignments, setAssignments] = useState(staffAssignment)

  const toggleChecked = (item, state = true) => {
    const engagementTypes = Object.keys(assignments)
    let checkedAssignments = { ...assignments }

    for (let index in engagementTypes) {
      assignments[engagementTypes[index]].forEach((engagement, i) => {
        if (engagement.staffLogin.staff_id === item.staffLogin.staff_id) {
          checkedAssignments[engagementTypes[index]][i] = {
            ...engagement,
            is_checked: state,
          }
        }
      })
    }

    setAssignments(checkedAssignments)
  }

  const onUpdateProcessInfo = () => {
    if (isOpen) {
      const taskTeam = modalInfo.processInfo.team || []

      setAssignTo({
        [roles.DOCK_LEAD]: taskTeam.filter(
          (teamItem) => teamItem.role_id === roles.DOCK_LEAD,
        ),
        [roles.UNLOAD_WORKER]: taskTeam.filter(
          (teamItem) => teamItem.role_id !== roles.DOCK_LEAD,
        ),
      })

      taskTeam.forEach((item) => toggleChecked(item))
    }
  }

  useEffect(() => {
    isOpen && fetchStaffAssignment()
  }, [fetchStaffAssignment, isOpen])

  useEffect(() => {
    setAssignments(staffAssignment)
  }, [staffAssignment])

  useEffect(onUpdateProcessInfo, [modalInfo])

  if (isEmptyObject(staffAssignment)) {
    return ''
  }

  if (!isOpen) {
    return <></>
  }

  const onAssign = (item) => {
    const existingObject =
      [...assignTo[roles.DOCK_LEAD], ...assignTo[roles.UNLOAD_WORKER]].find(
        (assignToItem) =>
          assignToItem.staffLogin.staff_id === item.staffLogin.staff_id,
      ) || {}

    if (isEmptyObject(existingObject)) {
      const isItemDockLead =
        item.roles.filter((role) => role.id === ROLES[0].value).length > 0
      const isDockLeadAssigned = !!assignTo[roles.DOCK_LEAD].length

      if (
        isItemDockLead &&
        isDockLeadAssigned &&
        selectedRole.value === roles.DOCK_LEAD
      ) {
        return
      }

      toggleChecked(item)

      setAssignTo({
        ...assignTo,
        [selectedRole.value]: [...assignTo[selectedRole.value], ...[item]],
      })
    }
  }

  const hasRole = (item, roleId) =>
    item.roles.filter((role) => role.id === roleId).length > 0

  const onRemoveAssignToItem = (item) => {
    toggleChecked(item, false)

    setAssignTo({
      ...assignTo,
      [selectedRole.value]: assignTo[selectedRole.value].filter(
        (assignToItem) =>
          assignToItem.staffLogin.staff_id !== item.staffLogin.staff_id,
      ),
    })
  }

  const onSwitchRole = (name, item) => setSelectedRole(item)

  const onCancel = () => {
    const taskTeam = modalInfo.processInfo.team || []

    setAssignTo({
      [roles.DOCK_LEAD]: taskTeam.filter((teamItem) =>
        hasRole(teamItem, roles.DOCK_LEAD),
      ),
      [roles.UNLOAD_WORKER]: taskTeam.filter(
        (teamItem) => !hasRole(teamItem, roles.DOCK_LEAD),
      ),
    })

    modalInfo.onDeny()
    setSelectedRole(ROLES[0])
  }

  return isOpen ? (
    <ModalDefault isOpen={true} style={customStyles} animation={'top'}>
      <div className="popup--assignment">
        <div className="header">
          <div className="text" onClick={onCancel}>
            Cancel
          </div>
          <div className="title">
            Container {modalInfo.processInfo.container.name} Assignments for{' '}
            {modalInfo.processInfo.container.invoice_cases_quantity} cases
          </div>
          <div
            className="text"
            onClick={() => {
              modalInfo.onAccept(modalInfo.processInfo, [
                ...assignTo[roles.DOCK_LEAD].map((item) => ({
                  ...item,
                  role_id: roles.DOCK_LEAD,
                })),
                ...assignTo[roles.UNLOAD_WORKER].map((item) => ({
                  ...item,
                  role_id: roles.UNLOAD_WORKER,
                })),
              ])

              setSelectedRole(ROLES[0])
            }}
          >
            Save
          </div>
        </div>
        <div className="body">
          <div className="row">
            <div className="title">Role</div>
            <Switch
              name="roleSwitch"
              selected={selectedRole}
              items={ROLES}
              onChange={onSwitchRole}
            />
          </div>
          <AssignToBlock
            items={assignTo[selectedRole.value]}
            onRemove={onRemoveAssignToItem}
          />
          <StaffEngagement
            staffAssignment={assignments}
            selectedRole={selectedRole}
            onAssign={onAssign}
            processInfo={modalInfo.processInfo}
          />
        </div>
      </div>
    </ModalDefault>
  ) : (
    <></>
  )
}
PopupAssignment.propTypes = {
  staffAssignment: PropTypes.array,

  isOpen: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  staffAssignment: getNormalizedStaffAssignments(state),
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalWorkersAssignment',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalWorkersAssignment',
  ),
})

const mapDispatchToProps = {
  fetchStaffAssignment: fetchStaffAssignmentStart,
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupAssignment)
