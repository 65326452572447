import React from 'react'
import * as PropTypes from 'prop-types'
import ModalDefault from 'components/common/Modal/ModalDefault'
import Button from 'components/common/Button'
import Alert from 'assets/svg/alert.svg'
import LocationIcon from 'assets/svg/location-ipod.svg'
import { connect } from 'react-redux'

const ModalWrongLocation = ({ isOpen, modalInfo }) => {
  if (!isOpen) {
    return <></>
  }

  return (
    <ModalDefault isOpen={isOpen}>
      <div
        className={`modal modal--confirm modal--wrong modal--start-picking ${
          modalInfo.className ? modalInfo.className : ''
        }`}
      >
        <Alert className="icon--alert" />
        <div className="text text--bold">Wrong Location</div>
        <div className="text-inner">
          <LocationIcon className="icon--location" />
          <span className="text">{modalInfo.text}</span>
        </div>
        <Button onClick={modalInfo.onAccept}>OK</Button>
      </div>
    </ModalDefault>
  )
}

ModalWrongLocation.propTypes = {
  isLocationScanned: PropTypes.bool,
  isOpen: PropTypes.bool,

  modalInfo: PropTypes.object,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalWrongLocation',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalWrongLocation',
  ),
})

export default connect(mapStateToProps)(ModalWrongLocation)
