import { requestWithRetry } from 'helpers'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}staff`

export const fetch = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}/assignments`,
    method: 'GET',
    params: payload,
  })
