import React, { useState, useEffect } from 'react'
import * as PropTypes from 'prop-types'

const Scanner = ({ onReceiveData, needToRemoveListener = true }) => {
  const [data, setData] = useState('')

  const onInit = () => {
    document.addEventListener('keypress', onKeyPress)

    return () => document.removeEventListener('keypress', onKeyPress)
  }

  useEffect(onInit)

  const onReading = (e) => setData(`${data}${e.key}`)

  const onFinishReading = () => {
    onReceiveData(data)
    setData('')

    if (needToRemoveListener) {
      document.removeEventListener('keypress', onKeyPress)
    }
  }

  const onKeyPress = (e) =>
    e.key !== 'Enter' ? onReading(e) : onFinishReading()

  return <></>
}

Scanner.propTypes = {
  onReceiveData: PropTypes.func,
  needToRemoveListener: PropTypes.bool,
}

export default Scanner
