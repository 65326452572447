import React, { useEffect, useState } from 'react'
import Button from 'components/common/Button'
import REGEX from 'constants/regex'
import Header from 'components/common/Header'
//import BackArrowIcon from 'assets/svg/back.svg'

const PalletWeightScreen = ({
  item,
  onConfirmAndPrint,
  onCancel,
  quantityToPrint,
}) => {
  const [weight, setWeight] = useState(undefined)

  const onMount = () => {
    if (item && item.pallet_weight) {
      setWeight(item.pallet_weight)
    }
  }

  useEffect(onMount, [item, setWeight])

  const onChangeHandler = (e) => {
    const value = e.target.value

    if (!value) {
      return setWeight(undefined)
    }

    if (value.match(REGEX.IS_FLOAT)) {
      return setWeight(parseFloat(value))
    }
  }

  const onConfirmAndPrintHandler = () => {
    onConfirmAndPrint(weight, quantityToPrint)
  }

  const showPrint = () => {
    return (
      <>
        <Button style={{ marginTop: '5px' }} onClick={onConfirmAndPrintHandler}>
          {quantityToPrint > 1 ? 'Print Labels' : 'Print Label'}
        </Button>
        <div>&nbsp;</div>
      </>
    )
  }

  return (
    <>
      <Header>
        <div className="header__action">
          {/* <BackArrowIcon onClick={onCancel} /> */}
        </div>
        <div className="title">Pallet Weight Total</div>
      </Header>
      <div className="content pallet-weight-screen">
        <div className="input-box">
          <input
            autofocus="autofocus"
            inputmode="numeric"
            type="text"
            value={weight}
            onChange={onChangeHandler}
          />
          <div className="input-box__label">LB</div>
        </div>
        {!!weight && showPrint()}
      </div>
    </>
  )
}

export default PalletWeightScreen
