import React from 'react'
import * as PropTypes from 'prop-types'
import { Card, ListItem, ListItemText } from '@material-ui/core'
import { ORDER_PICK_STATUSES } from 'constants/order'
import AvatarList from 'components/features/StaffCard/AvatarList'
import { dateToFormat } from 'helpers/index'
import { BiRun } from 'react-icons/bi'
import {
  GreenDot,
  YellowDot,
  YellowHoldDot,
  RedDot,
  BlackDot,
} from 'components/common/StockStatus'
import { GrNotes } from 'react-icons/gr'
import { ORDER_PRICE_LEVELS } from 'constants/order'

const OrderListItem = ({
  key,
  item,
  onSelect,
  onUnclaim,
  showModalOrderNotes,
}) => {
  const onClickSelect = () => onSelect(item)
  const onClickOrderNotes = (item) => showModalOrderNotes(item)
  const onClickUnclaim = () => onUnclaim(item)

  const buttonText = item.taskAssignment.id ? (
    item.entity.orderPickStatus.id === ORDER_PICK_STATUSES.STATUS_PICKING ? (
      <span style={{ color: 'orange' }} className="status-text">
        Resume
      </span>
    ) : (
      <span style={{ color: 'green' }} className="status-text">
        Start
      </span>
    )
  ) : (
    <span style={{ color: 'red' }} className="status-text">
      Claim
    </span>
  )

  const renderItem = () => {
    const { entity, team } = item

    const shipDate = entity.order.shipping_date
      ? dateToFormat(entity.order.shipping_date, 'MM/DD/YYYY')
      : dateToFormat(entity.order.pickup_date, 'MM/DD/YYYY')

    const unclaimButton = item.taskAssignment.id ? (
      entity.orderPickStatus.id !== ORDER_PICK_STATUSES.STATUS_PICKING ||
      (entity.orderPickStatus.id === ORDER_PICK_STATUSES.STATUS_PICKING &&
        entity.order.total_picked_quantity === "0") ? (
        <span
          style={{ color: 'red', cursor: 'pointer' }}
          className="status-text"
          onClick={onClickUnclaim}
        >
          Unclaim
        </span>
      ) : null
    ) : null

    const rushIcon =
      entity.order.rush === 1 ? <BiRun className="rush-icon" /> : ''

    const pickedCount =
      entity.orderPickStatus.id === ORDER_PICK_STATUSES.STATUS_PICKING ? (
        <span className="secondary-details-text">
          {entity.order.total_picked_quantity}/
          {entity.order.total_location_pick_quantity}
          &nbsp; cases
        </span>
      ) : (
        ''
      )
    const statusClassName = `item--status-${entity.orderPickStatus.id}`
    const itemClassName = `${statusClassName} task-div-padding`

    let stockDot = ''
    switch (entity.order.fill_status) {
      case 'green':
        stockDot = <GreenDot />
        break
      case 'yellow':
        stockDot = <YellowDot />
        break
      case 'yellow_hold':
        stockDot = <YellowHoldDot />
        break
      case 'red':
        stockDot = <RedDot />
        break
      case 'black':
        stockDot = <BlackDot />
        break
      default:
        stockDot = ''
    }

    return (
      <div className={itemClassName}>
        {rushIcon}
        <div className="spaced-div">
          <span>{stockDot}</span>
          <span className="status-text">
            {(entity.order.shipping_notes || entity.order.warehouse_notes) && (
              <GrNotes
                className="order-notes-icon-pp"
                onClick={() => {
                  onClickOrderNotes(item)
                }}
              />
            )}
            {entity.order.orders_id}
          </span>
          {/* <span className="primary-details-text closer-vert">
            {entity.order.total_products_quantity} cases
            <br />
            {entity.order.total_products_weight} lbs
          </span> */}
          <div>
            <AvatarList items={team} />
          </div>
        </div>
        <div className="spaced-div">
          <span className="secondary-details-text special-attention">
            {(entity.order.tag ? entity.order.tag : '') +
              (entity.order.tag &&
              entity.order.price_levels_id == ORDER_PRICE_LEVELS.ELITE
                ? ', '
                : '') +
              (entity.order.price_levels_id == ORDER_PRICE_LEVELS.ELITE
                ? 'Dealer Network Elite'
                : '')}
          </span>
        </div>

        <div className="spaced-div">
          <span></span>
          <span className="secondary-details-text position-left">
            {entity.order.customers_name}
            <br />
            {entity.carrier ? entity.carrier.real_name : 'No Carrier'}
            <br />
            {shipDate}
          </span>
          <span className="closer-vert">
            {buttonText}
            <br />
            {pickedCount}
            <br />
            <br />
            {unclaimButton}
          </span>
        </div>
      </div>
    )
  }

  return (
    <Card raised style={{ marginBottom: '5px' }}>
      <ListItem
        button
        key={key}
        onDoubleClick={(e) => {
          onClickSelect()
        }}
      >
        <ListItemText primary={renderItem()} />
      </ListItem>
    </Card>
  )
}

OrderListItem.propTypes = {
  key: PropTypes.any,
  item: PropTypes.object,

  onSelect: PropTypes.func,
}

export default OrderListItem
