export const types = {
  CLEAR_INVENTORY_LOCATION: 'CLEAR_INVENTORY_LOCATION',
  FETCH_INVENTORY_LOCATION_START: 'FETCH_INVENTORY_LOCATION_START',
  FETCH_INVENTORY_LOCATION_SUCCESS: 'FETCH_INVENTORY_LOCATION_SUCCESS',
  FETCH_INVENTORY_LOCATION_FAIL: 'FETCH_INVENTORY_LOCATION_FAIL',
  FETCH_PALLET_START: 'FETCH_PALLET_START',
  FETCH_PALLET_SUCCESS: 'FETCH_PALLET_SUCCESS',
  FETCH_PALLET_FAIL: 'FETCH_PALLET_FAIL',
  RESET_INVENTORY_LOCATION_START: 'RESET_INVENTORY_LOCATION_START',
  RESET_INVENTORY_LOCATION_SUCCESS: 'RESET_INVENTORY_LOCATION_SUCCESS',
  RESET_INVENTORY_LOCATION_FAIL: 'RESET_INVENTORY_LOCATION_FAIL',
  DELETE_PALLET_START: 'DELETE_PALLET_START',
  DELETE_PALLET_SUCCESS: 'DELETE_PALLET_SUCCESS',
  DELETE_PALLET_FAIL: 'DELETE_PALLET_FAIL',
  FETCH_PRODUCT_START: 'FETCH_PRODUCT_START',
  FETCH_PRODUCT_SUCCESS: 'FETCH_PRODUCT_SUCCESS',
  FETCH_PRODUCT_FAIL: 'FETCH_PRODUCT_FAIL',
  SAVE_PRODUCT_START: 'SAVE_PRODUCT_START',
  SAVE_PRODUCT_SUCCESS: 'SAVE_PRODUCT_SUCCESS',
  SAVE_PRODUCT_FAIL: 'SAVE_PRODUCT_FAIL',
  SAVE_PALLET_START: 'SAVE_PALLET_START',
  SAVE_PALLET_SUCCESS: 'SAVE_PALLET_SUCCESS',
  SAVE_PALLET_FAIL: 'SAVE_PALLET_FAIL',
  COPY_PRODUCT_TO_STAGE_START: 'COPY_PRODUCT_TO_STAGE_START',
  COPY_PRODUCT_TO_STAGE_SUCCESS: 'COPY_PRODUCT_TO_STAGE_SUCCESS',
  COPY_PRODUCT_TO_STAGE_FAIL: 'COPY_PRODUCT_TO_STAGE_FAIL',
}

export const clearInventoryLocation = () => ({
  type: types.CLEAR_INVENTORY_LOCATION,
  payload: {},
})

export const fetchInventoryLocationStart = (payload) => ({
  type: types.FETCH_INVENTORY_LOCATION_START,
  payload,
})

export const fetchInventoryLocationSuccess = (payload) => ({
  type: types.FETCH_INVENTORY_LOCATION_SUCCESS,
  payload,
})

export const fetchInventoryLocationFail = (payload) => ({
  type: types.FETCH_INVENTORY_LOCATION_FAIL,
  payload,
})

export const fetchPalletStart = (payload) => ({
  type: types.FETCH_PALLET_START,
  payload,
})

export const fetchPalletSuccess = (payload) => ({
  type: types.FETCH_PALLET_SUCCESS,
  payload,
})

export const fetchPalletFail = (payload) => ({
  type: types.FETCH_PALLET_FAIL,
  payload,
})

export const resetInventoryLocationStart = (payload) => ({
  type: types.RESET_INVENTORY_LOCATION_START,
  payload,
})

export const resetInventoryLocationSuccess = (payload) => ({
  type: types.RESET_INVENTORY_LOCATION_SUCCESS,
  payload,
})

export const resetInventoryLocationFail = (payload) => ({
  type: types.RESET_INVENTORY_LOCATION_FAIL,
  payload,
})

export const deletePalletStart = (payload) => ({
  type: types.DELETE_PALLET_START,
  payload,
})

export const deletePalletSuccess = (payload) => ({
  type: types.DELETE_PALLET_SUCCESS,
  payload,
})

export const deletePalletFail = (payload) => ({
  type: types.DELETE_PALLET_FAIL,
  payload,
})

export const fetchProductStart = (payload) => ({
  type: types.FETCH_PRODUCT_START,
  payload,
})

export const fetchProductSuccess = (payload) => ({
  type: types.FETCH_PRODUCT_SUCCESS,
  payload,
})

export const fetchProductFail = (payload) => ({
  type: types.FETCH_PRODUCT_FAIL,
  payload,
})

export const saveProductStart = (payload) => ({
  type: types.SAVE_PRODUCT_START,
  payload,
})

export const saveProductSuccess = (payload) => ({
  type: types.SAVE_PRODUCT_SUCCESS,
  payload,
})

export const saveProductFail = (payload) => ({
  type: types.SAVE_PRODUCT_FAIL,
  payload,
})

export const savePalletStart = (payload) => ({
  type: types.SAVE_PALLET_START,
  payload,
})

export const savePalletSuccess = (payload) => ({
  type: types.SAVE_PALLET_SUCCESS,
  payload,
})

export const savePalletFail = (payload) => ({
  type: types.SAVE_PALLET_FAIL,
  payload,
})

export const copyProductToStageStart = (payload) => ({
  type: types.COPY_PRODUCT_TO_STAGE_START,
  payload,
})

export const copyProductToStageSuccess = (payload) => ({
  type: types.COPY_PRODUCT_TO_STAGE_SUCCESS,
  payload,
})

export const copyProductToStageFail = (payload) => ({
  type: types.COPY_PRODUCT_TO_STAGE_FAIL,
  payload,
})
