import React, { useState } from 'react'
import '../style.scss'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import Header from 'components/common/Header'
import {
  createDamageProductsStart,
  deleteDamageProductsStart,
} from 'store/damageProduct/actions'
import Button from 'components/common/Button'
import { redirect } from 'helpers/index'
import {
  fetchOneWarehouseCaseSync,
  updateWarehouseCase,
} from 'store/warehouseCase/actions'
import QuantityControl from 'components/common/QuantityControl'
import {
  INVENTORY_TOOL_SCAN_CASE_PAGE,
  INVENTORY_TOOL_SCAN_LOCATION_PAGE,
} from 'constants/routes'

const DamageInventoryTool = (props) => {
  const {
    warehouseCase,

    updateWarehouseCase,
    deleteDamageProducts,
    createDamageProducts,
  } = props

  const damagedCasesWithOutResolution = warehouseCase.damaged_products.filter(
    (item) => !item.resolution_id,
  )

  const [caseQuantity, setCaseQuantity] = useState(
    damagedCasesWithOutResolution.length,
  )

  const onChange = (name, value) => setCaseQuantity(value)
  const onDecrement = () => setCaseQuantity(caseQuantity - 1)
  const onIncrement = () => setCaseQuantity(caseQuantity + 1)

  const createDamageCases = () => {
    const countToCreate = caseQuantity - damagedCasesWithOutResolution.length

    for (let i = 0; i < countToCreate; i++) {
      createDamageProducts({
        container_id: null,
        damage_reason: null,
        damage_upc: '111',
        damaged_by_vendor: 0,
        product_id: warehouseCase.product.products_id,
        resolution_id: null,
        warehouse_case_id: warehouseCase.id,
      })
    }

    updateWarehouseCase({
      id: warehouseCase.id,
      data: {
        id: warehouseCase.id,
        location_case_qty: warehouseCase.location_case_qty + countToCreate,
      },
    })
  }

  const deleteDamageCases = () => {
    const countToDelete = damagedCasesWithOutResolution.length - caseQuantity

    for (let i = 0; i < countToDelete; i++) {
      deleteDamageProducts(damagedCasesWithOutResolution[i].id)
    }

    updateWarehouseCase({
      id: warehouseCase.id,
      data: {
        id: warehouseCase.id,
        location_case_qty: warehouseCase.location_case_qty - countToDelete,
      },
    })
  }

  const onDone = () => {
    if (caseQuantity > damagedCasesWithOutResolution.length) {
      createDamageCases()
    }

    if (caseQuantity < damagedCasesWithOutResolution.length) {
      deleteDamageCases()
    }

    redirect(INVENTORY_TOOL_SCAN_LOCATION_PAGE)
  }

  const onScanNextCase = () =>
    redirect(
      INVENTORY_TOOL_SCAN_CASE_PAGE.replace(
        ':id',
        warehouseCase.warehouseLocation.id,
      ),
    )

  return (
    <div className="tasks-page tasks-page--damage-resolution tasks-page--scan-resolution task-page--inventory">
      <Header>
        {warehouseCase.product_description.products_name}
        <div className="text text--close" onClick={onDone}>
          Done
        </div>
      </Header>
      <div className="content">
        <div className="content__info">
          <div className="content__info-item">
            <div>Model</div>
            <div className="content__info-text">
              {warehouseCase.product.products_model}
            </div>
          </div>
          <div className="content__info-item">
            <div>Manf Modal</div>
            <div className="content__info-text">
              {warehouseCase.product.products_manf_model}
            </div>
          </div>
        </div>

        <QuantityControl
          minValue={0}
          onChange={onChange}
          value={caseQuantity}
          onDecrease={onDecrement}
          onIncrease={onIncrement}
          styles={{ fontSize: 56.7 }}
        />

        <Button onClick={onScanNextCase}>Scan Next Case</Button>
      </div>
    </div>
  )
}

DamageInventoryTool.propTypes = {
  warehouseCase: PropTypes.object,

  updateWarehouseCase: PropTypes.func,
  fetchOneWarehouseCase: PropTypes.func,
  deleteDamageProducts: PropTypes.func,
  createDamageProducts: PropTypes.func,
}

const mapDispatchToProps = {
  updateWarehouseCase: updateWarehouseCase,
  fetchOneWarehouseCase: fetchOneWarehouseCaseSync,
  deleteDamageProducts: deleteDamageProductsStart,
  createDamageProducts: createDamageProductsStart,
}

export default connect(null, mapDispatchToProps)(DamageInventoryTool)
