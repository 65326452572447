export const types = {
  UPDATE_WAREHOUSE_CASE: 'UPDATE_WAREHOUSE_CASE',
  CREATE_WAREHOUSE_CASE: 'CREATE_WAREHOUSE_CASE',
  DELETE_WAREHOUSE_CASE: 'DELETE_WAREHOUSE_CASE',
  CREATE_WAREHOUSE_CASE_SYNC: 'CREATE_WAREHOUSE_CASE_SYNC',
  FETCH_ONE_WAREHOUSE_CASE_SYNC: 'FETCH_ONE_WAREHOUSE_CASE_SYNC',
  FETCH_ONE_WAREHOUSE_CASE_SUCCESS: 'FETCH_ONE_WAREHOUSE_CASE_SUCCESS',
  UPDATE_WAREHOUSE_CASE_SOLD: 'UPDATE_WAREHOUSE_CASE_SOLD',
}

export const updateWarehouseCase = (payload) => ({
  type: types.UPDATE_WAREHOUSE_CASE,
  payload,
})

export const createWarehouseCase = (payload) => ({
  type: types.CREATE_WAREHOUSE_CASE,
  payload,
})

export const createWarehouseCaseSync = (payload) => ({
  type: types.CREATE_WAREHOUSE_CASE_SYNC,
  payload,
})

export const fetchOneWarehouseCaseSync = (payload) => ({
  type: types.FETCH_ONE_WAREHOUSE_CASE_SYNC,
  payload,
})

export const fetchOneWarehouseCaseSuccess = (payload) => ({
  type: types.FETCH_ONE_WAREHOUSE_CASE_SUCCESS,
  payload,
})

export const deleteWarehouseCase = (payload) => ({
  type: types.DELETE_WAREHOUSE_CASE,
  payload,
})

export const updateWarehouseCaseSold = (payload) => ({
  type: types.UPDATE_WAREHOUSE_CASE_SOLD,
  payload,
})
