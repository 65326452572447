import { requestWithIgnore } from 'helpers/index'
import { API_URL } from 'constants/apiconfig'

export const getRestockCount = (payload) =>
  requestWithIgnore({
    url: `${API_URL}processes/restock-count/${payload}`,
    method: 'GET',
  })

export const getBulkStorageFromCount = (payload) =>
  requestWithIgnore({
    url: `${API_URL}processes/restock/task/bulk-storage-from-count`,
    method: 'GET',
  })

export const getBulkStorageToCount = (payload) =>
  requestWithIgnore({
    url: `${API_URL}processes/restock/task/bulk-storage-to-count`,
    method: 'GET',
  })

export const getContainerUnloadCount = (payload) =>
  requestWithIgnore({
    url: `${API_URL}containers-count`,
    method: 'GET',
  })

export const getMyiPodTaskCount = (payload) =>
  requestWithIgnore({
    url: `${API_URL}task-count`,
    method: 'GET',
    params: payload,
  })

export const getPowerPickingCount = (payload) =>
  requestWithIgnore({
    url: `${API_URL}power-picking-count`,
    method: 'GET',
    params: payload,
  })

export const getPutawayCount = (payload) =>
  requestWithIgnore({
    url: `${API_URL}putaway-count`,
    method: 'GET',
    params: payload,
  })

export const getLoadingCount = (payload) =>
  requestWithIgnore({
    url: `${API_URL}loading-count`,
    method: 'GET',
    params: payload,
  })

export const getInventoryCount = (payload) =>
  requestWithIgnore({
    url: `${API_URL}inventory-taker/inventory-count`,
    method: 'GET',
    params: payload,
  })
