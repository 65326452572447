import request from 'helpers/index'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}container-pallet`

export const recountForContainerProduct = (payload) =>
  request({
    url: `${apiEndpoint}/recount`,
    method: 'POST',
    data: payload,
  })

export const markPalletComplete = (payload) =>
  request({
    url: `${apiEndpoint}/mark-complete/${payload}`,
    method: 'GET',
  })
