import { types } from './actions'

const INIT = {
  item: [],
  isLoading: false,
}

const staffReducer = (state = INIT, action) => {
  const { type, payload } = action

  switch (type) {
    case types.FETCH_STAFF_ASSIGNMENT_START:
      return { ...state, isLoading: true }
    case types.FETCH_STAFF_ASSIGNMENT_SUCCESS:
      return { item: payload, isLoading: false }
    case types.FETCH_STAFF_ASSIGNMENT_FAIL:
      return INIT
    default:
      return state
  }
}

export default staffReducer
