import React, { useEffect, useState } from 'react'
import './style.scss'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { STATUS_MEASURED, STATUS_CREATED } from 'constants/measurementStatuses'
import ModalDefault from 'components/common/Modal/ModalDefault'
import Button from 'components/common/Button'
import {
  clearMeasurementItems,
  fetchCreateMeasurementItemsStart,
  updateMeasurementItems,
} from 'store/measurement/actions'
import { resetModals, showModal, closeModal } from 'store/modal/actions'
import MeasurementItem from './MeasurementItem'

const DEFAULT_CASES_COUNT = 1

const MeasurementPopup = (props) => {
  const {
    modalInfo,
    measurementItems,
    isOpen,
    fetchCreateMeasurementItems,
    updateMeasurementItems,
    showModal,
    resetModals,
    closeModal,
  } = props

  const [casesQuantity, setCasesQuantity] = useState(0)
  const [popupItems, setPopupItems] = useState([])
  const [renderItems, setRenderItems] = useState([])
  const [additionMeasurements, setAdditionMeasurements] = useState([])

  const onUpdateMeasurementItems = () => {
    if (measurementItems) {
      closeModal('modalPending')
    }
    const mappedItems = measurementItems.map((item) => ({
      id: item.id,
      params: {
        case_length: item.case_length,
        case_width: item.case_width,
        case_height: item.case_height,
        case_weight: item.case_weight,
      },
      is_filled: false,
      is_checked: !!item.is_checked,
      is_different: !!item.is_different,
    }))

    setPopupItems([...popupItems, ...mappedItems])
  }

  const onFetchModalInfo = () => {
    setCasesQuantity(DEFAULT_CASES_COUNT)
  }

  const onChangeModalInfoWithOpen = () => {
    if (isOpen) {
      showModal({ name: 'modalPending' })

      fetchCreateMeasurementItems({
        container_product_id: modalInfo.scannedItem.id,
        quantity: DEFAULT_CASES_COUNT,
      })
    }
  }

  const onChangePopupItemsAndCaseQty = () => {
    if (isOpen) {
      let localCount = 0
      let lastCheckedItemId = undefined

      setRenderItems(
        popupItems.filter((item) => {
          if (item.is_filled || localCount === casesQuantity) {
            return false
          }

          localCount++

          if (!lastCheckedItemId) {
            lastCheckedItemId = !item.is_checked ? item.id : undefined

            if (!item.is_checked) {
              item.is_active = true
            }
          }

          return true
        }),
      )
    }
  }

  useEffect(onUpdateMeasurementItems, [measurementItems])
  useEffect(onFetchModalInfo, [modalInfo])
  useEffect(onChangeModalInfoWithOpen, [isOpen, modalInfo])
  useEffect(onChangePopupItemsAndCaseQty, [popupItems, casesQuantity])

  const isAllChecked = () =>
    renderItems.filter((item) => !item.is_checked).length === 0

  const prepareMeasurementItem = (item, status) => ({
    id: item.id,
    container_product_id: item.container_product_id,
    status: item.is_checked ? status : item.status,
    case_width: item.params.case_width,
    case_height: item.params.case_height,
    case_length: item.params.case_length,
    case_weight: item.params.case_weight,
    is_checked: item.is_checked,
    is_different: item.is_different,
  })

  const onCheck = (item) => {
    if (isAllChecked()) {
      setAdditionMeasurements([])
    }

    setPopupItems(
      popupItems.map((popupCaseItem) =>
        popupCaseItem.id === item.id ? item : popupCaseItem,
      ),
    )
  }

  const onSubmit = () => {
    let preparedItems = []
    setCasesQuantity(1)
    modalInfo.onClose()

    if (!isAllChecked()) {
      preparedItems = popupItems.map((item) =>
        prepareMeasurementItem(item, STATUS_CREATED),
      )
      modalInfo.showModalCaseReceivedWithData()
    } else {
      preparedItems = popupItems.map((item) =>
        prepareMeasurementItem(item, STATUS_MEASURED),
      )
      setAdditionMeasurements([])
      setCasesQuantity(DEFAULT_CASES_COUNT)

      showModal({
        name: 'modalPalletAdjustment',
        scannedItem: modalInfo.scannedItem,
        onConfirm: modalInfo.onConfirmPalletAdjustment,
        onClose: () => resetModals(),
      })
    }

    modalInfo.onConfirm(preparedItems)
    updateMeasurementItems({ data: preparedItems })
    setPopupItems([])
    setRenderItems([])
  }

  return (
    <>
      {isOpen && (
        <ModalDefault isOpen={isOpen}>
          <div className="modal modal--measurement-popup">
            <div className="modal__header">
              <div className="title">Measurement (IN/LB)</div>
            </div>
            <div className="modal__body">
              {renderItems.map((item, index) => (
                <React.Fragment key={item.id}>
                  <MeasurementItem
                    key={item.id}
                    index={index + 1}
                    item={item}
                    onCheck={(item) => onCheck(item)}
                  />
                  {(additionMeasurements.length === 0 && !item.is_checked) ||
                  !item.is_active ? (
                    <></>
                  ) : (
                    additionMeasurements.map((measurementItem) => (
                      <MeasurementItem
                        key={`${item.id}${item.id}`}
                        item={{
                          ...measurementItem,
                          id: item.id,
                          is_addition: true,
                        }}
                        onCheck={(item) =>
                          onCheck({ ...item, is_addition: false })
                        }
                      />
                    ))
                  )}
                </React.Fragment>
              ))}
            </div>
            <div className="btn-menu">
              <Button disabled={!isAllChecked()} onClick={onSubmit}>
                Update
              </Button>
            </div>
          </div>
        </ModalDefault>
      )}
    </>
  )
}

MeasurementPopup.propTypes = {
  isOpen: PropTypes.bool,

  measurementItems: PropTypes.array,

  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  fetchCreateMeasurementItems: PropTypes.func,
  fetchMeasurementItems: PropTypes.func,
  updateMeasurementItems: PropTypes.func,
}

const mapStateToProps = (state) => ({
  measurementItems: state.measurement.items,
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalMeasurementPopup',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalMeasurementPopup',
  ),
})

const mapDispatchToProps = {
  fetchCreateMeasurementItems: fetchCreateMeasurementItemsStart,
  updateMeasurementItems: updateMeasurementItems,
  showModal: showModal,
  clearMeasurementItems: clearMeasurementItems,
  resetModals: resetModals,
  closeModal: closeModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(MeasurementPopup)
