import React from 'react'
import * as PropTypes from 'prop-types'
import ModalDefault from 'components/common/Modal/ModalDefault'
import CancelIcon from 'assets/svg/cancel.svg'
import CancelIconIPod from 'assets/svg/cancel--ipod.svg'
import './style.scss'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'
import { MODAL_OPTIONS_LIST } from 'components/common/Modal'

const ModalOptionsList = ({ modalInfo, isOpen }) => {
  if (!isOpen) {
    return <></>
  }

  return (
    <ModalDefault isOpen={isOpen}>
      <div className="modal modal--options-list">
        <div className="title">{modalInfo.title}</div>
        {modalInfo.optionsList.map((option) => {
          const onSelect = option.action
          const buttonClass = 'online'

          return (
            <Card
              className={`modal--options-list__card ${buttonClass}`}
              onClick={onSelect}
            >
              <CardActionArea>
                <CardContent className="modal--options-list__card-content">
                  {option.label}
                </CardContent>
              </CardActionArea>
            </Card>
          )
        })}

        <div onClick={modalInfo.onDeny} className="btn--close">
          {window.innerWidth < 768 ? <CancelIconIPod /> : <CancelIcon />}
        </div>
      </div>
    </ModalDefault>
  )
}

ModalOptionsList.propTypes = {
  isOpen: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === MODAL_OPTIONS_LIST,
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === MODAL_OPTIONS_LIST,
  ),
})

export default connect(mapStateToProps)(ModalOptionsList)
