import request, { requestWithRetry } from 'helpers'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}warehouse-pallets`

export const create = (payload) =>
  request({
    url: `${apiEndpoint}`,
    method: 'POST',
    data: payload,
  })

export const createForPutaway = (payload) =>
  request({
    url: `${apiEndpoint}/createForPutaway`,
    method: 'POST',
    data: payload,
  })

export const createMany = (payload) =>
  request({
    url: `${apiEndpoint}/createMany`,
    method: 'POST',
    data: payload,
  })

export const update = (payload) =>
  request({
    url: `${apiEndpoint}/${payload.id}`,
    method: 'PUT',
    data: payload,
  })

export const remove = (payload) =>
  request({
    url: `${apiEndpoint}/${payload.id}`,
    method: 'DELETE',
    data: payload,
  })

export const removeMany = (payload) =>
  request({
    url: `${apiEndpoint}/deleteMany`,
    method: 'DELETE',
    data: payload,
  })

export const fetchOne = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}/${payload}`,
    method: 'GET',
  })

export const createPalletLabel = (payload) => {
  return request({
    url: `${payload}/api/warehouse-pallets/label/1`,
    method: 'GET',
  })
}
