import request, { requestWithRetry } from 'helpers'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}inventory-taker`

export const fetchLocation = (payload) => {
  return request({
    url: `${apiEndpoint}/get-location/${payload}`,
    method: 'GET',
    data: payload,
  })
}

export const fetchPallet = (payload) => {
  return request({
    url: `${apiEndpoint}/get-pallet/${payload.location_upc}/${payload.upc}`,
    method: 'GET',
    data: payload,
  })
}

export const savePallet = (payload) => {
  return request({
    url: `${apiEndpoint}/save-pallet/${payload.location_upc}`,
    method: 'POST',
    data: { data: payload.data },
  })
}

export const deletePallet = (payload) => {
  return request({
    url: `${apiEndpoint}/delete-pallet/${payload.location_upc}/${payload.upc}`,
    method: 'GET',
    data: payload,
  })
}

export const fetchProduct = (payload) => {
  return request({
    url: `${apiEndpoint}/get-product/${payload.location_upc}/${payload.upc}`,
    method: 'GET',
    data: payload,
  })
}

export const saveProduct = (payload) => {
  return request({
    url: `${apiEndpoint}/save-product/${payload.location_upc}`,
    method: 'POST',
    data: { data: payload.data },
  })
}

export const copyProductToStage = (payload) => {
  return request({
    url: `${apiEndpoint}/copy-product/${payload}`,
    method: 'GET',
    data: payload,
  })
}

export const resetLocation = (payload) => {
  return request({
    url: `${apiEndpoint}/reset-location/${payload}`,
    method: 'GET',
    data: payload,
  })
}
