import React from 'react'
import './style.scss'
import { connect } from 'react-redux'

const AppStatusBanner = ({ isOffline }) => {
  const offlineBanner = isOffline ? (
    <div className="offline-banner">
      <span className="offline-banner-text">OFFLINE</span>
    </div>
  ) : (
    ''
  )

  return offlineBanner
}

const mapStateToProps = (state) => ({
  isOffline: state.appInfo.isOffline,
})

export default connect(mapStateToProps)(AppStatusBanner)
