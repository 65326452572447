import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import Header from 'components/common/Header'
import LocationToIcon from 'assets/svg/to-location.svg'
import {
  fetchOneRestockProcessStart,
  fetchRestockProcessListStart,
  updateRestockProcess,
} from 'store/restockProcess/actions'
import Active from 'assets/svg/active--ipad.svg'
import ActiveGrey from 'assets/svg/active-grey--ipad.svg'
import Scanner from 'components/common/Scanner'
import { closeModal, resetModals, showModal } from 'store/modal/actions'
import Button from 'components/common/Button'
import {
  deleteWarehousePallet,
  updateWarehousePallet,
} from 'store/warehousePallet/actions'
import { isEmptyObject, redirect } from 'helpers/index'
import {
  createWarehouseCase,
  updateWarehouseCase,
} from 'store/warehouseCase/actions'
import { ANY_STORAGE_TYPE, ANY_PICK_TYPE } from 'constants/locationTypes'
import { RESTOCK_TASK_LIST_PAGE } from 'constants/routes'
import ExclamationSign from 'assets/svg/exclamation.svg'
import { STATUS_COMPLETED } from 'constants/processStatuses'

const RestockQaHoldPage = (props) => {
  const {
    user,
    match,
    currentProcess,

    resetModals,
    updateWarehouseCase,
    closeModal,
    updateWarehousePallet,
    updateRestockProcess,
    createWarehouseCase,
    deleteWarehousePallet,
    showModal,
    fetchOneRestockProcess,
  } = props

  const processId = match.params.processId

  const onMount = () => {
    showModal({
      name: 'modalPending',
    })
    fetchOneRestockProcess({
      id: processId,
    })
  }

  const [locationTitle, setLocationTitle] = useState('')
  const [restockLocation, setRestockLocation] = useState(undefined)
  const [isFromLocationScanned, setIsFromLocationScanned] = useState(false)
  const [isPalletScanned, setIsPalletScanned] = useState(false)
  const [isToLocationScanned, setIsToLocationScanned] = useState(false)
  const [scannedPallet, setScannedPallet] = useState(undefined)

  const [isScanned, setIsScanned] = useState(false)

  const onUpdateCurrentProcess = () => {
    if (!isEmptyObject(currentProcess)) {
      const firstAndSingleRestockLocation =
        currentProcess.entity.restock_location[0]
      setRestockLocation(firstAndSingleRestockLocation)
    }
  }

  const onUpdateRestockLocation = () => {
    if (restockLocation !== undefined) {
      let aisle = ''
      let bay = ''
      let level = ''

      if (isPalletScanned) {
        aisle =
          restockLocation.warehouse_location_to.regular_location.aisle_id.name
        bay = restockLocation.warehouse_location_to.regular_location.bay_id.name
        level = restockLocation.warehouse_location_to.regular_location.level
      } else {
        aisle =
          restockLocation.warehouse_location_from.regular_location.aisle_id.name
        bay =
          restockLocation.warehouse_location_from.regular_location.bay_id.name
        level = restockLocation.warehouse_location_from.regular_location.level
      }

      setLocationTitle(`${aisle}-${bay}-${level}`)

      closeModal('modalPending')
    }
  }

  useEffect(onMount, [])
  useEffect(onUpdateCurrentProcess, [currentProcess])
  useEffect(onUpdateRestockLocation, [restockLocation])

  const isHasStorageType = (id) => ANY_STORAGE_TYPE.indexOf(id) > -1
  const isHasPickType = (id) => ANY_PICK_TYPE.indexOf(id) > -1

  if (isEmptyObject(currentProcess) || !restockLocation || !locationTitle) {
    return <></>
  }

  const onScanFromLocation = (barcode) => {
    if (
      restockLocation.warehouse_location_from.upc.toString() ===
      barcode.toString()
    ) {
      setIsScanned(true)
      setTimeout(() => {
        setIsFromLocationScanned(true)
        setIsScanned(false)
      }, 1500)
    } else {
      showModal({
        name: 'modalWrongLocation',
        onAccept: resetModals,
        text: locationTitle,
      })
    }
  }

  const onScanPallet = (barcode) => {
    const productId = restockLocation.product.products_id
    const warehouseCasesId = restockLocation.warehouse_location_from.warehouse_cases_in_location.find(
      (item) => item.product_id === productId,
    ).id
    const warehousePallet = restockLocation.warehouse_pallets.find(
      (item) => item.pallet_upc.toString() === barcode.toString(),
    )

    switch (true) {
      case !warehousePallet:
        showModal({
          name: 'modalWarning',
          onAccept: resetModals,
          onDeny: resetModals,
          text: 'Wrong Pallet UPC',
          className: 'modal--tasks',
          modalIcon: (
            <ExclamationSign className="icon--restock icon--exclamation" />
          ),
        })
        break
      case warehousePallet.qty_cases_on_pallet !== restockLocation.quantity:
        showModal({
          name: 'modalWarning',
          onAccept: resetModals,
          onDeny: resetModals,
          text: 'Wrong Case Quantity',
          className: 'modal--tasks',
          modalIcon: (
            <ExclamationSign className="icon--restock icon--exclamation" />
          ),
        })
        break
      case warehousePallet.warehouse_case_id !== warehouseCasesId:
        showModal({
          name: 'modalWarning',
          onAccept: resetModals,
          onDeny: resetModals,
          text: 'Wrong Product',
          className: 'modal--tasks',
          modalIcon: (
            <ExclamationSign className="icon--restock icon--exclamation" />
          ),
        })
        break
      default:
        setIsScanned(true)
        setTimeout(() => {
          setScannedPallet(warehousePallet)
          setIsPalletScanned(true)
          setIsScanned(false)
        }, 1500)
    }
  }

  const onScanStorage = () => {
    const productId = restockLocation.product.products_id
    const fromWarehouseCases = restockLocation.warehouse_location_from.warehouse_cases_in_location.find(
      (item) => item.product_id === productId,
    )
    const toWarehouseCases = restockLocation.warehouse_location_to.warehouse_cases_in_location.find(
      (item) => item.product_id === productId,
    )

    updateWarehouseCase({
      id: fromWarehouseCases.id,
      data: {
        id: fromWarehouseCases.id,
        location_case_qty:
          fromWarehouseCases.location_case_qty -
          scannedPallet.qty_cases_on_pallet,
      },
    })

    if (!toWarehouseCases) {
      createWarehouseCase({
        request: {
          data: {
            product_id: restockLocation.product.products_id,
            updated_by_user_id: user.warehouseStaffId,
            created_by_user_id: user.warehouseStaffId,
            location_case_qty: scannedPallet.qty_cases_on_pallet,
            warehouse_location_id: restockLocation.warehouse_location_to.id,
          },
        },
        afterAction: (item) => {
          updateWarehousePallet({
            id: scannedPallet.id,
            data: {
              id: scannedPallet.id,
              warehouse_case_id: item.id,
            },
          })
        },
      })
    } else {
      updateWarehouseCase({
        id: toWarehouseCases.id,
        data: {
          id: toWarehouseCases.id,
          location_case_qty:
            toWarehouseCases.location_case_qty +
            scannedPallet.qty_cases_on_pallet,
        },
      })

      updateWarehousePallet({
        id: scannedPallet.id,
        data: {
          id: scannedPallet.id,
          warehouse_case_id: toWarehouseCases.id,
        },
      })
    }
  }

  const onScanPickSelect = () => {
    const productId = restockLocation.product.products_id
    const fromWarehouseCases = restockLocation.warehouse_location_from.warehouse_cases_in_location.find(
      (item) => item.product_id === productId,
    )
    const toWarehouseCases = restockLocation.warehouse_location_to.warehouse_cases_in_location.find(
      (item) => item.product_id === productId,
    )

    updateWarehouseCase({
      id: fromWarehouseCases.id,
      data: {
        id: fromWarehouseCases.id,
        location_case_qty:
          fromWarehouseCases.location_case_qty -
          scannedPallet.qty_cases_on_pallet,
      },
    })

    if (!toWarehouseCases) {
      createWarehouseCase({
        data: {
          product_id: restockLocation.product.products_id,
          updated_by_user_id: user.warehouseStaffId,
          created_by_user_id: user.warehouseStaffId,
          location_case_qty: scannedPallet.qty_cases_on_pallet,
          warehouse_location_id: restockLocation.warehouse_location_to.id,
        },
      })
    } else {
      updateWarehouseCase({
        id: toWarehouseCases.id,
        data: {
          id: toWarehouseCases.id,
          location_case_qty:
            toWarehouseCases.location_case_qty +
            scannedPallet.qty_cases_on_pallet,
        },
      })
    }

    deleteWarehousePallet({
      id: scannedPallet.id,
      data: {
        id: scannedPallet.id,
      },
    })
  }

  const onScanToLocation = (barcode) => {
    if (
      restockLocation.warehouse_location_to.upc.toString() ===
      barcode.toString()
    ) {
      setIsScanned(true)

      const locationTypeId =
        restockLocation.warehouse_location_to.location_type.id

      if (isHasStorageType(locationTypeId)) {
        onScanStorage()
      } else if (isHasPickType(locationTypeId)) {
        onScanPickSelect()
      }

      setTimeout(() => {
        setIsToLocationScanned(true)
        setIsScanned(false)
      }, 1500)
    } else {
      showModal({
        name: 'modalWrongLocation',
        onAccept: resetModals,
        text: locationTitle,
      })
    }
  }

  const onScan = (barcode) => {
    if (!isFromLocationScanned) {
      onScanFromLocation(barcode)
    } else if (!isPalletScanned) {
      onScanPallet(barcode)
    } else if (!isToLocationScanned) {
      onScanToLocation(barcode)
    }
  }

  const onComplete = () => {
    updateRestockProcess({
      id: processId,
      data: {
        id: processId,
        status: STATUS_COMPLETED,
      },
    })

    redirect(RESTOCK_TASK_LIST_PAGE)
  }

  const getBottomContent = () => {
    if (isPalletScanned && isToLocationScanned) {
      return (
        <div className="content__section content__section--white">
          <span className="status">
            <Button onClick={onComplete}>{'Complete'}</Button>
          </span>
        </div>
      )
    }

    if (isPalletScanned) {
      return (
        <div className="content__section content__section--white">
          <span className="status">
            Scan Location UPC{isScanned ? <Active /> : <ActiveGrey />}
          </span>
        </div>
      )
    }

    if (isFromLocationScanned) {
      return (
        <div className="content__section content__section--white">
          <span className="status">
            Scan Pallet UPC {isScanned ? <Active /> : <ActiveGrey />}
          </span>
        </div>
      )
    }

    if (!isFromLocationScanned) {
      return (
        <div className="content__section content__section--white">
          <span className="status">
            Scan Location UPC{isScanned ? <Active /> : <ActiveGrey />}
          </span>
        </div>
      )
    }
  }

  return (
    <div className="tasks-page tasks-page--start-restock">
      <Header />
      <Scanner needToRemoveListener={false} onReceiveData={onScan} />
      <div className="content">
        <div className="content__section">
          <div className="dock__wrapper">
            <div className="dock">
              <span className="dock__label">LOC</span>
              <span className="dock__name">{locationTitle}</span>
            </div>
          </div>
          <h3 className="fireworks-name">{restockLocation.product_name}</h3>
          <ul className="fireworks">
            <li className="fireworks__item">
              <span className="fireworks__name">Model</span>
              <span className="fireworks__sub-name">
                {restockLocation.product.products_model}
              </span>
            </li>
            <li className="fireworks__item">
              <span className="fireworks__name">Manf Model</span>
              <span className="fireworks__sub-name">
                {restockLocation.product.products_manf_model}
              </span>
            </li>
            <li className="fireworks__item">
              <LocationToIcon />
            </li>
          </ul>
        </div>
        {getBottomContent()}
      </div>
    </div>
  )
}

RestockQaHoldPage.propTypes = {
  currentProcess: PropTypes.object,

  fetchRestockProcessList: PropTypes.func,
  resetModals: PropTypes.func,
  updateWarehouseCase: PropTypes.func,
  closeModal: PropTypes.func,
  updateWarehousePallet: PropTypes.func,
  deleteWarehousePallet: PropTypes.func,
  showModal: PropTypes.func,
  fetchOneRestockProcess: PropTypes.func,
  updateRestockProcess: PropTypes.func,
}

const mapStateToProps = (state) => ({
  currentProcess: state.restockProcess.item,
})

const mapDispatchToProps = {
  fetchRestockProcessList: fetchRestockProcessListStart,
  resetModals: resetModals,
  updateWarehouseCase: updateWarehouseCase,
  closeModal: closeModal,
  updateWarehousePallet: updateWarehousePallet,
  deleteWarehousePallet: deleteWarehousePallet,
  showModal: showModal,
  fetchOneRestockProcess: fetchOneRestockProcessStart,
  updateRestockProcess: updateRestockProcess,
  createWarehouseCase: createWarehouseCase,
}

export default connect(mapStateToProps, mapDispatchToProps)(RestockQaHoldPage)
