import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import Header from 'components/common/Header'
import Scanner from 'components/common/Scanner'
import TableList from 'components/common/TableList'
import ArrowRoundedIcon from 'assets/svg/arrow-rounded.svg'
import { connect } from 'react-redux'
import Button from 'components/common/Button'
import { date, dateToFormat, redirect } from 'helpers/index'
import { CONTAINER_LIST_PAGE, HOME_PAGE } from 'constants/routes'
import { updateWarehouseCase } from 'store/warehouseCase/actions'
import { fetchQaHoldListStart, updateQaHold } from 'store/qaHold/actions'
import { resetModals, showModal } from 'store/modal/actions'
import {
  deleteWarehousePallet,
  updateWarehousePallet,
} from 'store/warehousePallet/actions'
import * as query from 'querystringify'
import ExclamationSign from 'assets/svg/exclamation.svg'

const columns = [
  {
    id: 1,
    label: 'QA',
    value: 'qa',
  },
  {
    id: 2,
    label: 'Model',
    value: 'model',
  },
  {
    id: 3,
    label: 'Name',
    value: 'name',
  },
  {
    id: 4,
    label: 'Case',
    value: 'caseQuantity',
  },
]

const ScanPalletUpcPage = (props) => {
  const {
    qaHolds,
    match,

    updateQaHold,
    deleteWarehousePallet,
    updateWarehousePallet,
    updateWarehouseCase,
    resetModals,
    showModal,
    fetchQaHoldList,
  } = props

  const containerId = match.params.id

  const [container, setContainer] = useState({})
  const [qaHoldsForContainer, setQaHoldsForContainer] = useState([])
  const [scannedWarehousePallet, setScannedWarehousePallet] = useState({})

  const onMount = () => {
    fetchQaHoldList(query.stringify({ 'filters[resolution_id]': null }, true))
  }

  const onUpdateQaHolds = () => {
    const oneQaHoldWithCurrentContainer = qaHolds.find(
      (item) => item.container.id.toString() === containerId.toString(),
    )
    const qaHoldWithCurrentContainer = qaHolds.filter(
      (item) => item.container.id.toString() === containerId.toString(),
    )

    if (oneQaHoldWithCurrentContainer) {
      setQaHoldsForContainer(qaHoldWithCurrentContainer)
      setContainer(oneQaHoldWithCurrentContainer.container)
    }
  }

  useEffect(onMount, [])
  useEffect(onUpdateQaHolds, [qaHolds])

  const getQaHoldsByPalletId = (id) => {
    if (id === undefined) {
      return null
    }

    return qaHoldsForContainer.find((item) => {
      const warehousePallets = item.warehouse_case_in_location.warehouse_pallets

      return !!warehousePallets.find(
        (element) => element.id.toString() === id.toString(),
      )
    })
  }

  const onScan = (barcode) => {
    qaHoldsForContainer.forEach((item) => {
      const warehousePallets = item.warehouse_case_in_location.warehouse_pallets
      warehousePallets.forEach((element) => {
        if (element.pallet_upc.toString() === barcode.toString()) {
          setScannedWarehousePallet(element)
          showModal({
            value: 1,
            onDeny: resetModals,
            onConfirm: onPrintLabel,
            maxValue: scannedWarehousePallet.qty_cases_on_pallet,
            isLastPallet: isLastPallet(scannedWarehousePallet.id),
            barcode: scannedWarehousePallet.pallet_upc,
            qaHold: getQaHoldsByPalletId(scannedWarehousePallet.id),
          })
        } else {
          showModal({
            name: 'modalWarning',
            onAccept: resetModals,
            onDeny: resetModals,
            text: 'Wrong Pallet UPC',
            className: 'modal--tasks',
            modalIcon: (
              <ExclamationSign className="icon--restock icon--exclamation" />
            ),
          })
        }
      })
    })
  }

  const getNormalizedItems = () => {
    return qaHoldsForContainer.reduce((accumulator, currentValue) => {
      return [
        ...accumulator,
        {
          id: currentValue.id,
          qa: currentValue.picked_up_qty ? currentValue.picked_up_qty : 0,
          model: currentValue.productInfo.product.products_model,
          name: currentValue.productInfo.description.products_name,
          caseQuantity: currentValue.quantity_on_hold,
          options: {
            selected: false,
          },
        },
      ]
    }, [])
  }

  const isLastPallet = (id) => {
    if (id) {
      const qaHold = getQaHoldsByPalletId(id)

      if (qaHold) {
        return qaHold.warehouse_case_in_location.warehouse_pallets.length === 1
      }
    }

    return false
  }

  const getCountOfPallets = () => {
    return qaHoldsForContainer.reduce((accumulator, currentValue) => {
      return (
        accumulator +
        currentValue.warehouse_case_in_location.warehouse_pallets.length
      )
    }, 0)
  }

  const onReturnToMenu = () => redirect(HOME_PAGE)

  const onPrintLabel = (quantity) => {
    const qaHold = getQaHoldsByPalletId(scannedWarehousePallet.id)

    updateWarehouseCase({
      id: qaHold.warehouse_case_in_location.id,
      data: {
        id: qaHold.warehouse_case_in_location.id,
        location_case_qty:
          qaHold.warehouse_case_in_location.location_case_qty - quantity,
      },
    })

    if (scannedWarehousePallet.qty_cases_on_pallet - quantity > 0) {
      updateWarehousePallet({
        id: scannedWarehousePallet.id,
        data: {
          id: scannedWarehousePallet.id,
          qty_cases_on_pallet:
            scannedWarehousePallet.qty_cases_on_pallet - quantity,
        },
      })
    } else {
      deleteWarehousePallet({
        id: scannedWarehousePallet.id,
        data: {
          id: scannedWarehousePallet.id,
        },
      })
    }

    updateQaHold({
      id: qaHold.id,
      data: {
        id: qaHold.id,
        picked_up_qty: qaHold.picked_up_qty + quantity,
        pick_up_date: dateToFormat(date(), 'YYYY-MM-DD HH:mm:ss'),
      },
    })

    fetchQaHoldList(query.stringify({ 'filters[resolution_id]': null }, true))
    resetModals()

    redirect(CONTAINER_LIST_PAGE)
  }

  return (
    <div className="page page--restock page--restock-dmg">
      <Header>
        <div className="title">{container.name}</div>
      </Header>
      <Scanner needToRemoveListener={false} onReceiveData={onScan} />
      <div className="content">
        <div className="content__header">
          <p className="text">
            <span className="text--bold"> {`Scan Pallet UPC `} </span>
          </p>
          <p className="text">
            <span className="text--bold">
              {' '}
              {`Available: ${getCountOfPallets()}`}{' '}
            </span>
          </p>
        </div>
        <TableList
          className="list--restock list--restock-dmg"
          columns={columns}
          rows={getNormalizedItems()}
          events={{}}
          rowBackgroundElement={
            <span className="swipe__text">
              Unload
              <ArrowRoundedIcon className="svg" />
            </span>
          }
        />
        <Button onClick={onReturnToMenu}>Return To Menu</Button>
      </div>
    </div>
  )
}

ScanPalletUpcPage.propTypes = {
  fetchQaHoldList: PropTypes.func,
  showModalQaCaseQty: PropTypes.func,
  resetModals: PropTypes.func,
  updateWarehouseCase: PropTypes.func,
  updateWarehousePallet: PropTypes.func,
  deleteWarehousePallet: PropTypes.func,
  updateQaHold: PropTypes.func,

  qaHolds: PropTypes.array,
}

const mapStateToProps = (state) => ({
  qaHolds: state.qaHoldReducer.list,
})

const mapDispatchToProps = {
  fetchQaHoldList: fetchQaHoldListStart,
  showModal: showModal,
  resetModals: resetModals,
  updateWarehouseCase: updateWarehouseCase,
  updateWarehousePallet: updateWarehousePallet,
  deleteWarehousePallet: deleteWarehousePallet,
  updateQaHold: updateQaHold,
}

export default connect(mapStateToProps, mapDispatchToProps)(ScanPalletUpcPage)
