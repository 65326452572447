import request, { requestWithRetry } from 'helpers'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}damage-products`

export const fetchAll = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}${payload}`,
    method: 'GET',
  })

export const fetchOneForInventory = (payload) =>
  requestWithRetry({
    url: `${API_URL}damage-product-resolution/${payload}`,
    method: 'GET',
  })

export const update = (payload) =>
  request({
    url: `${API_URL}damage-products/${payload.id}`,
    method: 'PUT',
    data: payload,
  })

export const remove = (payload) =>
  request({
    url: `${apiEndpoint}/${payload}`,
    method: 'DELETE',
  })

export const create = (payload) =>
  request({
    url: `${apiEndpoint}`,
    method: 'POST',
    data: payload,
  })
