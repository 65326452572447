export const PHOTO_LINK_START =
  'https://d29j2csl3b3dix.cloudfront.net/_store/images/staff/100_'

export const DAMAGED_LOCATION_TYPE_ID = 8

export const DIRECTION_ASC = 'asc'
export const DIRECTION_DESC = 'desc'

export const WAREHOUSE_SHIFT_HOURS = 9

export const API_CODES = {
  OK: 200,
  UNAUTHORIZED: 401,
  UNPROCESSABLE: 422,
}

export const PROJECT = {
  project_id: 5,
}

export const ENVIRONMENT_PROD = 'prod'
