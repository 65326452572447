import { types } from './actions'

const INIT = {
  item: {},
  items: [],
  nextTaskAssignmentId: null,
  conditions: {},
  redirectAction: {},
  lastRefresh: new Date(),
  isFetched: false,
  pauseReasons: [],
  isPickEnded: false,
}

const taskAssignmentReducer = (state = INIT, action) => {
  const { type, payload } = action
  switch (type) {
    case types.FETCH_TASK_ASSIGNMENT_LIST_START:
      return { ...state, items: [], isFetched: false }
    case types.FETCH_ONE_TASK_ASSIGNMENT_SUCCESS:
      return { ...state, item: payload }
    case types.FETCH_TASK_ASSIGNMENT_LIST_SUCCESS:
      return {
        ...state,
        isFetched: true,
        items: payload.assignments,
        conditions: payload.conditions,
        redirectAction: payload.redirectAction,
        lastRefresh: new Date(),
      }
    case types.FETCH_ONE_TASK_ASSIGNMENT_FAIL:
      return { ...state, isFetched: false }
    case types.FETCH_TASK_ASSIGNMENT_LIST_FAIL:
      return { ...state, isFetched: false }
    // case types.FETCH_PAUSE_REASONS_START:
    //   return { ...state, pauseReasons: [] };
    case types.FETCH_PAUSE_REASONS_SUCCESS:
      return { ...state, pauseReasons: payload }
    case types.UPDATE_NEXT_TASK_ASSIGNMENT_ID:
      return { ...state, nextTaskAssignmentId: payload }
    case types.COMPLETE_ORDER_PICK_SUCCESS:
      return { ...INIT, isPickEnded: true }
    case types.RESET_ASSIGNMENTS:
      return INIT
    default:
      return state
  }
}

export default taskAssignmentReducer
