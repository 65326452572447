export const types = {
  FETCH_ONE_TASK_ASSIGNMENT_START: 'FETCH_ONE_TASK_ASSIGNMENT_START',
  FETCH_TASK_ASSIGNMENT_LIST_SYNC_START:
    'FETCH_TASK_ASSIGNMENT_LIST_SYNC_START',
  FETCH_ONE_TASK_ASSIGNMENT_SUCCESS: 'FETCH_ONE_TASK_ASSIGNMENT_SUCCESS',
  FETCH_ONE_TASK_ASSIGNMENT_FAIL: 'FETCH_ONE_TASK_ASSIGNMENT_FAIL',
  FETCH_TASK_ASSIGNMENT_LIST_START: 'FETCH_TASK_ASSIGNMENT_LIST_START',
  FETCH_TASK_ASSIGNMENT_LIST_SUCCESS: 'FETCH_TASK_ASSIGNMENT_LIST_SUCCESS',
  FETCH_TASK_ASSIGNMENT_LIST_FAIL: 'FETCH_TASK_ASSIGNMENT_LIST_FAIL',
  CREATE_TASK_ASSIGNMENT: 'CREATE_TASK_ASSIGNMENT',
  DELETE_TASK_ASSIGNMENT: 'DELETE_TASK_ASSIGNMENT',
  CREATE_RESTOCK_TASK_ASSIGNMENT: 'CREATE_RESTOCK_TASK_ASSIGNMENT',
  CREATE_MANY_TASK_ASSIGNMENT: 'CREATE_MANY_TASK_ASSIGNMENT',
  DELETE_MANY_TASK_ASSIGNMENT: 'DELETE_MANY_TASK_ASSIGNMENT',
  UPDATE_TASK_ASSIGNMENT: 'UPDATE_TASK_ASSIGNMENT',
  UPDATE_TASK_ASSIGNMENT_SYNC: 'UPDATE_TASK_ASSIGNMENT_SYNC',
  UPDATE_TASK_ASSIGNMENT_WITHOUT_FETCH: 'UPDATE_TASK_ASSIGNMENT_WITHOUT_FETCH',
  FETCH_PAUSE_REASONS_START: 'FETCH_PAUSE_REASONS_START',
  FETCH_PAUSE_REASONS_SUCCESS: 'FETCH_PAUSE_REASONS_SUCCESS',
  RESET_ASSIGNMENTS: 'RESET_ASSIGNMENTS',
  UPDATE_NEXT_TASK_ASSIGNMENT_ID: 'UPDATE_NEXT_TASK_ASSIGNMENT_ID',
  COMPLETE_ORDER_PICK: 'COMPLETE_ORDER_PICK',
  COMPLETE_ORDER_PICK_SUCCESS: 'COMPLETE_ORDER_PICK_SUCCESS',
  ASSIGN_TEAM_TO_UNLOAD: 'ASSIGN_TEAM_TO_UNLOAD',
}

export const createManyTaskAssignment = (payload) => ({
  type: types.CREATE_MANY_TASK_ASSIGNMENT,
  payload,
})

export const deleteManyTaskAssignment = (payload) => ({
  type: types.DELETE_MANY_TASK_ASSIGNMENT,
  payload,
})

export const fetchOneTaskAssignmentStart = (payload) => ({
  type: types.FETCH_ONE_TASK_ASSIGNMENT_START,
  payload,
})

export const fetchTaskAssignmentListSyncStart = (payload) => ({
  type: types.FETCH_TASK_ASSIGNMENT_LIST_SYNC_START,
  payload,
})

export const fetchOneTaskAssignmentSuccess = (payload) => ({
  type: types.FETCH_ONE_TASK_ASSIGNMENT_SUCCESS,
  payload,
})

export const fetchOneTaskAssignmentFail = (payload) => ({
  type: types.FETCH_ONE_TASK_ASSIGNMENT_FAIL,
  payload,
})

export const fetchTaskAssignmentListStart = (payload) => ({
  type: types.FETCH_TASK_ASSIGNMENT_LIST_START,
  payload,
})

export const fetchTaskAssignmentListSuccess = (payload) => ({
  type: types.FETCH_TASK_ASSIGNMENT_LIST_SUCCESS,
  payload,
})

export const fetchTaskAssignmentListFail = (payload) => ({
  type: types.FETCH_TASK_ASSIGNMENT_LIST_FAIL,
  payload,
})

export const updateTaskAssignment = (payload) => ({
  type: types.UPDATE_TASK_ASSIGNMENT,
  payload,
})

export const updateTaskAssignmentSync = (payload) => ({
  type: types.UPDATE_TASK_ASSIGNMENT_SYNC,
  payload,
})

export const updateTaskAssignmentWithoutFetch = (payload) => ({
  type: types.UPDATE_TASK_ASSIGNMENT_WITHOUT_FETCH,
  payload,
})

export const createTaskAssignment = (payload) => ({
  type: types.CREATE_TASK_ASSIGNMENT,
  payload,
})

export const deleteTaskAssignment = (payload) => ({
  type: types.DELETE_TASK_ASSIGNMENT,
  payload,
})

export const createRestockTaskAssignment = (payload) => ({
  type: types.CREATE_RESTOCK_TASK_ASSIGNMENT,
  payload,
})

export const fetchPauseReasonsStart = (payload) => ({
  type: types.FETCH_PAUSE_REASONS_START,
  payload,
})

export const fetchPauseReasonsSuccess = (payload) => ({
  type: types.FETCH_PAUSE_REASONS_SUCCESS,
  payload,
})

export const updateNextTaskAssignmentId = (payload) => ({
  type: types.UPDATE_NEXT_TASK_ASSIGNMENT_ID,
  payload,
})

export const resetAssignments = (payload) => ({
  type: types.RESET_ASSIGNMENTS,
  payload,
})

export const completeOrderPick = (payload) => ({
  type: types.COMPLETE_ORDER_PICK,
  payload,
})

export const completeOrderPickSuccess = (payload) => ({
  type: types.COMPLETE_ORDER_PICK_SUCCESS,
  payload,
})

export const assignTeamToUnload = (payload) => ({
  type: types.ASSIGN_TEAM_TO_UNLOAD,
  payload,
})
