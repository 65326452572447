import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import './style.scss'
import ModalDefault from 'components/common/Modal/ModalDefault'
import Button from 'components/common/Button'
import CancelIcon from 'assets/svg/cancel.svg'
import CancelIconIPod from 'assets/svg/cancel--ipod.svg'
import QuantityControl from 'components/common/QuantityControl'
import { DAMAGED_LOCATION_TYPE_ID } from 'constants/common'
import { PROCESS_TYPES } from 'constants/process'
import { connect } from 'react-redux'

const ModalAddDamagedProducts = ({ isOpen, modalInfo }) => {
  const [damagedInContainer, setDamagedInContainer] = useState(0)
  const [damagedInWarehouse, setDamagedInWarehouse] = useState(0)

  if (!isOpen) {
    return <></>
  }

  if (!modalInfo.product) {
    return <></>
  }

  const onAcceptHandle = () => {
    setDamagedInWarehouse(0)
    setDamagedInContainer(0)

    modalInfo.onAccept({
      productInfo: modalInfo.product,
      damagedInContainer,
      damagedInWarehouse,
      process_type: PROCESS_TYPES.CONTAINER_UNLOAD_PROCESS_TYPE,
    })
  }

  const onDenyHandle = () => {
    setDamagedInWarehouse(0)
    setDamagedInContainer(0)

    modalInfo.onDeny()
  }

  const { container_pallets: containerPallets } = modalInfo.product

  let maxCases = 0

  containerPallets.forEach((item) => (maxCases += item.cases_per_pallet))

  const palletsForDamagedCases = containerPallets.filter(
    (item) =>
      item.warehouse_location.location_type.id === DAMAGED_LOCATION_TYPE_ID,
  )
  let quantityOfDamaged = 0

  palletsForDamagedCases.forEach(
    (item) => (quantityOfDamaged += item.cases_loaded),
  )

  return (
    <ModalDefault isOpen={isOpen}>
      <div className="modal modal--damaged-products">
        <div className="modal__header">
          <div className="title">Damaged Case ({quantityOfDamaged})</div>
          <div className="sub-title">
            {modalInfo.product.product.products_model}
          </div>
        </div>
        <div className="modal__row">
          <div className="text--bold">Damage in Container</div>
          <QuantityControl
            minValue={0}
            maxValue={maxCases}
            value={damagedInContainer}
            name="damagedInContainer"
            onChange={(name, value) => setDamagedInContainer(value)}
            onDecrease={() => setDamagedInContainer(damagedInContainer - 1)}
            onIncrease={() => setDamagedInContainer(damagedInContainer + 1)}
          />
        </div>
        <div className="modal__row">
          <div className="text--bold">Damage in Warehouse</div>
          <QuantityControl
            minValue={0}
            maxValue={maxCases}
            value={damagedInWarehouse}
            name="damagedInWarehouse"
            onChange={(name, value) => setDamagedInWarehouse(value)}
            onDecrease={() => setDamagedInWarehouse(damagedInWarehouse - 1)}
            onIncrease={() => setDamagedInWarehouse(damagedInWarehouse + 1)}
          />
        </div>
        <Button onClick={() => onAcceptHandle()}>Confirm</Button>
        <div onClick={onDenyHandle} className="btn--close">
          {window.innerWidth < 768 ? <CancelIconIPod /> : <CancelIcon />}
        </div>
      </div>
    </ModalDefault>
  )
}

ModalAddDamagedProducts.propTypes = {
  isOpen: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalAddDamagedProducts',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalAddDamagedProducts',
  ),
})

export default connect(mapStateToProps)(ModalAddDamagedProducts)
