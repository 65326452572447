import React, { useState, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import ModalDefault from 'components/common/Modal/ModalDefault'
import './style.scss'
import QuantityControl from 'components/common/QuantityControl'
import CancelIcon from 'assets/svg/cancel.svg'
import CancelIconIPod from 'assets/svg/cancel--ipod.svg'
import Button from 'components/common/Button'
import { connect } from 'react-redux'

const MIN_CASES_QUANTITY = 0

const ModalPickedQty = (props) => {
  const { isOpen, modalInfo } = props

  const [quantity, setQuantity] = useState(modalInfo ? modalInfo.value : 0)

  useEffect(() => {
    setQuantity(modalInfo ? modalInfo.value : 0)
  }, [modalInfo])

  if (!isOpen) {
    return <></>
  }

  const onIncrement = () => setQuantity(quantity + 1)
  const onDecrement = () => setQuantity(quantity - 1)
  const onChange = (name, value) =>
    value > modalInfo.maxValue
      ? setQuantity(modalInfo.maxValue)
      : setQuantity(value)
  const onClick = () => modalInfo.onConfirm(quantity)

  const quantityStyles = {
    fontSize: 32,
  }

  return (
    <ModalDefault isOpen={isOpen}>
      <div
        className={`modal modal--confirm modal--start-picking ${
          modalInfo.className ? modalInfo.className : ''
        }`}
      >
        <div className="location-info">
          {modalInfo.model && (
            <div className="text text--model">{modalInfo.model}</div>
          )}
          {modalInfo.location && (
            <div className="text text--location">{modalInfo.location}</div>
          )}
        </div>
        <div className="text text--damaged">Picked Qty</div>
        <QuantityControl
          minValue={modalInfo.minValue || MIN_CASES_QUANTITY}
          maxValue={modalInfo.neededValue || modalInfo.maxValue}
          value={quantity}
          label={`/${modalInfo.neededValue || modalInfo.maxValue}`}
          onChange={onChange}
          onDecrease={onDecrement}
          onIncrease={onIncrement}
          styles={quantityStyles}
        />
        <div className="button">
          <Button onClick={onClick}>Confirm</Button>
        </div>
        <div onClick={modalInfo.onDeny} className="btn--close">
          {window.innerWidth < 768 ? <CancelIconIPod /> : <CancelIcon />}
        </div>
      </div>
    </ModalDefault>
  )
}

ModalPickedQty.propTypes = {
  isOpen: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalPickedQty',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalPickedQty',
  ),
})

export default connect(mapStateToProps)(ModalPickedQty)
