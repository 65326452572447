export const types = {
  FETCH_RESTOCKING_COUNT_START: 'FETCH_RESTOCKING_COUNT_START',
  FETCH_CONTAINER_UNLOAD_COUNT_START: 'FETCH_CONTAINER_UNLOAD_COUNT_START',
  FETCH_MY_IPOD_TASK_COUNT_START: 'FETCH_MY_IPOD_TASK_COUNT_START',
  FETCH_POWER_PICKING_COUNT_START: 'FETCH_POWER_PICKING_COUNT_START',
  FETCH_RESTOCKING_COUNT_SUCCESS: 'FETCH_RESTOCKING_COUNT_SUCCESS',
  FETCH_CONTAINER_UNLOAD_COUNT_SUCCESS: 'FETCH_CONTAINER_UNLOAD_COUNT_SUCCESS',
  FETCH_MY_IPOD_TASK_COUNT_SUCCESS: 'FETCH_MY_IPOD_TASK_COUNT_SUCCESS',
  FETCH_POWER_PICKING_COUNT_SUCCESS: 'FETCH_POWER_PICKING_COUNT_SUCCESS',
  FETCH_PUTAWAY_COUNT_START: 'FETCH_PUTAWAY_COUNT_START',
  FETCH_PUTAWAY_COUNT_SUCCESS: 'FETCH_PUTAWAY_COUNT_SUCCESS',
  FETCH_LOADING_COUNT_START: 'FETCH_LOADING_COUNT_START',
  FETCH_LOADING_COUNT_SUCCESS: 'FETCH_LOADING_COUNT_SUCCESS',
  FETCH_BULK_RESTOCK_FROM_COUNT_START: 'FETCH_BULK_RESTOCK_FROM_COUNT_START',
  FETCH_BULK_RESTOCK_TO_COUNT_START: 'FETCH_BULK_RESTOCK_TO_COUNT_START',
  FETCH_BULK_RESTOCK_FROM_COUNT_SUCCESS:
    'FETCH_BULK_RESTOCK_FROM_COUNT_SUCCESS',
  FETCH_BULK_RESTOCK_TO_COUNT_SUCCESS: 'FETCH_BULK_RESTOCK_TO_COUNT_SUCCESS',
  FETCH_INVENTORY_COUNT_START: 'FETCH_INVENTORY_COUNT_START',
  FETCH_INVENTORY_COUNT_SUCCESS: 'FETCH_INVENTORY_COUNT_SUCCESS',
}

export const fetchRestockCount = (payload) => ({
  type: types.FETCH_RESTOCKING_COUNT_START,
  payload,
})

export const fetchBulkRestockFromCount = (payload) => ({
  type: types.FETCH_BULK_RESTOCK_FROM_COUNT_START,
  payload,
})

export const fetchBulkRestockToCount = (payload) => ({
  type: types.FETCH_BULK_RESTOCK_TO_COUNT_START,
  payload,
})

export const fetchContainerUnloadCount = (payload) => ({
  type: types.FETCH_CONTAINER_UNLOAD_COUNT_START,
  payload,
})

export const fetchMyiPodTaskCount = (payload) => ({
  type: types.FETCH_MY_IPOD_TASK_COUNT_START,
  payload,
})

export const fetchPowerPickingCount = (payload) => ({
  type: types.FETCH_POWER_PICKING_COUNT_START,
  payload,
})

export const fetchRestockCountSuccess = (payload) => ({
  type: types.FETCH_RESTOCKING_COUNT_SUCCESS,
  payload,
})

export const fetchBulkRestockFromCountSuccess = (payload) => ({
  type: types.FETCH_BULK_RESTOCK_FROM_COUNT_SUCCESS,
  payload,
})

export const fetchBulkRestockToCountSuccess = (payload) => ({
  type: types.FETCH_BULK_RESTOCK_TO_COUNT_SUCCESS,
  payload,
})

export const fetchContainerUnloadCountSuccess = (payload) => ({
  type: types.FETCH_CONTAINER_UNLOAD_COUNT_SUCCESS,
  payload,
})

export const fetchMyiPodTaskCountSuccess = (payload) => ({
  type: types.FETCH_MY_IPOD_TASK_COUNT_SUCCESS,
  payload,
})

export const fetchPowerPickingCountSuccess = (payload) => ({
  type: types.FETCH_POWER_PICKING_COUNT_SUCCESS,
  payload,
})

export const fetchLoadingCount = (payload) => ({
  type: types.FETCH_LOADING_COUNT_START,
  payload,
})

export const fetchLoadingCountSuccess = (payload) => ({
  type: types.FETCH_LOADING_COUNT_SUCCESS,
  payload,
})

export const fetchPutawayCount = (payload) => ({
  type: types.FETCH_PUTAWAY_COUNT_START,
  payload,
})

export const fetchPutawayCountSuccess = (payload) => ({
  type: types.FETCH_PUTAWAY_COUNT_SUCCESS,
  payload,
})

export const fetchInventoryCountStart = (payload) => ({
  type: types.FETCH_INVENTORY_COUNT_START,
  payload,
})

export const fetchInventoryCountSuccess = (payload) => ({
  type: types.FETCH_INVENTORY_COUNT_SUCCESS,
  payload,
})
