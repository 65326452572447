import { types } from './actions'

const INIT = {
  printDialogOpen: false,
  printData: null,
  hideCancel: false,
  printerLibrary: null,
}

const printReducer = (state = INIT, action) => {
  const { type, payload, additionalPayload } = action
  let hideCancel = false
  if (additionalPayload) {
    if (additionalPayload.hasOwnProperty('hideCancel')) {
      hideCancel = additionalPayload.hideCancel
    }
  }

  switch (type) {
    case types.OPEN_PRINT_DIALOG:
      return {
        ...state,
        printDialogOpen: true,
        printData: payload,
        hideCancel: hideCancel,
      }
    case types.CLOSE_PRINT_DIALOG:
      return {
        ...state,
        printDialogOpen: false,
        printData: null,
        hideCancel: false,
      }
    case types.FETCH_PRINTER_LIBRARY_SUCCESS:
      return {
        ...state,
        printerLibrary: payload.printer_library,
      }
    default:
      return state
  }
}

export default printReducer
