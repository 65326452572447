import React, { useEffect, useState } from 'react'
import '../../style.scss'
import './style.scss'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  POWER_PICKING_LIST_PAGE,
  START_POWER_PICKING_PAGE,
  POWER_PICKING_WRAP_PALLET_PAGE,
} from 'constants/routes'
import { ORDER_PALLET_STATUSES } from 'constants/orderPalletStatuses'
import { isEmptyObject, redirect, route } from 'helpers/index'
import {
  closeModal,
  resetModals,
  showModal,
  showPendingModal,
  closePendingModal,
} from 'store/modal/actions'
import Header from 'components/common/Header'
import PullsheetListItem from './PullsheetListItem'
import Button from 'components/common/Button'
import {
  resetAssignments,
  completeOrderPick,
} from 'store/taskAssignment/actions'
import {
  fetchOneOrderPowerPickStart,
  updatePowerPickAuto,
  resetOrders,
} from 'store/order/actions'
import { setPowerPick } from 'store/orderPallet/actions'
import { checkStock } from 'store/orderProductPickLocation/actions'
import { logout } from 'store/auth/actions'
import DonePickingModalIcon from 'assets/svg/check-icon.svg'
import { MdWarning } from 'react-icons/md'
import { GrNotes } from 'react-icons/gr'
import { openPrintDialog } from 'store/print/actions'
import Print from 'components/common/Print'
import { ORDER_PALLET_LABEL } from 'constants/print'
import { List } from '@material-ui/core'
import ExclamationSmall from 'assets/svg/exclamation-sign.svg'
import { CARRIER_SERVICE_TYPE } from 'constants/order'
import { DEFAULT_ORDER_PALLET_LABEL_PRINT_QUANTITY } from 'constants/order'
import Scanner from 'components/common/Scanner'

const PowerPickingPullsheetPage = (props) => {
  const {
    orderProcess = null,
    isPowerPickAuto,
    isAutoPickOnlyUser,
    fetchOneOrderPowerPick,
    resetModals,
    showModal,
    showPendingModal,
    closePendingModal,
    match,
    resetAssignments,
    updatePowerPickAuto,
    setPowerPick,
    resetOrders,
    completeOrderPick,
    checkStock,
    openPrintDialog,
  } = props

  const processId = match.params.id

  const [selectedItem, setSelectedItem] = useState({})

  const onLeave = () => {
    resetOrders()
  }

  const onChangeOrder = () => {
    if (orderProcess) {
      closePendingModal()
    }
  }

  const onLoad = () => {
    setTimeout(() => {
      //add a slight delay to prevent modal collision
      showPendingModal()
      fetchOneOrderPowerPick({ id: processId })
    }, 100)

    if (isAutoPickOnlyUser && !isPowerPickAuto) {
      updatePowerPickAuto(true)
    }

    return onLeave
  }

  const findFirstTaskToAutoPick = () => {
    for (var i = 0; i < orderProcess.tasks.length; i++) {
      if (orderProcess.tasks[i].end_date == null) {
        redirect(
          START_POWER_PICKING_PAGE.replace(
            ':id',
            orderProcess.tasks[i].task_assignment.id,
          ),
        )
        break
      }
    }
  }
  if (orderProcess && isPowerPickAuto) {
    findFirstTaskToAutoPick()
  }

  let timeoutId
  const reloadPage = () => {
    timeoutId = setTimeout(() => {
      fetchOneOrderPowerPick({ id: processId })
      reloadPage()
    }, 20000)
  }

  useEffect(() => {
    reloadPage()
    return () => clearTimeout(timeoutId)
  }, [])

  const onChangeSelectedTask = () => {
    if (isEmptyObject(selectedItem)) {
      return
    }

    resetAssignments()

    redirect(
      START_POWER_PICKING_PAGE.replace(':id', selectedItem.task_assignment.id),
    )
  }

  const onSelectReprint = (id) => {
    openPrintDialog({
      id: id,
      document: ORDER_PALLET_LABEL,
      qty: DEFAULT_ORDER_PALLET_LABEL_PRINT_QUANTITY,
    })
  }

  const onSelectWrap = (id, type = 'full') => {
    resetModals()
    setPowerPick({ isPowerPick: true, powerPickProcessId: processId })

    redirect(
      route(POWER_PICKING_WRAP_PALLET_PAGE, {
        orderPalletId: id,
        nextTaskAssignmentId: null,
      }),
    )
  }

  const showModalConfirmWrap = (id, type = 'full') => {
    showModal({
      name: 'modalConfirm',
      onAccept: () => {
        onSelectWrap(id, type)
      },
      onDeny: resetModals,
      className: 'modal--picking-task',
      children: (
        <div className="modal--content-centered">
          <MdWarning style={{ 'font-size': '75px', color: '#ffbf00' }} />
          <p className="text--bold">"Wrap Pallet"</p>
          <p>Are you sure you want continue?</p>
          <p>(This can't be undone)</p>
        </div>
      ),
    })
  }

  const showModalOrderNotes = () => {
    showModal({
      name: 'modalOrderNotes',
      onClose: resetModals,
      order: orderProcess.entity.order,
    })
  }

  const onSelectCheckStock = (item) => {
    showPendingModal()
    checkStock({
      id: item.task_assignment.pick_location.id,
      process_id: processId,
    })
  }

  useEffect(onLoad, [])
  useEffect(onChangeOrder, [orderProcess])
  useEffect(onChangeSelectedTask, [selectedItem, setSelectedItem])

  const orderTotalCasesToPick =
    orderProcess &&
    parseInt(orderProcess.entity.order.total_location_pick_quantity)
  const orderPickedQuantity =
    orderProcess && parseInt(orderProcess.entity.order.total_picked_quantity)
  const orderPallets = orderProcess && orderProcess.entity.order_pallets
  const orderType =
    orderProcess && orderProcess.entity.carrier.carrier_service_type

  const isCompletelyPicked =
    orderPickedQuantity > 0 && orderPickedQuantity === orderTotalCasesToPick
  const calculateWrappedStatus = (orderPallets) => {
    for (const [key, value] of Object.entries(orderPallets)) {
      if (
        value.status_id !== ORDER_PALLET_STATUSES.SHIPPING_LANE_TYPE && // checking for wrap-unfinished status, based on if not in shipping lane
        value.order_pick_pallets.length > 0 // need to check length, bc a blank pallet gets autocreated to have ready
      ) {
        return true
      }
    }
    return false
  }

  const isParcel = orderType ? orderType === CARRIER_SERVICE_TYPE.PARCEL : false // in case orderType isn't in DB or doesn't come through, assume false if null

  const hasUnwrappedPallet =
    orderPallets && calculateWrappedStatus(orderPallets) // if orderPallets comes back null, then we want it to break, bc orderPallets is essential.

  const getContent = () => {
    return orderProcess ? (
      <List>
        {orderProcess.tasks.map((item) => (
          <PullsheetListItem
            key={item.id}
            item={item}
            onSelectWrap={showModalConfirmWrap}
            onSelectReprint={onSelectReprint}
            onSelectCheckStock={onSelectCheckStock}
          />
        ))}
      </List>
    ) : (
      <div className="no-tasks">
        <div className="no-tasks__text"></div>
      </div>
    )
  }

  const onClose = () => redirect(POWER_PICKING_LIST_PAGE)

  const orders_id = orderProcess ? orderProcess.entity.order.orders_id : ''

  const onReasonSelected = (orderProcess, reasonText) => {
    resetModals()

    showPendingModal()

    const afterAction = () => {
      redirect(POWER_PICKING_LIST_PAGE)
    }
    completeOrderPick({
      request: {
        order_process_id: orderProcess.id,
        reason_selected: reasonText,
      },
      afterAction: afterAction,
    })
  }

  const optionsList = [
    {
      label: 'Split Order',
      value: 'Split Order',
      action: () => onReasonSelected(orderProcess, 'Split Order'),
    },
    {
      label: 'Parcel Shipping',
      value: 'Parcel Shipping',
      action: () => onReasonSelected(orderProcess, 'Parcel Shipping'),
    },
    {
      label: 'Missing Cases',
      value: 'Missing Cases',
      action: () => onReasonSelected(orderProcess, 'Missing Cases'),
    },
    {
      label: 'Multiple Pickers',
      value: 'Multiple Pickers',
      action: () => onReasonSelected(orderProcess, 'Multiple Pickers'),
    },
  ]
  const openNeedToWrapModal = (buttonText) => {
    showModal({
      name: 'modalWarning',
      text: `Wrap Pallet before ${buttonText}.`,
      onAccept: resetModals,
      onDeny: resetModals,
      className: 'modal--tasks modal--pause',
      modalIcon: <ExclamationSmall />,
    })
  }

  const openModalDoneWarning = () => {
    resetModals()
    showModal({
      name: 'modalOptionsList',
      title: 'End Pick Reason',
      optionsList: optionsList,
      onDeny: resetModals,
      className: 'modal--tasks modal--pause',
    })
  }

  const total_location_pick_quantity = orderProcess
    ? orderProcess.entity.order.total_location_pick_quantity
    : ''
  const total_picked_quantity = orderProcess
    ? orderProcess.entity.order.total_picked_quantity
    : ''
  const caseStatus = orderProcess
    ? `${total_picked_quantity}/${total_location_pick_quantity} cases`
    : ''

  const onScan = (value) => {
    const scannedTask = orderProcess.tasks.find((task) => {
      return (
        task.task_assignment.pick_location.product.products_upc_case === value
      )
    })

    if (scannedTask) {
      setSelectedItem(scannedTask)
    } else {
      showModal({
        name: 'modalWarning',
        text: 'Product UPC not found',
        onAccept: resetModals,
        onDeny: resetModals,
        className: 'modal--tasks modal--pause',
        modalIcon: <ExclamationSmall />,
      })
    }
  }

  return (
    <div className="tasks-page power-picking-pullsheet-page">
      <Header>
        {orderProcess &&
          (orderProcess.entity.order.shipping_notes ||
            orderProcess.entity.order.warehouse_notes) && (
            <GrNotes
              className="order-notes-icon-ppps"
              onClick={showModalOrderNotes}
            />
          )}
        <div className="title">
          <div>
            Order {orders_id} Pullsheet
            <br />
            {caseStatus}
            <div>
              {orderProcess && orderProcess.entity.order.estimated_pallets}{' '}
              {orderProcess && orderProcess.entity.order.estimated_pallets === 1
                ? 'pallet'
                : 'pallets'}{' '}
              est.
            </div>
            <div className="hard-center">
              <Button
                className="end-pick"
                onDoubleClick={(e) => {
                  isParcel
                    ? openModalDoneWarning()
                    : hasUnwrappedPallet
                    ? openNeedToWrapModal(e.target.innerText)
                    : openModalDoneWarning()
                }}
              >
                End Pick
              </Button>{' '}
            </div>
          </div>
        </div>
        {!isCompletelyPicked ? (
          <div className="text--close" onClick={(e) => onClose()}>
            Close
          </div>
        ) : (
          ''
        )}
      </Header>
      <div className="content">
        <Scanner needToRemoveListener={false} onReceiveData={onScan} />
        {getContent()}
      </div>
      <div>&nbsp;</div>
      <Print />
    </div>
  )
}

PowerPickingPullsheetPage.propTypes = {
  orderProcess: PropTypes.object,
  modals: PropTypes.object,
}

const mapStateToProps = (state) => ({
  orderProcess: state.order.powerPickOrder,
  isPowerPickAuto: state.order.isPowerPickAuto,
  isAutoPickOnlyUser:
    state.auth.user.user.warehouseStaff.is_auto_pick === 1 ? true : false,
})

const mapDispatchToProps = {
  fetchOneOrderPowerPick: fetchOneOrderPowerPickStart,
  resetAssignments: resetAssignments,
  resetModals: resetModals,
  logout: logout,
  showModal: showModal,
  showPendingModal: showPendingModal,
  closePendingModal: closePendingModal,
  closeModal: closeModal,
  updatePowerPickAuto: updatePowerPickAuto,
  setPowerPick: setPowerPick,
  resetOrders: resetOrders,
  completeOrderPick: completeOrderPick,
  checkStock: checkStock,
  openPrintDialog: openPrintDialog,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PowerPickingPullsheetPage)
