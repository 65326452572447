import React from 'react'
import * as PropTypes from 'prop-types'

import './style.scss'

import ModalDefault from 'components/common/Modal/ModalDefault/index'
import Button from 'components/common/Button'
import CancelIcon from 'assets/svg/cancel.svg'
import CancelIconIPod from 'assets/svg/cancel--ipod.svg'
import { connect } from 'react-redux'

const ModalYesOrNo = ({ isOpen, modalInfo }) => {
  if (!isOpen) {
    return <></>
  }

  let hideConfirmCancel = false
  if (modalInfo.hasOwnProperty('hideCancel')) {
    hideConfirmCancel = modalInfo.hideCancel
  }

  return (
    <ModalDefault isOpen={isOpen} onClose={modalInfo.onDeny}>
      <div
        className={`modal modal--confirm ${
          modalInfo.className ? modalInfo.className : ''
        }`}
      >
        {modalInfo.children}
        <Button onClick={modalInfo.onYes}>Yes, it's full</Button>
        <Button onClick={modalInfo.onNo}>No, it's not full</Button>
        {hideConfirmCancel ? (
          <></>
        ) : (
          <div onClick={modalInfo.onCancel} className="btn--close">
            {window.innerWidth < 768 ? <CancelIconIPod /> : <CancelIcon />}
          </div>
        )}
      </div>
    </ModalDefault>
  )
}

ModalYesOrNo.propTypes = {
  isOpen: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalYesOrNo',
  ),
  modalInfo: state.modal.openModalsInfo.find(
    (item) => item.name === 'modalYesOrNo',
  ),
})

export default connect(mapStateToProps)(ModalYesOrNo)
