import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import { Router } from 'react-router-dom'
import history from '../../../history'
import ErrorBoundary from 'components/common/ErrorBoundary'
import { connect } from 'react-redux'
import { useAuth } from 'hooks/useAuth'
import { useRouter } from 'hooks/useRouter'
import PageWrapper from 'components/common/PageWrapper'
import { fetchAppInfoStart } from 'store/appInfo/actions'
import { resetModals, showModal } from 'store/modal/actions'
import { PROJECT } from 'constants/common'
import { buildVersionText } from 'helpers/version'
import './style.scss'
import { GiFishbone } from 'react-icons/gi'
import { useCookies } from 'react-cookie'
import { WMS_PDF_KEY_VALUE, WMS_PDF_KEY_NAME } from 'constants/print'
import { SnackbarProvider } from 'notistack'

const App = (props) => {
  const { auth, appInfo, isStale, fetchAppInfo, showModal, resetModals } = props

  const [user, isAuthenticated] = useAuth(auth)
  const routing = useRouter(user, isAuthenticated)
  const [cookies, setCookie] = useCookies([WMS_PDF_KEY_NAME])

  //dummy statement to stop compile warning
  if (cookies) {
  }

  const onMount = () => {
    setCookie(WMS_PDF_KEY_NAME, WMS_PDF_KEY_VALUE, {
      path: '/',
    })
  }

  const getAppInfo = () => {
    fetchAppInfo(PROJECT)
    setTimeout(() => {
      getAppInfo()
    }, 10000)
  }

  const refreshApp = () => {
    window.location.reload()
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      getAppInfo()
    }, 500)
    return () => clearTimeout(timer)
  }, [])

  useEffect(onMount, [])

  if (appInfo && isStale) {
    const versionText = buildVersionText(appInfo)
    showModal({
      name: 'modalConfirm',
      className: 'modal--login-edit',
      onDeny: resetModals,
      onAccept: refreshApp,
      children: (
        <div className="modal--stale">
          <div className="modal--stale__exclamation">
            <GiFishbone />
          </div>
          <div className="modal--stale__text">
            <div className="modal--stale__title">Your app is out of date.</div>
            There is a newer version of this app.
            <div className="modal--stale__version">{versionText}</div>
            <br />
            Click 'Confirm' to refresh and upgrade.
          </div>
        </div>
      ),
    })
  }

  return (
    <Router history={history}>
      <ErrorBoundary>
        <PageWrapper>
          <SnackbarProvider maxSnack={3}>{routing}</SnackbarProvider>
        </PageWrapper>
      </ErrorBoundary>
    </Router>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  appInfo: state.appInfo.newItem,
  isStale: state.appInfo.isStale,
})

const mapDispatchToProps = {
  showModal,
  resetModals,

  fetchAppInfo: fetchAppInfoStart,
}

App.propTypes = {
  auth: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
