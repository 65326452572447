import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import Modal from 'react-modal'
import './style.scss'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.67)',
    zIndex: 1000,
  },
  content: {
    boxSizing: 'border-box',
    border: 'none',
    background: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '315px',
    marginRight: '-50%',
    overflow: 'hidden',
    transform: 'translate(-50%, -50%)',
    width: '550px',
  },
}

export default class ModalPending extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
  }

  render() {
    let { isOpen } = this.props

    if (!isOpen) {
      return <></>
    }

    return (
      <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false}>
        <div className="modal--pending">
          <div className="spinner">
            <div className="spinner__animate">
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
            </div>
            <div className="text">Loading...</div>
          </div>
        </div>
      </Modal>
    )
  }
}
