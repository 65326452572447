import React, { useEffect, useState } from 'react'
import './style.scss'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { closeModal, showModal } from 'store/modal/actions'
import { fetchOneWarehouseCaseSync } from 'store/warehouseCase/actions'
import { LOCATION_TYPE } from 'constants/locationTypes'
import DamageInventoryTool from 'components/features/InventoryTool/Inventory/Damage'
import ZoneInventoryTool from 'components/features/InventoryTool/Inventory/Zone'
import MultiplePalletInventoryTool from 'components/features/InventoryTool/Inventory/MultiplePallets'

const InventoryToolPage = (props) => {
  const {
    warehouseCase,
    match,

    closeModal,
    showModal,
    fetchOneWarehouseCase,
  } = props

  const warehouseCaseId = match.params.id

  const [isLoading, setIsLoading] = useState(true)

  const onMount = () => {
    const afterAction = () => closeModal('modalPending')
    const onSuccess = () => setIsLoading(false)

    showModal({
      name: 'modalPending',
    })

    setIsLoading(true)

    fetchOneWarehouseCase({
      request: warehouseCaseId,
      afterAction: afterAction,
      onSuccess: onSuccess,
    })
  }

  useEffect(onMount, [])

  if (isLoading) {
    return ''
  }

  const locationTypeId = warehouseCase.warehouseLocation.location_type.id

  const isDamageLocation = LOCATION_TYPE.DAMAGE_TYPE_ID === locationTypeId
  const isZoneLocation =
    [
      LOCATION_TYPE.ZONE_TYPE_ID,
      LOCATION_TYPE.OFFSITE_PICK_SELECT_TYPE_ID,
    ].indexOf(locationTypeId) > -1
  const isSinglePallet = warehouseCase.warehouseLocation.pallets_capacity === 1

  switch (true) {
    case isDamageLocation:
      return <DamageInventoryTool warehouseCase={warehouseCase} />
    case isZoneLocation:
      return <ZoneInventoryTool warehouseCase={warehouseCase} />
    case isSinglePallet:
      return <MultiplePalletInventoryTool warehouseCase={warehouseCase} />
    case !isSinglePallet:
      return <MultiplePalletInventoryTool warehouseCase={warehouseCase} />
    default:
      return <></>
  }
}

InventoryToolPage.propTypes = {
  warehouseCase: PropTypes.object,

  closeModal: PropTypes.func,
  showModal: PropTypes.func,
  fetchOneWarehouseCase: PropTypes.func,
}

const mapStateToProps = (state) => ({
  warehouseCase: state.warehouseCase.item,
})

const mapDispatchToProps = {
  closeModal: closeModal,
  showModal: showModal,
  fetchOneWarehouseCase: fetchOneWarehouseCaseSync,
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryToolPage)
