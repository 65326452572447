import { types } from './actions'

const INIT = {
  item: {},
  newItem: {},
  isLoading: false,
  isStale: false,
  isOffline: false,
  isSystemError: false,
  systemError: {},
  ignoreCount: 0,
}

const appInfoReducer = (state = INIT, action) => {
  const { type, payload } = action

  switch (type) {
    case types.FETCH_APP_INFO_START:
      return {
        ...state,
        isLoading: true,
      }
    case types.FETCH_APP_INFO_SUCCESS:
      const item = !state.item.build_id ? payload.data.item : state.item

      const isStale =
        state.item.build_id &&
        (state.isStale || state.item.build_id !== payload.data.item.build_id)

      return {
        ...state,
        item: item,
        newItem: payload.data.item,
        isLoading: false,
        isStale: isStale,
      }
    case types.FETCH_APP_INFO_FAIL:
      return INIT
    case types.SET_IS_OFFLINE:
      return { ...state, isOffline: payload }
    case types.SET_IGNORE_COUNT:
      return { ...state, ignoreCount: payload }
    case types.SET_SYSTEM_ERROR:
      return { ...state, isSystemError: true, systemError: payload }
    case types.CLEAR_SYSTEM_ERROR:
      return { ...state, isSystemError: false, systemError: {} }
    default:
      return state
  }
}

export default appInfoReducer
