import { call, put } from 'redux-saga/effects'
import {
  fetchOne,
  fetchMany,
  update,
  fetchPutawayTasks,
  beginUnloadPutawayProcess,
  startUnloadPutaway,
  startPutawayPalletRelocation,
  fetchUnloadPutaway,
  processUnloadPutaway,
  resetContainerProduct,
} from 'api/unloadProcess'
import {
  fetchOneUnloadProcessFail,
  fetchOneUnloadProcessSuccess,
  fetchPutawayTasksSuccess,
  fetchUnloadProcessListFail,
  fetchUnloadProcessListSuccess,
  // startUnloadPutawayTaskSuccess,
  fetchUnloadPutawayTaskSuccess,
  updateUnloadProcessSuccess,
  types,
} from './actions'
import { resetModals } from 'store/modal/actions'
import {
  // STATUS_COMPLETED,
  STATUS_IN_PROGRESS,
} from 'constants/processStatuses'
import { redirect } from 'helpers/index'
import {
  // PREPARE_UNLOAD_PROCESS_LIST_PAGE,
  UNLOAD_CONTAINER_PRODUCT_LIST_PAGE,
} from 'constants/routes'
import { takeLatest } from '@redux-saga/core/effects'

function* fetchUnloadProcessList(action) {
  const { payload } = action

  try {
    const response = yield call(fetchMany, payload)
    yield put(fetchUnloadProcessListSuccess(response.data.items))
  } catch (error) {
    yield put(fetchUnloadProcessListFail())
  }
}

function* fetchOneUnloadProcess(action) {
  const { payload } = action

  try {
    const response = yield call(fetchOne, payload)
    yield put(fetchOneUnloadProcessSuccess(response.data.data.item))
  } catch (error) {
    yield put(fetchOneUnloadProcessFail())
  }
}

function* updateUnloadProcess(action) {
  const { payload } = action

  try {
    const response = yield call(update, payload)
    yield put(resetModals())

    if (payload.data.status === STATUS_IN_PROGRESS) {
      yield put(updateUnloadProcessSuccess(response.data.meta.errors))
      redirect(UNLOAD_CONTAINER_PRODUCT_LIST_PAGE.replace(':id', payload.id))
    }
  } catch (error) {}
}

function* fetchPutawayTaskList(action) {
  const { payload } = action

  try {
    const response = yield call(fetchPutawayTasks, payload)

    yield put(fetchPutawayTasksSuccess(response.data.data.items))
  } catch (error) {}
}

function* beginUnloadPutaway(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    yield call(beginUnloadPutawayProcess, request)
    if (afterAction) {
      yield afterAction()
    }
  } catch (error) {}
}

function* startUnloadPutawayTask(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(startUnloadPutaway, request)

    if (afterAction) {
      yield afterAction(response.data.data)
    }
  } catch (error) {}
}

function* startPutawayPalletRelocationTask(action) {
  const { payload } = action

  try {
    yield call(startPutawayPalletRelocation, payload)
  } catch (error) {}
}

function* fetchUnloadPutawayTask(action) {
  const { payload } = action

  try {
    const response = yield call(fetchUnloadPutaway, payload)
    yield put(fetchUnloadPutawayTaskSuccess(response.data.data.item))
  } catch (error) {}
}

function* processUnloadPutawayTask(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(processUnloadPutaway, request)
    if (afterAction) {
      yield afterAction(response.data.data)
    }
  } catch (error) {}
}

function* processResetContainerProduct(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(resetContainerProduct, request)
    if (afterAction) {
      yield afterAction(response.data.data)
    }
  } catch (error) {}
}

export default function* unloadProcessSaga() {
  yield takeLatest(
    types.FETCH_UNLOAD_PROCESS_LIST_START,
    fetchUnloadProcessList,
  )
  yield takeLatest(types.FETCH_ONE_UNLOAD_PROCESS_START, fetchOneUnloadProcess)
  yield takeLatest(types.UPDATE_UNLOAD_PROCESS, updateUnloadProcess)
  yield takeLatest(types.FETCH_PUTAWAY_TASKS_START, fetchPutawayTaskList)
  yield takeLatest(types.BEGIN_UNLOAD_PUTAWAY_PROCESS, beginUnloadPutaway)
  yield takeLatest(types.START_UNLOAD_PUTAWAY_TASK, startUnloadPutawayTask)
  yield takeLatest(
    types.START_PUTAWAY_PALLET_RELOCATION_TASK,
    startPutawayPalletRelocationTask,
  )
  yield takeLatest(types.FETCH_UNLOAD_PUTAWAY_TASK, fetchUnloadPutawayTask)
  yield takeLatest(types.PROCESS_UNLOAD_PUTAWAY, processUnloadPutawayTask)
  yield takeLatest(types.RESET_CONTAINER_PRODUCT, processResetContainerProduct)
}
