import request, { requestWithRetry } from 'helpers'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}product`

export const fetchProductInfoBy = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}/product-info`,
    method: 'GET',
    params: payload,
  })

export const createProductCase = (payload) =>
  request({
    url: `${apiEndpoint}/product-cases`,
    method: 'POST',
    data: payload,
  })

export const updateProductCase = (payload) =>
  request({
    url: `${apiEndpoint}/product-cases/${payload.id}`,
    method: 'PUT',
    data: payload,
  })

export const fetchOneByProductCase = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}/product-cases/getOneBy${payload}`,
    method: 'GET',
    data: payload,
  })

export const createContainerProduct = (payload) =>
  request({
    url: `${apiEndpoint}/container-products`,
    method: 'POST',
    data: payload,
  })

export const updateContainerProduct = (payload) =>
  request({
    url: `${apiEndpoint}/container-products/${payload.data.id}`,
    method: 'PUT',
    data: payload,
  })

export const addLoadedCases = (payload) =>
  request({
    url: `${apiEndpoint}/product-cases/add-loaded`,
    method: 'POST',
    data: payload,
  })
