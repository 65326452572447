import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import './style.scss'
import LoginForm from 'components/features/Auth/LoginPage/LoginForm'
import { resetModals, showModal } from 'store/modal/actions'
import { isEmptyObject, redirect } from 'helpers/index'
import { resetUser } from 'store/auth/actions'
import { HOME_PAGE } from 'constants/routes'

const LoginPage = ({
  user,
  auth,
  showModal,
  resetModals,
  resetUser,
  modalSuccessIsOpen,
}) => {
  useEffect(() => {
    if (modalSuccessIsOpen) {
      setTimeout(() => {
        resetModals()
        resetUser()
      }, 2000)
    }
  }, [user.staff_id, modalSuccessIsOpen, resetModals, resetUser])

  const onMount = () => {
    if (auth.isAuthorized && !isEmptyObject(user)) {
      return redirect(HOME_PAGE)
    }

    resetUser()
  }

  useEffect(onMount, [])

  return (
    <div className="page--auth page">
      <LoginForm />
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  auth: state.auth,
  modals: state.modal,
  modalSuccessIsOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalSuccessReset',
  ),
})

const mapDispatchToProps = {
  showModal,
  resetModals,
  resetUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
