import React from 'react'
import PropTypes from 'prop-types'
import PalletLoadingProgressItem from 'components/common/PalletLocationsGroup/LocationPalletsItem/PalletLoadingProgressItem'
import Button from 'components/common/Button'
import { DAMAGED_LOCATION_TYPE_ID } from 'constants/common'

const LocationPalletsItem = ({
  pallets,
  locationType,
  onAddItem,
  onPalletSelect,
}) => {
  const { label, color } = locationType

  let totalPallets = 0
  let totalLoaded = 0

  return (
    <div className="pallet-cell">
      <div className="pallet-cell__list">
        {
          <>
            {onAddItem ? (
              <Button onClick={onAddItem} className="pallet-cell__add" />
            ) : (
              ''
            )}
            {pallets.length !== 0
              ? pallets.map((item) => {
                  totalPallets += item.cases_per_pallet
                    ? item.cases_per_pallet
                    : 0
                  totalLoaded += item.cases_loaded ? item.cases_loaded : 0

                  return locationType.location_type_id !==
                    DAMAGED_LOCATION_TYPE_ID && locationType.key !== 'extra' ? (
                    <PalletLoadingProgressItem
                      key={item.id}
                      warehousePalletId={item.id}
                      total={item.cases_per_pallet}
                      loaded={item.cases_loaded}
                      missing={item.cases_missing}
                      aisle_bay_level={item.warehouse_location.aisle_bay_level}
                      is_partial={item.is_partial}
                      // onSelect={onPalletSelect}
                    />
                  ) : (
                    <PalletLoadingProgressItem
                      key={item.id}
                      total={item.cases_loaded}
                    />
                  )
                })
              : ''}
          </>
        }
      </div>
      <div className="pallet-cell__info">
        {color && (
          <div
            style={{ backgroundColor: color }}
            className="pallet-cell__icon"
          />
        )}
        <span className="text">
          {label} {`(${totalLoaded}${totalPallets ? '/' + totalPallets : ''})`}
        </span>
      </div>
    </div>
  )
}

LocationPalletsItem.propTypes = {
  pallets: PropTypes.array.isRequired,

  locationType: PropTypes.object.isRequired,

  onAddItem: PropTypes.func,
}

export default LocationPalletsItem
