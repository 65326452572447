import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import {
  fetchOneUnloadProcessStart,
  updateUnloadProcess,
  dismissStartUnloadErrors,
  resetContainerProduct,
} from 'store/unloadProcess/actions'
import { closeModal, resetModals, showModal } from 'store/modal/actions'
import {
  fetchProductInfoStart,
  createProductCase,
  createContainerProduct,
  updateContainerProduct,
  addLoadedCases,
  updateProductCase,
} from 'store/product/actions'
import TableList from 'components/common/TableList'
import Header from 'components/common/Header'
import AvatarList from 'components/features/StaffCard/AvatarList'
import { isEmptyObject, redirect } from 'helpers'
import PalletLocationsGroup from 'components/common/PalletLocationsGroup'
import { PREPARE_UNLOAD_PROCESS_LIST_PAGE } from 'constants/routes'
import CardList from 'components/features/StaffCard/CardList'
import './style.scss'
import Button from 'components/common/Button'
import { STATUS_COMPLETED } from 'constants/processStatuses'
import ArrowRoundedIcon from 'assets/svg/arrow-rounded.svg'
import { clearMeasurementItems } from 'store/measurement/actions'
import {
  recountContainerPallets,
  markPalletComplete,
} from 'store/containerPallets/actions'
import ExclamationSmall from 'assets/svg/exclamation-sign.svg'
import BarcodeReader from 'react-barcode-reader'
import { BiBarcode } from 'react-icons/bi'
import { FaRegFileAlt, FaExclamationTriangle } from 'react-icons/fa'
import { MdModeEdit } from 'react-icons/md'
import { MODAL_CASE_QTY } from 'components/common/Modal'
import { openPrintDialog } from 'store/print/actions'
import Print from 'components/common/Print'
import { CONTAINER_PALLET_LABELS, CASE_UPC } from 'constants/print'
import Alert from '@material-ui/lab/Alert'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { LOCATION_TYPE } from 'constants/locationTypes'

const columns = [
  {
    id: 1,
    label: 'Case',
    value: 'invoiceCasesQuantity',
  },
  {
    id: 1,
    label: '1.3 Hazard Class',
    value: 'hazardClassIcon',
  },
  {
    id: 2,
    label: 'Model',
    value: 'model',
  },
  {
    id: 3,
    label: 'Name',
    value: 'name',
  },
  {
    id: 4,
    label: 'Pallet',
    value: 'invoicePalletsQuantity',
  },
  {
    id: 5,
    label: 'Print UPC',
    value: 'actionButton',
  },
  {
    id: 6,
    label: 'Print Pallet Labels',
    value: 'printPalletLabelsButton',
  },
  {
    id: 7,
    label: '',
    value: 'resetContainerProductButton',
  },
]

const locationTypesForProductInfo = [
  {
    id: 1,
    key: 'crossDock',
    label: 'CrossDock',
    location_type_id: LOCATION_TYPE.CROSSDOCK_TYPE_ID,
    color: '#F4D383',
  },
  {
    id: 2,
    key: 'storage',
    label: 'Storage',
    location_type_id: LOCATION_TYPE.STORAGE_TYPE_ID,
    color: '#B18CE7',
  },
  {
    id: 3,
    key: 'select',
    label: 'Pick Select',
    location_type_id: LOCATION_TYPE.PICK_SELECT_TYPE_ID,
    color: '#86AEDC',
  },
  // {
  //   id: 4,
  //   key: 'small_parcel',
  //   label: 'Small Parcel',
  //   location_type_id: 11,
  //   color: '#FF8701',
  // },
  {
    id: 5,
    key: 'zone',
    label: 'Zone Pick',
    location_type_id: LOCATION_TYPE.ZONE_TYPE_ID,
    color: '#FF81BC',
  },
  {
    id: 6,
    key: 'shipping',
    label: 'Shipping',
    location_type_id: LOCATION_TYPE.SHIPPING_TYPE_ID,
    color: '#D0021B',
  },
  {
    id: 8,
    key: 'damage',
    label: 'Damage',
    location_type_id: LOCATION_TYPE.DAMAGE_TYPE_ID,
    color: '#D0021B',
  },
  {
    id: 9,
    key: 'dock',
    label: 'Dock',
    location_type_id: LOCATION_TYPE.DOCK_TYPE_ID,
    color: '#D0021B',
  },
  {
    id: 10,
    key: 'quarantine',
    label: 'Quarantine',
    location_type_id: LOCATION_TYPE.QUARANTINE_TYPE_ID,
    color: '#D0021B',
  },
  {
    id: 11,
    key: 'small_parcel_pick_select',
    label: 'Small Parcel Pick Select',
    location_type_id: LOCATION_TYPE.SMALL_PARCEL_PICK_SELECT_TYPE_ID,
    color: '#D0021B',
  },
  {
    id: 12,
    key: 'oversized_pick',
    label: 'Oversized Pick',
    location_type_id: LOCATION_TYPE.OVERSIZED_PICK_TYPE_ID,
    color: '#D0021B',
  },
  {
    id: 13,
    key: 'oversized_storage',
    label: 'Oversized Storage',
    location_type_id: LOCATION_TYPE.OVERSIZED_STORAGE_TYPE_ID,
    color: '#D0021B',
  },
  {
    id: 14,
    key: 'offsite_pick_select',
    label: 'Offsite Pick Select',
    location_type_id: LOCATION_TYPE.OFFSITE_PICK_SELECT_TYPE_ID,
    color: '#D0021B',
  },
  {
    id: 15,
    key: 'pick_select_extra',
    label: 'Pick Select Extra',
    location_type_id: LOCATION_TYPE.PICK_EXTRA_TYPE_ID,
    color: '#D0021B',
  },
  {
    id: 16,
    key: 'pickup_lane',
    label: 'Pickup Lane',
    location_type_id: LOCATION_TYPE.PICKUP_LANE_ID,
    color: '#D0021B',
  },
  {
    id: 17,
    key: 'small_parcel_piece',
    label: 'Small Parcel Piece',
    location_type_id: LOCATION_TYPE.SMALL_PARCEL_PIECE_ID,
    color: '#D0021B',
  },
  {
    id: 18,
    key: 'returns',
    label: 'Returns',
    location_type_id: LOCATION_TYPE.RETURNS_TYPE_ID,
    color: '#D0021B',
  },
  {
    id: 19,
    key: 'inventory_hold',
    label: 'Inventory Hold',
    location_type_id: LOCATION_TYPE.INVENTORY_HOLD_TYPE_ID,
    color: '#fcc842',
  },
  {
    id: 22,
    key: 'storage_4_deep',
    label: 'Storage 4 Deep',
    location_type_id: LOCATION_TYPE.STORAGE_4_DEEP_TYPE_ID,
    color: '#3a0569',
  },
  {
    id: 23,
    key: 'bulk_storage',
    label: 'Bulk Storage',
    location_type_id: LOCATION_TYPE.BULK_STORAGE_TYPE_ID,
    color: '#7F00FF',
  },
  {
    id: 24,
    key: 'inventory_hold_bulk_storage',
    label: 'Inventory Hold Bulk Storage',
    location_type_id: LOCATION_TYPE.INVENTORY_HOLD_BULK_STORAGE_TYPE_ID,
    color: '#fcc842',
  },
]

const locationTypesForCaseReceived = [
  {
    id: 1,
    key: 'crossDock',
    label: 'CrossDock',
    location_type_id: LOCATION_TYPE.CROSSDOCK_TYPE_ID,
    color: '#F4D383',
  },
  {
    id: 2,
    key: 'storage',
    label: 'Storage',
    location_type_id: LOCATION_TYPE.STORAGE_TYPE_ID,
    color: '#B18CE7',
  },
  {
    id: 3,
    key: 'select',
    label: 'Pick Select',
    location_type_id: LOCATION_TYPE.PICK_SELECT_TYPE_ID,
    color: '#86AEDC',
  },
  // {
  //   id: 4,
  //   key: 'small_parcel',
  //   label: 'Small Parcel',
  //   location_type_id: 11,
  //   color: '#FF8701',
  // },
  {
    id: 5,
    key: 'zone',
    label: 'Zone Pick',
    location_type_id: LOCATION_TYPE.ZONE_TYPE_ID,
    color: '#FF81BC',
  },
  {
    id: 6,
    key: 'shipping',
    label: 'Shipping',
    location_type_id: LOCATION_TYPE.SHIPPING_TYPE_ID,
    color: '#D0021B',
  },
  {
    id: 9,
    key: 'dock',
    label: 'Dock',
    location_type_id: LOCATION_TYPE.DOCK_TYPE_ID,
    color: '#D0021B',
  },
  {
    id: 10,
    key: 'quarantine',
    label: 'Quarantine',
    location_type_id: LOCATION_TYPE.QUARANTINE_TYPE_ID,
    color: '#D0021B',
  },
  {
    id: 11,
    key: 'small_parcel_pick_select',
    label: 'Small Parcel Pick Select',
    location_type_id: LOCATION_TYPE.SMALL_PARCEL_PICK_SELECT_TYPE_ID,
    color: '#D0021B',
  },
  {
    id: 12,
    key: 'oversized_pick',
    label: 'Oversized Pick',
    location_type_id: LOCATION_TYPE.OVERSIZED_PICK_TYPE_ID,
    color: '#D0021B',
  },
  {
    id: 13,
    key: 'oversized_storage',
    label: 'Oversized Storage',
    location_type_id: LOCATION_TYPE.OVERSIZED_STORAGE_TYPE_ID,
    color: '#D0021B',
  },
  {
    id: 14,
    key: 'offsite_pick_select',
    label: 'Offsite Pick Select',
    location_type_id: LOCATION_TYPE.OFFSITE_PICK_SELECT_TYPE_ID,
    color: '#D0021B',
  },
  {
    id: 15,
    key: 'pick_select_extra',
    label: 'Pick Select Extra',
    location_type_id: LOCATION_TYPE.PICK_EXTRA_TYPE_ID,
    color: '#D0021B',
  },
  {
    id: 16,
    key: 'pickup_lane',
    label: 'Pickup Lane',
    location_type_id: LOCATION_TYPE.PICKUP_LANE_ID,
    color: '#D0021B',
  },
  {
    id: 17,
    key: 'small_parcel_piece',
    label: 'Small Parcel Piece',
    location_type_id: LOCATION_TYPE.SMALL_PARCEL_PIECE_ID,
    color: '#D0021B',
  },
  {
    id: 18,
    key: 'returns',
    label: 'Returns',
    location_type_id: LOCATION_TYPE.RETURNS_TYPE_ID,
    color: '#D0021B',
  },
  {
    id: 19,
    key: 'inventory_hold',
    label: 'Inventory Hold',
    location_type_id: LOCATION_TYPE.INVENTORY_HOLD_TYPE_ID,
    color: '#fcc842',
  },
  {
    id: 22,
    key: 'storage_4_deep',
    label: 'Storage 4 Deep',
    location_type_id: LOCATION_TYPE.STORAGE_4_DEEP_TYPE_ID,
    color: '#3a0569',
  },
  {
    id: 23,
    key: 'bulk_storage',
    label: 'Bulk Storage',
    location_type_id: LOCATION_TYPE.BULK_STORAGE_TYPE_ID,
    color: '#7F00FF',
  },
  {
    id: 24,
    key: 'inventory_hold_bulk_storage',
    label: 'Inventory Hold Bulk Storage',
    location_type_id: LOCATION_TYPE.INVENTORY_HOLD_BULK_STORAGE_TYPE_ID,
    color: '#fcc842',
  },
]

const UnloadContainerProductListPage = (props) => {
  const {
    match,

    item = {},
    containerProduct = {},
    startUnloadErrors,
    productInfo,

    resetModals,
    showModal,
    closeModal,

    updateProductCase,
    createContainerProduct,
    updateContainerProduct,
    addLoadedCases,

    fetchItem,
    fetchProductInfo,

    clearMeasurementItems,
    recountContainerPallets,
    openPrintDialog,
    markPalletComplete,
    dismissStartUnloadErrors,
    resetContainerProduct,
  } = props

  const [scannedItem, setScannedItem] = useState({})
  const [isMissing] = useState(false)
  const [measurementsAvg, setMeasurementsAvg] = useState(true)
  const [barcode, setBarcode] = useState('')

  const onChangeContainerProduct = () => {
    setScannedItem(containerProduct)
  }

  const onMount = () => {
    showModal({ name: 'modalPending' })
    fetchItem({ id: match.params.id })
  }

  let timeoutId
  const reloadFetchItem = () => {
    timeoutId = setTimeout(() => {
      fetchItem({ id: match.params.id })
      reloadFetchItem()
    }, 20000)
  }

  useEffect(() => {
    reloadFetchItem()
    return () => clearTimeout(timeoutId)
  }, [])

  const onModalClose = () => {
    setBarcode('')
    setScannedItem({})
    resetModals()
  }

  const showModalCaseReceivedWithData = () => {
    if (!isEmptyObject(scannedItem)) {
      showModal({
        name: 'modalCaseReceived',
        alreadyReceivedQty: scannedItem.received,
        locationTypes: locationTypesForCaseReceived,
        product: scannedItem,
        processId: item.id,
        onAccept: onAddReceivedCases,
        onClose: onModalClose,
      })
    }
  }

  const showModalMeasurementWithData = (scannedItem) => {
    if (!scannedItem || isEmptyObject(scannedItem)) {
      return
    }

    resetModals()

    showModal({
      name: 'modalMeasurementPopup',
      scannedItem: scannedItem,
      onClose: onModalClose,
      onConfirm: receiveMeasurements,
      fontSize: 22,
      onConfirmPalletAdjustment: onConfirmPalletAdjustment,
      showModalCaseReceivedWithData: showModalCaseReceivedWithData,
    })
  }

  const onBarcodeChange = () => {
    if (!barcode) {
      return
    }

    const onFetchAfterAction = (success) => {
      if (success) {
        showModal({
          name: 'modalWrongOrAdditionalProduct',
          productInfo: productInfo,
          onAccept: onAddNewProductToContainer,
          onClose: resetModals,
        })
      } else {
        showModal({
          name: 'modalWarning',
          text: 'Invalid Case UPC',
          onAccept: resetModals,
          onDeny: resetModals,
          className: 'modal--tasks modal--pause',
          modalIcon: <ExclamationSmall />,
        })
      }
    }

    const checkForMatch = () => {
      let objectReturn
      item.container.container_products.forEach((containerProduct) => {
        for (const [key, value] of Object.entries(
          containerProduct.product.p_products_upcs_case,
        )) {
          if (value.products_upc_case.toString() === barcode.toString()) {
            objectReturn = containerProduct
          }
        }
      })
      return objectReturn
    }
    const barcodeItem = checkForMatch()

    if (barcodeItem) {
      setScannedItem(barcodeItem)
    } else {
      fetchProductInfo({
        request: { products_upc_case: barcode },
        afterAction: onFetchAfterAction,
      })
    }

    setBarcode('')
  }

  const showModalAlreadyScanned = () => {
    showModal({
      name: 'modalWarning',
      text:
        'This product has been processed and has pallet labels ready to print.',
      onAccept: resetModals,
      className: 'modal--tasks modal--pause',
      modalIcon: <ExclamationSmall />,
    })
  }

  const onChangeScannedItemMeasurement = () => {
    if (!isEmptyObject(scannedItem)) {
      if (scannedItem.is_fully_loaded) {
        showModalAlreadyScanned()
      } else if (scannedItem.isMeasured) {
        showModalCaseReceivedWithData(scannedItem)
      } else {
        showModalMeasurementWithData(scannedItem)
      }
    }
  }

  const onChangeProcess = () => {
    if (!isEmptyObject(item)) {
      closeModal('modalPending')
    }

    if (item.status === STATUS_COMPLETED) {
      redirect(PREPARE_UNLOAD_PROCESS_LIST_PAGE)
    }
  }

  useEffect(onMount, [])
  useEffect(onChangeContainerProduct, [containerProduct])
  useEffect(onChangeProcess, [item])
  useEffect(onBarcodeChange, [barcode, item.container])
  useEffect(onChangeScannedItemMeasurement, [scannedItem])

  if (isEmptyObject(item)) {
    return <></>
  }

  const HazardClassButton = () => (
    <>
      <FaExclamationTriangle
        className="hazard-class"
        onClick={() => {
          onHazardClassClick()
        }}
        style={{ color: 'red', fontSize: '1.5em' }}
      />
    </>
  )

  const onHazardClassClick = () => {
    showModal({
      name: 'modalWarning',
      text: 'This product has been marked as 1.3 Hazard Class and needs a 1.4 sticker.',
      onAccept: resetModals,
      className: 'modal--tasks modal--pause',
      modalIcon: <ExclamationSmall />,
    })
  }

  //TODO refactor the product upc label
  const PrintCaseUpcButton = (id) => (
    <>
      <BiBarcode
        className="print-upc"
        onClick={() => {
          onPrintUpcClick(id)
        }}
      />
    </>
  )

  const onPrintUpcClick = (id) => {
    showModal({
      name: MODAL_CASE_QTY,
      onDeny: () => {
        closeModal(MODAL_CASE_QTY)
      },
      onConfirm: (qty) => {
        closeModal(MODAL_CASE_QTY)
        onConfirmPrintUpc(id, qty)
      },
      value: 5,
      maxValue: 100,
    })
  }

  const EditCaseQtyButton = (
    productItemId,
    productItemQty,
    productItemReceived,
    productItemExpectedQty,
  ) => (
    <>
      <MdModeEdit
        className="edit-case-quantity-pencil"
        onClick={() => {
          onClickEditCaseQty(productItemId, productItemQty, productItemReceived, productItemExpectedQty)
        }}
      />
    </>
  )

  const onClickEditCaseQty = (
    productItemId,
    productItemQty,
    productItemReceived,
    productItemExpectedQty
  ) => {
    showModal({
      name: MODAL_CASE_QTY,
      onDeny: () => {
        closeModal(MODAL_CASE_QTY)
      },
      onConfirm: (modalQty) => {
        closeModal(MODAL_CASE_QTY)
        onConfirmEditCaseQty(
          productItemQty,
          modalQty,
          productItemId,
          productItemReceived,
          productItemExpectedQty
        )
      },
      value: productItemQty,
      maxValue: 99999,
    })
  }

  const onConfirmEditCaseQty = (
    productItemQty,
    modalQty,
    productItemId,
    productItemReceived,
    productItemExpectedQty
  ) => {
    if (productItemQty !== modalQty) {
      showModal({ name: 'modalPending' })
      updateContainerProduct({
        containerQty: {
          data: {
            id: productItemId,
            quantity: modalQty,
            missing: (productItemExpectedQty - modalQty) < 0 ? 0 : (productItemExpectedQty - modalQty),
          },
        },
        request: {
          data: {
            container_product_id: productItemId,
            difference_quantity: modalQty - productItemQty,
          },
        },
        afterAction: () => {
          fetchItem({ id: match.params.id })
        },
      })
      closeModal({ name: 'modalPending' })
    }
  }

  const PrintPalletLabelsButton = (id) => (
    <>
      <FaRegFileAlt
        className="print-pallet-label"
        onClick={() => {
          onClickPrintPalletLabels(id)
        }}
      />
    </>
  )

  const onClickPrintPalletLabels = (id) => {
    openPrintDialog({
      id: id,
      document: CONTAINER_PALLET_LABELS,
    })
  }

  const onConfirmPrintUpc = (id, qty) => {
    openPrintDialog({
      id: id,
      qty: qty,
      document: CASE_UPC,
    })
  }

  const onResetAfterAction = (data) => {
    if (data.success) {
      fetchItem({ id: match.params.id })
    } else {
      showModal({
        name: 'modalWarning',
        text: data.errors.toString(),
        onAccept: resetModals,
        className: 'modal--tasks modal--pause',
        modalIcon: <ExclamationSmall />,
      })
    }
  }

  const onResetContainerProduct = (id) => {
    showModal({
      name: 'modalConfirm',
      className: 'modal--login-edit',
      onDeny: resetModals,
      onAccept: () => {
        closeModal('modalConfirm')
        resetContainerProduct({
          request: id,
          afterAction: onResetAfterAction,
        })
      },
      children: <div className="title"> Reset Container Product? </div>,
    })
  }

  const getNormalizedItems = (items, warehouseId) =>
    items.map((productItem) => ({
      id: productItem.id,
      invoiceCasesQuantity: (
        <>
          {productItem.received}/{productItem.quantity}
          {productItem.is_fully_loaded || isMissing
            ? ''
            : EditCaseQtyButton(
                productItem.id,
                productItem.quantity,
                productItem.received,
                productItem.expected_qty,
              )}
        </>
      ),
      model: productItem.product.products_model,
      name: productItem.description.products_name,
      invoicePalletsQuantity: `${productItem.container_pallets.length} (${productItem.product_case.cases_per_layer}X${productItem.product_case.layers_per_pallet}) `,
      actionButton: PrintCaseUpcButton(productItem.product_id),
      hazardClassIcon: productItem.product.hazard_class_1_3
        ? HazardClassButton()
        : '',
      printPalletLabelsButton:
        productItem.quantity !== 0
          ? productItem.is_fully_loaded || isMissing
            ? PrintPalletLabelsButton(productItem.id)
            : ''
          : '',
      resetContainerProductButton:
        productItem.pallets_put_away === 0 ? (
          <Button
            onClick={() => {
              onResetContainerProduct(productItem.id)
            }}
          >
            Reset
          </Button>
        ) : (
          <span style={{ fontStyle: 'italic' }}>
            Putaway: {productItem.pallets_put_away} /{' '}
            {productItem.container_pallets.length}
          </span>
        ),
      expandedContent: (
        <PalletLocationsGroup
          processId={item.id}
          product={productItem}
          locationTypes={locationTypesForProductInfo}
          key={item.id}
          warehouseId={warehouseId}
          onPalletSelect={onPalletSelect}
        />
      ),
      highlightClassName:
        productItem.received !== 0
          ? productItem.received + productItem.missing === productItem.quantity
            ? 'green'
            : 'orange'
          : 'black',
      backgroundClassName:
        productItem.cases_not_on_pallets !== 0 ? 'error' : '',
      options: {
        animated: false,
        expandable: true,
        swipeable: true,
        highlightable: true,
      },
    }))

  const onStopUnloading = () => {
    resetModals()
    dismissStartUnloadErrors()
    redirect(PREPARE_UNLOAD_PROCESS_LIST_PAGE)
  }

  const onAddNewProductToContainer = (productId, addNewCaseQuantity) => {
    resetModals()

    createContainerProduct({
      processId: item.id,
      data: {
        container_id: item.container.id,
        product_id: productId,
        quantity: parseInt(addNewCaseQuantity),
        received: 0,
        damaged: 0,
      },
    })
  }

  const onAddCasesAfterAction = (data) => {
    if (!data.success) {
      showModal({
        name: 'modalWarning',
        text: data.error.toString(),
        onAccept: resetModals,
        className: 'modal--tasks modal--pause',
        modalIcon: <ExclamationSmall />,
      })
    }
  }

  const onConfirmAddLoadedCases = (quantity) => {
    setTimeout(() => {
      showModal({ name: 'modalPending' })
    }, 100)

    addLoadedCases({
      id: scannedItem.id,
      processId: item.id,
      data: {
        id: scannedItem.id,
        processId: item.id,
        received: quantity,
      },
      afterAction: onAddCasesAfterAction,
    })

    setScannedItem({})
  }

  const onAddReceivedCases = (quantity) => {
    if (quantity > scannedItem.quantity - scannedItem.received) {
      const onAccept = () => onConfirmAddLoadedCases(quantity)
      const onDeny = () => closeModal('modalWarning')

      showModal({
        name: 'modalWarning',
        onAccept: onAccept,
        onDeny: onDeny,
        text: (
          <div className="text">
            Cases entered exceeds expected case quantity
          </div>
        ),
        className: 'modal--tasks',
        modalIcon: <ExclamationSmall />,
      })
    } else {
      onConfirmAddLoadedCases(quantity)
    }

    onModalClose()
  }

  const onAcceptModalWarningForClose = () => {
    onStopUnloading()
    resetModals()
  }

  const onConfirmPalletAdjustment = (params) => {
    const oldProductCase = scannedItem.product_case
    resetModals()

    updateProductCase({
      id: scannedItem.product_case.id,
      data: {
        id: scannedItem.product_case.id,
        ...params,
        ...measurementsAvg,
      },
    })
    const difference_quantity =
      params.cases_per_layer * params.layers_per_pallet -
      oldProductCase.cases_per_layer * oldProductCase.layers_per_pallet

    if (difference_quantity === 0 && !params.forceRecount) {
      showModalCaseReceivedWithData()
    } else {
      setTimeout(() => {
        showModal({ name: 'modalPending' })
      }, 200)
      recountContainerPallets({
        request: {
          data: {
            container_product_id: scannedItem.id,
            difference_quantity: difference_quantity,
          },
        },
        afterAction: () => {
          fetchItem({ id: match.params.id })
        },
      })
    }

    clearMeasurementItems()
  }

  const onPalletSelect = (warehousePalletId) => {
    showModal({
      name: 'modalConfirm',
      className: 'modal--login-edit',
      onDeny: resetModals,
      onAccept: () => {
        closeModal('modalConfirm')
        showModal({ name: 'modalPending' })

        markPalletComplete({
          request: warehousePalletId,
          afterAction: () => {
            fetchItem({ id: match.params.id })
          },
        })
      },
      children: (
        <div className="title">
          {' '}
          Set missing cases and allow pallet to be putaway?{' '}
        </div>
      ),
    })
  }

  const receiveMeasurements = (items) => {
    let measurementsAvg = {
      case_width: 0,
      case_length: 0,
      case_height: 0,
      case_weight: 0,
    }

    items.forEach((item) => {
      measurementsAvg.case_width += item.case_width / items.length
      measurementsAvg.case_length += item.case_length / items.length
      measurementsAvg.case_height += item.case_height / items.length
      measurementsAvg.case_weight += item.case_weight / items.length
    })

    setMeasurementsAvg(measurementsAvg)
  }

  const getQuantitySum = (type) => {
    switch (type) {
      case 'received-without-addition':
        return item.container.container_products
          .map((item) =>
            item.received > item.quantity ? item.quantity : item.received,
          )
          .reduce((sum, item) => sum + item)
      case 'quantity':
      case 'received':
      default:
        return item.container.container_products
          .map((item) => item[type])
          .reduce((sum, item) => sum + item)
    }
  }

  const realRestProducts =
    getQuantitySum('quantity') - getQuantitySum('received-without-addition')

  let qtyMissing = 0
  if (item) {
    item.container.container_products.forEach((containerProduct) => {
      qtyMissing += containerProduct.missing
    })
  }

  const restProducts =
    realRestProducts - qtyMissing < 0 ? 0 : realRestProducts - qtyMissing

  const isProcessCompleted =
    isMissing ||
    getQuantitySum('quantity') <=
      getQuantitySum('received') + getQuantitySum('missing')

  const onClickAvatarList = () => {
    showModal({
      name: 'modalWorkers',
      children: <CardList items={item.team} />,
      onDeny: resetModals,
    })
  }

  const StartUnloadErrors = () =>
    startUnloadErrors.map((error) => (
      <Alert className="error-item" severity="error">
        {error}
      </Alert>
    ))

  const DismissUnloadErrors = () => (
    <Button
      onClick={() => {
        dismissStartUnloadErrors()
      }}
    >
      Dismiss Errors
    </Button>
  )

  const StartUnloadErrorsCard = () =>
    startUnloadErrors.length > 0 ? (
      <Card className="errors-card">
        <CardContent>
          <StartUnloadErrors />
        </CardContent>
        <CardActions>
          <DismissUnloadErrors />
        </CardActions>
      </Card>
    ) : (
      ''
    )

  return (
    <div className="page page--container-list">
      <Header>
        <AvatarList items={item.team} onClick={onClickAvatarList} />
        <div className="title">{item.container.name}</div>
        <div className="text" onClick={onAcceptModalWarningForClose}>
          Close
        </div>
      </Header>

      <BarcodeReader
        onScan={(receivedBarcode) => {
          setBarcode(receivedBarcode)
        }}
        avgTimeByChar={60}
      />

      <div className="content">
        <StartUnloadErrorsCard />
        <div className="content__header">
          <p className="text">
            <span className="text--bold">
              {getQuantitySum('received')}/{getQuantitySum('quantity')}
            </span>{' '}
            Received
          </p>
          <p className="text">
            <span className="text--bold">{restProducts}</span> To Be Unloaded
          </p>
          <p className="text">
            <span className="text--bold">{qtyMissing}</span> Missing
          </p>
        </div>
        <TableList
          columns={columns}
          rows={getNormalizedItems(
            item.container.container_products,
            item.container.warehouse_id,
          )}
          events={{
            onSwipe: (itemId) =>
              setBarcode(
                item.container.container_products.find(
                  (item) => item.id === itemId,
                ).product.products_upc_case,
              ),
          }} // I -Clayton, July 2021- believe this onSwipe code is not working and needs to be refacotred. Also, if trying to do the setBarcode as just above, the API data has changed; look in the onBarcodeChange function above for an example.
          rowBackgroundElement={
            <span className="swipe__text">
              Unload
              <ArrowRoundedIcon className="svg" />
            </span>
          }
        />
      </div>
      <Print />
    </div>
  )
}

UnloadContainerProductListPage.propTypes = {
  item: PropTypes.object.isRequired,

  fetchItem: PropTypes.func,
}

const mapStateToProps = (state) => ({
  item: state.unloadProcess.item,
  productInfo: state.productInfo.item,
  containerProduct: state.containerProduct.item,
  measurementItems: state.measurement.items,
  startUnloadErrors: state.unloadProcess.startUnloadErrors,
})

const mapDispatchToProps = {
  fetchItem: fetchOneUnloadProcessStart,
  fetchProductInfo: fetchProductInfoStart,

  createProductCase: createProductCase,
  updateProductCase: updateProductCase,
  createContainerProduct: createContainerProduct,
  updateContainerProduct: updateContainerProduct,
  addLoadedCases: addLoadedCases,
  updateProcess: updateUnloadProcess,

  resetModals: resetModals,
  showModal: showModal,
  closeModal: closeModal,

  clearMeasurementItems: clearMeasurementItems,

  recountContainerPallets: recountContainerPallets,
  openPrintDialog: openPrintDialog,
  markPalletComplete: markPalletComplete,
  dismissStartUnloadErrors: dismissStartUnloadErrors,
  resetContainerProduct: resetContainerProduct,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnloadContainerProductListPage)
