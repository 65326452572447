import { types } from 'store/homeAnalytics/actions'

const INIT = {
  restockCount: 0,
  bulkRestockFromCount: 0,
  bulkRestockToCount: 0,
  containerUnloadCount: 0,
  myiPodTaskCount: 0,
  powerPickingCount: 0,
  putawayCount: 0,
  loadingCount: 0,
  inventoryCount: 0,
}

export default function homeAnalyticsReducer(state = INIT, action) {
  const { type, payload } = action

  switch (type) {
    case types.FETCH_RESTOCKING_COUNT_SUCCESS:
      return {
        ...state,
        restockCount: payload,
      }
    case types.FETCH_BULK_RESTOCK_FROM_COUNT_SUCCESS:
      return {
        ...state,
        bulkRestockFromCount: payload,
      }
    case types.FETCH_BULK_RESTOCK_TO_COUNT_SUCCESS:
      return {
        ...state,
        bulkRestockToCount: payload,
      }
    case types.FETCH_CONTAINER_UNLOAD_COUNT_SUCCESS:
      return {
        ...state,
        containerUnloadCount: payload,
      }
    case types.FETCH_MY_IPOD_TASK_COUNT_SUCCESS:
      return {
        ...state,
        myiPodTaskCount: payload,
      }
    case types.FETCH_POWER_PICKING_COUNT_SUCCESS:
      return {
        ...state,
        powerPickingCount: payload,
      }
    case types.FETCH_PUTAWAY_COUNT_SUCCESS:
      return {
        ...state,
        putawayCount: payload,
      }
    case types.FETCH_LOADING_COUNT_SUCCESS:
      return {
        ...state,
        loadingCount: payload,
      }
    case types.FETCH_INVENTORY_COUNT_SUCCESS:
      return {
        ...state,
        inventoryCount: payload,
      }
    default:
      return state
  }
}
