import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import Header from 'components/common/Header'
import {
  fetchOneRestockProcessStart,
  fetchRestockProcessListStart,
  updateRestockLocation,
  updateRestockProcess,
  processStorageToStorageStart,
} from 'store/restockProcess/actions'
import Active from 'assets/svg/active--ipad.svg'
import ActiveGrey from 'assets/svg/active-grey--ipad.svg'
import Scanner from 'components/common/Scanner'
import { closeModal, resetModals, showModal } from 'store/modal/actions'
import Button from 'components/common/Button'
import {
  deleteWarehousePallet,
  updateWarehousePallet,
} from 'store/warehousePallet/actions'
import { isEmptyObject, redirect } from 'helpers/index'
import {
  createWarehouseCaseSync,
  updateWarehouseCase,
} from 'store/warehouseCase/actions'
import { LOCATION_TYPE } from 'constants/locationTypes'
import { RESTOCK_TASK_LIST_PAGE } from 'constants/routes'
import ExclamationSign from 'assets/svg/exclamation.svg'
import { STATUS_COMPLETED } from 'constants/processStatuses'
import Tooltip from 'components/common/Tooltip'
import MoreIcon from 'assets/svg/more-icon.svg'
import Report from 'assets/svg/report.svg'
import { fetchLocationsStart } from 'store/location/actions'
import * as query from 'querystringify'
import { LOCATION_TYPE_FILTER, WAREHOUSE_FILTER } from 'constants/filters'
import { PROCESS_TYPES } from 'constants/process'
import { createDamageCases } from 'store/product/actions'
import { RESTOCK_LOCATION_STATUSES } from 'constants/restockLocationStatus'

const RestockStorageToStoragePage = (props) => {
  const {
    user,
    match,
    currentProcess,
    damageLocation,

    resetModals,
    updateWarehouseCase,
    closeModal,
    updateRestockProcess,
    createWarehouseCase,
    deleteWarehousePallet,
    showModal,
    fetchOneRestockProcess,
    fetchLocations,
    createDamageCases,
    updateRestockLocation,
    processStorageToStorage,
  } = props

  const processId = match.params.id

  const getLocationParamsForFetch = () =>
    query.stringify(
      {
        [WAREHOUSE_FILTER]: user.warehouseId,
        [LOCATION_TYPE_FILTER]: LOCATION_TYPE.DAMAGE_TYPE_ID,
      },
      true,
    )

  const onMount = () => {
    showModal({
      name: 'modalPending',
    })
    fetchOneRestockProcess({
      id: processId,
    })

    fetchLocations(getLocationParamsForFetch())
  }

  const [locationTitle, setLocationTitle] = useState('')
  const [restockLocation, setRestockLocation] = useState({})
  const [isPalletScanned, setIsPalletScanned] = useState(false)
  const [isToLocationScanned, setIsToLocationScanned] = useState(false)
  const [scannedPallet, setScannedPallet] = useState(undefined)

  const [isScanned, setIsScanned] = useState(false)

  const onUpdateCurrentProcess = () => {
    if (!isEmptyObject(currentProcess)) {
      const firstAndSingleRestockLocation =
        currentProcess.entity.restock_location
      setRestockLocation(firstAndSingleRestockLocation)
    }
  }

  const onUpdateRestockLocation = () => {
    if (!isEmptyObject(restockLocation)) {
      const type = isPalletScanned
        ? 'warehouse_location_to'
        : 'warehouse_location_from'

      setLocationTitle(
        [
          restockLocation[type].regular_location.aisle.name,
          restockLocation[type].regular_location.bay.name,
          restockLocation[type].regular_location.level,
        ].join('-'),
      )
    }
  }

  const onUpdate = () => {
    if (locationTitle || damageLocation) {
      closeModal('modalPending')
    }
  }

  const onIsPalletScannedChange = () => {
    if (isPalletScanned) {
      setLocationTitle(
        [
          restockLocation['warehouse_location_to'].regular_location.aisle.name,
          restockLocation['warehouse_location_to'].regular_location.bay.name,
          restockLocation['warehouse_location_to'].regular_location.level,
        ].join('-'),
      )
    }
  }

  useEffect(onMount, [])
  useEffect(onUpdateCurrentProcess, [currentProcess])
  useEffect(onUpdateRestockLocation, [restockLocation])
  useEffect(onUpdate, [locationTitle, damageLocation])
  useEffect(onIsPalletScannedChange, [isPalletScanned])

  if (
    isEmptyObject(currentProcess) ||
    isEmptyObject(restockLocation) ||
    !locationTitle ||
    !damageLocation
  ) {
    return <></>
  }

  // const afterActionUpdateWarehousePallet = (warehouseCase) => {
  //   updateWarehousePallet({
  //     id: scannedPallet.id,
  //     data: {
  //       id: scannedPallet.id,
  //       warehouse_case_id: warehouseCase.id,
  //     },
  //   })
  // }

  const onScanPallet = (barcode) => {
    const warehouseCases =
      restockLocation.warehouse_location_from.warehouse_cases_in_location
    const warehousePallet = restockLocation.warehouse_pallets.find(
      (item) => item.pallet_upc.toString() === barcode.toString(),
    )

    switch (true) {
      case !warehousePallet:
        showModal({
          name: 'modalWarning',
          onAccept: resetModals,
          onDeny: resetModals,
          text: 'Wrong Pallet UPC',
          className: 'modal--tasks',
          modalIcon: (
            <ExclamationSign className="icon--restock icon--exclamation" />
          ),
        })
        break
      case warehousePallet.qty_cases_on_pallet !== restockLocation.quantity:
        showModal({
          name: 'modalWarning',
          onAccept: resetModals,
          onDeny: resetModals,
          text: 'Wrong Case Quantity',
          className: 'modal--tasks',
          modalIcon: (
            <ExclamationSign className="icon--restock icon--exclamation" />
          ),
        })
        break
      case warehousePallet.warehouse_case_id !== warehouseCases.id:
        showModal({
          name: 'modalWarning',
          onAccept: resetModals,
          onDeny: resetModals,
          text: 'Wrong Product',
          className: 'modal--tasks',
          modalIcon: (
            <ExclamationSign className="icon--restock icon--exclamation" />
          ),
        })
        break
      default:
        setIsScanned(true)
        setTimeout(() => {
          setScannedPallet(warehousePallet)
          setIsPalletScanned(true)
          setIsScanned(false)
        }, 1000)
    }
  }

  const onScanToLocation = (barcode) => {
    if (
      restockLocation.warehouse_location_to.upc.toString() !==
      barcode.toString()
    ) {
      showModal({
        name: 'modalWrongLocation',
        onAccept: resetModals,
        text: locationTitle,
      })
      return
    }

    setIsScanned(true)
    setTimeout(() => {
      setIsToLocationScanned(true)
      setIsScanned(false)
    }, 1000)
  }

  const onPushCompleteButton = () => {
    const warehouseCasesTo =
      restockLocation.warehouse_location_to.warehouse_cases_in_location
    const warehouseCaseIdTo = warehouseCasesTo ? warehouseCasesTo.id : null

    processStorageToStorage({
      data: {
        to: {
          warehouse_case_id: warehouseCaseIdTo,
          product_id: restockLocation.product.products_id,
          updated_by_user_id: user.warehouseStaffId,
          created_by_user_id: user.warehouseStaffId,
          location_case_qty: scannedPallet.qty_cases_on_pallet,
          warehouse_location_id: restockLocation.warehouse_location_to.id,
        },
        from: {
          warehouse_case_id:
            restockLocation.warehouse_location_from.warehouse_cases_in_location
              .id,
        },
        warehouse_pallet: {
          id: scannedPallet.id,
        },
        process: {
          id: processId,
          status: STATUS_COMPLETED,
        },
        restock_location: {
          id: restockLocation.id,
          status_id: RESTOCK_LOCATION_STATUSES.COMPLETE,
        },
      },
    })

    redirect(RESTOCK_TASK_LIST_PAGE)
  }

  const onScan = (barcode) => {
    if (!isPalletScanned) {
      onScanPallet(barcode)
    } else if (!isToLocationScanned) {
      onScanToLocation(barcode)
    }
  }

  const onComplete = () => {
    // note (May2021) - this currently only is used when damage is involved. The complete used to fire this, but now rewired. I think later a single "processStorageToStorage" function was created and implemented , so perhaps this onComplete should not be used at all.
    updateRestockProcess({
      id: processId,
      data: {
        id: processId,
        status: STATUS_COMPLETED,
      },
    })

    updateRestockLocation({
      id: restockLocation.id,
      data: {
        id: restockLocation.id,
        status_id: RESTOCK_LOCATION_STATUSES.COMPLETE,
      },
    })

    redirect(RESTOCK_TASK_LIST_PAGE)
  }

  const getBottomContent = () => {
    const statusIcon = isScanned ? <Active /> : <ActiveGrey />
    let content = ''

    switch (true) {
      case isToLocationScanned:
        content = <Button onClick={onPushCompleteButton}>{'Complete'}</Button>
        break
      case isPalletScanned:
        content = <>Scan Location UPC {statusIcon}</>
        break
      case !isPalletScanned:
        content = <>Scan Pallet UPC {statusIcon}</>
        break
      default:
        break
    }

    return (
      <div className="content__section--white">
        <span className="status">{content}</span>
      </div>
    )
  }

  const onFailureScanDamageLocation = () => {
    const damageLocationTitle = `${damageLocation.aisle}-${damageLocation.bay}`

    const onAccept = () => closeModal('modalWrongLocation')

    showModal({
      name: 'modalWrongLocation',
      onAccept: onAccept,
      text: damageLocationTitle,
      className: 'modal--tasks',
    })
  }

  const onSuccessScanDamageLocation = () => {
    const damageCaseInLocation = damageLocation.warehouse_cases_in_location.find(
      (item) => item.product_id === restockLocation.product.products_id,
    )

    if (!damageCaseInLocation) {
      createWarehouseCase({
        request: {
          data: {
            product_id: restockLocation.product.products_id,
            updated_by_user_id: user.warehouseStaffId,
            created_by_user_id: user.warehouseStaffId,
            location_case_qty: scannedPallet.qty_cases_on_pallet,
            warehouse_location_id: damageLocation.id,
          },
        },
      })
    } else {
      updateWarehouseCase({
        id: damageCaseInLocation.id,
        data: {
          id: damageCaseInLocation.id,
          location_case_qty:
            damageCaseInLocation.location_case_qty +
            scannedPallet.qty_cases_on_pallet,
        },
      })

      createDamageCases({
        request: {
          data: {
            process_type: PROCESS_TYPES.RESTOCK_PROCESS_TYPE,
            warehouse_location_id: restockLocation.warehouseLocation.id,
            count_damaged_cases: scannedPallet.qty_cases_on_pallet,
          },
        },
      })
    }

    deleteWarehousePallet({
      id: scannedPallet.id,
      data: {
        id: scannedPallet.id,
      },
    })

    onComplete()
  }

  const showModalScanDamageLocationWithData = () => {
    showModal({
      name: 'modalScanDamageLocation',
      damageLocation: damageLocation,
      onSuccess: onSuccessScanDamageLocation,
      onFailure: onFailureScanDamageLocation,
      onBack: resetModals,
      className: 'modal--tasks modal--damaged',
    })
  }

  const tooltipItems = [
    {
      id: 1,
      icon: <Report className="tooltip__icon" />,
      label: 'Report Case Damage',
      className: `tooltip__item tooltip__item--disabled`,
      onClick: () => {},
    },
    {
      id: 2,
      icon: <Report className="tooltip__icon" />,
      label: 'Report Pallet Damage',
      className: 'tooltip__item',
      onClick: showModalScanDamageLocationWithData,
    },
  ]

  return (
    <div className="tasks-page tasks-page--start-restock page--restock-relocation">
      <Header>
        {isPalletScanned && (
          <Tooltip button={<MoreIcon />}>
            <ul>
              {tooltipItems.map((item) => (
                <li
                  key={item.id}
                  className={item.className}
                  onClick={item.onClick}
                >
                  {item.icon ? item.icon : ''}
                  {item.label}
                </li>
              ))}
            </ul>
            <span className="triangle" />
          </Tooltip>
        )}
      </Header>
      <Scanner needToRemoveListener={false} onReceiveData={onScan} />
      <div className="content">
        <div className="content__section">
          <div className="dock__new-wrapper">
            <div className="dock">
              <span className="dock__label">LOC</span>
              <span className="dock__name">{locationTitle}</span>
            </div>
          </div>
          <h3 className="fireworks-name-new">{restockLocation.product_name}</h3>
          <ul className="fireworks-new">
            <li className="fireworks-new__item">
              <span className="fireworks__name">Model</span>
              <span className="fireworks__sub-name">
                {restockLocation.product.products_model}
              </span>
            </li>
            <li className="fireworks-new__item">
              <span className="fireworks__name">Manf Model</span>
              <span className="fireworks__sub-name">
                {restockLocation.product.products_manf_model}
              </span>
            </li>
          </ul>
        </div>
        {getBottomContent()}
      </div>
    </div>
  )
}

RestockStorageToStoragePage.propTypes = {
  damageLocation: PropTypes.object,
  currentProcess: PropTypes.object,

  fetchRestockProcessList: PropTypes.func,
  resetModals: PropTypes.func,
  updateWarehouseCase: PropTypes.func,
  closeModal: PropTypes.func,
  updateWarehousePallet: PropTypes.func,
  deleteWarehousePallet: PropTypes.func,
  showModal: PropTypes.func,
  fetchOneRestockProcess: PropTypes.func,
  updateRestockProcess: PropTypes.func,
  createWarehouseCase: PropTypes.func,
  fetchLocations: PropTypes.func,
  createDamageCases: PropTypes.func,
  updateRestockLocation: PropTypes.func,
}

const mapStateToProps = (state) => ({
  damageLocation: state.location.list[0],
  currentProcess: state.restockProcess.item,
})

const mapDispatchToProps = {
  fetchLocations: fetchLocationsStart,
  fetchOneRestockProcess: fetchOneRestockProcessStart,
  fetchRestockProcessList: fetchRestockProcessListStart,

  createDamageCases: createDamageCases,
  createWarehouseCase: createWarehouseCaseSync,

  updateWarehouseCase: updateWarehouseCase,
  updateRestockProcess: updateRestockProcess,
  updateWarehousePallet: updateWarehousePallet,
  updateRestockLocation: updateRestockLocation,

  processStorageToStorage: processStorageToStorageStart,

  deleteWarehousePallet: deleteWarehousePallet,

  showModal: showModal,
  closeModal: closeModal,
  resetModals: resetModals,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RestockStorageToStoragePage)
