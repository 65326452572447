import { types } from './actions'
import * as api from 'api/emailer'
import { takeLatest, call } from 'redux-saga/effects'

function* startSendEmailPlog(action) {
  const { payload } = action
  const { request } = payload
  try {
    yield call(api.sendEmailPlogFromFrontend, { data: request })
  } catch (error) {}
}
export default function* emailerSaga() {
  yield takeLatest(types.SEND_EMAIL_PLOG, startSendEmailPlog)
}
