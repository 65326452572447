import React, { useState, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import './style.scss'
import StaffEngagementBlock from './StaffEngagementBlock'
import { TASK_ASSIGNMENT_TYPES } from 'constants/taskAssignment'
import Button from 'components/common/Button'
import Slider from 'react-slick'

const StaffEngagement = ({
  staffAssignment,
  selectedRole,
  onAssign,
  processInfo,
}) => {
  const [staffEngagement, setStaffEngagement] = useState({})

  useEffect(() => {
    let engagement = {}

    TASK_ASSIGNMENT_TYPES.forEach((item) => {
      if (staffAssignment[item.id]) {
        engagement[item.id] = staffAssignment[item.id].filter(
          (staffItem) =>
            staffItem.roles.filter((role) => role.id === selectedRole.value)
              .length > 0,
        )
      }
    })

    setStaffEngagement(engagement)
  }, [selectedRole, staffAssignment])

  const btns = TASK_ASSIGNMENT_TYPES.map((item) =>
    staffEngagement[item.id] && staffEngagement[item.id].length > 0 ? (
      <div className={`control`}>
        <Button>{item.shortName}</Button>
      </div>
    ) : (
      ''
    ),
  ).filter((item) => item !== '')

  return (
    <div className="staff-engagement">
      <Slider
        customPaging={(i) => btns[i]}
        dotsClass="r-col"
        className="l-col"
        infinite={false}
        arrows={false}
        slidesToShow={1}
        slidesToScroll={1}
        dots
        vertical
        swipeToSlide
        verticalSwiping
      >
        {TASK_ASSIGNMENT_TYPES.map((item) => {
          let staff = staffEngagement[item.id]

          return staff && staff.length > 0 ? (
            <StaffEngagementBlock
              key={item.id}
              item={{ ...item, staff }}
              onAssign={onAssign}
              processInfo={processInfo}
            />
          ) : (
            ''
          )
        })}
      </Slider>
    </div>
  )
}

StaffEngagement.propTypes = {
  staffAssignment: PropTypes.object,
  selectedRole: PropTypes.object,
}

export default StaffEngagement
